import * as React from 'react';
import { Collapse } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import TranslateService from 'services/TranslateService';
import { NavLink } from 'react-router-dom';
import * as FontAwesome from 'react-fontawesome';

interface ILasernetPrinterMenuProps {
  isExisted: boolean;
  translateService?: TranslateService;
}

@inject('translateService')
@observer
export default class LasernetPrinterMenu extends React.Component<ILasernetPrinterMenuProps> {
  @observable
  private _isOpen: boolean = false;

  @action
  private _toggleIsOpen = () => {
    this._isOpen = !this._isOpen;
  };

  public render() {
    const {
      isExisted,
      translateService: { t },
    } = this.props;
    return isExisted ? (
      <div data-test="lasernet-printers">
        <div onClick={this._toggleIsOpen} data-test="lasernet-printers-title" className="lasernet-printers">
          <FontAwesome name="print" />
          <span>{t.MENU_ADMIN_LASERNET_PRINTERS}</span>
        </div>

        <Collapse isOpen={this._isOpen} data-test="lasernet-printers-sections">
          <ul className="lasernet-printers-sections">
            <li>
              <NavLink to="/admin/lasernet-printers">{t.MENU_ADMIN_PRINTERS}</NavLink>
            </li>
            <li>
              <NavLink to="/admin/lasernet-document-types">{t.MENU_ADMIN_DOCUMENT_TYPES}</NavLink>
            </li>
            <li>
              <NavLink to="/admin/lasernet-configurations">{t.MENU_ADMIN_CONFIGURATIONS}</NavLink>
            </li>
          </ul>
        </Collapse>
      </div>
    ) : null;
  }
}
