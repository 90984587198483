import { action, computed, observable, observe } from 'mobx';

import DeliveryAdvisedGoodModel, { IDeliveryAdvisedGoodConstructSaveObj } from 'models/DeliveryAdvisedGoodModel';
import FileModel from 'models/FileModel';
import ValidateModel from 'models/ValidateModel';
import OperatorModel from 'models/OperatorModel';
import RoadHaulierModel from 'models/RoadHaulierModel';
import SupplierModel from 'models/SupplierModel';
import { AdvisedGoodStatus, AttachmentType, CountryCode, DeliveryStatus } from 'util/enums';
import IdCodeModel from 'models/IdCodeModel';
import { cloneObj } from 'util/helpers';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import IdCodeNameModel from 'models/IdCodeNameModel';

export interface IDeliveryConstructSaveObj {
  advisedGoods: IDeliveryAdvisedGoodConstructSaveObj[];
  containerNumber: string;
  contractId: string;
  contractNumber: string;
  deliveryNote: string;
  deliveryType: string;
  files: Array<{ id: string }>;
  grossWeight: number;
  id: string;
  grn: string;
  tareWeight: number;
  remarks: string;
  roadHaulierCode: string;
  supplierCode: string;
  traderId: string;
  vehicleRegistrationNumber: string;
  weatherCondition: string;
  originId: string;
  transactionType: string;
}

interface IDeliveryModelValidationKeys {
  advisedGoods: boolean;
  roadHaulier: boolean;
  supplier: boolean;
  vehicleRegistrationNumber: boolean;
  grn: boolean;
  origin: boolean;
  deliveryType: boolean;
  weatherCondition: boolean;
  AGDescription: boolean;
  AGYardLocation: boolean;
  AGStockItem: boolean;
  AGTicketNumber: boolean;
  AGDepartmentCode: boolean;
  AGFirstWeight: boolean;
  AGProductForm: boolean;
  AGProductQuality: boolean;
}

export interface IDeliveryAttachmentsConstructSaveObj {
  attachments: Array<{ id: string }>;
  radiationFiles: Array<{ id: string }>;
}

export type DeliveryValidatorKey = Partial<keyof IDeliveryModelValidationKeys>;

export default class DeliveryModel extends ValidateModel<IDeliveryModelValidationKeys> implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  @observable public hasChanged?: boolean = false;
  @observable public advisedGoods?: DeliveryAdvisedGoodModel[] = [new DeliveryAdvisedGoodModel()];
  @observable public attachments?: FileModel[] = [];
  @observable public containerNumber?: string = '';
  @observable public contractId?: string = null;
  @observable public contractNumber?: string = null;
  @observable public deliveryNote?: string = '';
  @observable public deliveryType?: string = '';
  @observable public flagged?: boolean = false;
  @observable public remarks?: string = '';
  @observable public roadHaulier?: RoadHaulierModel = null;
  @observable public status?: DeliveryStatus = null;
  @observable public supplier?: SupplierModel = null;
  @observable public trader?: IdCodeNameModel = null;
  @observable public vehicleRegistrationNumber?: string = '';
  @observable public weatherCondition?: string = '';
  @observable public grossWeight?: number = null; // DELIVERY GROSS WEIGHT
  @observable public tareWeight?: number = null; // DELIVERY TARE WEIGHT
  @observable public completedBy?: OperatorModel;
  @observable public createdBy?: OperatorModel;
  @observable public syncedBy?: OperatorModel;
  @observable public grn?: string = '';
  @observable public id?: string = null;
  @observable public signedOffBy?: OperatorModel;
  @observable public synced?: boolean = false;
  @observable public origin?: IdCodeModel = null;
  @observable public transactionType?: string;
  @observable public printed?: boolean = false;

  public generalValidatorKeys: Array<keyof Partial<IDeliveryModelValidationKeys>> = [
    'advisedGoods',
    'supplier',
    'vehicleRegistrationNumber',
    'AGDescription',
    'AGYardLocation',
    'AGStockItem',
  ];
  public validationKeysByCountryCode: Map<CountryCode, Array<keyof Partial<IDeliveryModelValidationKeys>>> = new Map<
    CountryCode,
    Array<keyof Partial<IDeliveryModelValidationKeys>>
  >([
    [CountryCode.US, this.generalValidatorKeys.concat(['roadHaulier', 'deliveryType', 'weatherCondition'])],
    [CountryCode.IT, this.generalValidatorKeys.concat(['roadHaulier', 'origin', 'AGFirstWeight', 'AGTicketNumber'])],
    [CountryCode.DE, this.generalValidatorKeys.concat(['grn', 'AGDepartmentCode', 'AGTicketNumber'])],
    [
      CountryCode.DE_D365,
      this.generalValidatorKeys.concat(['grn', 'AGTicketNumber', 'AGProductForm', 'AGProductQuality']),
    ],
    [CountryCode.UK, this.generalValidatorKeys.concat(['roadHaulier', 'AGTicketNumber'])],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  @computed
  public get deliveryNetWeight(): string {
    return this.grossWeight && this.tareWeight ? String(Number(this.grossWeight) - Number(this.tareWeight)) : '-';
  }

  @computed
  public get canBeSynced(): boolean {
    return (
      !!this.deliveryNote &&
      this.advisedGoods.find((ag: DeliveryAdvisedGoodModel) => {
        if (ag.nonAdvisedGood) {
          return !ag.firstWeight;
        }
        return !ag.firstWeight || !ag.secondWeight || !ag.ewcCode;
      }) === undefined
    );
  }

  @computed
  public get canBeSyncedWhenSaved(): boolean {
    return !!this.id && !!this.grossWeight && !!this.tareWeight;
  }

  @computed
  public get canBePrinted(): boolean {
    return this.isEveryAGUnloaded && this.hasEveryAGSecondWeight && !this.synced;
  }

  @computed
  public get canBeExported(): boolean {
    return this.isEveryAGUnloaded && this.hasEveryAGSecondWeightIncludeZero;
  }

  @computed
  public get isEveryAGUnloaded(): boolean {
    const preUnloadStatuses = [AdvisedGoodStatus.WAITING, AdvisedGoodStatus.CLAIMED];
    return !this.advisedGoods.some((ag: DeliveryAdvisedGoodModel) => preUnloadStatuses.includes(ag.status));
  }

  @computed
  public get hasEveryAGSecondWeight(): boolean {
    return this.advisedGoods.every((ag: DeliveryAdvisedGoodModel) => ag.secondWeight || ag.nonAdvisedGood);
  }

  @computed
  public get hasEveryAGSecondWeightIncludeZero(): boolean {
    return this.advisedGoods.every(
      (ag: DeliveryAdvisedGoodModel) => typeof ag.secondWeight === 'number' || ag.nonAdvisedGood
    );
  }

  @computed
  public get isDirty() {
    return this.hasChanged || this.advisedGoods.some((x) => x.isDirty);
  }

  @computed
  public get validators(): IDeliveryModelValidationKeys {
    return {
      advisedGoods: this.advisedGoods.length !== 0,
      roadHaulier: this.roadHaulier !== null,
      supplier: this.supplier !== null,
      vehicleRegistrationNumber: !!this.vehicleRegistrationNumber && this.vehicleRegistrationNumber !== '',
      grn: !!this.grn && this.grn !== '',
      origin: this.origin !== null, // for IT
      deliveryType: !!this.deliveryType && this.deliveryType !== '', // for US
      weatherCondition: !!this.weatherCondition && this.weatherCondition !== '', // for US
      AGDescription: this.advisedGoods.every((ag) => ag.validators.advisedDescription),
      AGYardLocation: this.advisedGoods.every((ag) => ag.validators.yardLocation),
      AGStockItem: this.advisedGoods.every((ag) => ag.validators.stockItem),
      AGTicketNumber: this.advisedGoods.every((ag) => ag.validators.ticketNumber),
      AGDepartmentCode: this.advisedGoods.every((ag) => ag.validators.departmentCode), // for DE
      AGFirstWeight: this.advisedGoods.every((ag) => ag.validators.firstWeight), // for IT
      AGProductForm: this.advisedGoods.every((ag) => ag.validators.productForm), // for DE_D365
      AGProductQuality: this.advisedGoods.every((ag) => ag.validators.productQuality), // for DE_D365
    };
  }

  @computed
  public get getAttachmentsIds() {
    return this.attachments
      .filter((item: FileModel) => item.type === AttachmentType.DELIVERY_ATTACHMENT)
      .map((file: FileModel) => ({ id: file.id }));
  }

  @computed
  public get getRadiationFilesIds() {
    return this.attachments
      .filter((item: FileModel) => item.type === AttachmentType.DELIVERY_RADIATION)
      .map((file: FileModel) => ({ id: file.id }));
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public update = (obj: DeliveryModel) => {
    const newDeliveryModel = cloneObj(obj);

    if (newDeliveryModel) {
      newDeliveryModel.advisedGoods = newDeliveryModel.advisedGoods.map((r: DeliveryAdvisedGoodModel) =>
        new DeliveryAdvisedGoodModel().update(r)
      );
      if (newDeliveryModel.attachments && newDeliveryModel.attachments.length !== 0) {
        newDeliveryModel.attachments = newDeliveryModel.attachments.map((r: FileModel) => new FileModel().update(r));
      }
      if (newDeliveryModel.supplier) {
        newDeliveryModel.supplier = new SupplierModel().update(newDeliveryModel.supplier);
      }
      if (newDeliveryModel.createdBy) {
        newDeliveryModel.createdBy = new OperatorModel().update(newDeliveryModel.createdBy);
      }
      if (newDeliveryModel.signedOffBy) {
        newDeliveryModel.signedOffBy = new OperatorModel().update(newDeliveryModel.signedOffBy);
      }
      if (newDeliveryModel.syncedBy) {
        newDeliveryModel.syncedBy = new OperatorModel().update(newDeliveryModel.syncedBy);
      }
      if (newDeliveryModel.completedBy) {
        newDeliveryModel.completedBy = new OperatorModel().update(newDeliveryModel.completedBy);
      }
    }

    this.updater.update(this, newDeliveryModel, DeliveryModel);

    return this;
  };

  @action public changeAttachments = (newAttachments: FileModel[] | void) => {
    if (newAttachments) {
      this.attachments.push(...newAttachments);
    }
  };

  @action
  public pushNewAdvisedGood() {
    this.advisedGoods.push(new DeliveryAdvisedGoodModel());
  }

  @action
  public removeAdvisedGood(ag: DeliveryAdvisedGoodModel) {
    this.advisedGoods = this.advisedGoods.filter((item) => item !== ag);
  }

  @action
  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att) => att.id !== attachmentId);
  }

  @action
  public setContainerNumber(val: string) {
    this.containerNumber = val;
  }

  @action
  public setDeliveryNote(val: string) {
    this.deliveryNote = val;
  }

  @action
  public setRemarks(val: string) {
    this.remarks = val;
  }

  @action
  public setRoadHaulier(item: RoadHaulierModel) {
    this.roadHaulier = new RoadHaulierModel().update(item);
  }

  @action
  public setTrader(item: IdCodeNameModel) {
    this.trader = new IdCodeNameModel().update(item);
  }

  @action
  public setOrigin(item: IdCodeModel) {
    this.origin = new IdCodeModel().update(item);
  }

  @action
  public setSupplier(item: SupplierModel) {
    this.supplier = new SupplierModel().update(item);
  }

  @action
  public setVehicleRegistrationNumber(val: string) {
    this.vehicleRegistrationNumber = val;
  }

  @action
  public setWeatherCondition(val: string) {
    this.weatherCondition = val;
  }

  @action
  public setDeliveryType(val: string) {
    this.deliveryType = val;
  }

  @action
  public setGrossWeight(val: number) {
    this.grossWeight = val;
  }

  @action
  public setTareWeight(val: number) {
    this.tareWeight = val;
  }

  // this method uses only for test purposes Germany workflow.
  @action
  public setGRN(newGRN: string) {
    this.grn = newGRN;
  }

  @action
  public setContractNumber(val: string) {
    this.contractNumber = val;
  }

  @action
  public setContractId(val: string) {
    this.contractId = val;
  }

  @action
  public setTransactionType(val: string) {
    this.transactionType = val;
  }

  @action
  public clearContract() {
    this.contractId = null;
    this.contractNumber = null;
  }

  @action
  public constructSaveAttachmentsObj(): IDeliveryAttachmentsConstructSaveObj {
    return {
      attachments: this.getAttachmentsIds,
      radiationFiles: this.getRadiationFilesIds,
    };
  }

  // CONSTRUCT OBJ FOR POST/PUT DELIVERY
  @action
  public constructSaveObj(): IDeliveryConstructSaveObj {
    return {
      advisedGoods: this.advisedGoods.map((ag) => ag.constructSaveObj()),
      containerNumber: this.containerNumber,
      contractId: this.contractId,
      contractNumber: this.contractNumber,
      deliveryNote: this.deliveryNote,
      deliveryType: this.deliveryType ? this.deliveryType : null,
      files: this.attachments.map((file: FileModel) => ({ id: file.id })),
      grossWeight: this.grossWeight,
      id: this.id,
      grn: this.grn ? this.grn : null,
      tareWeight: this.tareWeight,
      remarks: this.remarks,
      roadHaulierCode: this.roadHaulier ? this.roadHaulier.code : '',
      supplierCode: this.supplier ? this.supplier.code : '',
      traderId: this.trader ? this.trader.id : '',
      vehicleRegistrationNumber: this.vehicleRegistrationNumber,
      weatherCondition: this.weatherCondition ? this.weatherCondition : null,
      originId: this.origin ? this.origin.id : '',
      transactionType: this.transactionType ? this.transactionType : null,
    };
  }
}
