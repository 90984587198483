import { observable } from 'mobx';
import LabAnalysisModel from 'models/LabAnalysisModel';
import PrinterModel from 'models/PrinterModel';
import RootService from 'services/RootService';
import UploadAndDownloadAttachmentsAbstractStore from 'stores/UploadAndDownloadAttachmentsAbstractStore';

export default class AdvisedGoodLabStore extends UploadAndDownloadAttachmentsAbstractStore {
  @observable public printers: PrinterModel[];

  public constructor(protected readonly rootService: RootService) {
    super(rootService);
  }

  public urlSection: string = 'lab-analysis';

  public getLabAdvisedGoodsById(advisedGoodsId: string) {
    return this.rootService.ajaxService.get(`lab/advised-goods/${advisedGoodsId}`);
  }

  /* BOF LAB ANALYSIS */
  public putLabAnalysis(labAnalysis: LabAnalysisModel) {
    return this.rootService.ajaxService.put(`lab-analysis/${labAnalysis.id}`, labAnalysis.saveObject());
  }

  public putLabAnalysisStatus(labAnalysisId: string, newLocationId: string, newStatusId: string) {
    return this.rootService.ajaxService.put(`lab-analysis/${labAnalysisId}/status`, {
      locationId: newLocationId,
      statusId: newStatusId,
    });
  }

  public deleteLabAnalysis(labAnalysisId: string) {
    return this.rootService.ajaxService.delete(`lab-analysis/${labAnalysisId}`);
  }
}
