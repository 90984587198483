import { action, observable, observe, computed } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import LabAnalysisModel from 'models/LabAnalysisModel';
import LabStatsModel from 'models/LabStatsModel';
import { cloneObj } from 'util/helpers';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';
import MaterialModel from 'models/MaterialModel';

export default class ReceivedGoodsLabModel extends UpdateModel<ReceivedGoodsLabModel> implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  @observable public description: string;
  @observable public id: string;
  @observable public labAnalysis: LabAnalysisModel[];
  @observable public supplier: string;
  @observable public agTitle: string;
  @observable public supplierCode: string;
  @observable public stats: LabStatsModel[];

  @computed public get isDirty() {
    return this.hasChanged || (this.labAnalysis && this.labAnalysis.some((x) => x.hasChanged));
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action public update = (
    obj: ReceivedGoodsLabModel,
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) => {
    const newReceivedGoodsLabModel = cloneObj(obj);
    if (newReceivedGoodsLabModel) {
      newReceivedGoodsLabModel.labAnalysis = newReceivedGoodsLabModel.labAnalysis.map((la) =>
        new LabAnalysisModel().update(la, defaultMaterials, nonDefaultMaterials)
      );
    }

    this.updater.update(this, newReceivedGoodsLabModel, ReceivedGoodsLabModel);

    return this;
  };
}
