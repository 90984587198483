import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { getTranslation } from 'util/helpers';

import LabAnalysisModel from 'models/LabAnalysisModel';
import LabStatusBoxComponent from 'pod/advisedGoodLab/LabStatusBoxComponent';
import LabStatusModel from 'models/LabStatusModel';
import PrinterModel from 'models/PrinterModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import { I18N } from '../../../assets/i18n/i18n';
import * as classnames from 'classnames';
import { SIGNED_OFF_VALUE } from 'util/constants';
import AjaxService from 'services/AjaxService';
import ViewStore from 'stores/ViewStore';
import InputWithNullCheck from 'components/InputWithNullCheck';
import MaterialsComponent from 'components/MaterialsComponent';
import CommonStore from 'stores/CommonStore';
import LabMeasurementComponent from 'pod/advisedGoodLab/LabMeasurementComponent';
import PicturesComponent from 'components/PicturesComponent';
import AdvisedGoodLabStore from 'pod/advisedGoodLab/AdvisedGoodLabStore';

interface IProps {
  labAnalysisStatusPut?: (labAnalysis: LabAnalysisModel, newLocationId: string, newStatusId: string) => void;
  labInput: LabAnalysisModel;
  labStatuses: LabStatusModel[];
  printers: PrinterModel[];
  receivedGood: ReceivedGoodsLabModel;
  changePrintImageUrl: (newImage: string) => void;
  openPrintImageModal: () => void;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  ajaxService?: AjaxService;
  viewStore?: ViewStore;
  commonStore?: CommonStore;
  advisedGoodLabStore?: AdvisedGoodLabStore;
}

@inject('sessionStore', 'translateService', 'ajaxService', 'viewStore', 'commonStore', 'advisedGoodLabStore')
@observer
export default class LabAnalysisComponent extends React.Component<IProps> {
  // UPDATE LAB ANALYSIS STATUS
  public changeLabAnalysisStatus = (newLocationId: string, newStatusId: string) => {
    const { labInput } = this.props;
    this.props.labAnalysisStatusPut(labInput, newLocationId, newStatusId);
  };

  public render() {
    const {
      labInput,
      receivedGood,
      printers,
      labStatuses,
      sessionStore: { isLab },
      translateService: { t },
      viewStore: { isLabFromDE, isLabFromUS, isLabFromDE_D365 },
      commonStore: { nonDefaultMaterial },
      advisedGoodLabStore,
    } = this.props;

    return (
      <div>
        <h5 className="mt-4 clearfix">
          {t.GLOBAL_LABEL_STATUS}
          <div className="float-right">
            {getTranslation(`STATUS_LABINPUT_${labInput.labInputType.name.toUpperCase()}` as keyof I18N, t)}
          </div>
        </h5>
        <Row className="bg-white border-top border-bottom">
          <Col xs={12}>
            <div className="lab-status-component clearfix">
              {labStatuses &&
                labStatuses.map((labStatus) => (
                  <LabStatusBoxComponent
                    key={labStatus.id}
                    labAnalysisStatusPut={this.changeLabAnalysisStatus}
                    labInputTracking={labInput.tracking}
                    labStatus={labStatus}
                  />
                ))}
            </div>
          </Col>
        </Row>

        <LabMeasurementComponent
          labInput={labInput}
          receivedGood={receivedGood}
          printers={printers}
          getPrintLabImage={this._getPrintLabImage}
        />

        <PicturesComponent
          data-test="lab-pictures"
          isReadOnly={false}
          content={labInput}
          uploadAttachment={advisedGoodLabStore.uploadAttachment}
          uploadAttachments={advisedGoodLabStore.uploadAttachments}
          downloadAttachments={advisedGoodLabStore.downloadAttachments}
          fileAccept="image/*, .pdf"
          title={t.GLOBAL_LABEL_ATTACHMENTS}
        />

        {isLabFromUS && (
          <MaterialsComponent
            isReadOnly={false}
            materials={labInput.spectroAnalysis.items}
            nonDefaultMaterial={nonDefaultMaterial}
            headerTitle={t.LABORATORY_ANALISYS_SPECTRO_ANALYSIS}
            data-test="lab-spectro-analysis"
          />
        )}

        {(isLabFromDE || isLabFromDE_D365) && (
          <React.Fragment>
            <h5 className="mt-4">{t.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE}</h5>
            <Row className="bg-white border-top border-bottom">
              <Col className="pt-2 pb-2">
                <InputWithNullCheck
                  data-test="stock-code-input"
                  className={classnames(['width-150', { 'border-danger': !this._isStockCodeValid }])}
                  value={labInput.stockCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => labInput.setStockCode(e.target.value)}
                  disabled={labInput.deliveryStatus === SIGNED_OFF_VALUE}
                  id="stockCode"
                />
              </Col>
            </Row>
          </React.Fragment>
        )}

        <h5 className="mt-4">{t.GLOBAL_LABEL_REMARKS}</h5>
        <Row className="bg-white border-top border-bottom">
          <Col className="pt-2 pb-2">
            <InputWithNullCheck
              value={labInput.remarks}
              onChange={(e: React.FormEvent<HTMLInputElement>) => labInput.setRemarks(e.currentTarget.value)}
              disabled={!isLab}
              id="remarks"
              type="textarea"
            />
          </Col>
        </Row>
      </div>
    );
  }

  private get _isStockCodeValid() {
    const {
      labInput,
      viewStore: { stockCodeFormat },
    } = this.props;

    return new RegExp(stockCodeFormat).test(labInput.stockCode);
  }

  private _getPrintLabImage = () => {
    const { openPrintImageModal, changePrintImageUrl } = this.props;

    changePrintImageUrl(this._createImageUrl);
    openPrintImageModal();
  };

  private get _createImageUrl() {
    const {
      labInput: { id: labInputId },
      ajaxService,
    } = this.props;
    const url = `lab-analysis/${labInputId}/print-lab.png`;
    return ajaxService.download(url);
  }
}
