import { action, computed, observable } from 'mobx';
import UserModel from 'models/UserModel';
import WorkflowModel from 'models/WorkflowModel';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import IdNameModel from 'models/IdNameModel';
import SharepointModel from 'models/SharepointModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export interface ITenantSaveObj {
  code: string;
  currentSequenceValue: number | string;
  currentLabSequenceValue: number | string;
  grnPrefix: string;
  locationCode: string;
  name: string;
  workflowId: string;
  timezone: string;
  regionCode: string;
  agSignOffEnabled: boolean;
  balanceDeductionEnabled: boolean;
  integrationApiBase?: string;
  units?: string;
  legalEntityId?: string;
  site?: string;
  sharepointUploadFolderId?: string;
  deliveryAttachmentsRequired?: boolean;
  agAttachmentsRequired?: boolean;
  rgClassificationsSectionRequired?: boolean;
  rgContaminationsSectionRequired?: boolean;
  overwriteRgDescriptionDialogEnabled?: boolean;
}

export default class TenantModel extends UpdateModel<TenantModel> {
  @observable public admins?: UserModel<IUserModelConstructObj>[] = [];
  @observable public code?: string = '';
  @observable public site?: string = '';
  @observable public grnPrefix?: string = '';
  @observable public workflowId?: string = '';
  @observable public locationCode?: string = '';
  @observable public name?: string = '';
  @observable public currentSequenceValue?: number = null;
  @observable public currentLabSequenceValue?: number = null;
  @observable public workflow?: WorkflowModel = new WorkflowModel();
  @observable public id?: string;
  @observable public createdAt?: string;
  @observable public timezone?: string;
  @observable public regionCode?: string = '';
  @observable public integrationApiBase?: string = '';
  @observable public units?: string = '';
  @observable public legalEntity?: IdNameModel = null;
  @observable public isDeliveryCreated?: boolean = false;
  @observable public sharepointUploadFolder?: SharepointModel = new SharepointModel();
  @observable public agSignOffEnabled?: boolean = false;
  @observable public balanceDeductionEnabled?: boolean = true;
  @observable public deliveryAttachmentsRequired?: boolean = false;
  @observable public agAttachmentsRequired?: boolean = false;
  @observable public rgClassificationsSectionRequired?: boolean = true;
  @observable public rgContaminationsSectionRequired?: boolean = true;
  @observable public overwriteRgDescriptionDialogEnabled?: boolean = false;

  @computed
  public get constructSaveObj(): ITenantSaveObj {
    const result: ITenantSaveObj = {
      code: this.code,
      currentSequenceValue: this.currentSequenceValue,
      currentLabSequenceValue: this.currentLabSequenceValue,
      grnPrefix: this.grnPrefix,
      locationCode: this.locationCode,
      name: this.name,
      workflowId: this.workflowId,
      timezone: this.timezone,
      regionCode: this.regionCode,
      integrationApiBase: this.integrationApiBase,
      agSignOffEnabled: this.agSignOffEnabled,
      balanceDeductionEnabled: this.balanceDeductionEnabled,
      deliveryAttachmentsRequired: this.deliveryAttachmentsRequired,
      agAttachmentsRequired: this.agAttachmentsRequired,
      rgClassificationsSectionRequired: this.rgClassificationsSectionRequired,
      rgContaminationsSectionRequired: this.rgContaminationsSectionRequired,
      overwriteRgDescriptionDialogEnabled: this.overwriteRgDescriptionDialogEnabled,
    };
    if (this.units) {
      result.units = this.units;
    }
    if (this.legalEntity) {
      result.legalEntityId = this.legalEntity.id;
    }
    if (this.site) {
      result.site = this.site;
    }
    if (this.sharepointUploadFolder.id) {
      result.sharepointUploadFolderId = this.sharepointUploadFolder.id;
    }
    return result;
  }

  @computed
  public get isNeedCheckDeliveryAttachments(): boolean {
    return this.deliveryAttachmentsRequired;
  }

  @computed
  public get isNeedCheckAGAttachments(): boolean {
    return this.agAttachmentsRequired;
  }

  @action
  public update = (obj: TenantModel) => {
    const newTenantModel = cloneObj(obj);

    if (newTenantModel) {
      if (newTenantModel.admins && newTenantModel.admins.length !== 0) {
        newTenantModel.admins = newTenantModel.admins.map((admin) =>
          new UserModel<IUserModelConstructObj>().update(admin)
        );
      }
      newTenantModel.workflow = newTenantModel.workflow
        ? new WorkflowModel().update(newTenantModel.workflow)
        : new WorkflowModel();
      newTenantModel.workflowId = newTenantModel.workflow.id || '';
      newTenantModel.sharepointUploadFolder = !!newTenantModel.sharepointUploadFolder
        ? new SharepointModel().update(newTenantModel.sharepointUploadFolder)
        : new SharepointModel();
    }
    this.updater.update(this, newTenantModel, TenantModel);
    return this;
  };

  @action
  public pushAdmin(newAdmin: UserModel<IUserModelConstructObj>) {
    this.admins.push(new UserModel<IUserModelConstructObj>().update(newAdmin));
  }

  @action
  public removeAdmin(user: UserModel<IUserModelConstructObj>) {
    this.admins = this.admins.filter((a) => a.id !== user.id);
  }

  @action
  public setCode(val: string) {
    this.code = val;
  }

  @action
  public setSite(val: string) {
    this.site = val;
  }

  @action
  public setLocationCode(val: string) {
    this.locationCode = val;
  }

  @action
  public setGrnPrefix(val: string) {
    this.grnPrefix = val;
  }

  @action
  public setCurrentSequenceValue(val: number) {
    this.currentSequenceValue = val;
  }

  @action
  public setCurrentLabSequenceValue(val: number) {
    this.currentLabSequenceValue = val;
  }

  @action
  public setName(val: string) {
    this.name = val;
  }

  @action
  public setWorkflowId(val: string) {
    this.workflowId = val;
  }

  @action
  public setTimezone(val: string) {
    this.timezone = val;
  }

  @action
  public setRegionCode(val: string) {
    this.regionCode = val;
  }

  @action
  public setIntegrationApiBase(val: string) {
    this.integrationApiBase = val;
  }

  @action
  public setUnits(val: string) {
    this.units = val;
  }

  @action
  public setLegalEntity(val: IdNameModel) {
    this.legalEntity = val;
  }

  @action
  public setIsDeliveryCreated(val: boolean) {
    this.isDeliveryCreated = val;
  }

  @action
  public setSharepointUploadFolder(item: SharepointModel) {
    this.sharepointUploadFolder = new SharepointModel().update(item);
  }

  @action
  public setAgSignOffEnabled(val: boolean) {
    this.agSignOffEnabled = val;
  }

  @action
  public setBalanceDeductionEnabled(val: boolean) {
    this.balanceDeductionEnabled = val;
  }

  @action
  public setDeliveryAttachmentsRequired(val: boolean) {
    this.deliveryAttachmentsRequired = val;
  }

  @action
  public setAgAttachmentsRequired(val: boolean) {
    this.agAttachmentsRequired = val;
  }

  @action
  public setClassificationSectionRequired(val: boolean) {
    this.rgClassificationsSectionRequired = val;
  }

  @action
  public setContaminationSectionRequired(val: boolean) {
    this.rgContaminationsSectionRequired = val;
  }

  @action
  public setOverwriteRgDescriptionDialogEnabled(val: boolean) {
    this.overwriteRgDescriptionDialogEnabled = val;
  }
}
