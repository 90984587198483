import { computed, observable } from 'mobx';
import SupplierModel from 'models/SupplierModel';

export default abstract class SupplierInfo {
  @observable public supplier?: SupplierModel;
  @observable public supplierCity?: string;

  @computed
  public get supplierInfo(): string {
    const city = this.supplierCity ? `, ${this.supplierCity}` : '';
    return `${this.supplier.name}${city}`;
  }
}
