import { action, computed, observable, observe } from 'mobx';

import UpdateModel from 'models/UpdateModel';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';
import { CountryCode } from '../util/enums';

export interface IMeasurementModelSaveObj {
  id: string;
  sampleWet: number;
  sampleDry: number;
  container: number;
  forMelting: number;
  ingot: number;
}

export default class MeasurementModel extends UpdateModel<MeasurementModel> implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  @observable public id?: string = null;
  @observable public sampleWet?: number = null;
  @observable public container?: number = null;
  @observable public sampleDry?: number = null;
  @observable public forMelting?: number = null;
  @observable public ingot?: number = null;

  @computed
  public get humidity() {
    return this.sampleWet && this.sampleDry && (1 - this.sampleDry / this.sampleWet) * 100;
  }

  @computed
  public get humidityWithTare() {
    return (
      this.sampleWet && this.sampleDry && ((this.sampleWet - this.sampleDry) / (this.sampleWet - this.container)) * 100
    );
  }

  @computed
  public get yield() {
    return this.forMelting && this.ingot && (this.ingot / this.forMelting) * 100;
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public setSampleWet = (val: number) => {
    this.sampleWet = val;
  };

  @action
  public setContainer = (val: number) => {
    this.container = val;
  };

  @action
  public setSampleDry = (val: number) => {
    this.sampleDry = val;
  };

  @action
  public setForMelting = (val: number) => {
    this.forMelting = val;
  };

  @action
  public setIngot = (val: number) => {
    this.ingot = val;
  };

  @action
  public update = (obj: MeasurementModel) => {
    this.updater.update(this, obj, MeasurementModel);

    return this;
  };

  @action
  public constructSaveObj?(): IMeasurementModelSaveObj {
    return this._noValues
      ? null
      : {
          id: this.id,
          sampleWet: this.sampleWet,
          sampleDry: this.sampleDry,
          container: this.container,
          forMelting: this.forMelting,
          ingot: this.ingot,
        };
  }

  private get _noValues(): boolean {
    return !(this.sampleWet || this.sampleDry || this.forMelting || this.ingot);
  }
}
