import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { Button, Col, Container, FormGroup, InputGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import TenantModel from 'models/TenantModel';
import TenantAdminLineComponent from 'pod/superAdmin/tenants/TenantAdminLineComponent';
import UserModel from 'models/UserModel';
import TranslateService from 'services/TranslateService';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import { InputType } from 'reactstrap/lib/Input';
import IdNameModel from 'models/IdNameModel';
import InputNumber from 'components/InputNumber';
import Autocomplete from 'domain/Autocomplete';
import * as moment from 'moment-timezone';
import InputWithNullCheck from 'components/InputWithNullCheck';
import WorkflowModel from 'models/WorkflowModel';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';
import { sortActiveFirst } from 'util/helpers';
import AdminDetailComponent from 'pod/superAdmin/admins/AdminDetailComponent';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import { CountryCode } from 'util/enums';
import UserTenantModel from 'models/UserTenantModel';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import SharepointModel from 'models/SharepointModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  tenant: TenantModel;
  workflowList: WorkflowModel[];
  saveAction: (tenant: TenantModel) => void;
  toggleAddTenantModal?: () => void; // FUNCTION TO CLOSE MODAL WINDOW
  saveAdmin?: (user: UserModel<IUserModelConstructObj>) => Promise<void>;
  postAdmin?: (user: UserModel<IUserModelConstructObj>) => Promise<void>;
  hideAdmin?: (user: UserModel<IUserModelConstructObj>) => Promise<void>;
  unhideAdmin?: (user: UserModel<IUserModelConstructObj>) => Promise<void>;
  translateService?: TranslateService;
  workflowTenants?: UserTenantModel[];
  superAdminStore?: SuperAdminStore;
}

interface IDocument {
  documentMode?: boolean;
}

@inject('translateService', 'superAdminStore')
@observer
export default class TenantDetailComponent extends React.Component<IProps> {
  @observable
  public admin: UserModel<IUserModelConstructObj> = new UserModel<IUserModelConstructObj>();

  @observable
  public isModalOpen: boolean = false;

  private _typeCurrentGRNInput = 'number' as InputType;

  private _typeCurrentLabSequenceInput = 'number' as InputType;

  public componentDidMount() {
    if ((document as IDocument).documentMode) {
      this._typeCurrentGRNInput = 'text' as InputType;
      this._typeCurrentLabSequenceInput = 'text' as InputType;
    }
  }

  @action
  public clearAdmin = () => (this.admin = new UserModel<IUserModelConstructObj>());

  public saveAdminAndCloseModal = (user: UserModel<IUserModelConstructObj>) => {
    this.props.postAdmin(user).then(() => {
      this.clearAdmin();
      this._toggleIsModalOpen();
    });
  };

  public render() {
    const {
      toggleAddTenantModal,
      tenant,
      workflowList,
      translateService: { t },
      workflowTenants,
      superAdminStore: { tenantUnits, legalEntities, sharePointSiteList },
    } = this.props;
    const selectedWorkflow = this._selectedWorkflow;

    return (
      <div>
        <FormGroup row className="remaining-padding">
          <Label for="name" sm={4}>
            {t.SUPERADMIN_TENANTADMIN_TENANT}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="name"
              id="name"
              maxLength={50}
              onChange={this._changeName}
              required
              type="text"
              value={tenant.name}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="code" sm={4}>
            {t.GLOBAL_LABEL_CODE}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="code"
              id="code"
              maxLength={50}
              onChange={this._changeCode}
              required
              type="text"
              value={tenant.code}
            />
          </Col>
        </FormGroup>
        {this._isDE_D365Selected && (
          <FormGroup row className="remaining-padding">
            <Label for="site" sm={4}>
              {t.SUPERADMIN_TENANTDETAIL_SITE}
            </Label>
            <Col sm={8}>
              <InputWithLimitOfCharacters
                data-test="site"
                id="site"
                maxLength={50}
                onChange={this._changeSite}
                required
                type="text"
                value={tenant.site}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup row className="remaining-padding">
          <Label for="locationCode" sm={4}>
            {t.SUPERADMIN_TENANTADMIN_LOCATION_CODE}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="location-code"
              id="locationCode"
              maxLength={50}
              onChange={this._changeLocationCode}
              required
              type="text"
              value={tenant.locationCode}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="regionCode" sm={4}>
            {t.SUPERADMIN_TENANTADMIN_REGION_CODE}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="region-code"
              id="regionCode"
              maxLength={10}
              onChange={this._changeRegionCode}
              required
              type="text"
              value={tenant.regionCode}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="grnprefix" sm={4}>
            {t.SUPERADMIN_TENANTLIST_GRN}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="grn-prefix"
              id="grnprefix"
              maxLength={50}
              onChange={this._changeGrnPrefix}
              required
              type="text"
              value={tenant.grnPrefix}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="current-seq-value" sm={4}>
            {t.SUPERADMIN_TENANTADMIN_GRN_VALUE}
          </Label>
          <Col sm={8}>
            <InputNumber
              data-test="grn"
              id="current-seq-value"
              disabled={tenant.isDeliveryCreated}
              decimalPlaces={0}
              onChangeValue={this._changeCurrentGRN}
              required={tenant.id == null}
              type={this._typeCurrentGRNInput}
              value={tenant.currentSequenceValue}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label sm={4}>{t.SUPERADMIN_TENANTADMIN_TIMEZONE}</Label>
          <Col sm={8}>
            <Autocomplete
              data-test="timezone"
              disabled={false}
              placeholder={t.GLOBAL_LABEL_SELECT}
              items={this._getTimezones}
              onSelect={this._changeTimezone}
              selectedValue={tenant.timezone}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="workflow" sm={4}>
            {t.SUPERADMIN_TENANTADMIN_WORKFLOW}
          </Label>
          <Col sm={8}>
            <InputGroup size="md" className="full-width">
              <InputWithNullCheck
                data-test="workflow"
                type="select"
                id="workflow"
                value={tenant.workflowId}
                onChange={this._changeWorkflow}
              >
                <option value="" disabled>
                  {t.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW}
                </option>
                {workflowList &&
                  workflowList.map((workflow: WorkflowModel) => (
                    <option key={workflow.id} value={workflow.id}>
                      {workflow.code}
                    </option>
                  ))}
              </InputWithNullCheck>
            </InputGroup>
          </Col>
        </FormGroup>
        {this._isWeightUnitsLegalEntitiesShown && (
          <React.Fragment>
            <FormGroup row className="remaining-padding">
              <Label for="legal-entity" sm={4}>
                {t.GLOBAL_LABEL_LEGAL_ENTITY}
              </Label>
              <Col sm={8}>
                <InputGroup size="md" className="full-width">
                  <InputWithNullCheck
                    data-test="legal-entity"
                    type="select"
                    id="legal-entity"
                    value={tenant.legalEntity?.id || ''}
                    disabled={tenant.isDeliveryCreated}
                    onChange={this._changeLegalEntity}
                  >
                    <option value="" disabled />
                    {legalEntities &&
                      legalEntities.map((entity: IdNameModel) => (
                        <option key={entity.id} value={entity.id}>
                          {entity.name}
                        </option>
                      ))}
                  </InputWithNullCheck>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row className="remaining-padding">
              <Label for="unit" sm={4}>
                {t.GLOBAL_LABEL_WEIGHT_UNITS}
              </Label>
              <Col sm={8}>
                <InputGroup size="md" className="full-width">
                  <InputWithNullCheck
                    data-test="units"
                    type="select"
                    id="unit"
                    value={tenant.units}
                    onChange={this._changeUnit}
                  >
                    <option value="" disabled />
                    {tenantUnits &&
                      tenantUnits.map((unit: string) => (
                        <option key={unit} value={unit}>
                          {unit}
                        </option>
                      ))}
                  </InputWithNullCheck>
                </InputGroup>
              </Col>
            </FormGroup>
          </React.Fragment>
        )}
        {selectedWorkflow && (selectedWorkflow.isDE || selectedWorkflow.isDE_D365) && (
          <FormGroup row className="remaining-padding">
            <Label for="integrationApiBase" sm={4}>
              {t.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE}
            </Label>
            <Col sm={8}>
              <InputWithLimitOfCharacters
                data-test="integration-api-base"
                id="integrationApiBase"
                maxLength={100}
                onChange={this._changeIntegrationApiBase}
                type="text"
                value={tenant.integrationApiBase}
              />
            </Col>
          </FormGroup>
        )}
        {selectedWorkflow && selectedWorkflow.isIT && (
          <FormGroup row className="remaining-padding">
            <Label for="current-seq-value" sm={4}>
              {t.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE}
            </Label>
            <Col sm={8}>
              <InputNumber
                data-test="lab-sequence"
                id="current-lab-sequence-value"
                disabled={tenant.id != null}
                decimalPlaces={0}
                onChangeValue={this._changeCurrentLabSequence}
                required={tenant.id == null}
                type={this._typeCurrentLabSequenceInput}
                value={tenant.currentLabSequenceValue}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup row className="remaining-padding">
          <Label for="unit" sm={4}>
            {t.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS}
          </Label>
          <Col sm={8}>
            <InputGroup size="md" className="full-width align-items-center full-height">
              <Label className="padding-left-30" key="deliveryAttachmentsRequired-label">
                <InputWithNullCheck
                  data-test="delivery-attachments-required"
                  key="deliveryAttachmentsRequired"
                  type="checkbox"
                  checked={tenant.deliveryAttachmentsRequired}
                  onChange={this._changeDeliveryAttachmentsRequired}
                />
                {t.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL}
              </Label>
              <Label className="padding-left-30" key="agAttachmentsRequired-label">
                <InputWithNullCheck
                  data-test="ag-attachments-required"
                  key="agAttachmentsRequired"
                  type="checkbox"
                  checked={tenant.agAttachmentsRequired}
                  onChange={this._changeAgAttachmentsRequired}
                />
                {t.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL}
              </Label>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="ag-sign-off-enable" sm={4}>
            {t.GLOBAL_LABEL_PARTIAL_SIGN_OFF}
          </Label>
          <Col sm={8}>
            <Label className="padding-left-right-20">
              <InputWithNullCheck
                data-test="ag-sign-off-enable"
                type="checkbox"
                checked={tenant.agSignOffEnabled}
                onChange={this._changAagSignOffEnabled}
                className="pointer"
              />
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="balance-deduction-enable" sm={4}>
            {t.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT}
          </Label>
          <Col sm={8}>
            <Label className="padding-left-right-20">
              <InputWithNullCheck
                data-test="balance-deduction-enable"
                type="checkbox"
                checked={tenant.balanceDeductionEnabled}
                onChange={this._changeBalanceDeductionEnabled}
                className="pointer"
                id="balance-deduction-enable"
              />
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="overwrite-rg-description-dialog-enable" sm={4}>
            {t.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT}
          </Label>
          <Col sm={8}>
            <Label className="padding-left-right-20">
              <InputWithNullCheck
                data-test="overwrite-rg-description-dialog-enable"
                type="checkbox"
                checked={tenant.overwriteRgDescriptionDialogEnabled}
                onChange={this._changeOverwriteRgDescriptionDialogEnabled}
                className="pointer"
                id="overwrite-rg-description-dialog-enable"
              />
            </Label>
          </Col>
        </FormGroup>
        {this._isDE_D365Selected && (
          <React.Fragment>
            <FormGroup row className="remaining-padding">
              <Label for="require-classification" sm={4}>
                {t.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION}
              </Label>
              <Col sm={8}>
                <Label className="padding-left-right-20">
                  <InputWithNullCheck
                    data-test="require-classification"
                    type="checkbox"
                    checked={tenant.rgClassificationsSectionRequired}
                    onChange={this._changeClassificationRequired}
                    className="pointer"
                    id="require-classification"
                  />
                </Label>
              </Col>
            </FormGroup>
            <FormGroup row className="remaining-padding">
              <Label for="require-contamination" sm={4}>
                {t.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION}
              </Label>
              <Col sm={8}>
                <Label className="padding-left-right-20">
                  <InputWithNullCheck
                    data-test="require-contamination"
                    type="checkbox"
                    checked={tenant.rgContaminationsSectionRequired}
                    onChange={this._changeContaminationRequired}
                    className="pointer"
                    id="require-contamination"
                  />
                </Label>
              </Col>
            </FormGroup>
          </React.Fragment>
        )}
        {this._isDE_D365Selected && (
          <React.Fragment>
            <hr />
            <div>
              <h5 className="clearfix">{t.GLOBAL_LABEL_SHAREPOINT}</h5>
              <FormGroup row className="remaining-padding">
                <Label for="sharepoint-site" sm={4}>
                  {t.SUPERADMIN_TENANTDETAIL_SITE}
                </Label>
                <Col sm={8}>
                  <InputGroup size="md" className="full-width">
                    <InputWithNullCheck
                      data-test="sharepoint-site"
                      type="select"
                      id="sharepoint-site"
                      value={tenant.sharepointUploadFolder.id}
                      onChange={this._changeSharePointSite}
                    >
                      <option value="" disabled>
                        {t.SUPERADMIN_SHAREPOINT_CHOOSE_SITE}
                      </option>
                      {sharePointSiteList &&
                        sharePointSiteList.map((site: SharepointModel) => (
                          <option key={site.id} value={site.id}>
                            {site.site}
                          </option>
                        ))}
                    </InputWithNullCheck>
                  </InputGroup>
                </Col>
              </FormGroup>
              {!!tenant.sharepointUploadFolder.path && (
                <FormGroup row className="remaining-padding">
                  <Label for="image-path" sm={4}>
                    {t.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH}
                  </Label>
                  <Col sm={8}>
                    <div data-test="sharepoint-path" className="flex flex-nowrap-space-between word-break-all">
                      <div>
                        <a data-test="sharepoint-path-link" href={tenant.sharepointUploadFolder.path} target="_blank">
                          {tenant.sharepointUploadFolder.path}
                        </a>
                      </div>
                      <div className="margin-left-1rem">
                        <CopyToClipboardButton
                          text={tenant.sharepointUploadFolder.path}
                          tooltipText={t.GLOBAL_COPY_TITLE(t.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH.toLowerCase())}
                          messageOnCopied={t.GLOBAL_COPY_SUCCESS_MESSAGE(t.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH)}
                        />
                      </div>
                    </div>
                  </Col>
                </FormGroup>
              )}
            </div>
          </React.Fragment>
        )}
        <hr />

        {this.props.tenant.id && (
          <div>
            <h5 className="clearfix">
              {t.SUPERADMIN_TENANTADMIN_ADMINS}
              <Button
                data-test="add-admin-btn"
                color="primary"
                onClick={this._toggleIsModalOpen}
                className="pull-right"
              >
                {t.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN}
              </Button>
            </h5>
            {tenant.admins && tenant.admins.length !== 0 ? (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>{t.GLOBAL_LABEL_NAME}</th>
                    <th />
                  </tr>
                </thead>
                {sortActiveFirst(tenant.admins).map((tenantAdmin) => (
                  <TenantAdminLineComponent
                    key={tenantAdmin.id}
                    tenantAdmin={tenantAdmin}
                    saveAction={this.props.saveAdmin}
                    hideAdmin={this.props.hideAdmin}
                    unhideAdmin={this.props.unhideAdmin}
                    workflowTenants={workflowTenants}
                    selectedTenant={tenant}
                  />
                ))}
              </table>
            ) : (
              <p className="text-muted text-center">{t.SUPERADMIN_TENANTADMIN_NO_ADMINS}</p>
            )}

            {this.isModalOpen && this.props.postAdmin && (
              <Modal
                data-test="create-admin-modal"
                isOpen={this.isModalOpen}
                toggle={this._toggleIsModalOpenAndClearAdmin}
                backdrop="static"
              >
                <ModalHeader toggle={this._toggleIsModalOpenAndClearAdmin}>
                  {t.SUPERADMIN_TENANTADMIN_HEADER}
                </ModalHeader>
                <ModalBody className="pl-0 pr-0 pt-0">
                  <Container className="pt-3">
                    <CommonAdminDetailComponent content={this.admin} saveAction={this.saveAdminAndCloseModal}>
                      <AdminDetailComponent content={this.admin} tenants={workflowTenants} selectedTenant={tenant} />
                    </CommonAdminDetailComponent>
                  </Container>
                </ModalBody>
              </Modal>
            )}
            <hr />
          </div>
        )}

        <div className="clearfix">
          <Button data-test="save" type="button" onClick={this._submitForm} className="pull-right" color="success">
            {t.GLOBAL_LABEL_SAVE}
          </Button>
          {toggleAddTenantModal && (
            <Button className="pull-right mr-3" color="secondary" onClick={toggleAddTenantModal}>
              {t.GLOBAL_LABEL_CANCEL}
            </Button>
          )}
        </div>
      </div>
    );
  }

  @action
  private _toggleIsModalOpen = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  @action
  private _toggleIsModalOpenAndClearAdmin = () => {
    this._toggleIsModalOpen();
    this.clearAdmin();
  };

  private _submitForm = () => {
    this.props.saveAction(this.props.tenant);
  };

  private _changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setName(e.target.value);
  };

  private _changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setCode(e.target.value);
  };

  private _changeSite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setSite(e.target.value);
  };

  private _changeLocationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setLocationCode(e.target.value);
  };

  private _changeRegionCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setRegionCode(e.target.value);
  };

  private _changeGrnPrefix = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setGrnPrefix(e.target.value);
  };

  private _changeCurrentGRN = (value: number) => {
    const { tenant } = this.props;
    tenant.setCurrentSequenceValue(value);
  };

  private _changeCurrentLabSequence = (value: number) => {
    const { tenant } = this.props;
    tenant.setCurrentLabSequenceValue(value);
  };

  private _changeWorkflow = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant, workflowList } = this.props;

    const code = workflowList.find((w) => w.id === e.target.value)?.code;
    if (
      Boolean(tenant.units || tenant.legalEntity || tenant.sharepointUploadFolder.id) &&
      code !== CountryCode.DE_D365
    ) {
      tenant.setUnits('');
      tenant.setLegalEntity(null);
      tenant.setSharepointUploadFolder(new SharepointModel());
    }
    tenant.setWorkflowId(e.target.value);
  };

  private _changeUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setUnits(e.target.value);
  };

  private _changeDeliveryAttachmentsRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setDeliveryAttachmentsRequired(e.target.checked);
  };

  private _changeAgAttachmentsRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setAgAttachmentsRequired(e.target.checked);
  };

  private _changeLegalEntity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant, superAdminStore } = this.props;
    const entity = (!!superAdminStore && superAdminStore.legalEntities.find((l) => l.id === e.target.value)) || null;
    tenant.setLegalEntity(entity);
  };

  private _changeSharePointSite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      tenant,
      superAdminStore: { sharePointSiteList },
    } = this.props;
    const site = sharePointSiteList.find((s) => s.id === e.target.value);
    if (site) {
      tenant.setSharepointUploadFolder(site);
    }
  };

  private _changAagSignOffEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setAgSignOffEnabled(checked);
  };

  private _changeBalanceDeductionEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setBalanceDeductionEnabled(checked);
  };

  private _changeClassificationRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setClassificationSectionRequired(checked);
  };

  private _changeContaminationRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setContaminationSectionRequired(checked);
  };

  private _changeOverwriteRgDescriptionDialogEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setOverwriteRgDescriptionDialogEnabled(checked);
  };

  private get _getTimezones(): IdNameModel[] {
    return moment.tz.names().map((timezone: string) => {
      return {
        id: timezone,
        name: `(UTC${moment.tz(timezone).format('Z')}) ${timezone}`,
      };
    }) as IdNameModel[];
  }

  private _changeTimezone = (selectedTimezone: IdNameModel) => {
    const { tenant } = this.props;
    tenant.setTimezone(selectedTimezone.id);
  };

  private _changeIntegrationApiBase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setIntegrationApiBase(e.target.value);
  };

  private get _selectedWorkflow(): WorkflowModel {
    const { tenant, workflowList } = this.props;

    return tenant.workflowId && workflowList.find((workflow: WorkflowModel) => workflow.id === tenant.workflowId);
  }

  @computed
  private get _isWeightUnitsLegalEntitiesShown(): boolean {
    return !!this._selectedWorkflow && this._selectedWorkflow.code === CountryCode.DE_D365;
  }

  @computed
  private get _isDE_D365Selected(): boolean {
    return !!this._selectedWorkflow && this._selectedWorkflow.code === CountryCode.DE_D365;
  }
}
