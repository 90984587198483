import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import TenantModel from 'models/TenantModel';
import { cloneObj } from 'util/helpers';
import MaterialModel from 'models/MaterialModel';

export default class AdvisedGoodLabModel extends UpdateModel<AdvisedGoodLabModel> {
  @observable public advisedDescription: string;
  @observable public flagged: boolean;
  @observable public grn: string;
  @observable public agTitle: string;
  @observable public id: string;
  @observable public receivedGoods: ReceivedGoodsLabModel[];
  @observable public supplier: string;
  @observable public tenant: TenantModel;

  @action
  public update = (
    obj: AdvisedGoodLabModel,
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) => {
    const newAdvisedGoodLabModel = cloneObj(obj);
    if (newAdvisedGoodLabModel) {
      newAdvisedGoodLabModel.receivedGoods = newAdvisedGoodLabModel.receivedGoods.map((rg: ReceivedGoodsLabModel) =>
        new ReceivedGoodsLabModel().update(rg, defaultMaterials, nonDefaultMaterials)
      );
    }

    this.updater.update(this, newAdvisedGoodLabModel, AdvisedGoodLabModel);
    return this;
  };
}
