import { action, observable } from 'mobx';
import env from 'env';

import DeliveryModel, { IDeliveryAttachmentsConstructSaveObj, IDeliveryConstructSaveObj } from 'models/DeliveryModel';
import DeliveryListModel from 'models/DeliveryListModel';
import RootService from 'services/RootService';
import FileModel from 'models/FileModel';
import { ALL, CREATED_DESC, DATE_DESC } from 'util/constants';

export interface IGetDeliveriesResponse {
  values: DeliveryListModel[];
  offset: number;
}

export interface IGrnModelResponse {
  nextGrn: string;
}

export default class DeliveryStore {
  @observable public deliveryList: DeliveryListModel[] = [];
  @observable public filters = {
    filterByClaimedBy: '',
    filterByGrnValue: '',
    filterByStatus: ALL,
    filterByStatusValue: '',
    isFlagged: false,
    sortByQp: CREATED_DESC,
    sortByValue: DATE_DESC,
    hasFiltersApplied: false,
  };

  public constructor(private readonly rootService: RootService) {}

  public downloadList(params: string = '') {
    const url = `${
      env.apiUrl
    }/api/export/delivery-list${params}&x-auth-token=${this.rootService.ajaxService.getSessionId()}&x-client-version=${
      env.version
    }&x-active-tenant=${this.rootService.ajaxService.getActiveTenantId()}`;
    window.open(url);
  }

  @action
  public async getDeliveries(params: string = ''): Promise<boolean> {
    const deliveries = await this._getDeliveriesRequest(params);
    return this.setDeliveryList(deliveries);
  }

  @action
  public getDeliveriesWithCheck(params: string = ''): Promise<IGetDeliveriesResponse> {
    if (this.deliveryList) {
      this.deliveryList = [];
    }

    return this._getDeliveriesRequest(params);
  }

  public getNextGrn(): Promise<IGrnModelResponse> {
    return this.rootService.ajaxService.get('grns/next');
  }

  public postResyncWb(deliveryId: string): Promise<void> {
    return this.rootService.ajaxService.post(`deliveries/${deliveryId}/resync-wb`);
  }

  public postResyncSr(deliveryId: string): Promise<void> {
    return this.rootService.ajaxService.post(`deliveries/${deliveryId}/resync-sr`);
  }

  public getDeliveryById(deliveryId: string) {
    return this.rootService.ajaxService.get(`deliveries/${deliveryId}`);
  }

  public syncDelivery(deliveryId: string) {
    return this.rootService.ajaxService.put(`deliveries/${deliveryId}/sync`);
  }

  public exportDelivery(deliveryId: string, doAfter: () => void) {
    const url = this.rootService.ajaxService.download(`deliveries/${deliveryId}/export`);
    window.open(url);

    // when download tab will close this handler will trigger
    function onFocusHandler() {
      window.removeEventListener('focus', onFocusHandler, false);
      doAfter && doAfter();
    }

    window.addEventListener('focus', onFocusHandler, false);
  }

  public exportDeliveryToCSV(deliveryId: string) {
    const url = this.rootService.ajaxService.download(`deliveries/${deliveryId}/export-to-csv`);
    window.open(url);
  }

  public printDelivery(deliveryId: string) {
    const url = this.rootService.ajaxService.download(`deliveries/${deliveryId}/print`);
    window.open(url);
  }

  public reexportDelivery(deliveryId: string) {
    const url = this.rootService.ajaxService.download(`deliveries/${deliveryId}/re-export`);
    window.open(url);
  }

  public uploadAttachments(id: string, attachments: FormData): Promise<FileModel[]> {
    return this.rootService.ajaxService.post(`deliveries/${id}/upload`, attachments);
  }

  public uploadRadiationFiles(id: string, radiationFiles: FormData): Promise<FileModel[]> {
    return this.rootService.ajaxService.post(`deliveries/${id}/upload/radiation`, radiationFiles);
  }

  public async postDelivery(delivery: IDeliveryConstructSaveObj): Promise<string> {
    const deliveryResponse = await this._postDeliveryRequest(delivery);
    return deliveryResponse.id;
  }

  public confirmDeliveryUnloaded(deliveryId: string) {
    return this.rootService.ajaxService.post(`deliveries/${deliveryId}/confirm-delivery-unloaded`);
  }

  public signOffDelivery(deliveryId: string) {
    return this.rootService.ajaxService.post(`deliveries/${deliveryId}/signoff`);
  }

  public traderMarkDeliveryComplete(delivery: DeliveryListModel) {
    return this.rootService.ajaxService.post(`deliveries/${delivery.id}/complete?tenantId=${delivery.tenantId}`);
  }

  public async putDelivery(delivery: IDeliveryConstructSaveObj): Promise<boolean> {
    await this._putDeliveryRequest(delivery);
    return this.getDeliveries();
  }

  public async putAttachments(deliveryId: string, attachments: IDeliveryAttachmentsConstructSaveObj) {
    await this._putAttachmentsRequest(deliveryId, attachments);
    return this.getDeliveries();
  }

  public async importDelivery(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.rootService.ajaxService.post('deliveries/import', data);
  }

  public deleteDelivery = async (id: string) => {
    return this.rootService.ajaxService.delete(`deliveries/${id}`);
  };

  @action public setDeliveryList = (res: IGetDeliveriesResponse) => {
    if (res.offset) {
      this.deliveryList.push(...res.values.map((item: DeliveryListModel) => new DeliveryListModel().update(item)));
    } else {
      this.deliveryList = res.values.map((item: DeliveryListModel) => new DeliveryListModel().update(item));
    }
    return Promise.resolve(!!res.values.length);
  };

  private _getDeliveriesRequest(params: string = '') {
    return this.rootService.ajaxService.get(`deliveries${params}`);
  }

  private _postDeliveryRequest(delivery: IDeliveryConstructSaveObj): Promise<DeliveryModel> {
    return this.rootService.ajaxService.post(`deliveries`, delivery);
  }

  private _putDeliveryRequest(delivery: IDeliveryConstructSaveObj) {
    return this.rootService.ajaxService.put(`deliveries/${delivery.id}`, delivery);
  }

  private _putAttachmentsRequest(deliveryId: string, attachments: IDeliveryAttachmentsConstructSaveObj) {
    return this.rootService.ajaxService.put(`deliveries/${deliveryId}/attachments`, attachments);
  }
}
