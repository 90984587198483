import UserModel from 'models/UserModel';
import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class OperatorModel extends UpdateModel<OperatorModel> {
  @observable public date: string;
  @observable public user: UserModel<IUserModelConstructObj>;

  @action
  public update = (obj: OperatorModel) => {
    const newOperatorModel = cloneObj(obj);

    if (newOperatorModel) {
      newOperatorModel.user = new UserModel<IUserModelConstructObj>().update(newOperatorModel.user);
    }

    this.updater.update(this, newOperatorModel, OperatorModel);
    return this;
  };
}
