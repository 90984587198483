import { action, computed, observable } from 'mobx';

import IdNameActiveModel from 'models/IdNameActiveModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export default class ScaleModel extends IdNameActiveModel implements IConstructSaveObj<ScaleModel> {
  @observable public port: string = '';
  @observable public ip: string = '';

  @computed
  public get constructSaveObj(): ScaleModel {
    return {
      name: this.name,
      port: this.port,
      ip: this.ip,
      active: this.active,
    } as ScaleModel;
  }

  @action
  public update = (obj: ScaleModel) => {
    this.updater.update(this, super.update(obj), ScaleModel);
    return this;
  };

  @action
  public changePort = (newPort: string) => (this.port = newPort);

  @action
  public changeIp = (newIp: string) => (this.ip = newIp);
}
