import { action, computed, observable } from 'mobx';

import IdNameActiveModel from 'models/IdNameActiveModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export default class PrinterModel extends IdNameActiveModel implements IConstructSaveObj<PrinterModel> {
  @observable public ip: string = '';
  @observable public model: string = '';

  @computed
  public get constructSaveObj(): PrinterModel {
    return {
      name: this.name,
      ip: this.ip,
      model: this.model,
      active: this.active,
    } as PrinterModel;
  }

  @action
  public update = (obj: PrinterModel) => {
    this.updater.update(this, super.update(obj), PrinterModel);
    return this;
  };

  @action public changeIp = (newIp: string) => (this.ip = newIp);
  @action public changeModel = (newModel: string) => (this.model = newModel);
}
