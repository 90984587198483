import { logger } from 'util/logger';
import { action, computed, observable } from 'mobx';

import storage from 'util/storage';
import { instance as notification } from 'util/notification';

import SessionModel from 'models/SessionModel';
import UserModel from 'models/UserModel';
import env from 'env';

import { UserRoles } from 'util/enums';
import RootStore from 'stores/RootStore';
import RootService from 'services/RootService';
import TenantModel from 'models/TenantModel';
import { ACTIVE_TENANT_ID_STORAGE_KEY, ACTIVE_USER_ROLE_NAME_STORAGE_KEY, DEVICE_TOKEN } from 'util/constants';

import bugsnagClient from 'util/bugsnag';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class SessionStore {
  @observable public user: UserModel<IUserModelConstructObj>;
  @observable public tenant: TenantModel;

  public constructor(private readonly rootService: RootService, private readonly rootStore: RootStore) {}

  public login(username: string, password: string) {
    return this.rootService.ajaxService
      .post(`auth/login?x-client-version=${env.version}`, { username, password })
      .then((res: SessionModel) => {
        this.updateUser(res.user);
        storage.set('sid', res.id);
        if (res.id && !this.isSuperAdmin && !res.user.requiresPasswordChange) {
          this.rootStore.deviceTokenStore.sendUserIsLoggedInStatus();
        }

        return res.user;
      })
      .catch((err: Error) => {
        logger.error(err);
        throw err;
      });
  }

  @action
  public setTenant(tenant: TenantModel) {
    this.tenant = new TenantModel().update(tenant);
  }

  @action
  public updateUser(userData: UserModel<IUserModelConstructObj>) {
    this.user = new UserModel<IUserModelConstructObj>().update(userData);

    // need during reload page
    const activeRoleName = storage.get(ACTIVE_USER_ROLE_NAME_STORAGE_KEY);
    if (activeRoleName) {
      this.user.setActiveRole(this.user.getRoleByName(activeRoleName));
    } else {
      storage.set(ACTIVE_USER_ROLE_NAME_STORAGE_KEY, this.user.activeRole.name);
    }

    if (this.isAdmin) {
      const activeTenantId = storage.get(ACTIVE_TENANT_ID_STORAGE_KEY);
      if (activeTenantId) {
        this.user.setActiveTenant(this.user.getTenantById(activeTenantId));
      } else {
        storage.set(ACTIVE_TENANT_ID_STORAGE_KEY, this.user.activeTenant.id);
      }
    }

    if (env.isProduction) {
      bugsnagClient.metaData = {
        clientMetadata: {
          id: userData.id,
          role: this.user.activeRole ? this.user.activeRole.name : 'NO_ROLE',
          uiVersion: env.version,
        },
      };
    }
  }

  @action
  public logout(withoutPopup: boolean = false) {
    this.rootService.socketService.close();
    let deviceToken = null;
    if (!this.isSuperAdmin) {
      deviceToken = storage.get(DEVICE_TOKEN);
      this.rootStore.deviceTokenStore.sendUserIsLogoutStatus();
    }
    this.rootService.ajaxService.post(`auth/logout/${storage.get('sid')}`, deviceToken ? { deviceToken } : null);
    this._clearData();

    if (!withoutPopup) {
      notification.success(this.rootService.translateService.t.GLOBAL_LOGOUT_SUCCESS_MSG);
    }
  }

  public restoreSession(): Promise<UserModel<IUserModelConstructObj> | Error> {
    const sid = storage.get('sid');

    if (!sid) {
      return Promise.reject(new Error('no session token'));
    }

    return this.rootService.ajaxService
      .get(`auth/session/${sid}`)
      .then((res: SessionModel) => {
        if (res.user.requiresPasswordChange) {
          return Promise.reject(new Error('require password change'));
        }
        this.updateUser(res.user);

        this.setTenant(res.tenant);

        this.rootService.socketService.open(res.id);

        return this.user;
      })
      .catch((err: Error) => {
        this.rootService.socketService.close();
        logger.error(err);
        this._clearData();
        throw err;
      });
  }

  public recoverPassword(username: string, oldPassword: string, newPassword: string) {
    return this.rootService.ajaxService.put(`auth/recover-password`, {
      username,
      oldPassword,
      newPassword,
    });
  }

  public hasRight?(right: string) {
    // TODO: Add user role handling here
    // return this.user && this.user.rights && this.user.rights.includes(right);
  }

  public get canCreateDeliveries() {
    return this.isWeightBridgeOperator;
  }

  public get canListenToNewDeliveries() {
    return this.isTier3 || this.isTier1 || this.isTier2;
  }

  private _clearData() {
    storage.remove('sid');
    storage.remove(ACTIVE_USER_ROLE_NAME_STORAGE_KEY);
    storage.remove(ACTIVE_TENANT_ID_STORAGE_KEY);
    this.user = null;
    this.rootStore.commonStore.changeIsDataLoaded(false);
    this.rootStore.commonStore.changeIsLabDataLoaded(false);
  }

  // BOF IS CHECK FOR USER ROLE
  @computed
  public get isSuperAdmin() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.SuperAdmin;
  }

  @computed
  public get isAdmin() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Admin;
  }

  @computed
  public get isWeightBridgeOperator() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.WeightBridgeOperator;
  }

  @computed
  public get isTier3() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Tier3;
  }

  @computed
  public get isTier2() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Tier2;
  }

  @computed
  public get isTier1() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Tier1;
  }

  @computed
  public get isManager() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Manager;
  }

  @computed
  public get isLab() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Lab;
  }

  @computed
  public get isTrader() {
    return this.user?.activeRole && this.user.activeRole.name === UserRoles.Trader;
  }

  @computed
  public get isTraderOrBackOffice() {
    return (
      this.user?.activeRole &&
      (this.user.activeRole.name === UserRoles.Trader || this.user.activeRole.name === UserRoles.BackOffice)
    );
  }

  public hasRole = (role: UserRoles) => {
    return !!this.user.roles.find((userRole) => userRole.name === role);
  };

  public isSameUser = (userId: string): boolean => {
    return userId !== undefined && this.user?.id === userId;
  };
}
