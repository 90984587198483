import { action, computed, observable, reaction } from 'mobx';
import { Moment } from 'moment';
import FilterAbstractStore from 'stores/FilterAbstractStore';
import RootService from 'services/RootService';
import { DATE_FORMAT_WITH_DOT } from 'util/constants';

export interface ICommonFiltersApplied {
  startDate: boolean;
  endDate: boolean;
  isFlagged: boolean;
}

export interface ICommonQueryParams {
  createdFrom: string;
  createdTo: string;
  isFlagged: boolean;
  offset: number;
  limit: number;
}

export default class DeliveryCommonFilterStore extends FilterAbstractStore<ICommonFiltersApplied, ICommonQueryParams> {
  @observable
  public offset: number = 0;

  @observable
  public limit: number = 30;

  @observable
  public startDate: Moment = null;

  @observable
  public endDate: Moment = null;

  @observable
  public isFlagged: boolean = false;

  @observable
  public shouldScrollToTop: boolean = false;

  constructor(private readonly rootService: RootService) {
    super();
    reaction(
      () => this.hasFiltersApplied,
      () => {
        this.changeOffset(0);
      }
    );
  }

  @computed
  public get getFiltersApplied(): ICommonFiltersApplied {
    return {
      startDate: !!this.startDate,
      endDate: !!this.endDate,
      isFlagged: !!this.isFlagged,
    } as ICommonFiltersApplied;
  }

  @computed
  public get getQueryParams(): ICommonQueryParams {
    return {
      createdFrom: this.startDate ? this.startDate.format(DATE_FORMAT_WITH_DOT) : '',
      createdTo: this.endDate ? this.endDate.format(DATE_FORMAT_WITH_DOT) : '',
      isFlagged: this.isFlagged,
      offset: this.offset,
      limit: this.limit,
    };
  }

  @action
  public changeOffset = (offset: number) => (this.offset = offset);

  @action
  public async changeStartDate(startDate: Moment) {
    this.startDate = startDate;
  }

  @action
  public async changeEndDate(endDate: Moment) {
    this.endDate = endDate;
  }

  @action
  public async changeDateRange(startDate: Moment, endDate: Moment) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  public toggleIsFlagged = () => (this.isFlagged = !this.isFlagged);

  @action
  public setShouldScrollToTop = (value: boolean) => (this.shouldScrollToTop = value);
}
