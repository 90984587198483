import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import PrinterDetailRoute from 'pod/admin/printers/PrinterDetailRoute';
import AdminStore from 'pod/admin/AdminStore';
import PrinterDetailComponent from 'pod/admin/printers/PrinterDetailComponent';
import PrinterModel from 'models/PrinterModel';
import TranslateService from 'services/TranslateService';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import { AdminRootPath } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

@inject('adminStore', 'translateService')
@observer
export default class PrinterListRoute extends React.Component<IProps> {
  @observable public content: PrinterModel = new PrinterModel();

  @action
  public clearModel = () => {
    this.content = new PrinterModel();
  };

  public get printerList() {
    const {
      translateService: { t },
      adminStore: { printerListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.ADMIN_PRINTERLIST_NAME}</th>
            <th>{t.GLOBAL_LABEL_IP}</th>
            <th className="pr-3">{t.GLOBAL_LABEL_MODEL}</th>
          </tr>
        </thead>
        {printerListActiveFirst.map((printer: PrinterModel) => this._renderItem(printer))}
      </table>
    );
  }

  public get printerForm() {
    return <PrinterDetailComponent content={this.content} />;
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { printerApi },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={printerApi}
        rootPath={AdminRootPath.PRINTERS}
        itemsTable={this.printerList}
        itemCreateForm={this.printerForm}
        componentRoute={PrinterDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_PRINTER}
        header={t.MENU_ADMIN_PRINTERS}
        hasUploadBtn={false}
      />
    );
  }

  private _renderItem = (printer: PrinterModel) => (
    <tbody key={printer.id}>
      <NavlinkCustom
        to={`/admin/printers/${printer.id}`}
        tagName="tr"
        key={printer.id}
        className={classnames(['pointer', { 'bg-secondary': !printer.active }])}
      >
        <td className="pl-3">{printer.name}</td>
        <td>{printer.ip}</td>
        <td className="pr-3">{printer.model}</td>
      </NavlinkCustom>
    </tbody>
  );
}
