import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import TranslateService from 'services/TranslateService';
import TenantModel from 'models/TenantModel';

interface IQuickLoginButtons {
  devLogin: (username: string, password: string) => void;
  selectedTenant: TenantModel;
  translateService?: TranslateService;
}

@inject('translateService')
@observer
export default class QuickLoginButtons extends React.Component<IQuickLoginButtons> {
  public render(): React.ReactNode {
    const {
      selectedTenant,
      translateService: { t },
    } = this.props;

    return (
      <React.Fragment>
        <Button
          data-test="login-button-super-admin"
          block
          type="button"
          color="dark"
          className="margin-top-2rem"
          onClick={this._onClickDevLogin}
          data-username={'super-admin'}
          data-password={'asdfasdf'}
        >
          {t.GLOBAL_USERROLE_SUPERADMIN}
        </Button>
        {selectedTenant && (
          <React.Fragment>
            <Button
              data-test="login-button-admin"
              block
              type="button"
              color="dark"
              className="margin-top-2rem"
              onClick={this._onClickDevLogin}
              data-username={`admin.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_ADMIN}
            </Button>
            <Button
              data-test="login-button-wb-operator"
              block
              type="button"
              color="dark"
              className="margin-top-2rem"
              onClick={this._onClickDevLogin}
              data-username={`wb-operator.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR}
            </Button>
            {!selectedTenant.workflow.isDE && !selectedTenant.workflow.isDE_D365 && (
              <Button
                data-test="login-button-tier-3"
                block
                type="button"
                color="dark"
                onClick={this._onClickDevLogin}
                data-username={`tier-3.${selectedTenant.code}`}
                data-password={'asdfasdf'}
              >
                {t.GLOBAL_USERROLE_TIER3}
              </Button>
            )}
            <Button
              data-test="login-button-tier-2"
              block
              type="button"
              color="dark"
              onClick={this._onClickDevLogin}
              data-username={`tier-2.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_TIER2}
            </Button>
            <Button
              data-test="login-button-tier-1"
              block
              type="button"
              color="dark"
              onClick={this._onClickDevLogin}
              data-username={`tier-1.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_TIER1}
            </Button>
            <Button
              data-test="login-button-manager"
              block
              type="button"
              color="dark"
              onClick={this._onClickDevLogin}
              data-username={`manager.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_MANAGER}
            </Button>
            {!selectedTenant.workflow.isDE &&
              !selectedTenant.workflow.isDE_D365 &&
              !selectedTenant.workflow.isIT &&
              !selectedTenant.workflow.isFR && (
                <Button
                  data-test="login-button-lab"
                  block
                  type="button"
                  color="dark"
                  onClick={this._onClickDevLogin}
                  data-username={`lab.${selectedTenant.code}`}
                  data-password={'asdfasdf'}
                >
                  {t.GLOBAL_USERROLE_LAB}
                </Button>
              )}
            <Button
              data-test="login-button-trader"
              block
              type="button"
              color="dark"
              onClick={this._onClickDevLogin}
              data-username={`trader.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_TRADER}
            </Button>
            <Button
              data-test="login-button-back-office"
              block
              type="button"
              color="dark"
              onClick={this._onClickDevLogin}
              data-username={`back-office.${selectedTenant.code}`}
              data-password={'asdfasdf'}
            >
              {t.GLOBAL_USERROLE_BACKOFFICE}
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private _onClickDevLogin = (e: React.SyntheticEvent<HTMLElement>): void => {
    const { devLogin } = this.props;

    devLogin(e.currentTarget.dataset.username, e.currentTarget.dataset.password);
  };
}
