import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { ICode } from 'models/ModelInterfaces';

export default class IdCodeModel extends UpdateModel<IdCodeModel> implements ICode {
  @observable
  public id?: string = '';

  @observable
  public code: string = '';

  @action
  public update(obj: IdCodeModel) {
    this.updater.update(this, obj, IdCodeModel);
    return this;
  }

  @action
  public changeCode = (newCode: string) => (this.code = newCode);
}
