import * as React from 'react';
import { inject, observer } from 'mobx-react';
import env from 'env';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import PrintComponent from 'components/PrintComponent';
import InputNumber from 'components/InputNumber';
import { inputNumberStep, processDecimals } from 'util/helpers';
import ScaleComponent from 'components/ScaleComponent';
import InputWithNullCheck from 'components/InputWithNullCheck';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import LabAnalysisModel from 'models/LabAnalysisModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import PrinterModel from 'models/PrinterModel';
import ViewStore from 'stores/ViewStore';
import { NUMBER_OF_DECIMALS_MEASUREMENT } from 'util/constants';

interface ILabMeasurementProps {
  labInput: LabAnalysisModel;
  receivedGood: ReceivedGoodsLabModel;
  printers: PrinterModel[];
  getPrintLabImage: () => void;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

@inject('sessionStore', 'translateService', 'viewStore')
@observer
export default class LabMeasurementComponent extends React.Component<ILabMeasurementProps> {
  public render() {
    const {
      sessionStore: { isLab },
      translateService: { t },
      labInput,
      receivedGood,
      printers,
      viewStore: { numberOfDecimals, isLabFromUS },
      getPrintLabImage,
    } = this.props;
    return (
      <React.Fragment>
        <h5 className="mt-4 clearfix">
          {t.LABORATORY_ANALISYS_MEASUREMENTS}
          {isLab && (
            <div className="pull-right">
              <PrintComponent labInput={labInput} selectedReceivedGoodLab={receivedGood} printers={printers} />
            </div>
          )}

          {!env.isProduction && (
            <Button
              data-test="qr-button"
              size="sm"
              className="margin-right-1rem float-right"
              onClick={getPrintLabImage}
            >
              QR
            </Button>
          )}
        </h5>
        {isLabFromUS ? (
          <Row className="bg-white border-top border-bottom">
            <Col className="pt-2 pb-2">
              <FormGroup row className="padding-left-right-20">
                <Col md={2} xs={5} className="align-self-center padding-none">
                  <Label>{t.SORTINGREPORT_MEASUREMENT_SAMPLE_WET}</Label>
                </Col>
                <Col md={2} xs={4} className="padding-only-top-right-bottom-10">
                  <InputNumber
                    data-test="wet-weight-us"
                    value={labInput.wetWeight}
                    decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
                    step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
                    onChangeValue={(number: number) => labInput.setWetWeight(number)}
                  />
                </Col>
                <Col md={2} xs={5} className="align-self-center padding-none">
                  <Label>{t.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY}</Label>
                </Col>
                <Col md={2} xs={4} className="padding-only-top-right-bottom-10">
                  <InputNumber
                    data-test="dry-weight-us"
                    value={labInput.dryWeight}
                    decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
                    step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
                    onChangeValue={(number: number) => labInput.setDryWeight(number)}
                  />
                </Col>
                <Col md={2} xs={5} className="align-self-center padding-none">
                  <Label>{t.SORTINGREPORT_MEASUREMENT_HUMIDITY}</Label>
                </Col>
                <Col md={2} xs={4} className="padding-only-top-right-bottom-10">
                  <Input
                    data-test="humidity"
                    className="text-right"
                    disabled={true}
                    value={
                      labInput.humidity ? `${processDecimals(labInput.humidity, NUMBER_OF_DECIMALS_MEASUREMENT)} %` : ''
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="padding-left-right-20">
                <Col md={2} xs={5} className="align-self-center padding-none">
                  <Label>{t.SORTINGREPORT_MEASUREMENT_FOR_MELTING}</Label>
                </Col>
                <Col md={2} xs={4} className="padding-only-top-right-bottom-10">
                  <InputNumber
                    data-test="for-melting"
                    value={labInput.forMeltingWeight}
                    decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
                    step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
                    onChangeValue={(number: number) => labInput.setForMelting(number)}
                  />
                </Col>
                <Col md={2} xs={5} className="align-self-center padding-none">
                  <Label>{t.SORTINGREPORT_MEASUREMENT_INGOT}</Label>
                </Col>
                <Col md={2} xs={4} className="padding-only-top-right-bottom-10">
                  <InputNumber
                    data-test="ingot-us"
                    value={labInput.ingotWeight}
                    decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
                    step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
                    onChangeValue={(number: number) => labInput.setIngotWeight(number)}
                  />
                </Col>
                <Col md={2} xs={5} className="align-self-center padding-none">
                  <Label>{t.SORTINGREPORT_MEASUREMENT_YIELD}</Label>
                </Col>
                <Col md={2} xs={4} className="padding-only-top-right-bottom-10">
                  <Input
                    data-test="yield-us"
                    className="text-right"
                    disabled={true}
                    value={
                      labInput.yieldUS ? `${processDecimals(labInput.yieldUS, NUMBER_OF_DECIMALS_MEASUREMENT)}%` : ''
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          <Row className="bg-white border-top border-bottom">
            <Col className="pt-2 pb-2">
              <FormGroup row>
                <Col xs={6}>
                  <Row>
                    <Label xs={5} for="wetWeight">
                      {t.LABORATORY_ANALISYS_WET_WEIGHT}
                    </Label>
                    <Col xs={7} className="pr-0">
                      <InputGroup>
                        <InputNumber
                          data-test="wet-weight"
                          disabled={!isLab}
                          id="wetWeight"
                          decimalPlaces={numberOfDecimals}
                          onChangeValue={(value: number) => labInput.setWetWeight(value)}
                          step={inputNumberStep(numberOfDecimals)}
                          value={labInput.wetWeight}
                        />
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <ScaleComponent onValue={(value: number) => labInput.setWetWeight(value)} />
                          </span>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Label xs={5} for="dryWeight" className="pl-0 text-right">
                      {t.LABORATORY_ANALISYS_DRY_WEIGHT}
                    </Label>
                    <Col xs={7} className="pl-0">
                      <InputGroup>
                        <InputNumber
                          data-test="dry-weight"
                          disabled={!isLab}
                          id="dryWeight"
                          decimalPlaces={numberOfDecimals}
                          onChangeValue={(value: number) => labInput.setDryWeight(value)}
                          step={inputNumberStep(numberOfDecimals)}
                          value={labInput.dryWeight}
                        />
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <ScaleComponent onValue={(value: number) => labInput.setDryWeight(value)} />
                          </span>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={6}>
                  <Row>
                    <Label xs={5} for="ingotWeight">
                      {t.LABORATORY_ANALISYS_INGOTE_WEIGHT}
                    </Label>
                    <Col xs={7} className="pr-0">
                      <InputGroup>
                        <InputNumber
                          data-test="ingot-weight"
                          disabled={!isLab}
                          id="ingotWeight"
                          decimalPlaces={numberOfDecimals}
                          onChangeValue={(value: number) => labInput.setIngotWeight(value)}
                          step={inputNumberStep(numberOfDecimals)}
                          value={labInput.ingotWeight}
                        />
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <ScaleComponent onValue={(value: number) => labInput.setIngotWeight(value)} />
                          </span>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Label xs={5} className="pl-0 text-right">
                      {t.LABORATORY_ANALISYS_YIELD}
                    </Label>
                    <Col xs={7} className="pl-0">
                      <InputWithNullCheck
                        data-test="yield"
                        value={labInput.yield}
                        disabled
                        type="text"
                        className="text-right"
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
