import { computed } from 'mobx';
import { stringifyNonNullableObj } from 'util/helpers';

export default abstract class FilterAbstractStore<T, K extends {}> {
  protected abstract get getFiltersApplied(): T;
  protected abstract get getQueryParams(): K;

  @computed
  public get buildQueryString(): string {
    return `?${stringifyNonNullableObj(this.getQueryParams, { arrayFormat: 'none' })}`;
  }

  @computed
  public get hasFiltersApplied(): T {
    return this.getFiltersApplied;
  }
}
