import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import TranslateService from 'services/TranslateService';
import FileModel from 'models/FileModel';
import TakePicture from 'components/TakePicture';
import AttachmentGallery from 'domain/AttachmentGallery';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import { logger } from 'util/logger';
import { instance as notification } from 'util/notification';
import { isMobile } from 'util/helpers';
import LabAnalysisModel from 'models/LabAnalysisModel';
import TakeFile from 'components/TakeFile';
import DeliveryAdvisedGoodModel from 'models/DeliveryAdvisedGoodModel';

interface IProps {
  isReadOnly: boolean;
  content: AdvisedGoodsModel | ReceivedGoodsModel | LabAnalysisModel | DeliveryAdvisedGoodModel;
  uploadAttachment: (id: string, attachment: File) => Promise<FileModel[]> | undefined;
  uploadAttachments: (id: string, attachments: FileList) => Promise<FileModel[]> | undefined;
  downloadAttachments: (id: string) => string;
  translateService?: TranslateService;
  fileAccept?: string;
  title?: string;
}

interface IState {
  uploadingCount: number;
  isGalleryVisible?: boolean;
}

@inject('translateService')
@observer
export default class PicturesComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      uploadingCount: 0,
      isGalleryVisible: false,
    };
  }

  public get isContentSaved(): boolean {
    return Boolean(this.props.content.id);
  }

  public render(): React.ReactNode {
    const { isGalleryVisible } = this.state;
    const {
      isReadOnly,
      content,
      downloadAttachments,
      translateService: { t },
      title,
    } = this.props;
    const sectionTitle = title ? title : t.ADVISEDGOOD_ROUTE_PICTURES;
    return (
      <React.Fragment>
        <h5 data-test="picture-header" className="mt-4 clearfix" onClick={this._toggleGallery}>
          {sectionTitle} <span className="pl-3 font-light">{content.attachments.length}</span>
          {this._hasPictureAttachments && (
            <FontAwesome className="margin-left-20" name={isGalleryVisible ? 'angle-up' : 'angle-down'} />
          )}
          {!isReadOnly && this._renderTakeFileComponent}
          {this._hasDownloadButton && (
            <a
              data-test="pictures-download"
              href={downloadAttachments(content.id)}
              download
              onClick={() => notification.success(t.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG)}
              className="pull-right btn btn-false"
            >
              <FontAwesome name="download" />
            </a>
          )}
        </h5>
        <Row className="bg-white border-top border-bottom">
          <Col xs={12} className="pt-1 pb-1">
            <AttachmentGallery
              attachments={content.attachments}
              onDelete={this.deleteAttachment}
              isGalleryVisible={isGalleryVisible}
            />

            {/* SHOW UPLOAD COUNT */}
            {this.state.uploadingCount > 0 && (
              <div className="text-center alert alert-warning fade show">{t.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES}</div>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  // UPLOAD ATTACHMENT WITH FORM
  public uploadNewAttachment = (e: React.ChangeEvent<HTMLInputElement>): Promise<void> | undefined => {
    if (!this.isContentSaved) {
      notification.warn(this.props.translateService.t.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG);
      return;
    }

    if (e.target.files && e.target.files.length !== 0) {
      this.setState({ uploadingCount: this.state.uploadingCount + 1 });

      return this.props
        .uploadAttachments(this.props.content.id, e.target.files)
        .then((newAttachment: FileModel[]) => {
          newAttachment.forEach((a) => this.props.content.pushNewAttachment(a));
          this._changeUploadingCount();
        })
        .catch(() => {
          this._changeUploadingCount();
        });
    }

    return Promise.reject(new Error('files did not find'));
  };

  private _changeUploadingCount() {
    this.setState({ uploadingCount: this.state.uploadingCount - 1 }, (): boolean | undefined => {
      this.props.content.setHasChanged(true);
      if (this.state.uploadingCount === 0) {
        return true;
      }
    });
  }

  // UPLOAD ATTACHMENT NATIVELY
  public onPictureTaken = (imageBase64: string) => {
    if (imageBase64 && imageBase64.length !== 0) {
      try {
        this.setState({
          isGalleryVisible: true,
          uploadingCount: this.state.uploadingCount + 1,
        });
        const blob = this._dataURItoBlob(imageBase64);
        const file = new File([blob], `${new Date().toISOString()}.jpg`);
        this.props
          .uploadAttachment(this.props.content.id, file)
          .then((newAttachment: FileModel[]) => {
            newAttachment.forEach((a) => this.props.content.pushNewAttachment(a));
            this.setState({ uploadingCount: this.state.uploadingCount - 1 });
          })
          .catch((err: Error) => {
            logger.error('error while uploading image', err);
            this.setState({ uploadingCount: this.state.uploadingCount - 1 });
          });
      } catch (e) {
        logger.error('error while processing uploaded image', e);
      }
    }
  };

  private _dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  private get _hasPictureAttachments(): boolean {
    const { content } = this.props;
    return content.attachments && content.attachments.length !== 0;
  }

  // DELETE ATTACHMENT
  public deleteAttachment = (attachment: FileModel) => {
    const { content } = this.props;
    content.setHasChanged(true);
    content.removeAttachment(attachment.id);
    notification.success(this.props.translateService.t.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG);
  };

  // TOGGLE PICTURE IMAGE GALLERY
  private _toggleGallery = () => {
    this.setState({ isGalleryVisible: !this.state.isGalleryVisible });
  };

  private get _hasDownloadButton() {
    return !isMobile() && this._hasPictureAttachments;
  }

  private get _renderTakeFileComponent() {
    const { fileAccept } = this.props;

    return !!fileAccept && !isMobile() ? (
      <TakeFile
        data-test="take-file"
        buttonClassName="pull-right ml-2"
        onChangeFunc={this.uploadNewAttachment}
        fileAccept={fileAccept}
      />
    ) : (
      <TakePicture
        data-test="take-picture"
        buttonClassName="pull-right ml-2"
        onChangeFunc={this.uploadNewAttachment}
        onPictureTaken={this.onPictureTaken}
        isContentSaved={this.isContentSaved}
      />
    );
  }
}
