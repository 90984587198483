import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import AttachmentGalleryPicture from 'domain/AttachmentGalleryPicture';
import FileModel from 'models/FileModel';
import SessionStore from 'stores/SessionStore';
import ViewStore from 'stores/ViewStore';
import { inject, observer } from 'mobx-react';
import { Collapse } from 'reactstrap';
import { instance as notification } from 'util/notification';
import TranslateService from 'services/TranslateService';
import Lightbox from 'react-image-lightbox';
import { isMobile, splitFileModelsArrayToImagesAndPdfs } from 'util/helpers';
import reactNative from 'util/react-native';
import { ReactNativeActionType } from 'util/enums';

interface IProps {
  attachments: FileModel[];
  isGalleryVisible: boolean;
  onDelete: (attachment: FileModel) => void;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

interface IState {
  imageToShow: number; // INDEX
  isOpen: boolean;
}

@inject('sessionStore', 'translateService', 'viewStore')
@observer
export default class AttachmentGallery extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      imageToShow: 0,
      isOpen: false,
    };
  }

  public setIndexForLightbox = (imageToShow: number) => {
    this.setState({
      imageToShow,
      isOpen: true,
    });
  };

  public openPDF = (url: string) => {
    if (reactNative.isReactNativeWebViewExist) {
      reactNative.send({ command: ReactNativeActionType.SHOW_PDF_READER, payload: url });
    }
  };

  public render() {
    const {
      attachments,
      isGalleryVisible,
      translateService: { t },
    } = this.props;
    const { imageToShow, isOpen } = this.state;
    const [imagesFileModels, pdfFileModels] = splitFileModelsArrayToImagesAndPdfs(attachments);
    const images = imagesFileModels.map((attachment) => attachment.path);

    return attachments && attachments.length ? (
      <div className="attachment-gallery clearfix">
        <Collapse isOpen={isGalleryVisible}>
          {pdfFileModels.map((attachment: FileModel, index) => (
            <AttachmentGalleryPicture
              attachment={attachment}
              index={index}
              onDelete={this.props.onDelete}
              key={attachment.id}
              openPDF={this.openPDF}
            />
          ))}
          {imagesFileModels.map((attachment: FileModel, index) => (
            <AttachmentGalleryPicture
              attachment={attachment}
              index={index}
              onDelete={this.props.onDelete}
              setIndexForLightbox={this.setIndexForLightbox}
              key={attachment.id}
            />
          ))}
        </Collapse>

        {isOpen && (
          <Lightbox
            toolbarButtons={[
              this._hasDownloadButton && (
                <a
                  href={images[imageToShow]}
                  onClick={() => notification.success(t.GLOBAL_LABEL_FILE_DOWNLOADED_MSG)}
                  download
                  data-test="download-image-btn"
                  className="lightbox-btn ril__builtinButton"
                >
                  <FontAwesome name="download" />
                </a>
              ),
            ]}
            mainSrc={images[imageToShow]}
            nextSrc={images[(imageToShow + 1) % images.length]}
            prevSrc={images[(imageToShow + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            reactModalStyle={{ overlay: { zIndex: '1100' } }}
            onMovePrevRequest={() =>
              this.setState({
                imageToShow: (imageToShow + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                imageToShow: (imageToShow + 1) % images.length,
              })
            }
          />
        )}
      </div>
    ) : null;
  }

  private get _hasDownloadButton() {
    const {
      sessionStore: { isTier3 },
      viewStore: { isUS },
    } = this.props;
    return !isMobile() && (!isTier3 || isUS);
  }
}
