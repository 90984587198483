import { action, computed, observable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import ObjectUpdater from 'util/objectUpdater';
import { IActive } from 'models/ModelInterfaces';

export default class IdNameActiveModel extends IdNameModel implements IActive {
  public readonly updater = new ObjectUpdater<IdNameActiveModel>();

  @observable
  public active: boolean;

  @computed
  public get constructSaveObj(): IdNameActiveModel {
    return {
      name: this.name,
      active: this.active,
    } as IdNameActiveModel;
  }

  @action
  public update(obj: IdNameActiveModel) {
    this.updater.update(this, super.update(obj), IdNameActiveModel);
    return this;
  }

  @action
  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
}
