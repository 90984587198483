import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';

import AdminStore from 'pod/admin/AdminStore';
import MaterialModel from 'models/MaterialModel';
import NavlinkCustom from 'domain/NavlinkCustom';
import TranslateService from 'services/TranslateService';
import { action, observable } from 'mobx';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import MaterialDetailRoute from 'pod/admin/materials/MaterialDetailRoute';
import MaterialDetailComponent from 'pod/admin/materials/MaterialDetailComponent';
import { AdminRootPath } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

@inject('adminStore', 'translateService')
@observer
export default class MaterialListRoute extends React.Component<IProps> {
  @observable public content: MaterialModel = new MaterialModel();

  @action
  public clearModel = () => {
    this.content = new MaterialModel();
  };

  public get materialList() {
    const {
      translateService: { t },
      adminStore: { materialListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.GLOBAL_LABEL_NAME}</th>
            <th>{t.GLOBAL_LABEL_CODE}</th>
            <th className="pr-3">{t.GLOBAL_LABEL_IS_DEFAULT}</th>
          </tr>
        </thead>
        {materialListActiveFirst.map((material: MaterialModel) => this._renderItem(material))}
      </table>
    );
  }

  public get materialForm() {
    return <MaterialDetailComponent content={this.content} />;
  }

  public render() {
    const {
      adminStore: { materialApi },
      translateService: { t },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={materialApi}
        rootPath={AdminRootPath.MATERIALS}
        itemsTable={this.materialList}
        itemCreateForm={this.materialForm}
        componentRoute={MaterialDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_MATERIAL}
        hasUploadBtn={false}
        header={t.MENU_ADMIN_MATERIALS}
      />
    );
  }

  private _renderItem = (material: MaterialModel) => (
    <tbody key={material.id}>
      <NavlinkCustom
        to={`/admin/materials/${material.id}`}
        tagName="tr"
        className={classnames(['pointer', { 'bg-secondary': !material.active }])}
      >
        <td className="pl-3">{material.name}</td>
        <td>{material.code}</td>
        <td className="pr-3">{material.isDefault && 'Yes'}</td>
      </NavlinkCustom>
    </tbody>
  );
}
