import { action, computed, observable } from 'mobx';
import ObjectUpdater from 'util/objectUpdater';

import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import { IUpdateModel } from 'models/UpdateModel';
import SupplierInfo from 'models/SupplierInfoAbstractModel';
import { cloneObj } from 'util/helpers';
import { DeliveryStatus } from 'util/enums';
import UserModel from 'models/UserModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class DeliveryListModel extends SupplierInfo implements IUpdateModel<DeliveryListModel> {
  public readonly updater = new ObjectUpdater<DeliveryListModel>();

  @observable public advisedGoods?: DeliveryListAdvisedGoodModel[] = [new DeliveryListAdvisedGoodModel()];
  @observable public createdAt?: string = '';
  @observable public flagged?: boolean = false;
  @observable public wasFlagged?: boolean = false;
  @observable public grn?: string = '';
  @observable public id?: string = null;
  @observable public status?: DeliveryStatus = null;
  @observable public synced?: boolean = false;
  @observable public vehicleRegistrationNumber?: string = '';
  @observable public tenantId?: string = null;
  @observable public trader?: UserModel<IUserModelConstructObj> = null;

  @computed get advisedGoodsToClam() {
    const toClamArray = this.advisedGoods.filter(
      (item: DeliveryListAdvisedGoodModel) => item.claimedBy && item.claimedBy.user === null
    );
    return toClamArray && toClamArray.length !== 0 ? toClamArray.length : null;
  }

  @computed
  public get isAGWeightDiscrepancy() {
    return this.advisedGoods.some((ag: DeliveryListAdvisedGoodModel) => !!ag.weightDiscrepancy);
  }

  @computed
  public get traderFullName() {
    return this.trader !== null ? `${this.trader.firstname} ${this.trader.lastname}` : '';
  }

  @action
  public update = (obj: DeliveryListModel) => {
    const newDeliveryListModel = cloneObj(obj);

    if (newDeliveryListModel) {
      newDeliveryListModel.advisedGoods = newDeliveryListModel.advisedGoods.map((r: DeliveryListAdvisedGoodModel) =>
        new DeliveryListAdvisedGoodModel().update(r)
      );
    }

    this.updater.update(this, newDeliveryListModel, DeliveryListModel);
    return this;
  };

  @action
  public setStatus = (newStatus: DeliveryStatus) => {
    this.status = newStatus;
  };
}
