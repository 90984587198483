import * as React from 'react';
import checkLatestAppVersion from 'util/checkLatestAppVersion';
import { observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import { UncontrolledTooltip } from 'reactstrap';
import env from 'env';
import CopyToClipboardButton from 'components/CopyToClipboardButton';

interface IUIVersion {
  translateService?: TranslateService;
}

@inject('translateService')
@observer
export default class UIVersion extends React.Component<IUIVersion> {
  @observable private _sha = '';

  public componentDidMount() {
    this._getLatestAppVersion();
  }

  public render(): React.ReactNode {
    const {
      translateService: { t },
    } = this.props;
    return (
      <div className="ui-version">
        <div id="ui-version-commit" className="ui-version-commit text-truncate">
          {t.GLOBAL_LABEL_VERSION(env.version)}
        </div>
        <CopyToClipboardButton
          text={this._sha}
          tooltipText={t.GLOBAL_COPY_TITLE('commit number')}
          buttonClassName={'ui-version-copy'}
          messageOnCopied={t.GLOBAL_COPY_SUCCESS_MESSAGE('Version')}
        />
        <UncontrolledTooltip
          placement="bottom"
          target="ui-version-commit"
          modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
        >
          <span>SHA: {this._sha}</span>
          <br />
          <span>Version: {env.version}</span>
        </UncontrolledTooltip>
      </div>
    );
  }

  private _getLatestAppVersion = async () => {
    const newVersion = await checkLatestAppVersion();
    runInAction(() => {
      this._sha = newVersion;
    });
  };
}
