import { action, computed, observable } from 'mobx';

import RootService from 'services/RootService';
import RootStore from 'stores/RootStore';
import LasernetPrinterModel from 'models/LasernetPrinterModel';
import { sortActiveFirst } from 'util/helpers';
import CommonApiCalls from 'pod/admin/commonComponents/CommonApiCalls';
import LasernetDocumentTypeModel from 'models/LasernetDocumentTypeModel';
import LasernetPrinterConfigurationModel from 'models/LasernetPrinterConfigurationModel';
import IdNameModel from 'models/IdNameModel';
import { LASERNET_PRINTER_CONFIGURATION_ALL_VALUE } from 'util/constants';

export default class LasernetPrinterStore {
  public constructor(public readonly rootService: RootService, public readonly rootStore: RootStore) {}

  @observable public printers: LasernetPrinterModel[] = [];
  @observable public documentTypes: LasernetDocumentTypeModel[] = [];
  @observable public configurations: LasernetPrinterConfigurationModel[] = [];

  @action
  public setPrinterList = (res: LasernetPrinterModel[]) => {
    this.printers = res.map((e: LasernetPrinterModel) => new LasernetPrinterModel().update(e));
  };

  @action
  public setDocumentTypes = (res: LasernetDocumentTypeModel[]) => {
    this.documentTypes = res.map((e: LasernetDocumentTypeModel) => new LasernetDocumentTypeModel().update(e));
  };

  @action
  public setConfigurations = (res: LasernetPrinterConfigurationModel[]) => {
    this.configurations = res.map((e: LasernetPrinterConfigurationModel) =>
      new LasernetPrinterConfigurationModel().update(e)
    );
  };

  public printerApi = new CommonApiCalls<LasernetPrinterModel>(
    this.rootService,
    'lasernet/printers',
    this.setPrinterList
  );

  @computed
  public get printerListActiveFirst(): LasernetPrinterModel[] {
    return sortActiveFirst<LasernetPrinterModel>(this.printers);
  }

  @computed
  public get printerListOnlyActive(): LasernetPrinterModel[] {
    return this.printers.filter((p) => p.active);
  }

  @computed
  public get documentTypesList(): LasernetDocumentTypeModel[] {
    return this.documentTypes;
  }

  @computed
  public get configurationsList(): LasernetPrinterConfigurationModel[] {
    return this.configurations;
  }

  @computed
  public get documentTypesListForAutocomplete(): IdNameModel[] {
    const documentTypes = this.documentTypes.map((item) =>
      new IdNameModel().update({ id: item.id, name: item.documentTypeName } as IdNameModel)
    );
    const allVariant = new IdNameModel().update({
      id: LASERNET_PRINTER_CONFIGURATION_ALL_VALUE,
      name: LASERNET_PRINTER_CONFIGURATION_ALL_VALUE,
    } as IdNameModel);

    return [allVariant, ...documentTypes];
  }

  @computed
  public get userListForAutocomplete(): IdNameModel[] {
    const users = this.rootStore.adminStore.userListActiveFirst.map((item) =>
      new IdNameModel().update({ id: item.id, name: item.fullName } as IdNameModel)
    );
    const allValue = new IdNameModel().update({
      id: LASERNET_PRINTER_CONFIGURATION_ALL_VALUE,
      name: LASERNET_PRINTER_CONFIGURATION_ALL_VALUE,
    } as IdNameModel);

    return [allValue, ...users];
  }

  public async getAllDocumentTypeItems() {
    const items = await this._getDocumentTypeRequest();
    this.setDocumentTypes(items);
    return this.documentTypes;
  }

  public async putDocumentType(item: LasernetDocumentTypeModel) {
    await this._putDocumentTypeRequest(item);
    await this.getAllDocumentTypeItems();
    return this.documentTypes;
  }

  public async getPrinterConfigurations() {
    const items = await this._getConfigurationsRequest();
    this.setConfigurations(items);
    return this.configurationsList;
  }

  public async createConfiguration(item: LasernetPrinterConfigurationModel) {
    await this._postConfigurationsRequest(item);
    await this.getPrinterConfigurations();
    return this.configurationsList;
  }

  public async updateConfiguration(item: LasernetPrinterConfigurationModel) {
    await this._putConfigurationsRequest(item);
    await this.getPrinterConfigurations();
    return this.configurationsList;
  }

  public async deleteConfiguration(item: LasernetPrinterConfigurationModel) {
    await this._deleteConfiguration(item.id);
    await this.getPrinterConfigurations();
    return this.configurationsList;
  }

  public getConfigurationById(id: string) {
    return this._getConfigurationByIdRequest(id);
  }

  public async fetchDocumentTypeById(id: string) {
    return this._getDocumentTypeByIdRequest(id);
  }

  public getUserById = (userId: string): IdNameModel => {
    return this.userListForAutocomplete.find((user) => user.id === userId);
  };

  public getPrinterById = (printerId: string) => {
    return this.printerListActiveFirst.find((printer) => printer.id === printerId);
  };

  public getDocumentTypeById = (documentTypeId: string) => {
    return this.documentTypesListForAutocomplete.find((documentType) => documentType.id === documentTypeId);
  };

  public printReceivedGood = (rgId: string, data: { numberOfCopies: number }) => {
    return this.rootService.ajaxService.put(`received-goods/${rgId}/lasernet-print`, data);
  };

  private async _getConfigurationsRequest(): Promise<LasernetPrinterConfigurationModel[]> {
    return this.rootService.ajaxService.get('lasernet/printer-configs');
  }

  private async _putConfigurationsRequest(item: LasernetPrinterConfigurationModel) {
    return this.rootService.ajaxService.put(`lasernet/printer-configs/${item.id}`, item.constructSaveObj);
  }

  private async _postConfigurationsRequest(item: LasernetPrinterConfigurationModel) {
    return this.rootService.ajaxService.post(`lasernet/printer-configs`, item.constructSaveObj);
  }

  private async _getConfigurationByIdRequest(id: string): Promise<LasernetPrinterConfigurationModel> {
    return this.rootService.ajaxService.get(`lasernet/printer-configs/${id}`);
  }

  private async _deleteConfiguration(id: string) {
    return this.rootService.ajaxService.delete(`lasernet/printer-configs/${id}`);
  }

  private async _getDocumentTypeRequest(): Promise<LasernetDocumentTypeModel[]> {
    return this.rootService.ajaxService.get('lasernet/document-types');
  }

  private async _putDocumentTypeRequest(item: LasernetDocumentTypeModel) {
    return this.rootService.ajaxService.put(`lasernet/document-types/${item.id}`, item.constructSaveObj);
  }

  private async _getDocumentTypeByIdRequest(id: string): Promise<LasernetDocumentTypeModel> {
    return this.rootService.ajaxService.get(`lasernet/document-types/${id}`);
  }
}
