import * as React from 'react';
import TranslateService from 'services/TranslateService';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';
import { action, observable, runInAction } from 'mobx';

interface IChangeLanguageProps {
  width?: string;
  translateService?: TranslateService;
}

enum Language {
  de = 'de',
  es = 'es',
  en_us = 'en_us',
  en = 'en',
  fr = 'fr',
  it = 'it',
  zh = 'zh',
  ja = 'ja',
}

enum NextLanguage {
  de = 'Deutsch',
  es = 'Español',
  en_us = 'English_US',
  en = 'English_UK',
  fr = 'French',
  it = 'Italian',
  zh = '中文',
  ja = '日本人',
}

@inject('translateService')
@observer
export default class ChangeLanguage extends React.Component<IChangeLanguageProps> {
  public constructor(props: IChangeLanguageProps) {
    super(props);
    runInAction(() => (this._isOpen = false));
  }

  @observable
  private _isOpen: boolean;

  public changeLanguage = (e: React.SyntheticEvent<HTMLElement>): void => {
    const { translateService } = this.props;
    translateService.setLanguage(e.currentTarget.dataset.lang as Language);
  };

  public render() {
    const { translateService, width } = this.props;

    return (
      <Dropdown data-test="language-dropdown" isOpen={this._isOpen} toggle={this._toggleIsOpen} className={width}>
        <DropdownToggle
          data-test="language-dropdown-toggle"
          outline={false}
          size="sm"
          caret
          className={classnames([width, 'btn btn-secondary btn-block margin-bottom change-language'])}
        >
          {translateService.t.GLOBAL_LABEL_LANGUAGE(translateService.language.toUpperCase())}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.en}
            className={classnames({ active: this._isEnActive })}
          >
            {NextLanguage.en}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.en_us}
            className={classnames({ active: this._isEnUSActive })}
          >
            {NextLanguage.en_us}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.de}
            className={classnames({ active: this._isDeActive })}
          >
            {NextLanguage.de}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.es}
            className={classnames({ active: this._isEsActive })}
          >
            {NextLanguage.es}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.fr}
            className={classnames({ active: this._isFrActive })}
          >
            {NextLanguage.fr}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.it}
            className={classnames({ active: this._isItActive })}
          >
            {NextLanguage.it}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.zh}
            className={classnames({ active: this._isZhActive })}
          >
            {NextLanguage.zh}
          </DropdownItem>
          <DropdownItem
            onClick={this.changeLanguage}
            data-lang={Language.ja}
            className={classnames({ active: this._isJaActive })}
          >
            {NextLanguage.ja}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  @action
  private _toggleIsOpen: () => void = () => {
    this._isOpen = !this._isOpen;
  };

  private get _isEnActive(): boolean {
    return this.props.translateService.language === Language.en;
  }

  private get _isEnUSActive(): boolean {
    return this.props.translateService.language === Language.en_us;
  }

  private get _isDeActive(): boolean {
    return this.props.translateService.language === Language.de;
  }

  private get _isEsActive(): boolean {
    return this.props.translateService.language === Language.es;
  }

  private get _isFrActive(): boolean {
    return this.props.translateService.language === Language.fr;
  }

  private get _isItActive(): boolean {
    return this.props.translateService.language === Language.it;
  }

  private get _isZhActive(): boolean {
    return this.props.translateService.language === Language.zh;
  }

  private get _isJaActive(): boolean {
    return this.props.translateService.language === Language.ja;
  }
}
