import { action, observable, computed } from 'mobx';

export default class VersionStore {
  @observable private _isNeedUpdateVersion: boolean = false;

  @computed
  public get needUpdateVersion(): boolean {
    return this._isNeedUpdateVersion;
  }

  @action
  public setIsNeedUpdateVersion = (val: boolean) => {
    this._isNeedUpdateVersion = val;
  };
}
