import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import IdNameActiveModel from 'models/IdNameActiveModel';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

@inject('adminStore', 'translateService')
@observer
export default class TargetBatchDetailRoute extends React.Component<IProps> {
  @observable
  public content: IdNameActiveModel = null;

  @action
  public updateContent = (newTargetBatch: IdNameActiveModel) => {
    this.content = new IdNameActiveModel().update(newTargetBatch);
  };

  public render() {
    const {
      translateService: { t },
      adminStore: { targetBatchApi, targetBatchList },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.TARGET_BATCH}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_TARGET_BATCH}
        itemsList={targetBatchList}
        api={targetBatchApi}
        updateItem={this.updateContent}
      />
    );
  }
}
