import { action, computed, observable } from 'mobx';
import UserConfigurationModel from 'models/UserConfigurationModel';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import { CountryCode } from 'util/enums';

export default class WorkflowModel extends UpdateModel<WorkflowModel> {
  public id: string;
  @observable public configuration: UserConfigurationModel = new UserConfigurationModel();
  @observable public code: CountryCode = CountryCode.INIT;

  @computed get isUK(): boolean {
    return this.code === CountryCode.UK;
  }

  @computed get isUS(): boolean {
    return this.code === CountryCode.US;
  }

  @computed get isDE(): boolean {
    return this.code === CountryCode.DE;
  }

  @computed get isDE_D365(): boolean {
    return this.code === CountryCode.DE_D365;
  }

  @computed get isIT(): boolean {
    return this.code === CountryCode.IT;
  }

  @computed get isFR(): boolean {
    return this.code === CountryCode.FR;
  }

  @action
  public update = (obj: WorkflowModel): WorkflowModel => {
    const newWorkflow = cloneObj(obj);

    if (newWorkflow) {
      newWorkflow.configuration = newWorkflow.configuration
        ? new UserConfigurationModel().update(newWorkflow.configuration)
        : new UserConfigurationModel();
    }

    this.updater.update(this, newWorkflow, WorkflowModel);
    return this;
  };
}
