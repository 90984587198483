import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as H from 'history';
import { Route } from 'react-router-dom';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';

import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TenantDetailComponent from 'pod/superAdmin/tenants/TenantDetailComponent';
import TenantDetailRoute from 'pod/superAdmin/tenants/TenantDetailRoute';
import TenantModel from 'models/TenantModel';
import NavlinkCustom from 'domain/NavlinkCustom';
import TranslateService from 'services/TranslateService';
import WorkflowModel from 'models/WorkflowModel';
import { CountryCode } from 'util/enums';

interface IProps {
  history: H.History;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

@inject('superAdminStore', 'translateService')
@observer
export default class TenantListRoute extends React.Component<IProps> {
  @observable private addTenantModal: boolean = false;
  @observable private newTenantModel: TenantModel = new TenantModel();

  public componentDidMount() {
    const { superAdminStore } = this.props;
    superAdminStore.getTenants();
    superAdminStore.getWorkflows();
    superAdminStore.getTenantCommon();
    superAdminStore.getSharePointSites();
  }

  // SHOW / HIDE MODAL WINDOW
  public toggleAddTenantModal = () => {
    this._setAddTenantModal(!this.addTenantModal);
    if (!this.addTenantModal) {
      this._setNewTenantModel(new TenantModel());
    }
  };

  // POST CALL FOR CREATE NEW TENANT
  public saveNewTenant = (tenant: TenantModel) => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    return superAdminStore.postTenant(tenant.constructSaveObj).then(() => {
      this.toggleAddTenantModal();
      notification.success(t.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG);
      this.props.history.push(`/super-admin/tenants`);
    });
  };

  public render() {
    const {
      superAdminStore: { workflowList, tenants },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.SUPERADMIN_TENANTLIST_HEADER}
              <Button
                data-test="add-new-button"
                className="float-right"
                color="success"
                onClick={this.toggleAddTenantModal}
              >
                <FontAwesome name="plus" className="mr-2" />
                {t.SUPERADMIN_TENANTLIST_ADD_BTN}
              </Button>
            </h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="pl-3">{t.GLOBAL_LABEL_NAME}</th>
                  <th className="pr-3">{t.SUPERADMIN_TENANTLIST_GRN}</th>
                  <th>{t.GLOBAL_LABEL_CODE}</th>
                  {this._isSiteColumnShown && <th data-test="site-column">{t.SUPERADMIN_TENANTDETAIL_SITE}</th>}
                </tr>
              </thead>
              {tenants &&
                tenants.length !== 0 &&
                tenants.map((tenant: TenantModel) => (
                  <tbody key={tenant.id}>
                    <NavlinkCustom
                      data-test="tenant-item-link"
                      to={`/super-admin/tenants/${tenant.id}`}
                      tagName="tr"
                      className="pointer"
                    >
                      <td className="pl-3">{tenant.name}</td>
                      <td className="pr-3">{tenant.grnPrefix}</td>
                      <td data-test="code">{tenant.code}</td>
                      {this._isSiteColumnShown && <td data-test="site">{tenant.site}</td>}
                    </NavlinkCustom>
                  </tbody>
                ))}
            </table>
          </Col>
        </Row>

        {/* MODAL - CREATE NEW TENANT */}
        <Modal
          isOpen={this.addTenantModal}
          toggle={this.toggleAddTenantModal}
          backdrop="static"
          data-test="create-tenant"
        >
          <ModalHeader toggle={this.toggleAddTenantModal}>{t.SUPERADMIN_TENANTLIST_CREATE_NEW}</ModalHeader>
          <ModalBody>
            <TenantDetailComponent
              workflowList={workflowList}
              tenant={this.newTenantModel}
              saveAction={this.saveNewTenant}
              toggleAddTenantModal={this.toggleAddTenantModal}
              workflowTenants={[]}
            />
          </ModalBody>
        </Modal>

        <Route path="/super-admin/tenants/:id" component={TenantDetailRoute} />
      </Container>
    );
  }

  @action
  private _setAddTenantModal = (val: boolean) => {
    this.addTenantModal = val;
  };

  @action
  private _setNewTenantModel = (val: TenantModel) => {
    this.newTenantModel = val;
  };

  private isDE_D365 = (tenant: TenantModel) => {
    const {
      superAdminStore: { workflowList },
    } = this.props;
    const currentWorkflow =
      tenant.workflowId && workflowList.find((workflow: WorkflowModel) => workflow.id === tenant.workflowId);
    return !!currentWorkflow && currentWorkflow.code === CountryCode.DE_D365;
  };

  private get _isSiteColumnShown() {
    const {
      superAdminStore: { tenants },
    } = this.props;
    return !!tenants && tenants.some((tenant: TenantModel) => this.isDE_D365(tenant));
  }
}
