import { action, computed, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { CountryCode } from 'util/enums';

export default abstract class ValidateModel<
  ValidationKeysType,
  UpdateMethodParamsType = undefined,
  ConstructorParamsType = undefined
> extends UpdateModel<
  ValidateModel<ValidationKeysType, UpdateMethodParamsType, ConstructorParamsType>,
  UpdateMethodParamsType,
  ConstructorParamsType
> {
  public abstract generalValidatorKeys: Array<keyof Partial<ValidationKeysType>>;
  public abstract validationKeysByCountryCode: Map<CountryCode, Array<keyof Partial<ValidationKeysType>>>;
  public abstract get validators(): ValidationKeysType;
  @observable public needToCheck: boolean = false;

  @computed
  public get invalidValidatorKeys(): Map<CountryCode, Array<keyof Partial<ValidationKeysType>>> {
    const newMap: Map<CountryCode, Array<keyof Partial<ValidationKeysType>>> = new Map();
    this.validationKeysByCountryCode.forEach((value: Array<keyof Partial<ValidationKeysType>>, key: CountryCode) => {
      newMap.set(
        key,
        value.filter((val: keyof Partial<ValidationKeysType>) => !this.validators[val])
      );
    });
    return newMap;
  }

  @computed
  public get isValidGeneral(): boolean {
    return this.generalValidatorKeys.every((g) => this.validators[g]);
  }

  @computed
  public get hasValidation(): boolean {
    for (const keysArray of this.validationKeysByCountryCode.values()) {
      if (keysArray.length) {
        return true;
      }
    }
    return false;
  }

  public isValid(countryCode: CountryCode): boolean {
    return this.invalidValidatorKeys.get(countryCode).length === 0;
  }

  public isValidByKey = (key: keyof Partial<ValidationKeysType>): boolean => {
    return !!this.validators[key];
  };

  @action
  public setNeedToCheck = (val: boolean) => {
    this.needToCheck = val;
  };

  @action
  public validateOnlyExistingFields = (
    keys: (keyof Partial<ValidationKeysType>)[],
    countryCode: CountryCode
  ): boolean => {
    return keys.every((key) => !this.invalidValidatorKeys.get(countryCode).includes(key));
  };
}
