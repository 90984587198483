import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import * as H from 'history';
import LabAnalysisModel from 'models/LabAnalysisModel';
import LabAnalysisComponent from 'pod/advisedGoodLab/LabAnalysisComponent';
import LabStatusModel from 'models/LabStatusModel';
import PrinterModel from 'models/PrinterModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import LabStatsModel from 'models/LabStatsModel';
import TranslateService from 'services/TranslateService';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../../assets/i18n/i18n';
import { action, observable } from 'mobx';

interface IProps {
  updateAdvisedGoodAfterModalClose: () => Promise<void>;
  advisedGoodDescription: string;
  advisedGoodGrn: string;
  advisedGoodId: string;
  advisedGoodSupplier: string;
  advisedGoodTenant: string;
  history: H.History;
  item: ReceivedGoodsLabModel;
  key: string;
  labAnalysisPut: (labInput: LabAnalysisModel) => Promise<void>;
  labAnalysisStatusPut: (labAnalysis: LabAnalysisModel, newLocationId: string, newStatusId: string) => void;
  labStatuses: LabStatusModel[];
  preOpenModal: (itemId: string) => boolean;
  printers: PrinterModel[];
  translateService?: TranslateService;
}

interface IState {
  isModalOpen: boolean;
}

@inject('translateService')
@observer
export default class SortingReportLabComponent extends React.Component<IProps, IState> {
  @observable private _isSortingReportModal: boolean = undefined;
  @observable private _isPrintLabModalOpen: boolean = undefined;

  @observable private _printImageUrl: string = '';

  @action
  public openPrintLabModel = () => {
    this._isPrintLabModalOpen = true;
  };

  @action
  public changePrintImageUrl = (newImage: string) => {
    this._printImageUrl = newImage;
  };

  public componentDidMount() {
    if (this.props.preOpenModal(this.props.item.id)) {
      this._openSortingReportModal();
    }
  }

  public render() {
    const {
      item,
      advisedGoodDescription,
      advisedGoodGrn,
      advisedGoodSupplier,
      advisedGoodTenant,
      translateService: { t },
    } = this.props;

    return (
      <tbody>
        <tr className="pointer">
          <td className="pl-3">{item.description}</td>
          <td className="text-right">
            {item.stats.map((stat: LabStatsModel, index) => (
              <span key={`ts-${index}`} className="pl-2 font-12 font-light">
                {getTranslation(`STATUS_LABSTATUSES_${stat.status.name.toUpperCase()}` as keyof I18N, t)} ({stat.count})
              </span>
            ))}
          </td>
          <td className="text-right pr-3">
            <Button
              data-test="sorting-report-modal-open-button"
              color="success"
              className="width-38"
              onClick={this._openSortingReportModal}
            >
              <FontAwesome name="flask" />
            </Button>

            {/* MODAL - SORTING REPORT DETAIL MODAL */}
            {this._isSortingReportModal && (
              <Modal
                data-test="sorting-report-modal"
                isOpen={this._isSortingReportModal}
                toggle={this._closeSortingReportModal}
                backdrop="static"
              >
                <ModalHeader toggle={this._closeSortingReportModal}>{t.LABORATORY_ANALISYS_HEADER}</ModalHeader>
                <ModalBody className="pl-0 pr-0 pt-0 pb-0">
                  <Container>
                    <Row className="bg-white border-bottom">
                      <Col className="pt-2 pb-2">
                        <table className="full-width">
                          <tbody>
                            <tr>
                              <td className="width-130 font-light">{t.GLOBAL_LABEL_GRN}</td>
                              <td>{advisedGoodGrn}</td>
                            </tr>
                            <tr>
                              <td className="font-light">{t.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION}</td>
                              <td>{advisedGoodDescription}</td>
                            </tr>
                            <tr>
                              <td className="font-light">{t.GLOBAL_LABEL_SUPPLIER}</td>
                              <td>{advisedGoodSupplier}</td>
                            </tr>
                            <tr>
                              <td className="font-light">{t.GLOBAL_LABEL_OPERATION}</td>
                              <td>{advisedGoodTenant}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>

                    {item.labAnalysis &&
                      item.labAnalysis.length !== 0 &&
                      item.labAnalysis.map((labInput: LabAnalysisModel, index: number) => (
                        <div key={`la-${labInput.id ? labInput.id : index}`}>
                          <LabAnalysisComponent
                            changePrintImageUrl={this.changePrintImageUrl}
                            openPrintImageModal={this.openPrintLabModel}
                            labInput={labInput}
                            receivedGood={item}
                            labAnalysisStatusPut={this.props.labAnalysisStatusPut}
                            printers={this.props.printers}
                            labStatuses={this.props.labStatuses}
                          />
                          <div className="clearfix">
                            <Button
                              data-test="save-button"
                              color="success"
                              className="min-width-200 pull-right mt-3 mb-3"
                              type="button"
                              onClick={() => this._saveHandler(labInput)}
                            >
                              {t.GLOBAL_LABEL_SAVE}
                            </Button>
                          </div>
                        </div>
                      ))}
                  </Container>
                </ModalBody>
              </Modal>
            )}

            {this._isPrintLabModalOpen && this._printImageUrl && (
              <Modal
                data-test="print-lab-modal"
                isOpen={this._isPrintLabModalOpen}
                toggle={this._closePrintModal}
                backdrop="static"
              >
                <ModalHeader toggle={this._closePrintModal} />
                <img className="image-by-width" src={this._printImageUrl} alt="qr code image" />
              </Modal>
            )}
          </td>
        </tr>
      </tbody>
    );
  }

  @action
  private _closeSortingReportModal = () => {
    this._isSortingReportModal = false;
    this.props.updateAdvisedGoodAfterModalClose();
  };

  @action
  private _openSortingReportModal = () => (this._isSortingReportModal = true);

  @action
  private _closePrintModal = () => (this._isPrintLabModalOpen = false);

  private _saveHandler = (labInput: LabAnalysisModel) => {
    this.props.labAnalysisPut(labInput).then(() => {
      this._closeSortingReportModal();
    });
  };
}
