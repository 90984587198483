import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import IdNameActiveModel from 'models/IdNameActiveModel';
import * as classnames from 'classnames';

interface IProps {
  items: IdNameActiveModel[];
  title: string;
  onRefreshCallback?: () => void;
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

const ProductVariants: React.FC<IProps> = (props) => {
  const {
    translateService: { t },
    items,
    title,
  } = props;
  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <h2 className="clearfix">{title}</h2>
          <table className="custom-table mb-3">
            <thead>
              <tr>
                <th className="pl-3">{t.GLOBAL_LABEL_NAME}</th>
              </tr>
            </thead>
            {items.length > 0
              ? items.map((item: IdNameActiveModel) => (
                  <tbody key={item.name}>
                    <tr
                      data-test={`quality-row-${item.name}`}
                      className={classnames([{ 'bg-secondary': !item.active }])}
                    >
                      <td data-test="row-name" className="pl-3">
                        {item.name}
                      </td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default inject('adminStore', 'translateService')(observer(ProductVariants));
