import RootService from 'services/RootService';
import UploadAndDownloadAttachmentsAbstractStore from 'stores/UploadAndDownloadAttachmentsAbstractStore';
import { action, computed, observable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import { ITransferData } from 'models/TransferModel';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import RootStore from 'stores/RootStore';

export interface ICommonDataForSortingReportResponse {
  machines: IdNameModel[];
  targetBatches: IdNameModel[];
  packaging: IdNameModel[];
  bulkDensities: { values: string[]; defaultBulkDensity: string };
  foundQualities: string[];
  mainTypes: string[];
  materialDescriptions: string[];
}

export default class ReceivedGoodsStore extends UploadAndDownloadAttachmentsAbstractStore {
  public constructor(protected readonly rootService: RootService, private readonly rootStore: RootStore) {
    super(rootService);
  }

  @observable
  public machines: IdNameModel[] = [];

  @observable
  public targetBatches: IdNameModel[] = [];

  @observable
  public packaging: IdNameModel[] = [];

  @observable
  public bulkDensities: string[] = [];

  @observable
  public defaultBulkDensity = '';

  @observable
  public foundQualities: string[] = [];

  @observable
  public mainTypes: string[] = [];

  @observable
  public materialDescriptions: string[] = [];

  @computed
  public get isSortingReportDataExist(): boolean {
    return !!(
      this.machines &&
      this.machines.length &&
      this.targetBatches &&
      this.targetBatches.length &&
      this.packaging &&
      this.packaging.length
    );
  }

  public urlSection: string = 'received-goods';

  public printYard = (id: string) => {
    return this.rootService.ajaxService.download(`received-goods/${id}/print-yard.png`);
  };

  public postFlagReceivedGood = (receivedGoodId: string, isFlagged: boolean) => {
    return this.rootService.ajaxService.post(`received-goods/${receivedGoodId}/flag`, { isFlagged });
  };

  public getCommonDataForSortingReportRequest = () => {
    return this.rootService.ajaxService.get('common/received-good');
  };

  public getCommonDataForSortingReport = () => {
    if (this.isSortingReportDataExist) {
      return Promise.resolve();
    }

    return this.getCommonDataForSortingReportRequest().then((res: ICommonDataForSortingReportResponse) => {
      this._setCommonSortingReportData(res);
    });
  };

  public getReceivedGood(receivedGoodId: string): Promise<ReceivedGoodsModel> {
    const { defaultMaterials, nonDefaultMaterial } = this.rootStore.commonStore;
    const { countryCode, rgContaminationsSectionRequired, rgClassificationsSectionRequired } = this.rootStore.viewStore;
    return this.rootService.ajaxService.get(`received-goods/${receivedGoodId}`).then((x) =>
      new ReceivedGoodsModel({
        countryCode,
        rgClassificationsSectionRequired,
        rgContaminationsSectionRequired,
      }).update(x, defaultMaterials, nonDefaultMaterial)
    );
  }

  /* BOF LAB ANALYSIS */
  public postLabAnalysis(receivedGoodId: string, labStatusSelected: string) {
    return this.rootService.ajaxService.post(`lab-analysis/${receivedGoodId}`, {
      labInputTypeId: labStatusSelected,
    });
  }

  /* EOF LAB ANALYSIS */

  // TRANSFER RECEIVED GOOD
  public postTransferDeliveredGood(
    deliveredGood: ReceivedGoodsModel,
    transfer: ITransferData
  ): Promise<AdvisedGoodsModel> {
    return this.rootService.ajaxService
      .post(`received-goods/${deliveredGood.id}/transfer`, transfer)
      .then((res) => this.rootStore.advisedGoodsStore.getUpdatedAGModelInstanceWithProcessing(res));
  }

  @action
  private _setCommonSortingReportData = (data: ICommonDataForSortingReportResponse) => {
    this.machines = data.machines;
    this.targetBatches = data.targetBatches;
    this.packaging = data.packaging;
    this.bulkDensities = data.bulkDensities.values;
    this.defaultBulkDensity = data.bulkDensities.defaultBulkDensity;
    this.mainTypes = data.mainTypes;
    this.foundQualities = data.foundQualities;
    this.materialDescriptions = data.materialDescriptions;
  };
}
