import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import * as Popper from 'popper.js';
import * as FontAwesome from 'react-fontawesome';
import { instance as notification } from 'util/notification';
import * as classnames from 'classnames';

interface IProps {
  messageOnCopied?: string;
  text?: string;
  tooltipText?: string;
  buttonClassName?: string;
  tooltipPlacement?: Popper.Placement;
}

interface IDefaultProps {
  text?: string;
  tooltipPlacement?: Popper.Placement;
}

@observer
export default class CopyToClipboardButton extends React.Component<IProps> {
  public static defaultProps: IDefaultProps = {
    text: '',
    tooltipPlacement: 'top',
  };

  @observable private _btnRef: HTMLElement;

  public copyToClipboard = () => {
    const { text, messageOnCopied } = this.props;
    if (!!text) {
      const textField = document.createElement('textarea');
      textField.value = text;
      textField.style.position = 'absolute';
      textField.style.left = '-9999px';
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      if (messageOnCopied) {
        notification.success(messageOnCopied);
      }
    }
  };

  @action
  public setRef = (btn: HTMLElement) => {
    this._btnRef = btn;
  };

  public render() {
    const { tooltipText, tooltipPlacement } = this.props;
    return (
      <React.Fragment>
        <Button
          innerRef={this.setRef}
          size="sm"
          className={classnames('position-center', this.props.buttonClassName)}
          onClick={this.copyToClipboard}
        >
          <FontAwesome name="copy" />
        </Button>
        {this._btnRef && tooltipText && (
          <UncontrolledTooltip
            placement={tooltipPlacement}
            target={this._btnRef}
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
          >
            {tooltipText}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    );
  }
}
