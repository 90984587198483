import { action, computed, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

import IdNameModel from 'models/IdNameModel';
import OperatorModel from 'models/OperatorModel';
import UserModel from 'models/UserModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import { AdvisedGoodWeighingTypes } from 'util/enums';

export default class DeliveryListAdvisedGoodModel extends UpdateModel<DeliveryListAdvisedGoodModel> {
  @observable public claimedBy?: OperatorModel;
  @observable public status?: string;
  @observable public advisedDescription?: string = '';
  @observable public createdAt?: string = '';
  @observable public deliveryId?: string = '';
  @observable public flagged?: boolean = false;
  @observable public wasFlagged?: boolean = false;
  @observable public agTitle?: string = '';
  @observable public id?: string = null;
  @observable public index?: number = null;
  @observable public nonAdvisedGood?: boolean = false;
  @observable public responsible?: UserModel<IUserModelConstructObj> = null;
  @observable public yardLocation?: IdNameModel = null;
  @observable public weightDiscrepancy?: boolean = false;
  @observable public weighingType?: string = AdvisedGoodWeighingTypes.WEIGHBRIDGE;

  @action
  public update = (obj: DeliveryListAdvisedGoodModel) => {
    const newDeliveryListAdvisedGoodModel = cloneObj(obj);

    if (newDeliveryListAdvisedGoodModel) {
      newDeliveryListAdvisedGoodModel.yardLocation = new IdNameModel().update(
        newDeliveryListAdvisedGoodModel.yardLocation
      );
      if (newDeliveryListAdvisedGoodModel.claimedBy && newDeliveryListAdvisedGoodModel.claimedBy.user !== null) {
        newDeliveryListAdvisedGoodModel.claimedBy = new OperatorModel().update(
          newDeliveryListAdvisedGoodModel.claimedBy
        );
      }

      if (newDeliveryListAdvisedGoodModel.responsible) {
        newDeliveryListAdvisedGoodModel.responsible = new UserModel<IUserModelConstructObj>().update(
          newDeliveryListAdvisedGoodModel.responsible
        );
      }
    }

    this.updater.update(this, newDeliveryListAdvisedGoodModel, DeliveryListAdvisedGoodModel);
    return this;
  };

  @action
  public setClaimedBy(user: UserModel<IUserModelConstructObj>) {
    this.claimedBy = new OperatorModel().update({ date: '', user } as OperatorModel);
  }

  @action
  public setStatus(val: string) {
    this.status = val;
  }

  @action
  public setResponsible(val: UserModel<IUserModelConstructObj>) {
    this.responsible = val;
  }

  @computed
  public get isScaleWeights(): boolean {
    return this.weighingType === AdvisedGoodWeighingTypes.SCALE;
  }
}
