import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { DateRangePicker, FocusedInputShape, isInclusivelyAfterDay } from 'react-dates';
/** Needed for DateRangePicker */
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/zh-cn';
import 'moment/locale/ja';
import '../../assets/sass/domain/DateRangePicker.scss';
import { Moment, utc, defineLocale } from 'moment';
import TranslateService from 'services/TranslateService';
import { MAX_MOBILE_PORTRAIT_WIDTH, DATE_FORMAT_DEFAULT } from 'util/constants';
import useWindowWidth from 'components/UseWindowWidth';
import DeliveryCommonFilterStore from 'pod/deliveries/DeliveryCommonFilterStore';
import ViewStore from 'stores/ViewStore';

defineLocale('zh', { parentLocale: 'zh-cn' });

interface IProps {
  deliveryCommonFilterStore: DeliveryCommonFilterStore;
  getListData: (queryParams: string) => void;
  windowWidth?: number;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

@inject('translateService', 'viewStore')
@observer
export class DateRangePickerComponent extends React.Component<IProps> {
  @observable
  private _focusedDate: FocusedInputShape = undefined;

  @action
  public changeDateRange = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }): void => {
    const { deliveryCommonFilterStore, getListData } = this.props;

    deliveryCommonFilterStore.changeDateRange(startDate, endDate);
    getListData(deliveryCommonFilterStore.buildQueryString);
  };

  @action
  public changeDateInputFocus = (focusedInput: FocusedInputShape): void => {
    this._focusedDate = focusedInput;
  };

  public render(): React.ReactNode {
    const {
      deliveryCommonFilterStore: { startDate, endDate },
      translateService: { t },
      viewStore,
    } = this.props;

    return (
      <React.Fragment>
        <DateRangePicker
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="start_date_id" // PropTypes.string.isRequired,
          startDatePlaceholderText={t.GLOBAL_DATERANGEPICKER_START_DATE_PLH}
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="end_date_id" // PropTypes.string.isRequired,
          endDatePlaceholderText={t.GLOBAL_DATERANGEPICKER_END_DATE_PLH}
          onDatesChange={this.changeDateRange} // PropTypes.func.isRequired,
          focusedInput={this._focusedDate} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={this.changeDateInputFocus} // PropTypes.func.isRequired,
          isDayBlocked={this._isDayBlocked}
          small={true}
          showClearDates={true}
          isOutsideRange={() => false} // allow all days
          numberOfMonths={this._getNumberOfMonth}
          minimumNights={0}
          displayFormat={viewStore && viewStore.dateFormat ? viewStore.dateFormat : DATE_FORMAT_DEFAULT}
        />
      </React.Fragment>
    );
  }

  private get _getNumberOfMonth() {
    return this.props.windowWidth >= MAX_MOBILE_PORTRAIT_WIDTH ? 2 : 1;
  }

  private _isDayBlocked = (day: Moment): boolean => {
    return !isInclusivelyAfterDay(utc(), day);
  };
}

export default useWindowWidth(DateRangePickerComponent);
