import { action, computed, observable } from 'mobx';

import { IConstructSaveObj, ILasernetDocumentTypeConstructObj } from 'models/ModelInterfaces';
import UpdateModel from 'models/UpdateModel';

export default class LasernetDocumentTypeModel extends UpdateModel<LasernetDocumentTypeModel>
  implements IConstructSaveObj<ILasernetDocumentTypeConstructObj> {
  @observable public defaultNumberOfCopies: number = null;
  @observable public lasernetReportName: string = '';
  @observable public documentTypeName: string = '';
  @observable public id: string = '';

  @computed
  public get constructSaveObj(): ILasernetDocumentTypeConstructObj {
    return {
      defaultNumberOfCopies: this.defaultNumberOfCopies,
      lasernetReportName: this.lasernetReportName,
    } as ILasernetDocumentTypeConstructObj;
  }

  @action
  public update = (obj: LasernetDocumentTypeModel) => {
    this.updater.update(this, obj, LasernetDocumentTypeModel);
    return this;
  };

  @action public changeNumberOfCopies = (newNumber: number) => (this.defaultNumberOfCopies = newNumber);
  @action public changeReportName = (newName: string) => (this.lasernetReportName = newName);
}
