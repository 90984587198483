import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';
import { Route } from 'react-router-dom';

import NavlinkCustom from 'domain/NavlinkCustom';
import TranslateService from 'services/TranslateService';
import { AdminRootUrl } from 'util/enums';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetDocumentTypeModel from 'models/LasernetDocumentTypeModel';
import { Col, Container, Row } from 'reactstrap';
import LasernetDocumentTypeDetailRoute from 'pod/admin/lasernetPrinters/documentTypes/LasernetDocumentTypeDetailRoute';

interface IProps {
  translateService?: TranslateService;
  lasernetPrinterStore?: LasernetPrinterStore;
}

@inject('lasernetPrinterStore', 'translateService')
@observer
export default class LasernetDocumentTypeListRoute extends React.Component<IProps> {
  public componentDidMount() {
    this.props.lasernetPrinterStore.getAllDocumentTypeItems();
  }

  public render() {
    const {
      translateService: { t },
      lasernetPrinterStore: { documentTypesList },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">{t.MENU_ADMIN_DOCUMENT_TYPES}</h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="pl-3">{t.GLOBAL_LABEL_NAME}</th>
                  <th>{t.ADMIN_LASERNET_REPORT_NAME}</th>
                  <th className="pr-3">{t.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES}</th>
                </tr>
              </thead>
              {documentTypesList.map((type: LasernetDocumentTypeModel) => this._renderItem(type))}
            </table>
          </Col>
        </Row>
        <Route path={`${AdminRootUrl.LASERNET_DOCUMENT_TYPES}/:id`} component={LasernetDocumentTypeDetailRoute} />
      </Container>
    );
  }

  private _renderItem = (type: LasernetDocumentTypeModel) => (
    <tbody key={type.id}>
      <NavlinkCustom
        to={`${AdminRootUrl.LASERNET_DOCUMENT_TYPES}/${type.id}`}
        tagName="tr"
        key={type.id}
        className={classnames(['pointer'])}
      >
        <td className="pl-3">{type.documentTypeName}</td>
        <td data-test="lasernet-report-name" className={classnames({ 'text-danger': !type.lasernetReportName })}>
          {type.lasernetReportName || this.props.translateService.t.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG}
        </td>
        <td className="pr-3">{type.defaultNumberOfCopies}</td>
      </NavlinkCustom>
    </tbody>
  );
}
