import { action, computed, observable } from 'mobx';

import DeliveryLabReceivedGoodModel from 'models/DeliveryLabReceivedGoodModel';
import ObjectUpdater from 'util/objectUpdater';

import { IUpdateModel } from 'models/UpdateModel';
import LabStatsModel from 'models/LabStatsModel';
import TenantModel from 'models/TenantModel';
import SupplierInfo from 'models/SupplierInfoAbstractModel';
import { cloneObj } from 'util/helpers';

export default class DeliveryLabModel extends SupplierInfo implements IUpdateModel<DeliveryLabModel> {
  public readonly updater = new ObjectUpdater<DeliveryLabModel>();

  @observable public createdAt?: string = '';
  @observable public flagged?: boolean;
  @observable public wasFlagged?: boolean;
  @observable public grn?: string = '';
  @observable public id: string = null;
  @observable public receivedGoods?: DeliveryLabReceivedGoodModel[];
  @observable public stats?: LabStatsModel[];
  @observable public tenant?: TenantModel;

  @computed
  public get hasNotification(): boolean {
    return this.receivedGoods.some((rg: DeliveryLabReceivedGoodModel) => {
      return rg.stats.some((st: LabStatsModel) => st.hasNotification);
    });
  }

  @action
  public update = (obj: DeliveryLabModel) => {
    const newDeliveryLabModel = cloneObj(obj);

    if (newDeliveryLabModel && newDeliveryLabModel.receivedGoods && newDeliveryLabModel.receivedGoods.length) {
      newDeliveryLabModel.receivedGoods = newDeliveryLabModel.receivedGoods.map((i: DeliveryLabReceivedGoodModel) =>
        new DeliveryLabReceivedGoodModel().update(i)
      );
    }

    this.updater.update(this, newDeliveryLabModel, DeliveryLabModel);
    return this;
  };
}
