import * as React from 'react';
import { inject, observer } from 'mobx-react';

import Sidebar from 'domain/layout/Sidebar';
import ModalConfirmation from 'domain/ModalConfirmation';

import { Button } from 'reactstrap';
import TranslateService from 'services/TranslateService';
import { action, observable } from 'mobx';

export interface IContent {
  active?: boolean;
  code?: string;
  name?: string;
}

interface IProps {
  content: IContent;
  sidebarHeader: string;
  closeRoute: string;
  hideConfirmationHeader: string;
  hideConfirmationTextFunction: (name: string) => string;
  unhideConfirmationHeader: string;
  unhideConfirmationTextFunction: (name: string) => string;
  hideItem: () => void;
  unhideItem: () => void;
  children: React.ReactNode;
  translateService?: TranslateService;
}

@inject('translateService')
@observer
export default class AdminDetailHideUnhideComponent extends React.Component<IProps> {
  @observable
  private _showHideConfirmationModal: boolean = false;

  @observable
  private _showUnhideConfirmationModal: boolean = false;

  @action
  public toggleHideConfirmationModal = () => {
    this._showHideConfirmationModal = !this._showHideConfirmationModal;
  };

  @action
  public toggleUnhideConfirmationModal = () => {
    this._showUnhideConfirmationModal = !this._showUnhideConfirmationModal;
  };

  public render() {
    const {
      translateService: { t },
      sidebarHeader,
      hideConfirmationHeader,
      hideConfirmationTextFunction,
      unhideConfirmationHeader,
      unhideConfirmationTextFunction,
      hideItem,
      unhideItem,
      closeRoute,
      content,
      children,
    } = this.props;

    return content ? (
      <Sidebar heading={sidebarHeader} close={closeRoute} ico="print">
        {children}

        {content.active ? (
          <Button
            data-test="hide-btn"
            color="danger"
            className="button-move-up"
            onClick={this.toggleHideConfirmationModal}
          >
            {t.GLOBAL_LABEL_HIDE}
          </Button>
        ) : (
          <Button
            data-test="unhide-btn"
            color="success"
            className="button-move-up"
            onClick={this.toggleUnhideConfirmationModal}
          >
            {t.GLOBAL_LABEL_UNHIDE}
          </Button>
        )}

        <ModalConfirmation
          callbackYes={hideItem}
          heading={hideConfirmationHeader}
          isOpen={this._showHideConfirmationModal}
          text={hideConfirmationTextFunction(content.name || content.code)}
          toggleModal={this.toggleHideConfirmationModal}
        />

        <ModalConfirmation
          callbackYes={unhideItem}
          heading={unhideConfirmationHeader}
          isOpen={this._showUnhideConfirmationModal}
          text={unhideConfirmationTextFunction(content.name || content.code)}
          toggleModal={this.toggleUnhideConfirmationModal}
        />
      </Sidebar>
    ) : null;
  }
}
