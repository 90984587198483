import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Yes';
lang.GLOBAL_LABEL_YES_SMALL = 'yes';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_NO_SMALL = 'no';
lang.GLOBAL_LABEL_SUBMIT = 'Submit';
lang.GLOBAL_LABEL_RESULT = 'Result';
lang.GLOBAL_LABEL_ERROR = 'Error';
lang.GLOBAL_LABEL_REFRESH = 'Refresh';
lang.GLOBAL_LABEL_ADD_NEW = 'Add new';
lang.GLOBAL_LABEL_NAME = 'Name';
lang.GLOBAL_LABEL_CODE = 'Code';
lang.GLOBAL_LABEL_UNIQUE_ID = 'Unique id';
lang.GLOBAL_LABEL_LOCATION = 'Location';
lang.GLOBAL_LABEL_ONLINE = 'Online';
lang.GLOBAL_LABEL_IS_DEFAULT = 'Is default';
lang.GLOBAL_LABEL_MODEL = 'Model';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = 'Save';
lang.GLOBAL_LABEL_DELETE = 'Delete';
lang.GLOBAL_LABEL_CANCEL = 'Cancel';
lang.GLOBAL_LABEL_UPLOAD = 'Upload';
lang.GLOBAL_LABEL_FILE = 'File';
lang.GLOBAL_LABEL_PORT = 'Port';
lang.GLOBAL_LABEL_FIRST_NAME = 'First name';
lang.GLOBAL_LABEL_LAST_NAME = 'Last name';
lang.GLOBAL_LABEL_USERNAME = 'Username';
lang.GLOBAL_LABEL_PASSWORD = 'Password';
lang.GLOBAL_LABEL_EMAIL = 'Email';
lang.GLOBAL_LABEL_ROLE = 'Role';
lang.GLOBAL_LABEL_STATUS = 'Status';
lang.GLOBAL_LABEL_REMARKS = 'Remarks';
lang.GLOBAL_LABEL_SELECT = 'Select';
lang.GLOBAL_LABEL_GRN = 'GRN';
lang.GLOBAL_LABEL_IN_PROGRESS = 'In Progress';
lang.GLOBAL_LABEL_COMPLETED = 'Completed';
lang.GLOBAL_LABEL_REGISTER = 'Register';
lang.GLOBAL_LABEL_OPERATION = 'Operation';
lang.GLOBAL_LABEL_SUPPLIER = 'Supplier';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'Supplier city';
lang.GLOBAL_LABEL_DRIVER = 'Driver';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'Licence plate';
lang.GLOBAL_LABEL_TO_CLAIM = 'To claim';
lang.GLOBAL_LABEL_CREATED = 'Created';
lang.GLOBAL_LABEL_ACTIONS = 'Actions';
lang.GLOBAL_LABEL_CLAIM = 'Claim';
lang.GLOBAL_LABEL_DESCRIPTION = 'Description';
lang.GLOBAL_LABEL_ADD = 'Add';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'Not claimed';
lang.GLOBAL_LABEL_DELIVERIES = 'Deliveries';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = 'Save attachments';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = 'Upload attachments';
lang.GLOBAL_LABEL_ATTACHMENTS = 'Attachments';
lang.GLOBAL_LABEL_ATTACHMENT = 'Attachment';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'Advised goods';
lang.GLOBAL_LABEL_SYNC = 'Sync';
lang.GLOBAL_LABEL_CLOSE = 'Close';
lang.GLOBAL_LABEL_VERIFY = 'Verify';
lang.GLOBAL_LABEL_ALERT = '!!! ADVISED GOOD IS FLAGGED !!!';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = '!!! RECEIVED GOOD IS FLAGGED !!!';
lang.GLOBAL_LABEL_CLAIM_BY = 'Claim by';
lang.GLOBAL_LABEL_CLASSIFIED_BY = 'Classified by';
lang.GLOBAL_LABEL_VERIFIED_BY = 'Verified by';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = 'Rolled back by';
lang.GLOBAL_LABEL_FLAGGED_BY = 'Flagged by';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'Unflagged by';
lang.GLOBAL_LABEL_TRANSFERS = 'Transfers';
lang.GLOBAL_LABEL_TRANSFERED = 'Transfered';
lang.GLOBAL_LABEL_WEIGHT = 'Weight';
lang.GLOBAL_LABEL_CREATE = 'Create';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `Characters remaining: ${number}`;
lang.GLOBAL_LABEL_HIDE = 'Hide';
lang.GLOBAL_LABEL_UNHIDE = 'Unhide';
lang.GLOBAL_LABEL_SHOW = 'Show';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `Language: ${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'File downloaded';
lang.GLOBAL_LABEL_DECIMALS = 'Decimals';
lang.GLOBAL_LABEL_GO_BACK = 'Go back';
lang.GLOBAL_LABEL_VERSION = (version) => `Version - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = 'Confirm';
lang.GLOBAL_LABEL_FIELD = 'field';
lang.GLOBAL_LABEL_FIELDS = 'fields';
lang.GLOBAL_LABEL_NONE = 'None';
lang.GLOBAL_LABEL_ALL_TENANTS = 'All Tenants';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = 'Name of input field is not defined';
lang.GLOBAL_LABEL_UNCLAIM = 'Unclaim';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = 'Cert. 333/EU';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = 'Cert. aut. rif.';
lang.GLOBAL_LABEL_CERT_715_13 = 'Cert. 715/13';
lang.GLOBAL_LABEL_TYPE = 'Type';
lang.GLOBAL_LABEL_REVISION_REQUEST = 'Revision Request';
lang.GLOBAL_LABEL_EXPIRED = 'Expired';
lang.GLOBAL_LABEL_NO_CERTIFICATE = 'No Certificate';
lang.GLOBAL_LABEL_RESPONSIBLE = 'Responsible';
lang.GLOBAL_LABEL_DEPARTMENT = 'Department';
lang.GLOBAL_LABEL_EXPORT = 'Export';
lang.GLOBAL_LABEL_REEXPORT = 'Reexport';
lang.GLOBAL_LABEL_FLAGGED = 'Flagged';
lang.GLOBAL_LABEL_EXPORTED = 'Exported';
lang.GLOBAL_LABEL_SYNCED = 'Synced';
lang.GLOBAL_LABEL_PRINT = 'Print';
lang.GLOBAL_LABEL_FLAG = 'FLAG';
lang.GLOBAL_LABEL_UN_FLAG = 'UN-FLAG';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = 'No authorization number';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = 'Authorization expired';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = 'No expiration date';
lang.GLOBAL_LABEL_AUTH_NUMBER = 'Auth. number';
lang.GLOBAL_LABEL_EXP_DATE = 'Exp. date';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `${name} is not valid`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `Invalid fields: ${name}`;
lang.GLOBAL_LABEL_USER_ROLES = 'User roles';
lang.GLOBAL_LABEL_DEFAULT_ROLE = 'Default role';
lang.GLOBAL_LABEL_DEFAULT_TENANT = 'Default Tenant';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = 'New version was released.';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = 'To apply changes - refresh the page. All unsaved changes will be lost!';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = 'Tenants available';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `Tenants available for ${roleName}`;
lang.GLOBAL_LABEL_IMPORT = 'Import';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `Your password expires on ${date}. Please change the password as soon as possible to avoid account blocking`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'Your password has expired. Please set a new one';
lang.GLOBAL_LABEL_PROCESSING = 'Processing';
lang.GLOBAL_LABEL_PRICE = 'Price';
lang.GLOBAL_LABEL_UNIT = 'Unit';
lang.GLOBAL_LABEL_BREAKDOWN = 'Breakdown';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'Password exp. date';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'Italia';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'Estero';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'Ditta';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'Suppliers Details';
lang.GLOBAL_LABEL_FORM = 'Form';
lang.GLOBAL_LABEL_QUALITY = 'Quality';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = 'Refresh Product Variants';
lang.GLOBAL_LABEL_WEIGHT_UNITS = 'Weight Units';
lang.GLOBAL_LABEL_EMPLOYEE_ID = 'Employee ID';
lang.GLOBAL_LABEL_LEGAL_ENTITY = 'Legal Entity';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = 'Product Variants';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `Refresh ${name}`;
lang.GLOBAL_LABEL_TOTAL = 'Total';
lang.GLOBAL_LABEL_SHAREPOINT = 'Sharepoint';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = 'Partial Sign Off';
lang.GLOBAL_LABEL_TITLE = 'Title';
lang.GLOBAL_LABEL_CLIENT_ID = 'Client Id';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'Client Sercet';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = 'Connection Address';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'Enable balance deduction by default';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = 'Detailed weighing';
lang.GLOBAL_FILTERKEY_DATE_ASC = 'Date ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = 'Date DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = 'Status ASC';
lang.GLOBAL_FILTERKEY_STATUS_DESC = 'Status DESC';
lang.GLOBAL_FILTERKEY_ALL = 'All';
lang.GLOBAL_FILTERKEY_ONLY_MY = 'Only my';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = 'Only unclaimed';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = 'Only unsynced';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = 'In progress';
lang.GLOBAL_FILTERKEY_IN_LAB = 'In lab';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = 'Ready for Tier 2';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'Ready for signoff';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'Signed off';
lang.GLOBAL_FILTERKEY_COMPLETE = 'Complete';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = 'Partially signed off';
lang.GLOBAL_RADIOBUTTON_DRY = 'Dry';
lang.GLOBAL_RADIOBUTTON_RAIN = 'Rain';
lang.GLOBAL_RADIOBUTTON_SNOW = 'Snow';
lang.GLOBAL_RADIOBUTTON_DUMP = 'Dump';
lang.GLOBAL_RADIOBUTTON_VAN = 'Van';
lang.GLOBAL_RADIOBUTTON_FLATBED = 'Flatbed';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = 'Roll-off box';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'Railcar';
lang.GLOBAL_RADIOBUTTON_OTHER = 'Other';
lang.GLOBAL_USERROLE_SUPERADMIN = 'Super Admin';
lang.GLOBAL_USERROLE_ADMIN = 'Admin';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = 'Weighbridge';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = 'External Weighbridge';
lang.GLOBAL_USERROLE_TIER3 = 'Tier 3';
lang.GLOBAL_USERROLE_TIER2 = 'Tier 2';
lang.GLOBAL_USERROLE_TIER1 = 'Tier 1';
lang.GLOBAL_USERROLE_MANAGER = 'Manager';
lang.GLOBAL_USERROLE_LAB = 'Lab';
lang.GLOBAL_USERROLE_TRADER = 'Trader';
lang.GLOBAL_USERROLE_BACKOFFICE = 'Back Office';
lang.GLOBAL_USERROLE_DATAANALYZER = 'Data Analyzer';
lang.GLOBAL_LOGIN_HEADER = 'Login';
lang.GLOBAL_LOGIN_TITLE = 'Sign In to your account';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'You\'ve been logged out';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = 'Start date';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = 'End Date';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'Set<br />password';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'Password was updated';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'Set Password';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = 'Current Password';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = 'New Password';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = 'Re-enter new Password';
lang.GLOBAL_FILE_SELECT_ERROR = 'Error during file selecting';
lang.GLOBAL_SERVERERROR_DEFAULT = 'Something went wrong…';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = 'Failure in external service...';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2 cannot unflag advised good.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = 'T2 cannot unflag received good.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2 cannot roll back advised good status.';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = 'Trader cannot unflag advised good.';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = 'Admin cannot roll back advised good from its current status.';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1 cannot claim advised good';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1 cannot unload advised good';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1 cannot verify advised good';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = 'Cannot verify advised good because delivery is not synced yet';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'Flagged advised good cannot be ready for sign off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = 'Advised good is not ready for sign off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = 'All items has to be stock coded before advised good can be signed-off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = 'You cannot sign off advised good if you already verified it';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = 'Advised good does not have tare weight filled in';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = 'Sign off on advised good level is disabled for the tenant';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = 'Cannot update advised good if delivery already signed off';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = 'Comment cannot be updated if AG is unloaded';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = 'Cannot verify advised good, advised good tare weight is missing';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = 'Cannot verify advised good, delivery tare weight is missing';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = 'All stock codes has to be filled in before advised good can be verified';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'There are still some items in the lab';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `Advised good is already claimed by ${fullname}`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = 'Rollback cannot be done once delivery is signed off';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = 'Rollback cannot be done once advised good is signed off';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = 'Delivery with lab samples cannot be deleted';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = '.Template does not support more than 11 received goods within one advised good.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = '.Maximum allowed 10 received goods within one advised good.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = '.Maximum allowed 19 received goods within one advised good.';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'Timeout while getting image from camera';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = 'Please calibrate camera before using it';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'Timeout while getting ocr result';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = 'Admin role does not exist';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = 'AppRegistration role does not exist';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = 'Only editing accounts of AppRegistration type is allowed';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = 'Client Secret requires at least 20 characters, one uppercase letter, one digit, one special character';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = 'Client ID already exists';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = 'App Registration title is invalid';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = 'App Registration description is invalid';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = 'Client Secret requires at least 20 characters';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = 'Cannot remove tenant because some users belongs to it';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = 'Cannot set sequence for the tenant because it already has some deliveries created';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = 'Cannot set Legal Entity for the tenant because it already has some deliveries created';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = 'This site cannot be used as it is already used by other tenant';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = 'Please provide site';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = 'Please provide SharePoint folder for attachments upload';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = 'Selected SharePoint folder does not exist';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = 'SharePoint folder for attachments upload not selected for the tenant. Please contact your SuperAdmin';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = 'dOps application is not allowed to upload files to the selected SharePoint site. Please contact your SuperAdmin';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = 'External Service Error: Failed to update attachment metadata on SharePoint Online';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = 'External Service Error: Cannot login to SharePoint Online';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = 'Delivery cannot be updated after it is synced';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = 'Delivery note number is mandatory for delivery sync';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = 'Cannot delete advised goods which are already claimed';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = 'Tare weight is mandatory for delivery sync';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = 'Sub Advised Good must have at least one Received Good item';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = 'Advised good must contain at least one item in advised good breakdown';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = 'Delivery must contain at least one Advised Good item';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = 'Cannot save tare weight until advised good is claimed';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = 'All items has to be stock coded before delivery can be signed-off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = 'All lab items has to be completed before delivery can be signed-off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = 'There are some advised goods which does not have tare weight filled in';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = 'There are some received goods which does not have gross weight filled in';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = 'There are some advised goods which does not have EWC code filled in';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'Delivery should be synced before Sign-off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'Cannot sign off advised good, delivery should be synced';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = 'All advised goods have to be ready for sign off before delivery can be signed-off';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = 'Cannot complete delivery until all tare weights are provided';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'Cannot complete delivery which is not signed off.';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'Delivery cannot be re-synced because it is not yet synced at weighbridge';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'Sorting cannot be re-synced because it is not yet signed off';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = 'Material with the same code already exists';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `Material is in use by delivery ${grn}`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = 'Cannot edit advised goods without claiming it first';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'Cannot add lab analysis for completed sorting report items';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `Provided stock code ${stockCode} is invalid`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `Provided user role id ${userRole} not found`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'Cannot add lab analysis for not claimed sorting report items';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'An exception occurred while generating the QR code image';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = 'Received good weight cannot be NULL when transferring';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = 'Maximum weight to transfer is whole weight of received good';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = 'Advised good weight must be set before transferring';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = 'Maximum weight of transfer cannot be greater than advised good weight';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = 'Can not transfer received good with negative weight';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = 'Can not transfer full received good';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = 'Can not transfer full advised good';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = 'Can not transfer received good if only one in advised good';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'Cannot assign super admin role to the user';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = 'Password requires at least 10 characters, one uppercase letter, one digit, one special character';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'You cannot delete other admins';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = 'Undocumented error';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'Error while sending email';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = 'Erro while processing request';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'File not found';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = 'Advised good not found';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = 'Advised good attachment not found';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = 'Contamination not found';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = 'Sub advised good not found';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = 'Received good not found';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = 'Received good description cannot be longer than 79 characters';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'Camera not found';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'Camera not active';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'Tenant not found';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = 'Admin not found';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'User not found';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = 'Location not found';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = 'Location already exists';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = 'Delivery not found';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = 'Delivery attachment not found';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'Supplier not found';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'Supplier type not found';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = 'Road haulier not found';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = 'Material not found';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'Lab input type not found';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'Lab input type not found for workflow';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'Lan input not found';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'Lab status not supported';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'Lab status not found for workflow';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = 'Stock item not found';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = 'Stock item already exists';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = 'Stock item code length should be 5 digits';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'Printer not found';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = 'Scale not found';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = 'Print template not found';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = 'Product form not found';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = 'Provided product form is not related to provided Stock Item';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = 'Product quality not found';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = 'Product variant not found';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = 'Product Variants are required for Sign Off';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'Username already exists';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = 'Cannot delete yourself';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = 'Invalid username or password';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = 'Please choose a different password. This one has already been used before';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = 'Current client secret has been user for 2 years now and expired. Please contact superadmin';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = 'Please provide valid email';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'Please provide username';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'Please provide password';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'Minimum password length is 10 characters';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = 'Invalid client id';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = 'Invalid client secret';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'Please provide firstname';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'Please provide lastname';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'Please provide user roles';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = 'Please provide user tenants';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = 'External employee Id must be at least 6 symbols';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = 'External employee Id field is required';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = 'External employee with provided Id not found';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `Selected employee id is not eligible to work on tenant: ${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = 'Please provide only one default role for user';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = 'Please provide only one default tenant for user';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = 'User active role must match one of existing user roles';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = 'You don\'t have permission to access selected tenant';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = 'Only Super Admin can create or edit user with role "Admin"';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = 'User cannot be Trader and BackOffice simultaneously';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = 'User with role DataAnalyzer cannot have multiple roles';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = 'Multiple tenants can be assigned only if user has role Trader, BackOffice or Admin';
lang.GLOBAL_SERVERERROR_INVALID_NAME = 'Please provide name';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'Access is denied';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'Session not found';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'Session expired';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = 'Please try again in 1 minute';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = 'Please provide stock item name';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = 'You cannot sign off delivery if you already verified one of advised goods';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = 'Supplier with provided code already exists';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'Please provide supplier name';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'Please provide supplier code';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'Please provide flagged status';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = 'Please provide description';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = 'Received good description cannot be longer than 255 characters';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = 'Please provide material information';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = 'Please provide material ID';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = 'Please provide material weight';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = 'Please provide sub advised good id';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'Please provide value for p1';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'Please provide value for p2';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'Please provide value for p3';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'Please provide value for p4';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'Please provide contrast';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = 'Please provide display type';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'Please provide camera name';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'Please provide camera place';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'Please provide camera unique id';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'Please provide supplier';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = 'Please provide road haulier';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = 'Please provide department';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = 'Remarks can be max 30 characters long';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = 'Please provide vehicle registration number';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = 'Please provide stock item';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = 'Please provide an advised description';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = 'Advised good description cannot be longer than 30 characters';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = 'Advised good description cannot be longer than 50 characters';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = 'Advised good description cannot be longer than 100 characters';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = 'Please provide a weighbridge ticket number';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = 'Please provide a location';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = 'Please provide material code';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = 'Please provide material name';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'Please provide printer name';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'Please provide printer ip';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'Please provide printer model';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = 'Please provide printer connection address';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = 'Description can be max 255 characters long';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = 'Address can be max 255 characters long';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = 'Name can be max 255 characters long';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = 'Document Type not found';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = 'Please provide Lasernet report name';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = 'Lasernet Report Name can be max 255 characters long';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = 'Please provide default number of copies';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = 'Please provide printer ID';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = 'Printer with provided name already exists';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = 'Printer configuration not found';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = 'Printer configuration with provided parameters already exists';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = 'Please provide number of copies';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = 'Please provide weight to transfer';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = 'Please provide new status';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = 'Please provide scale name';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = 'Please provide scale ip';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = 'Please provide scale port';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = 'Please provide delivery type';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = 'Please provide weather conditions';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = 'Please provide transaction type';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = 'Please provide processing price';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = 'Processing price not found';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = 'Image resolution is too high';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = 'Only PDF files and images are allowed as attachments';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'GRN can not be empty';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `Cannot delete goods which are related to transfer items: ${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = 'Unsupported action';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = 'There are no D365 tenants available on this server';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = 'Can not create lab input because another lab input of same type already exists';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = 'Advised good Deduction weight cannot be empty';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = 'Machine not found';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'Target batch not found';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `Please provide Weight for: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `Please provide Machine in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `Please provide WI Done in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `Please provide Bulk Density in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `Please provide Material Description in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `Please provide Found Quality in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `Please provide Main Type in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `Please provide Target Batch in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'Please provide tenant name';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'Please provide tenant code';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'Please provide tenant location code';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'Tenant Location code can be max 10 characters long';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'Please provide tenant GRN prefix';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = 'Please provide current GRN value for tenant';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'Please provide tenant workflow';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'Please provide tenant timezone';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'Please provide tenant region code';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'Tenant Region code can be max 10 characters long';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = 'Please provide tenant Legal Entity';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = 'Only attachment owner or admin can delete attachment';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = 'Integration endpoint configuration is missing. Please contact your Administrator.';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = 'Advised good can be unclaimed only if in status CLAIMED';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = 'Advised good can be unclaimed only by person who claimed it or by Administrator';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = 'Advised good can be unclaimed only if Lab inputs were not created';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = 'Packaging already exists';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = 'Packaging not found';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = 'Sum of “Sizes” Contamination fields must be 100%';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = 'Processing Time not found';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `Please provide Contamination in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'Please provide code';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = 'Please provide delivery origin';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = 'Origin not found';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = 'Origin length should be 35 letters';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'EWC not found';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = 'EWC already exists';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = 'GRN already exists';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = 'Delivery cannot be exported because it is not yet signed off';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = 'Please use a filter to reduce the number of deliveries to be exported. The selected delivery list is too long. ';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = 'Measurement not found';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = 'Spectro Analysis not found';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `.Please provide: ${joinedlabelslist}`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `User code length should be ${allowedLength} letters`;
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = 'Please provide delivery creation date';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = 'Please provide advised good line number';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = 'Please provide trader code';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = 'Please provide advised net weight';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `Delivery ${grn} already completed and cannot be updated`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `Delivery ${grn} already signed-off and cannot be updated`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = 'File is empty';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = 'Failed to import contract';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = 'Number of columns does not correspond the template';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = 'Order of columns does not correspond the template';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = 'Failed to upload yard locations, wrong column name provided';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = 'Failed to upload suppliers, wrong column name(s) provided';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = 'Failed to upload road hauliers, wrong column name(s) provided';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = 'Failed to upload stock items, wrong column name(s) provided';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = 'Please provide Weight Units';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = 'Please provide Legal Entity';
lang.GLOBAL_COPY_TITLE = (itemname) => `.Click to copy ${itemname} to clipboard`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `.${name} was copied to clipboard`;
lang.HEADER_QRCODE_HEADER = 'Scan QR Code';
lang.HEADER_QRCODE_ERROR_MSG = 'Something went wrong while opening the qr code reader';
lang.HEADER_LABEL_LOGOUT = 'Logout';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'Change Password';
lang.HEADER_LABEL_SWITCH_ROLE = 'Switch role';
lang.HEADER_LABEL_SWITCH_TENANT = 'Switch tenant';
lang.HEADER_MODAL_LOGOUT = 'Would you like to logout? All unsaved changes will be lost.';
lang.HEADER_MODAL_SWITCH_ROLE = 'Would you like to switch role? All unsaved changes will be lost.';
lang.HEADER_MODAL_SWITCH_TENANT = 'Would you like to switch tenant? All unsaved changes will be lost.';
lang.ADMIN_SECTIONENTITY_USER = 'User';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = 'Stock Item';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'Supplier';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = 'Road haulier';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = 'Yard Location';
lang.ADMIN_SECTIONENTITY_PRINTER = 'Printer';
lang.ADMIN_SECTIONENTITY_SCALE = 'Scale';
lang.ADMIN_SECTIONENTITY_MATERIAL = 'Material';
lang.ADMIN_SECTIONENTITY_MACHINE = 'Machine';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'Target Batch';
lang.ADMIN_SECTIONENTITY_PACKAGE = 'Package';
lang.ADMIN_SECTIONENTITY_EWC = 'EWC number';
lang.ADMIN_SECTIONENTITY_ORIGIN = 'Origin';
lang.ADMIN_ACTIVITYLOG_TITLE = 'Activity log';
lang.ADMIN_ACTIVITYLOG_ACTION = 'Action';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = 'Created at';
lang.ADMIN_ACTIVITYLOG_USER = 'User';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'Message';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = 'New material was created';
lang.ADMIN_MATERIALS_TITLE = 'Material';
lang.ADMIN_MATERIALS_CREATE_NEW = 'Create new material';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = 'Materials was updated';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = 'Materials was deleted';
lang.ADMIN_MATERIALDETAIL_HEADER = 'Material detail';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = 'Delete material confirmation';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `Would you like to delete material <strong>${name}</strong>?`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = 'New printer was created';
lang.ADMIN_PRINTERLIST_HEADER = 'Printers';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'Create printer';
lang.ADMIN_PRINTERLIST_NAME = 'Printer Name';
lang.ADMIN_PRINTERLIST_CREATE_NEW = 'Create new printer';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'Printer was updated';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'Printer was deleted';
lang.ADMIN_PRINTERDETAIL_HEADER = 'Printer location detail';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'Delete printer confirmation';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `Would you like to delete printer <strong>${name}</strong>?`;
lang.ADMIN_ROADHAULIERLIST_HEADER = 'Road Hauliers';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = 'Upload Road Hauliers';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = 'Authorization number';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = 'Expiration date';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `Create new ${name}`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `Upload ${name}`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `New ${name} was created`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `${name} was updated`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `${name} was hidden`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `${name} was unhidden`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `${name} detail`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `Hide ${name} confirmation`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Would you like to hide <strong>${name}</strong>?`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `Unhide ${name} confirmation`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Would you like to unhide <strong>${name}</strong>?`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `${name} did not find`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = 'List uploaded successfully!';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'Something went wrong during the list upload.';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = 'Take a look into example excel file:';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = 'New scale was created';
lang.ADMIN_SCALELIST_HEADER = 'Scales';
lang.ADMIN_SCALELIST_ADD_BTN = 'Create scale';
lang.ADMIN_SCALELIST_NAME = 'Scale name';
lang.ADMIN_SCALELIST_CREATE_NEW = 'Create new scale';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = 'Scale was updated';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = 'Scale was deleted';
lang.ADMIN_SCALEDETAIL_HEADER = 'Scale detail';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = 'Delete scale confirmation';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `Would you like to delete scale <strong>${name}</strong>?`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = 'Name of input field is not defined';
lang.ADMIN_STOCKEITEMLIST_HEADER = 'Stock Items';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = 'Upload Stock Items';
lang.ADMIN_SUPPLIERLIST_HEADER = 'Suppliers';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'Upload suppliers';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'Supplier was updated';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'Supplier was hidden';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'Supplier was unhidden';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'Supplier detail';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'Hide supplier confirmation';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Would you like to hide supplier <strong>${name}</strong>?`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Unhide supplier confirmation';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Would you like to unhide supplier <strong>${name}</strong>?`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'Supplier did not find';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = 'Machine was updated';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = 'Machine was hided';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = 'Machine was unhidden';
lang.ADMIN_MACHINEDETAIL_HEADER = 'Machine detail';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = 'Hide machine confirmation';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Would you like to hide machine <strong>${name}</strong>?`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Unhide machine confirmation';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Would you like to unhide machine <strong>${name}</strong>?`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = 'Machine did not find';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = 'Packaging was updated';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = 'Packaging was hided';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = 'Packaging was unhidden';
lang.ADMIN_PACKAGING_DETAIL_HEADER = 'Packaging detail';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = 'Hide packaging confirmation';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Would you like to hide packaging <strong>${name}</strong>?`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = 'Unhide packaging confirmation';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Would you like to unhide packaging <strong>${name}</strong>?`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = 'Packaging did not find';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'Target batch was updated';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'Target batch was hided';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'Target batch was unhidden';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'Target batch detail';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'Hide target batch confirmation';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Would you like to hide target batch <strong>${name}</strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Unhide target batch confirmation';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Would you like to unhide target batch <strong>${name}</strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'Target batch did not find';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = 'New user was created';
lang.ADMIN_USERLIST_HEADER = 'User management';
lang.ADMIN_USERLIST_ADD_BTN = 'Create new user';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'User was updated';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'User was deleted';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'Delete user';
lang.ADMIN_USERDETAIL_HEADER = 'User detail';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'Delete user confirmation';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `Would you like to delete user <strong>${name}</strong>?`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = 'New yard location was created';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'Yard Locations';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'Create yard location';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'Upload yard location';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'Yard location name';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = 'Create new yard location';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'Yard location was updated';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'Yard location was deleted';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'Yard location detail';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = 'Delete location';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `Would you like to delete location <strong> ${name} </strong>?`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'Yard Location';
lang.ADMIN_MACHINE_LIST_HEADER = 'Machines';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = 'Upload Machines';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'Target Batches';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'Upload Target Batches';
lang.ADMIN_PACKAGING_ADD_BTN = 'Add Packaging';
lang.ADMIN_PACKAGING_UPLOAD_BTN = 'Upload Packaging';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = 'Create New Packaging';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = 'New packaging was created';
lang.ADMIN_EWCLIST_HEADER = 'EWCs';
lang.ADMIN_EWCLIST_ADD_BTN = '.Create EWC';
lang.ADMIN_EWCLIST_UPLOAD_BTN = '.Upload EWC';
lang.ADMIN_EWCLIST_CODE = '.Code';
lang.ADMIN_EWCLIST_CREATE_NEW = '.Create new EWC';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = '.New EWC code was edded';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = '.EWC code was updated';
lang.ADMIN_EWCDETAIL_HEADER = '.EWC detail';
lang.ADMIN_EWCDETAIL_NAME = '.Code';
lang.ADMIN_EWCDETAIL_HIDE_MSG = '.EWC was hidden';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = '.EWC was unhidden';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = '.Hide EWC confirmation';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Would you like to hide EWC code <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Unhide EWC confirmation';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Would you like to unhide EWC code <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = '.EWC did not find';
lang.ADMIN_ORIGINLIST_HEADER = '.Origins';
lang.ADMIN_ORIGINLIST_ADD_BTN = '.Create origin code';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = '.Upload origin';
lang.ADMIN_ORIGINLIST_CODE = '.Code';
lang.ADMIN_ORIGINLIST_CREATE_NEW = '.Create new origin';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = '.New origin code was added';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = '.Origin code was updated';
lang.ADMIN_ORIGINDETAIL_HEADER = '.Origin detail';
lang.ADMIN_ORIGINDETAIL_NAME = '.Code';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = '.Origin was hidden';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = '.Origin was unhidden';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = '.Hide origin confirmation';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Would you like to hide origin code <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Unhide origin confirmation';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Would you like to unhide origin code <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = '.Origin did not find';
lang.ADMIN_CONTRACTS_TITLE = 'Contracts';
lang.ADMIN_CONTRACTS_FILE_IMPORT = 'FIle import';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = 'Trigger SFTP Sync';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = 'File imported';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = 'SFTP import triggered';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '£/hour';
lang.ADMIN_PROCESSING_UNIT_TONS = '£/tonne';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = 'Refresh Product Variants Confirmation';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = 'Refresh process can take up to few minutes. Do you want to proceed?';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = 'Product Variants refreshed successfully';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'Refresh Product Variants still in progress. Update the page after 2min';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `Refresh ${name} Confirmation`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name} refreshed successfully`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = 'Refresh process can take up to few minutes. Do you want to proceed?';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `Refresh ${name} still in progress. Update the page after 2min`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = 'Employees refreshed successfully';
lang.ADMIN_LASERNET_REPORT_NAME = 'Laserner Report Name';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'Default Number of Copies';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'Document Type';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'Document Type detail';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'Document Type Id';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'Please, provide Lasernet report name';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'Printer Configurations';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = 'Configuration Details';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = 'Delete Confirmation';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = 'Would you like to delete configuration?';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = 'Confirmation was deleted successfully';
lang.ADMIN_LASERNET_PRINTER_ID = 'Printer id';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = 'New tenant was created';
lang.SUPERADMIN_TENANTLIST_HEADER = 'Tenants';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = 'Add new';
lang.SUPERADMIN_TENANTLIST_GRN = 'GRN Prefix';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = 'Create new tenant';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'Tenant was updated';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'Tenant was deleted';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'Tenant detail';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'Delete tenant confirmation';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `Would you like to delete tenant <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTDETAIL_SITE = 'Site';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = 'Check for missing attachments';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'On Advised Good level';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = 'On Delivery level';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = 'Require classification';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = 'Require contamination';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'Confirm overwriting description in Sorting report';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'Tenant updated';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = 'Admin added';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = 'Admin deleted';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'Tenant';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = 'Location Code';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = 'Region Code';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = 'Current GRN Value';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = 'Current Lab sequence value';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'Workflow';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'Choose workflow';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = 'Timezone';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = 'Integration API base';
lang.SUPERADMIN_TENANTADMIN_ADMINS = 'Admins';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = 'Add admin';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = 'No admins';
lang.SUPERADMIN_TENANTADMIN_HEADER = 'Add admin user';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = 'Admin user';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = 'Hide admin';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `Would you like to hide <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = 'Unhide admin';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `Would you like to unhide <strong>${name}</strong>?`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = 'Choose Sharepoint Site';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = 'Image Folder Path';
lang.SUPERADMIN_SHAREPOINT_SITES = 'Sharepoint sites';
lang.SUPERADMIN_APP_USERS_TITLE = 'App Registrations';
lang.SUPERADMIN_APP_USERS_ADD_MSG = 'App Registration Added';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = 'Add App Registration';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = 'App Registration Updated';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'Generate Random Secret';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'Copy this value because you won\'t be able to retrive the key after saving and closing this app user';
lang.MENU_ADMIN_TITLE = 'Administration';
lang.MENU_ADMIN_USER_MANAGEMENT = 'User management';
lang.MENU_ADMIN_ACTIVITY_LOG = 'Activity log';
lang.MENU_ADMIN_STOCK_ITEMS = 'Stock items';
lang.MENU_ADMIN_SUPPLIERS = 'Suppliers';
lang.MENU_ADMIN_ROAD_HAULIERS = 'Road hauliers';
lang.MENU_ADMIN_YARD_LOCATION = 'Yard location';
lang.MENU_ADMIN_PRINTERS = 'Printers';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'Lasernet Printers';
lang.MENU_ADMIN_DOCUMENT_TYPES = 'Document Types';
lang.MENU_ADMIN_CONFIGURATIONS = 'Configurations';
lang.MENU_ADMIN_SCALES = 'Scales';
lang.MENU_ADMIN_MATERIALS = 'Materials';
lang.MENU_ADMIN_APPLICATION = 'Application';
lang.MENU_ADMIN_DELIVERY_LIST = 'Delivery list';
lang.MENU_ADMIN_MACHINES = 'Machines';
lang.MENU_ADMIN_TARGET_BATCHES = 'Target batches';
lang.MENU_ADMIN_PACKAGING = 'Packaging';
lang.MENU_ADMIN_ORIGINS = 'Origins';
lang.MENU_ADMIN_EWCS = 'EWCs';
lang.MENU_SUPERADMIN_TENANTS = 'Tenants';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'Lab analysis - updated';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'Lab analysis status - updated';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = 'Deliveries';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = 'Adv. Description';
lang.LABORATORY_ADVISEGOODS_TENANT = 'Tenant';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = 'Sorting report';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'Lab statuses';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'Lab List';
lang.LABORATORY_ANALISYS_HEADER = 'Lab analysis';
lang.LABORATORY_ANALISYS_MEASUREMENTS = 'Measurements';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'Lab analysis status - updated';
lang.LABORATORY_ANALISYS_WET_WEIGHT = 'Wet weight';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = 'Dry weight';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'Ingot weight';
lang.LABORATORY_ANALISYS_YIELD = 'Yield';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = 'Spectro analysis';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'Page was sent to printer';
lang.LABORATORY_PRINTER_WARN_MSG = 'Unsupported printer used';
lang.LABORATORY_PRINTER_ERROR_MSG = 'An error occurred while printing';
lang.LABORATORY_PRINTER_LABEL = 'Label';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'No printers';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = 'The connection to the scale could not be established';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = 'Scale - an error occurred';
lang.LABORATORY_SCALE_FAKE_RESULT = 'Face result';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'Filter by:';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = 'New delivery has been received';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `Delivery ${name} has been updated`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = 'Advised good was claimed';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = 'Delivery resynced';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `Sort by ${name}`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'GRN search';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = 'Advised good was claimed';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = 'Delivery resynced';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = 'Delivery signed-off';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = 'Delivery completed';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'Sign off';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = 'Resync Wb';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = 'Resync Sr';
lang.DELIVERIES_DELIVERYLIST_RESP = 'Resp.';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = 'Sign off delivery?';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `Would you like to sign off <strong>${grn}</strong>?`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = 'Mark as complete?';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `Would you like to complete <strong>${grn}</strong>?`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = 'Export delivery?';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `Would you like to export <strong>${grn}</strong>?`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = 'Weight discrepancy confirmation';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = 'Sorting Total weight does not match Received weight. Would you like to sign-off?';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'An error occured, redirecting back to delivery list';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = 'Delivery was created';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = 'Attachment upload failed, please upload attachment again';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = 'Contract Number';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'GRN number';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = 'Create new delivery';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = 'Delivery note number';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = 'Vehicle registration number';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'Container number';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = 'Road haulier';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = 'No attachments';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = 'Responsible people';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = 'Created by';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'Signed-off by';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = 'Synced by';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = 'Completed by';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = 'Stock Item';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = 'Adv. Description';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = 'Adv. Weight';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = 'Adv. Weight (gross)';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = 'Adv. Weight (net)';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'Yard Location';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = 'Gross Weight';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = 'Tare Weight';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'WB Ticket no.';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = 'Net';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = 'Save changes?';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = 'Would you like to <strong>save changes</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = 'Mark as complete?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = 'Would you like to mark delivery as <strong>complete</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Save without attachments?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Would you like to save delivery <strong>without attachments</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = 'Save with expired certificate?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'Suppliers certificate is expired or missed. Do you want to save delivery anyway?';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = 'Origin';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = 'Save with expired authorization?';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = 'Authorization for RoadHaulier is missing or expired. Do you want to save delivery anyway?';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = 'Transaction type';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = 'Trade Payable';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = 'Transfer';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = 'Return of Rejects';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = 'Void';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'Advised goods details';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = 'Adv. description';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = 'Adv. weight';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = 'Order Line Seq.';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = 'Line Number';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = 'Delivery was updated';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = 'Attachments updated successfully!';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = 'Attachment upload failed, please upload attachment again';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = 'Attachment removed';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = 'Delete attachment confirmation';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = 'Would you like to delete this attachment?';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `Advised good in delivery <strong>${deliveryGrn}<strong/> was claimed`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `Stock codes [${codes}] do not match with any of stock codes from dOps. Please contact your admin`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = 'Delete Delivery?';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = 'Would you like to delete Delivery? It will not be possible to restore it in a future.';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `Delivery ${name} was deleted`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'EWC number';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'Driver';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = 'Advised good updated';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `Weights updated in: ${entity}`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = 'Advised good was FLAGGED';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = 'Advised good was UN-FLAGGED';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = 'Advised good was Completed';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = 'Advised good was Verified';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'Lab analysis has been created successfully';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = 'Advised good must be saved before creating transfer';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = 'Advised good must be saved before creating new lab analysis';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = 'Advised good must be saved before printing';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'Status rollback';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = 'Vehicle Registration';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = 'Gross Weight';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = 'Tare Weight';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = 'Net Weight';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = 'Gross weight required';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = 'Received Weight';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = 'Tare weight required';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = 'Sorting Report';
lang.ADVISEDGOOD_ROUTE_BALANCED = 'Balanced';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'File zip downloaded';
lang.ADVISEDGOOD_ROUTE_PICTURES = 'Pictures';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = 'No picture';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = 'Uploading pictures…';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'Comment';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'No comment';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `${hasFlag} confirmation`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Would you like to <strong class=${className}>${hasFlag}</strong> this advised good?`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = 'Save changes?';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = 'Would you like to <strong>save changes</strong>?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = 'Mark as complete?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = 'Would you like to mark this advised good as <strong>complete</strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = 'Mark as verified';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = 'Would you like to mark this advised good as <strong>verified</strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = '.Sorting total is bigger than received weight from truck scale. To Verify, please confirm that the sorting weight has been checked on a platform scale.';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = 'Rollback to claimed status?';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = 'Would you like to rollback status to <strong>claimed</strong>?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = 'Rollback to unloaded status?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = 'Would you like to rollback status to <strong>unloaded</strong>?';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = 'Sorting total (gross)';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = 'Sorting total (net)';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = 'Gross weight was updated';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = 'Received weight was updated';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'Ewc number was updated';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = 'Delivery type';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = 'Confirm Unload location';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = 'Select the Yard location for Unloading';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = 'Unload Location';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = 'Advised good cannot be verified because Sorting total is greater than Received weight';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = 'Unclaim Advised good';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = 'Would you like to unclaim Advised good?';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = 'Advised good was unclaimed';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = '.Received goods amount can not be more than 10';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = 'Truck Received weight (gross)';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = 'Truck Sorting Total (gross) ';
lang.ADVISEDGOOD_ROUTE_PACKAGING = 'Packaging';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = 'No packaging';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'Sign off AG';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = 'Sign off AG?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = 'Would you like to sign off AG?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG was successfully signed';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Save without attachments?';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Would you like to save advised good <strong>without attachments</strong>?';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = 'Do you wish to overwrite the item\'s description?';
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = 'Sorting report detail';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = 'Received good can\'t be transfered because transfer weight is bigger than current weight';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = 'Received good was transferred';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = 'Stock Code';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = 'Transfer';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'Lab analysis';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'No lab analysis requested';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = 'Material';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI done';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = 'Create new Lab Analysis';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = 'Do you want to create new Lab Analysis?';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'Delete Lab Analysis';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `Do you want to delete <strong>${labAnalysisName}</strong> request?`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = 'Machine';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'Target Batch';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = 'Bulk Density';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = 'Found Quality';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = 'Main Type';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = 'Material Description';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `You need to fill up <strong>${emptyRequiredField}</strong> before saving`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = 'Advised good must be saved before adding picture';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = 'Change Location';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = 'Target Location';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'Lab Remarks';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = 'Delete picture confirmation';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = 'Would you like to delete this picture?';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = 'free Water';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = 'free Oil';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = 'free Emulsion';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = 'free Snow/Ice';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = 'No deduction';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = 'Deduction';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = 'Confirm Advised good Unloaded';
lang.ADVISEDGOOD_UNLOAD_TITLE = 'Unloaded';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = 'Advised Good unloading completed';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = 'Negative weight confirmation';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = 'Weight of the material is negative. Please confirm';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = 'Received good was FLAGGED';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = 'Received good  was UN-FLAGGED';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Would you like to <strong class=${className}>${hasFlag}</strong> this received good?`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = 'Contamination';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'Other';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = 'No Contamination';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = 'Non-metallic Att.';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = 'Water (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = 'free Oil (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = 'Dirt (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = 'Wood (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'Plastic (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'Glass Wool (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = 'Burning Slag (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'Bitumen (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = 'att. Oil/Grease (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'Cardboard (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = 'Metallic Att.';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = 'Iron (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'Mn-Steel (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = 'CrFe 10 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = 'CrFe 13 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_301 = '301 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = 'CrFe 17 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'Shredder (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = 'Incinerator Mat. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = 'Different Metals';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'Al (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'Ms (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'Pb (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'Ti (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'StSt/Cu cooler (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'Mat. for Shredding (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = 'Turnings in Solids';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'CrFe turnings (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = 'low alloyed Tur. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'StSt Turnings (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = '304 Turnings (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = '316 Turnings (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = 'Not in Furnace Size';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = 'For mobile Shear (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = 'For stationary Shear (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'For torch cutting (kgs)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'For Press (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = 'No Aggregate necessary (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = 'Consists Hollow Bodies (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = 'Material Forms';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = 'Packets (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'Briquettes (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'Ingots (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = 'Fine Material (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = 'Runnings (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = 'Tare (inbound)';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = 'Total (pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'Drums (pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = 'Boxes (pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'Grid Boxes (pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = 'BigBags (pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'Pallets (pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = 'Tare (outbound)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = 'Turnings composition';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = 'Magnetic Content';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = 'Metallic Turning';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = 'Grinding Turning';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'Sizes';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'Size 0-15 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'Size 16-35 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'Size 36-60 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'Size 61-80 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'Size 81-120 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'Size 121-150 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'Size 150-999 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = 'Sum is not 100%';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = 'Packaging';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = 'Classification';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = 'very light';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = 'light';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = 'middle';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = 'heavy';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = 'very heavy';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'Fe-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'mixed Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'V13F-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = '1.4510-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = '1.4512-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = '17er Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = '1.4016-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = '1.4509-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = '304-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'CrNi-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'CrNiMo-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'Fe-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'mixed Cr-/CrNi-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'CrNi-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'CrNiMo-TUR';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'Other';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'Shredder';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = 'Solids';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = 'Turnings';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = 'Package';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'Briketts';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'Ingots';
lang.SORTINGREPORT_CLASSIFICATION_FINES = 'Fines';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = 'Waste incineration';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = 'Runnings';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = 'Demolition Scrap';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = 'Production Scrap';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = 'Collecting Scrap';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = 'Shearing Scrap';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = 'Turnings with Solids';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = 'Processing Time';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = 'CUTTING HOURS bending / crushing';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'SPIDER HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'CART HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = 'OPERATOR HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = 'Minutes';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = 'Tonnes';
lang.SORTINGREPORT_PROCESSING_TITLE = 'Processing';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = 'Operator';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = 'Forklift';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = 'Crane';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = 'Torch Cutting';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = 'Plasma';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = 'Crane Shear';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = 'Guillotine Shear';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = 'Densifier';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = 'Baler';
lang.SORTINGREPORT_MEASUREMENT_TITLE = 'Measurement';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = 'Sample wet (g)';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = 'Sample dry (g)';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = 'Humidity (%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = 'For melting (g)';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'Ingot (g)';
lang.SORTINGREPORT_MEASUREMENT_YIELD = 'Yield (%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = 'Container';
lang.SORTINGREPORT_LASERNET_COPIES = 'Copies';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} label(s) are queued and will be printed during 1 minute`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'Printer setup error. Please contact your administrator';
lang.STATUS_LABINPUT_PRE_SAMPLE = 'Pre Sample';
lang.STATUS_LABINPUT_SAMPLE = 'Sample';
lang.STATUS_LABINPUT_RE_SAMPLE = 'Re Sample';
lang.STATUS_LABINPUT_SORTATION = 'Sortation';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'Niton Analysis';
lang.STATUS_LABSTATUSES_IN_LAB = 'In lab';
lang.STATUS_LABSTATUSES_DRYING = 'Drying';
lang.STATUS_LABSTATUSES_MELTING = 'Melting';
lang.STATUS_LABSTATUSES_MELTED = 'Melted';
lang.STATUS_LABSTATUSES_COMPLETE = 'Complete';
lang.STATUS_LABSTATUSES_REQUESTED = 'Requested';
lang.STATUS_LABSTATUSES_SAMPLED = 'Sampled';
lang.STATUS_LABSTATUSES_IN_PROGRESS = 'In progress';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = 'In progress';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = 'Ready for Tier 2';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'Ready for sign off';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = 'Partially signed off';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'Signed off';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = 'Complete';
lang.STATUS_ADVISEDGOOD_WAITING = 'Waiting';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'Sorting';
lang.STATUS_ADVISEDGOOD_UNLOADED = 'Unloaded';
lang.STATUS_ADVISEDGOOD_IN_LAB = 'In lab';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = 'Ready for Tier 2';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'Ready for sign off';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'Signed off';
lang.STATUS_ADVISEDGOOD_COMPLETE = 'Complete';
lang.DASHBOARD_TITLES_DASHBOARD = 'Dashboard';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = 'Deliveries in Progress';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'Delivery by Status';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = 'Average Sorting Time';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = 'Average Turnaround Time';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = 'Last Deliveries Sorting Time';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'Flagged Items';
lang.DASHBOARD_TITLES_TREES_SAVED = 'Trees Saved';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'Sorting Time (hours)';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = 'Turnaround Time (hours)';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'Sorting Time';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `In progress ${condition} months`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `In progress ${condition} month`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `In progress ${condition} week`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `Flagged Items ${condition} months`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `Flagged Items ${condition} month`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `Flagged Items ${condition} week`;
lang.USA_CONFIGURATION_CONFIGURATION = 'Configuration';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = 'Show contract number';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = 'Show advised good breakdown';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = 'Show advised good tare';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = 'Show advised good location';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = 'Show delivery gross/tare/net';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = 'Show delivery type';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = 'Radiation file upload';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = 'Show weather conditions';
lang.USA_CONFIGURATION_STOCK_CODE = 'Stock Code format';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = 'Weighbridge tolerance';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = 'Radiation file upload failed, please upload radiation file again';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = 'Radiation file is required';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = 'Weather conditions';
lang.USA_CONFIGURATION_DELIVERY_TYPE = 'Delivery type';
lang.USA_CONFIGURATION_GROSS = 'Gross';
lang.USA_CONFIGURATION_TARE = 'Tare';
lang.USA_CONFIGURATION_NET = 'Net';
lang.USA_CONFIGURATION_RADIATION_FILE = 'Radiation file';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = 'No radiation file';
lang.USA_CONFIGURATION_ADV_TARE = 'Adv. tare';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = 'New delivery created';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = 'Delivery ready for sign off';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = 'Delivery signed off';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = 'Delivery completed by trader';

export default lang;
