import * as React from 'react';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import LayoutStore from 'stores/LayoutStore';

interface IProps {
  layoutStore?: LayoutStore;
}

@inject('layoutStore')
@observer
export default class ContentWrapper extends React.Component<IProps> {
  public render() {
    const { layoutStore } = this.props;

    return (
      <div id="nodeForScrolling" className={classNames(['content-wrapper', { 'menu-open': layoutStore.menuOpen }])}>
        {this.props.children}
      </div>
    );
  }
}
