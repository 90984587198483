import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';
import { action, observable } from 'mobx';

import TranslateService from 'services/TranslateService';
import SessionStore from 'stores/SessionStore';
import AdminStore from 'pod/admin/AdminStore';
import UserTenantModel from 'models/UserTenantModel';

interface IChangeUserRoleProps {
  onTenantChange: (id: string) => void;
  translateService?: TranslateService;
  sessionStore?: SessionStore;
  adminStore?: AdminStore;
}

@inject('translateService', 'sessionStore', 'adminStore')
@observer
export default class ChangeTenant extends React.Component<IChangeUserRoleProps> {
  @observable
  private _isOpen: boolean = false;

  public render() {
    const {
      translateService: { t },
      sessionStore: { user },
    } = this.props;

    return !!user && user.tenants.length > 1 ? (
      <Dropdown isOpen={this._isOpen} toggle={this._toggleIsOpen}>
        <DropdownToggle
          outline={false}
          size="sm"
          caret
          className={classnames(['btn btn-secondary btn-block margin-bottom change-user-role'])}
        >
          {t.HEADER_LABEL_SWITCH_TENANT}
        </DropdownToggle>
        <DropdownMenu>
          {user.tenants.map((tenant: UserTenantModel) => {
            return (
              <DropdownItem
                key={tenant.id}
                data-tenant-name={tenant.name}
                onClick={() => {
                  this._onDropDownItemClick(tenant.id);
                }}
                className={classnames({ active: this._isDropDownItemActive(tenant.id) })}
              >
                {tenant.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    ) : null;
  }

  private _onDropDownItemClick = (id: string) => {
    const { onTenantChange } = this.props;
    if (!this._isDropDownItemActive(id)) {
      onTenantChange(id);
    }
  };

  private _isDropDownItemActive = (id: string) => {
    const {
      sessionStore: { user },
    } = this.props;
    return user.activeTenant?.id === id;
  };

  @action
  private _toggleIsOpen: () => void = () => {
    this._isOpen = !this._isOpen;
  };
}
