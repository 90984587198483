import { action, computed, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import UserModel from 'models/UserModel';
import { AttachmentType } from 'util/enums';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class FileModel extends UpdateModel<FileModel> {
  @observable public id: string;
  @observable public name: string;
  @observable public path: string;
  @observable public type: AttachmentType;
  @observable public thumbnailPath?: string;
  @observable public createdBy?: UserModel<IUserModelConstructObj>;

  @computed
  public get isAttachmentPDF(): boolean {
    return this.name.includes('.pdf');
  }

  @action
  public update = (obj: FileModel) => {
    const newFileModel = cloneObj(obj);

    if (newFileModel) {
      newFileModel.createdBy = newFileModel.createdBy
        ? new UserModel<IUserModelConstructObj>().update(newFileModel.createdBy)
        : new UserModel<IUserModelConstructObj>();
    }

    this.updater.update(this, newFileModel, FileModel);
    return this;
  };
}
