import { action, computed, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import IdCodeNameModel from 'models/IdCodeNameModel';

export default class EmployeeModel extends UpdateModel<EmployeeModel> {
  @observable public id: string = '';
  @observable public externalId: string = '';
  @observable public firstName: string = '';
  @observable public lastName: string = '';
  @observable public legalEntity?: string = '';

  @computed
  public get convertedIdCodeNameModel(): IdCodeNameModel {
    return new IdCodeNameModel().update({ id: this.id, name: this.fullName, code: this.externalId } as IdCodeNameModel);
  }

  @computed
  public get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  @action
  public update(obj: EmployeeModel) {
    this.updater.update(this, obj, EmployeeModel);
    return this;
  }
}
