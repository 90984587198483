import * as React from 'react';
import * as classnames from 'classnames';
import * as H from 'history';
import * as moment from 'moment';
import * as FontAwesome from 'react-fontawesome';

import DeliveryLabModel from 'models/DeliveryLabModel';
import DeliveryLabReceivedGoodModel from 'models/DeliveryLabReceivedGoodModel';
import LabStatusModel from 'models/LabStatusModel';
import LabStatsModel from 'models/LabStatsModel';
import TranslateService from 'services/TranslateService';
import { inject, observer } from 'mobx-react';
import { LabAnalysisStatus } from 'util/enums';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../../assets/i18n/i18n';
import useWindowWidth from 'components/UseWindowWidth';
import { MAX_MOBILE_PORTRAIT_WIDTH, TEXT_DANGER } from 'util/constants';

interface IProps {
  delivery: DeliveryLabModel;
  removeNotificationFlagFromReceivedGood: (id: string) => void;
  labStatuses: LabStatusModel[];
  windowWidth?: number;
  history?: H.History;
  key?: string;
  translateService?: TranslateService;
}

interface IState {
  isOpen: boolean;
}

@inject('translateService')
@observer
export class DeliveryListLabComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public render() {
    const { isOpen } = this.state;
    const {
      delivery,
      labStatuses,
      translateService: { t },
    } = this.props;

    return (
      <React.Fragment>
        <tr
          data-test="lab-item"
          onClick={this._toggleCollapse}
          className={classnames(['pointer lab-item', { 'border-bottom-0': isOpen }])}
        >
          <td className="text-center">
            <FontAwesome name={isOpen ? 'angle-up' : 'angle-down'} />
          </td>
          <td className={classnames({ [TEXT_DANGER]: delivery.flagged })}>
            {delivery.grn}
            {(delivery.flagged || delivery.wasFlagged) && <FontAwesome name="flag" className="ml-2" />}
          </td>
          <td>{delivery.supplierInfo}</td>
          <td>{delivery.tenant.name}</td>
          {labStatuses.map((labStatus) => (
            <td
              className={classnames([
                'text-center',
                { 'color-red': delivery.hasNotification && labStatus.name === LabAnalysisStatus.REQUESTED },
              ])}
              key={`sd2-${labStatus.id}`}
            >
              {this._countStats(delivery.stats, labStatus)}
            </td>
          ))}
          <td />
        </tr>
        {delivery.receivedGoods && isOpen && (
          <React.Fragment>
            <tr className="lab-sample">
              <th colSpan={2} className="padding-left-30">
                {t.GLOBAL_LABEL_GRN}
              </th>
              <th colSpan={2}>{t.GLOBAL_LABEL_DESCRIPTION}</th>
              {this._hasInternalLabStatuses &&
                labStatuses.map((labStatus) => (
                  <th data-test="internal-lab-statuses" className="text-center" key={`sd3-${labStatus.id}`}>
                    {getTranslation(`STATUS_LABSTATUSES_${labStatus.name}` as keyof I18N, t)}
                  </th>
                ))}
            </tr>

            {delivery.receivedGoods.map((ag: DeliveryLabReceivedGoodModel) => (
              <tr
                data-test="received-good"
                key={ag.receivedGoodId}
                className={classnames([
                  'pointer lab-sample',
                  { 'bg-red': moment.utc(ag.oldestInLabDate).add(3, 'days').isBefore(moment.now()) },
                ])}
                onClick={() => this._goToReceivedGood(ag)}
              >
                <td colSpan={2} className="padding-left-30">
                  /{ag.agTitle.split('/')[1]}
                </td>
                <td colSpan={2}>{ag.description}</td>
                {labStatuses.map((labStatus) => (
                  <td
                    className={classnames(['text-center', { 'color-red': this._hasNotification(ag.stats, labStatus) }])}
                    key={`sd3-${labStatus.id}`}
                  >
                    {this._countStats(ag.stats, labStatus)}
                  </td>
                ))}
                <td className="text-center">
                  <FontAwesome name="angle-right" />
                </td>
              </tr>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private get _hasInternalLabStatuses() {
    return this.props.windowWidth >= MAX_MOBILE_PORTRAIT_WIDTH;
  }

  private _goToReceivedGood = (ag: DeliveryLabReceivedGoodModel) => {
    this.props.removeNotificationFlagFromReceivedGood(ag.receivedGoodId);
    this.props.history.push(`/advised-good-lab/${ag.advisedGoodId}/${ag.receivedGoodId}`);
  };

  private _toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  private _countStats = (stats: LabStatsModel[], labStatus: LabStatusModel): string => {
    const find = stats.find((item: LabStatsModel) => item.status.id === labStatus.id);
    return find ? find.count : '';
  };

  private _hasNotification = (stats: LabStatsModel[], labStatus: LabStatusModel): boolean => {
    const find = stats.find((item: LabStatsModel) => item.status.id === labStatus.id);
    return find ? find.hasNotification : false;
  };
}

export default useWindowWidth(DeliveryListLabComponent);
