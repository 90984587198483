import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, reaction } from 'mobx';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import { Route } from 'react-router';

import NavlinkCustom from 'domain/NavlinkCustom';
import TranslateService from 'services/TranslateService';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetPrinterConfigurationModel from 'models/LasernetPrinterConfigurationModel';
import LasernetPrinterConfigurationDetailRoute from 'pod/admin/lasernetPrinters/printerConfigurations/LasernetPrinterConfigurationDetailRoute';
import LasernetPrinterConfigurationDetailComponent from 'pod/admin/lasernetPrinters/printerConfigurations/LasernetPrinterConfigurationDetailComponent';
import AdminStore from 'pod/admin/AdminStore';
import { instance as notification } from 'util/notification';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';

interface IProps {
  translateService?: TranslateService;
  adminStore?: AdminStore;
  lasernetPrinterStore?: LasernetPrinterStore;
}

@inject('lasernetPrinterStore', 'adminStore', 'translateService')
@observer
export default class LasernetPrinterConfigurationsListRoute extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
    reaction(() => !this.showCreate, this.clearModel);
  }
  @observable public showCreate: boolean = false;
  @observable public content: LasernetPrinterConfigurationModel = new LasernetPrinterConfigurationModel();

  @action
  public clearModel = () => {
    this.content = new LasernetPrinterConfigurationModel();
  };

  @action
  public toggleCreate = () => {
    this.showCreate = !this.showCreate;
  };

  public createItem = (item: LasernetPrinterConfigurationModel) => {
    const {
      translateService: { t },
      lasernetPrinterStore,
    } = this.props;
    return lasernetPrinterStore.createConfiguration(item).then(() => {
      notification.success(t.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG(t.USA_CONFIGURATION_CONFIGURATION.toLowerCase()));
      this.toggleCreate();
    });
  };

  public componentDidMount() {
    this.props.adminStore.userApi.getAllItems();
    this.props.lasernetPrinterStore.getAllDocumentTypeItems();
    this.props.lasernetPrinterStore.getPrinterConfigurations();
    this.props.lasernetPrinterStore.printerApi.getAllItems();
  }

  public get configurationList() {
    const {
      translateService: { t },
      lasernetPrinterStore: { configurationsList },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.ADMIN_SECTIONENTITY_USER}</th>
            <th>{t.ADMIN_LASERNET_DOCUMENT_TYPE_ID}</th>
            <th className="pr-3">{t.ADMIN_PRINTERLIST_NAME}</th>
          </tr>
        </thead>
        {configurationsList.map((configuration: LasernetPrinterConfigurationModel) => this._renderItem(configuration))}
      </table>
    );
  }

  public get configurationForm() {
    return <LasernetPrinterConfigurationDetailComponent content={this.content} />;
  }

  public render() {
    const {
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.ADMIN_LASERNET_CONFIGURATIONS_HEADER}
              <Button data-test="create-btn" className="float-right" color="success" onClick={this.toggleCreate}>
                <FontAwesome name="plus" className="mr-2" />
                {t.GLOBAL_LABEL_CREATE}
              </Button>
            </h2>
            {this.configurationList}
          </Col>
        </Row>
        {/* MODAL - CREATE NEW ITEM */}
        <Modal isOpen={this.showCreate} toggle={this.toggleCreate} backdrop="static">
          <ModalHeader toggle={this.toggleCreate}>
            {t.ADMIN_COMMONLIST_CREATE_HEADER(t.USA_CONFIGURATION_CONFIGURATION.toLowerCase())}
          </ModalHeader>
          <ModalBody>
            <CommonAdminDetailComponent content={this.content} saveAction={this.createItem}>
              {this.configurationForm}
            </CommonAdminDetailComponent>
          </ModalBody>
        </Modal>

        <Route path={`/admin/lasernet-configurations/:id`} component={LasernetPrinterConfigurationDetailRoute} />
      </Container>
    );
  }

  private _renderItem = (configuration: LasernetPrinterConfigurationModel) => {
    const {
      lasernetPrinterStore: { getPrinterById, getDocumentTypeById, getUserById },
    } = this.props;
    return (
      <tbody key={configuration.id}>
        <NavlinkCustom
          to={`/admin/lasernet-configurations/${configuration.id}`}
          tagName="tr"
          key={configuration.id}
          className="pointer"
        >
          <td className="pl-3">{getUserById(configuration.userId)?.name}</td>
          <td>{getDocumentTypeById(configuration.documentTypeId)?.name}</td>
          <td className="pr-3">{getPrinterById(configuration.printerId)?.name}</td>
        </NavlinkCustom>
      </tbody>
    );
  };
}
