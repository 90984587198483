import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { DepartmentsMap } from 'util/constants';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../../assets/i18n/i18n';
import AttachmentList from 'domain/AttachmentList';
import TransfersHistory from 'pod/advisedGoods/TransfersHistory';
import ViewStore from 'stores/ViewStore';
import TranslateService from 'services/TranslateService';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import DateFormattingService from 'services/DateFormattingService';
import { AdvisedGoodAction } from 'pod/advisedGoods/AdvisedGoodAction';

interface IProps {
  advisedGoodsStore?: AdvisedGoodsStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  dateFormattingService?: DateFormattingService;
}

@inject('advisedGoodsStore', 'translateService', 'viewStore', 'dateFormattingService')
@observer
export default class AdvisedGoodDeliveryDetailsComponent extends React.Component<IProps> {
  public render() {
    const {
      advisedGoodsStore: { content },
      translateService: { t },
      viewStore: { isIT, isUS, isDE_D365 },
      dateFormattingService: { formatDate },
    } = this.props;

    if (!(content && content.delivery)) {
      return null;
    }

    return (
      <table>
        <tbody id="delivery-detail-body" className="vertical-align-top" data-test="delivery-details">
          <tr>
            <td className="width-130 font-light">{t.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION}</td>
            <td className="font-16" colSpan={3}>
              {content.advisedDescription}
            </td>
          </tr>

          <tr>
            <td className="font-light">{t.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION}</td>
            <td className="font-16" colSpan={3}>
              {content.vehicleRegistrationNumber}
            </td>
          </tr>

          {this._hasDeliveryNote && (
            <tr data-test="delivery-note">
              <td className="font-light">{t.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER}</td>
              <td className="font-16" colSpan={3} data-test="delivery-note-value">
                {content.delivery.deliveryNote}
              </td>
            </tr>
          )}

          {!!content.delivery.containerNumber && (
            <tr>
              <td className="font-light">{t.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER}</td>
              <td className="font-16" colSpan={3}>
                {content.delivery.containerNumber}
              </td>
            </tr>
          )}

          {!!content.deliveryType && !!content.deliveryTypeNumber && (
            <tr data-test="has-delivery-type">
              <td className="font-light">{t.ADVISEDGOOD_ROUTE_DELIVERY_TYPE}</td>
              <td className="font-16" colSpan={3}>{`${content.deliveryType} (${content.deliveryTypeNumber})`}</td>
            </tr>
          )}

          {!!content.departmentCode && (
            <tr data-test="has-department">
              <td className="font-light">{t.GLOBAL_LABEL_DEPARTMENT}</td>
              <td className="font-16" colSpan={3}>
                {DepartmentsMap.get(content.departmentCode)}
              </td>
            </tr>
          )}

          {!!content.delivery.driverName && (
            <tr data-test="has-driver-name">
              <td className="font-light">{t.GLOBAL_LABEL_DRIVER}</td>
              <td className="font-16" colSpan={3}>
                {content.delivery.driverName}
              </td>
            </tr>
          )}

          <tr>
            <td className="font-light">{t.GLOBAL_LABEL_STATUS}</td>
            <td className="font-16" colSpan={3}>
              {getTranslation(`STATUS_ADVISEDGOOD_${content.status}` as keyof I18N, t)}
            </td>
          </tr>

          {!!content.ewcCode && (
            <tr data-test="has-ewc-code">
              <td className="font-light">{t.ADVISEDGOOD_ROUTE_EWC_NUMBER}</td>
              <td className="font-16" colSpan={3}>
                {content.ewcCode}
              </td>
            </tr>
          )}
          {isUS && !!content.delivery.transactionType && (
            <tr data-test="transaction-type">
              <td className="font-light">{t.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE}</td>
              <td className="font-16" colSpan={3} data-test="transaction-type-value">
                {getTranslation(
                  `DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_${content.delivery.transactionType}` as keyof I18N,
                  this.props.translateService.t
                )}
              </td>
            </tr>
          )}

          <tr>
            <td className="font-light">{t.GLOBAL_LABEL_SUPPLIER}</td>
            <td className="font-16" colSpan={3}>
              {content.delivery.supplier.name}
            </td>
          </tr>
          {isIT && (
            <tr>
              <td className="font-light">{t.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE}</td>
              <td className="font-16" colSpan={3} data-test="certificate">
                {content.delivery.supplier && content.delivery.supplier.expirationDate
                  ? formatDate(content.delivery.supplier.formattedExpirationDate)
                  : t.GLOBAL_LABEL_NO_CERTIFICATE}
              </td>
            </tr>
          )}
          {isIT && !!content.delivery.trader && (
            <tr>
              <td className="font-light">{t.GLOBAL_USERROLE_TRADER}</td>
              <td className="font-16" colSpan={3} data-test="trader">
                {content.delivery.trader.fullName}
              </td>
            </tr>
          )}

          {!!content.delivery.supplierCity && (
            <tr data-test="has-supplier-city">
              <td className="font-light">{t.GLOBAL_LABEL_SUPPLIER_CITY}</td>
              <td className="font-16" colSpan={3}>
                {content.delivery.supplierCity}
              </td>
            </tr>
          )}

          <tr>
            <td className="font-light">{t.GLOBAL_LABEL_REMARKS}</td>
            <td className="font-16" colSpan={3}>
              {content.remarks}
            </td>
          </tr>

          {isUS && (
            <React.Fragment>
              <tr data-test="weather-condition">
                <td className="font-light">{t.USA_CONFIGURATION_WEATHER_CONDITIONS}</td>
                <td className="font-16" colSpan={3} data-test="weather-condition-value">
                  {getTranslation(`GLOBAL_RADIOBUTTON_${content.delivery.weatherCondition}` as keyof I18N, t)}
                </td>
              </tr>
              <tr data-test="delivery-type">
                <td className="font-light">{t.ADVISEDGOOD_ROUTE_DELIVERY_TYPE}</td>
                <td className="font-16" colSpan={3} data-test="delivery-type-value">
                  {getTranslation(`GLOBAL_RADIOBUTTON_${content.delivery.deliveryType}` as keyof I18N, t)}
                </td>
              </tr>
            </React.Fragment>
          )}

          {isDE_D365 && content.hasProductionFormAndQuality && (
            <React.Fragment>
              <tr data-test="product-form">
                <td className="font-light">{t.GLOBAL_LABEL_FORM}</td>
                <td className="font-16" colSpan={3} data-test="product-form-value">
                  {content.productForm.name}
                </td>
              </tr>
              <tr data-test="product-quality">
                <td className="font-light">{t.GLOBAL_LABEL_QUALITY}</td>
                <td className="font-16" colSpan={3} data-test="product-quality-value">
                  {content.productQuality.name}
                </td>
              </tr>
            </React.Fragment>
          )}

          {this._hasClaimedBy && (
            <AdvisedGoodAction dataTest="claimed-by" title={t.GLOBAL_LABEL_CLAIM_BY} model={content.claimedBy} />
          )}

          {this._hasFlaggedBy && (
            <AdvisedGoodAction dataTest="flagged-by" title={t.GLOBAL_LABEL_FLAGGED_BY} model={content.flaggedBy} />
          )}

          {this._hasUnflaggedBy && (
            <AdvisedGoodAction
              dataTest="unflagged-by"
              title={t.GLOBAL_LABEL_UNFLAGGED_BY}
              model={content.unflaggedBy}
            />
          )}

          {this._hasClassifiedBy && (
            <AdvisedGoodAction
              dataTest="classified-by"
              title={t.GLOBAL_LABEL_CLASSIFIED_BY}
              model={content.classifiedBy}
            />
          )}

          {this._hasVerifiedBy && (
            <AdvisedGoodAction dataTest="verified-by" title={t.GLOBAL_LABEL_VERIFIED_BY} model={content.verifiedBy} />
          )}

          {this._hasSignedOffBy && (
            <AdvisedGoodAction
              dataTest="signed-off-by"
              title={t.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY}
              model={content.signedOffBy}
            />
          )}

          {this.__hasCompletedBy && (
            <AdvisedGoodAction
              dataTest="completed-by"
              title={t.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY}
              model={content.completedBy}
            />
          )}

          {this._hasRolledbackBy && (
            <AdvisedGoodAction
              dataTest="rolledback-by"
              title={t.GLOBAL_LABEL_ROLLEDBACK_BY}
              model={content.rolledbackBy}
            />
          )}

          {this._hasDeliveryAttachments && (
            <tr data-test="delivery-attachments">
              <td className="pt-2 font-light">{t.GLOBAL_LABEL_ATTACHMENTS}</td>
              <td className="pt-2 font-16" data-test="delivery-attachments-value">
                <AttachmentList attachments={content.delivery.attachments} />
              </td>
            </tr>
          )}

          {this._hasTransfers && (
            <tr data-test="transfers">
              <td className="font-light">{t.GLOBAL_LABEL_TRANSFERS}</td>
              <td className="font-16" colSpan={3} data-test="transfers-value">
                <TransfersHistory transfers={content.transfers} />
              </td>
            </tr>
          )}

          {content.nonAdvisedGood && (
            <tr data-test="non-advised-good">
              <td className="font-light">{t.GLOBAL_LABEL_TRANSFERED}</td>
              <td className="font-16">{t.GLOBAL_LABEL_YES}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  private get _hasDeliveryNote() {
    const {
      viewStore: { isIT },
    } = this.props;
    return isIT;
  }

  private get _hasClaimedBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.claimedBy && content.claimedBy.date && content.claimedBy.user);
  }

  private get _hasFlaggedBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.flaggedBy && content.flaggedBy.date && content.flaggedBy.user);
  }

  private get _hasUnflaggedBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.unflaggedBy && content.unflaggedBy.date && content.unflaggedBy.user);
  }

  private get _hasClassifiedBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.classifiedBy && content.classifiedBy.date && content.classifiedBy.user);
  }

  private get _hasVerifiedBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.verifiedBy && content.verifiedBy.date && content.verifiedBy.user);
  }

  private get _hasSignedOffBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.signedOffBy && content.signedOffBy.date && content.signedOffBy.user);
  }

  private get __hasCompletedBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.completedBy && content.completedBy.date && content.completedBy.user);
  }

  private get _hasRolledbackBy(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return !!(content.rolledbackBy && content.rolledbackBy.date && content.rolledbackBy.user);
  }

  private get _hasDeliveryAttachments(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.delivery.attachments && content.delivery.attachments.length !== 0;
  }

  private get _hasTransfers(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.transfers && content.transfers.length !== 0;
  }
}
