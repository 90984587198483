import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';

interface IProps {
  callbackYes: () => void;
  heading: string;
  isOpen: boolean;
  toggleModal: () => void;
  buttonNo?: string;
  buttonYes?: string;
  buttonYesColor?: string;
  callbackNo?: () => void;
  ico?: string;
  text?: string;
  body?: React.ReactNode;
  translateService?: TranslateService;
  customButtons?: React.ReactNode;
}

@inject('translateService')
@observer
export default class ModalConfirmation extends React.Component<IProps> {
  public render() {
    const {
      heading,
      ico,
      text,
      buttonNo,
      buttonYes,
      isOpen,
      toggleModal,
      buttonYesColor,
      body,
      translateService: { t },
      customButtons,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal} data-test="header">
          {ico && <FontAwesome name={ico} className="mr-2" />}
          {heading}
        </ModalHeader>
        {text && (
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </ModalBody>
        )}
        {body && <ModalBody>{body}</ModalBody>}
        <ModalFooter>
          {customButtons}
          <Button data-test="no" color="secondary" onClick={this._callbackNo}>
            {buttonNo ? buttonNo : t.GLOBAL_LABEL_NO}
          </Button>
          <Button data-test="yes" color={buttonYesColor ? buttonYesColor : 'danger'} onClick={this._callbackYes}>
            {buttonYes ? buttonYes : t.GLOBAL_LABEL_YES}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  private _callbackYes = () => {
    this.props.callbackYes();
    this.props.toggleModal();
  };

  private _callbackNo = () => {
    if (this.props.callbackNo) {
      this.props.callbackNo();
    }
    this.props.toggleModal();
  };
}
