import axios from 'axios';
import storage from 'util/storage';
import { logger } from './logger';

// FUNC TO CHECK, IF APP HASH IS THE SAME LIKE LAST DEPLOYMENT HASH
export default function checkLatestAppVersion(cache: number = new Date().getTime()) {
  const version = axios.get(`/assets/dops-version.txt?cache=${cache}`);
  return version
    .then((res) => {
      const currentVersion = `${storage.get('currentVersion')}`;
      const deployedVersion = `${String(res.data)}`;

      if (currentVersion !== deployedVersion) {
        storage.set('currentVersion', deployedVersion);

        if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.reloadApp) {
          JSInterface.reloadApp();
        } else {
          window.location.reload(true);
        }
      }

      return deployedVersion;
    })
    .catch((e) => {
      logger.error('error while looking for latest UI version', e);
      return '';
    });
}
