import { action, observable, observe, computed } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';

export default class DeliverySubAdvisedGoodModel extends UpdateModel<DeliverySubAdvisedGoodModel>
  implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  @observable public advisedDescription?: string = '';
  @observable public advisedWeight?: number = null;
  @observable public id?: string = null;

  @computed public get shouldAddNewLine() {
    return !!this.advisedDescription || !!this.advisedWeight;
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public update = (obj: DeliverySubAdvisedGoodModel) => {
    this.updater.update(this, obj, DeliverySubAdvisedGoodModel);

    return this;
  };

  @action public setAdvisedDescription(val: string) {
    this.advisedDescription = val;
  }
  @action public setAdvisedWeight(val: number) {
    this.advisedWeight = val;
  }

  // CONSTRUT OBJ FOR PUT/POST DELIVERY
  @action public constructSaveObj() {
    return {
      advisedDescription: this.advisedDescription,
      advisedWeight: this.advisedWeight,
      id: this.id,
    };
  }
}
