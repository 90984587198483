import * as React from 'react';
import * as H from 'history';
import { inject } from 'mobx-react';
import SessionStore from 'stores/SessionStore';

interface IProps {
  history: H.History;
  sessionStore: SessionStore;
}

@inject('sessionStore')
export default class IndexRoute extends React.Component<IProps> {
  public componentDidMount() {
    const {
      sessionStore: { isSuperAdmin, isAdmin, isLab },
    } = this.props;
    if (isSuperAdmin) {
      this.props.history.push('/super-admin/tenants');
    } else if (isAdmin) {
      this.props.history.push('/admin/user-management');
    } else if (isLab) {
      this.props.history.push('/deliveries/lab');
    } else {
      this.props.history.push('/deliveries');
    }
  }

  public render() {
    return <div />;
  }
}
