import { action, observable } from 'mobx';

export default class LayoutStore {
  @observable public menuOpen: boolean = false;
  @observable public isLoading: boolean = false;

  @action
  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  public toggleMenu(forceOpen: boolean | null = null) {
    this.menuOpen = forceOpen !== null ? forceOpen : !this.menuOpen;
  }
}
