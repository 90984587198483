import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

import IdNameModel from 'models/IdNameModel';
import { DepartmentCode } from 'util/enums';
import { cloneObj } from 'util/helpers';

export interface ITransferData {
  description: string;
  weight?: number;
  yardLocationId?: string;
  departmentCode?: DepartmentCode;
}

export default class TransferModel extends UpdateModel<TransferModel> {
  @observable public description?: string;
  @observable public weight?: number;
  @observable public yardLocation?: IdNameModel = null;
  @observable public departmentCode?: DepartmentCode = null;
  @observable public id?: string = null;

  @action
  public update = (obj: TransferModel) => {
    const newTransferModel = cloneObj(obj);

    if (newTransferModel) {
      newTransferModel.yardLocation = new IdNameModel().update(newTransferModel.yardLocation);
    }

    this.updater.update(this, newTransferModel, TransferModel);
    return this;
  };

  @action
  public setWeight(newWeight: number) {
    this.weight = newWeight;
  }

  @action
  public setYardLocation(newYardLocation: IdNameModel) {
    this.yardLocation = newYardLocation;
  }

  @action
  public setDepartmentCode(newDepartmentCode: DepartmentCode) {
    this.departmentCode = newDepartmentCode;
  }

  public getTransferData(isDE: boolean): ITransferData {
    const res: ITransferData = { description: this.description };
    if (isDE) {
      res.departmentCode = this.departmentCode;
    } else {
      res.yardLocationId = this.yardLocation ? this.yardLocation.id : null;
      res.weight = this.weight;
    }

    return res;
  }
}
