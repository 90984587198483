import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';

import PrinterDetailComponent from 'pod/admin/printers/PrinterDetailComponent';
import AdminStore from 'pod/admin/AdminStore';
import PrinterModel from 'models/PrinterModel';
import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

@inject('adminStore', 'translateService')
@observer
export default class PrinterDetailRoute extends React.Component<IProps> {
  @observable
  public content: PrinterModel = null;

  @action
  public updateContent = (newPrinter: PrinterModel) => {
    this.content = new PrinterModel().update(newPrinter);
  };

  public render() {
    const {
      translateService: { t },
      adminStore: { printerApi, printerList },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.PRINTER}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_PRINTER}
        itemsList={printerList}
        api={printerApi}
        updateItem={this.updateContent}
      >
        <PrinterDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}
