import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import * as Popper from 'popper.js';
import { action, observable } from 'mobx';
import { randomPasswordGenerator } from 'util/randomPasswordGenerator';

interface IRandomPasswordGeneratorDefaultButtonProps {
  tooltipText?: string;
  tooltipPlacement?: Popper.Placement;
}

interface IRandomPasswordGeneratorButtonProps extends IRandomPasswordGeneratorDefaultButtonProps {
  passwordLength: number;
  callback: (password: string) => void;
  buttonClassName?: string;
}

@observer
export default class RandomPasswordGeneratorButton extends React.Component<IRandomPasswordGeneratorButtonProps> {
  public static defaultProps: IRandomPasswordGeneratorDefaultButtonProps = {
    tooltipText: '',
    tooltipPlacement: 'top',
  };

  @observable private _btnRef: HTMLElement;

  public render() {
    const { tooltipText, tooltipPlacement } = this.props;
    return (
      <React.Fragment>
        <Button
          data-test="random-password-generator"
          innerRef={this._setRef}
          size="sm"
          className={classnames('position-center', this.props.buttonClassName)}
          onClick={this._generateRandomPassword}
        >
          <FontAwesome name="key" />
        </Button>
        {this._btnRef && tooltipText && (
          <UncontrolledTooltip
            data-test="tooltip"
            placement={tooltipPlacement}
            target={this._btnRef}
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
          >
            {tooltipText}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    );
  }

  private _generateRandomPassword = () => {
    const password = randomPasswordGenerator(this.props.passwordLength);
    this.props.callback(password);
  };

  @action
  private _setRef = (btn: HTMLElement) => {
    this._btnRef = btn;
  };
}
