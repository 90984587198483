import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { IActive } from 'models/ModelInterfaces';

export default class IdActiveModel extends UpdateModel<IdActiveModel> implements IActive {
  public id: string;

  @observable
  public active: boolean;

  @action
  public update(obj: IdActiveModel) {
    this.updater.update(this, obj, IdActiveModel);
    return this;
  }

  @action
  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
}
