import * as React from 'react';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Button, Collapse } from 'reactstrap';

import LabStatusModel from 'models/LabStatusModel';
import LabTrackingModel from 'models/LabTrackingModel';
import IdNameModel from 'models/IdNameModel';
import { getTranslation } from 'util/helpers';
import SessionStore from 'stores/SessionStore';
import { I18N } from '../../../assets/i18n/i18n';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import DateFormattingService from 'services/DateFormattingService';

interface IProps {
  key?: string;
  labAnalysisStatusPut?: (newLocationId: string, newStatusId: string) => void;
  labInputTracking: LabTrackingModel[];
  labStatus: LabStatusModel;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  dateFormattingService?: DateFormattingService;
}

interface IState {
  showLocationDropdown: boolean;
}

@inject('sessionStore', 'viewStore', 'translateService', 'dateFormattingService')
@observer
export default class LabStatusBoxComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showLocationDropdown: false,
    };
  }

  public render() {
    const {
      labStatus,
      labInputTracking,
      translateService: { t },
      viewStore: { timezone },
      dateFormattingService: { formatDateTime },
    } = this.props;
    const { showLocationDropdown } = this.state;
    const trackingStatuses = labInputTracking.filter(
      (labInputTrackingItem) => labInputTrackingItem.status.id === labStatus.id
    );
    const isActive = trackingStatuses && trackingStatuses.length !== 0 && trackingStatuses[0].active;

    return (
      <div key={`ls-${labStatus.id}`} className="float-left lab-status-item">
        <div
          className={classnames(['text-center font-light lab-status-box bg-secondary', { 'bg-success': isActive }])}
          onClick={this._handleStatusChange}
          data-test="lab-status-box"
        >
          <div className={`lab-status-box-icon icon-${labStatus.name.toLowerCase()}`} />
          {trackingStatuses[0] && trackingStatuses[0].location && (
            <div className="status-location-number font-medium">{trackingStatuses[0].location.name.split(' ')[1]}</div>
          )}
        </div>

        <Collapse
          isOpen={showLocationDropdown}
          className="lab-status-location-dropdown border-left border-right border-bottom"
        >
          {labStatus.locations.length !== 0 && (
            <div className="text-center">
              <div className="pt-1 pb-1 font-12">{labStatus.locations[0].name.split(' ')[0]}</div>
              {labStatus.locations.map((location) => (
                <div key={location.id}>
                  <Button
                    data-test="lab-status-button"
                    color="success"
                    onClick={() => this._handleStatusWithLocationChange(location)}
                  >
                    {location.name.split(' ')[1]}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </Collapse>

        <div className="text-center mt-1">
          {getTranslation(`STATUS_LABSTATUSES_${labStatus.name.toUpperCase()}` as keyof I18N, t)}
          {trackingStatuses && trackingStatuses.length !== 0 && (
            <div className="pt-1">
              <strong className="d-block">{trackingStatuses[0].createdBy.fullName}</strong>
              <div className="font-10">{formatDateTime(trackingStatuses[0].createdAt, timezone)}</div>
            </div>
          )}
        </div>
        <div className={classnames(['lab-analysis-line', { 'bg-success': isActive }])} />
      </div>
    );
  }

  private _handleStatusChange = () => {
    const {
      sessionStore: { isLab },
      labAnalysisStatusPut,
    } = this.props;
    if (!isLab || !labAnalysisStatusPut) {
      return;
    }
    const { labStatus } = this.props;
    const { showLocationDropdown } = this.state;

    // IF HAS LOCATIONS, OPEN DROPDOWN AND RETURN
    if (labStatus.locations.length) {
      this.setState({
        showLocationDropdown: !showLocationDropdown,
      });
      return;
    }

    // CHANGE LAB STATUS WITHOUT LOCATION
    labAnalysisStatusPut(null, labStatus.id);
  };

  private _handleStatusWithLocationChange = (location: IdNameModel) => {
    const {
      sessionStore: { isLab },
      labAnalysisStatusPut,
    } = this.props;

    if (!isLab || !labAnalysisStatusPut) {
      return;
    }
    // CHANGE LAB STATUS WITH CHOSEN LOCATION
    const { labStatus } = this.props;
    this.setState(
      {
        showLocationDropdown: false,
      },
      () => {
        labAnalysisStatusPut(location.id, labStatus.id);
      }
    );
  };
}
