import { action, computed, observable } from 'mobx';
import TenantModel, { ITenantSaveObj } from 'models/TenantModel';
import UserModel from 'models/UserModel';
import RootService from 'services/RootService';
import WorkflowModel from 'models/WorkflowModel';
import { logger } from 'util/logger';
import IdNameModel from 'models/IdNameModel';
import { sortActiveFirst } from 'util/helpers';
import UserTenantModel from 'models/UserTenantModel';
import SharepointModel from 'models/SharepointModel';
import AppUserModel from 'models/AppUserModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class SuperAdminStore {
  @observable public tenants: TenantModel[];
  @observable public workflowList: WorkflowModel[] = [];
  @observable public admins: UserModel<IUserModelConstructObj>[] = [];
  @observable public tenantUnits: string[] = [];
  @observable public legalEntities: IdNameModel[] = [];
  @observable public sharePointSites: SharepointModel[] = [];
  @observable public appUsers: AppUserModel[] = [];

  public constructor(private readonly rootService: RootService) {}

  // BOF TENANTS
  public async getTenants() {
    const tenants = await this._getTenantsRequest(false);
    this._setTenants(tenants);
    return this.tenants;
  }

  public getTenantsForTheSameWorkflow = async (currentTenant: TenantModel): Promise<UserTenantModel[]> => {
    if (!this.tenants) {
      await this.getTenants();
    }
    return this.tenants
      .filter(
        (item: TenantModel) =>
          item.regionCode === currentTenant.regionCode && item.workflow.code === currentTenant.workflow.code
      )
      .map((tenant: TenantModel) => {
        const userTenantModelTenant = new UserTenantModel();
        userTenantModelTenant.changeName(tenant.name);
        userTenantModelTenant.id = tenant.id;
        userTenantModelTenant.code = tenant.code;
        userTenantModelTenant.regionCode = tenant.regionCode;
        userTenantModelTenant.workflowCode = tenant.workflow.code;
        return userTenantModelTenant;
      });
  };

  public async getTenantsForDevLogin(): Promise<TenantModel[]> {
    const tenants = await this._getTenantsRequest(true);
    this._setTenants(tenants);
    return this.tenants;
  }

  public async getWorkflows() {
    const workflows = await this._getWorkflowsRequest();
    this._setWorkflowList(workflows);
    return this.workflowList;
  }

  @action
  private _setTenants = (res: TenantModel[]) => {
    this.tenants = res.map((t: TenantModel) => new TenantModel().update(t));
  };

  public async postTenant(tenant: ITenantSaveObj) {
    const tenantResponse = await this._postTenantRequest(tenant);
    this.getTenants();
    return tenantResponse;
  }

  public async putTenant(tenant: ITenantSaveObj, id: string) {
    await this._putTenantRequest(tenant, id);
    this.getTenants();
  }

  public async getTenantById(tenantId: string) {
    const tenant = new TenantModel().update(await this._getTenantByIdRequest(tenantId));
    const isDeliveryCreated = await this._isDeliveryCreatedInTenantRequest(tenantId);
    tenant.setIsDeliveryCreated(Boolean(isDeliveryCreated));
    return tenant;
  }

  public async deleteTenantById(tenantId: string) {
    await this._deleteTenantByIdRequest(tenantId);
    this.getTenants();
  }

  private _postTenantRequest(tenant: ITenantSaveObj) {
    return this.rootService.ajaxService.post(`tenants`, tenant);
  }

  private _putTenantRequest(tenant: ITenantSaveObj, id: string) {
    return this.rootService.ajaxService.put(`tenants/${id}`, tenant);
  }

  private _getTenantByIdRequest(tenantId: string): Promise<TenantModel> {
    return this.rootService.ajaxService.get(`tenants/${tenantId}`);
  }

  private _deleteTenantByIdRequest(tenantId: string) {
    return this.rootService.ajaxService.delete(`tenants/${tenantId}`);
  }

  private _isDeliveryCreatedInTenantRequest = (tenantId: string): Promise<number> => {
    return this.rootService.ajaxService.get(`tenants/${tenantId}/grns`);
  };

  // EOF TENANTS

  // BOF SUPER ADMIN USER CREATE
  public postAdmin(admin: UserModel<IUserModelConstructObj>) {
    return this.rootService.ajaxService.post('users/admin', admin);
  }

  public putAdmin(admin: UserModel<IUserModelConstructObj>) {
    return this.rootService.ajaxService.put(`users/${admin.id}`, admin.constructSaveObj);
  }

  public hideAdminById(adminsList: UserModel<IUserModelConstructObj>[], adminId: string) {
    const hidedItem = adminsList.find((item: UserModel<IUserModelConstructObj>) => item.id === adminId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(false);
      return this.putAdmin(hidedItem);
    } else {
      logger.error(`Admin ${adminId} can not be find`);
    }
  }

  public unhideAdminById(adminsList: UserModel<IUserModelConstructObj>[], adminId: string) {
    const hidedItem = adminsList.find((item: UserModel<IUserModelConstructObj>) => item.id === adminId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(true);
      return this.putAdmin(hidedItem);
    } else {
      logger.error(`Admin ${adminId} can not be find`);
    }
  }

  public hideAppUserById(appUsersList: AppUserModel[], appUserId: string): Promise<AppUserModel> | undefined {
    const hidedItem = appUsersList.find((item: AppUserModel) => item.id === appUserId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(false);
      return this.putAppUser(hidedItem);
    } else {
      logger.error(`AppUser ${appUserId} can not be find`);
    }
  }

  public unhideAppUserById(appUsersList: AppUserModel[], appUserId: string): Promise<AppUserModel> | undefined {
    const hidedItem = appUsersList.find((item: AppUserModel) => item.id === appUserId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(true);
      return this.putAppUser(hidedItem);
    } else {
      logger.error(`AppUser ${appUserId} can not be find`);
    }
  }

  @computed
  public get adminListActiveFirst(): UserModel<IUserModelConstructObj>[] {
    return sortActiveFirst<UserModel<IUserModelConstructObj>>(this.admins);
  }
  // EOF SUPER ADMIN USER CREATE

  // BOF SUPER ADMIN APP USERS

  public async getAppUsers() {
    const users = await this._getAppUsersRequest();
    this._setAppUsers(users);
    return this.appUsers;
  }

  public postAppUser(user: AppUserModel) {
    return this.rootService.ajaxService.post('users/app-regs', user.constructSaveObj);
  }

  public putAppUser(user: AppUserModel) {
    return this.rootService.ajaxService.put(`users/app-regs/${user.id}`, user.constructSaveObj);
  }

  public getAppUserById(id: string): AppUserModel | undefined {
    return this.appUsers.find((user: AppUserModel) => user.id === id);
  }

  @computed
  public get appUsersListActiveFirst(): AppUserModel[] {
    return sortActiveFirst<AppUserModel>(this.appUsers);
  }

  // EOF SUPER ADMIN APP USERS

  public getAdminById(id: string): UserModel<IUserModelConstructObj> | undefined {
    return this.admins.find((admin: UserModel<IUserModelConstructObj>) => admin.id === id);
  }

  public async getAdmins() {
    const admins = await this._getAdminsRequest();
    this._setAdmins(admins);
    return this.admins;
  }

  public async getTenantCommon() {
    const common = await this._getCommonTenantRequest();
    this._setUnits(common.tenantUnits);
    this._setLegalEntities(common.legalEntities);
    return common;
  }

  public async getSharePointSites() {
    const sites = await this._getSharePointSitesRequest();
    this._setSharePointSites(sites);
    return this.sharePointSites;
  }

  @computed
  public get sharePointSiteList(): SharepointModel[] {
    return this.sharePointSites;
  }

  @action
  private _setSharePointSites = (sites: SharepointModel[]) => {
    this.sharePointSites = sites.map((s: SharepointModel) => new SharepointModel().update(s));
  };

  @action
  private _setAdmins = (res: UserModel<IUserModelConstructObj>[]) => {
    this.admins = res.map((u: UserModel<IUserModelConstructObj>) => new UserModel<IUserModelConstructObj>().update(u));
  };

  @action
  private _setAppUsers = (res: AppUserModel[]) => {
    this.appUsers = res.map((u: AppUserModel) => new AppUserModel().update(u));
  };

  @action
  private _setWorkflowList(workflows: WorkflowModel[]) {
    this.workflowList = workflows.map((workflow: WorkflowModel) => new WorkflowModel().update(workflow));
  }

  @action
  private _setUnits = (res: string[]) => {
    this.tenantUnits = res;
  };

  @action
  private _setLegalEntities = (res: IdNameModel[]) => {
    this.legalEntities = res.map((e: IdNameModel) => new IdNameModel().update(e));
  };

  private _getTenantsRequest(isDevLogin: boolean) {
    return this.rootService.ajaxService.get(`tenants${isDevLogin ? '/login' : ''}`);
  }

  private _getAdminsRequest() {
    return this.rootService.ajaxService.get('users/admins');
  }

  private _getWorkflowsRequest() {
    return this.rootService.ajaxService.get('workflow');
  }

  private _getCommonTenantRequest() {
    return this.rootService.ajaxService.get('common/tenant');
  }

  private _getSharePointSitesRequest() {
    return this.rootService.ajaxService.get('sharepoint-upload-folder');
  }

  private _getAppUsersRequest() {
    return this.rootService.ajaxService.get('users/app-regs');
  }
}
