import UserModel from 'models/UserModel';
import UpdateModel from 'models/UpdateModel';
import { action } from 'mobx';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class ActivityLogModel extends UpdateModel<ActivityLogModel> {
  public id: string;
  public action: string;
  public createdAt: string;
  public message: string;
  public grn: string;

  public user: UserModel<IUserModelConstructObj>;

  @action public update = (obj: ActivityLogModel) => {
    const newActivityLogModel = cloneObj(obj);

    if (newActivityLogModel) {
      newActivityLogModel.user = newActivityLogModel.user
        ? new UserModel<IUserModelConstructObj>().update(newActivityLogModel.user)
        : new UserModel<IUserModelConstructObj>();
    }

    this.updater.update(this, newActivityLogModel, ActivityLogModel);

    return this;
  };
}
