import { action, computed, observable } from 'mobx';

import RoadHaulierModel from 'models/RoadHaulierModel';
import StockItemModel from 'models/StockItemModel';
import SupplierModel, { SupplierTypes } from 'models/SupplierModel';
import UserModel from 'models/UserModel';
import MaterialModel from 'models/MaterialModel';
import PrinterModel from 'models/PrinterModel';
import RootService from 'services/RootService';
import IdNameActiveModel from 'models/IdNameActiveModel';
import CommonApiCalls from 'pod/admin/commonComponents/CommonApiCalls';
import IdCodeActiveModel from 'models/IdCodeActiveModel';
import ScaleModel from 'models/ScaleModel';
import UserRoleModel from 'models/UserRoleModel';
import IdNameModel from 'models/IdNameModel';
import { sortActiveFirst } from 'util/helpers';
import KeyValueModel from 'models/KeyValueModel';
import UserTenantModel from 'models/UserTenantModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import LasernetPrinterConfigurationModel from 'models/LasernetPrinterConfigurationModel';

type TAdminCommonItemWithName = SupplierModel | RoadHaulierModel | IdNameActiveModel | ScaleModel | StockItemModel;
type TAdminCommonItemWithCode = SupplierModel | RoadHaulierModel | StockItemModel | IdCodeActiveModel;
export type TAdminCommonItemWithActive =
  | SupplierModel
  | RoadHaulierModel
  | ScaleModel
  | IdCodeActiveModel
  | IdNameActiveModel
  | StockItemModel;
export type TAdminCommonItem =
  | TAdminCommonItemWithName
  | IdCodeActiveModel
  | UserModel<IUserModelConstructObj>
  | LasernetPrinterConfigurationModel;

interface IProcessingResponse {
  id: string;
  name: string;
  price: number;
  units: string;
}

export interface IProductVariantResponseItem {
  id: string;
  productForm: IdNameModel;
  productQuality: IdNameModel;
}

export function hasCommonItemName(item: TAdminCommonItem): item is TAdminCommonItemWithName {
  if ('name' in item && 'changeName' in item) {
    return typeof item.name === 'string';
  }
  return false;
}

export function hasCommonItemCode(item: TAdminCommonItem): item is TAdminCommonItemWithCode {
  if ('code' in item && 'changeCode' in item) {
    return typeof item.code === 'string';
  }
  return false;
}

export function hasCommonActive(item: TAdminCommonItem): item is TAdminCommonItemWithActive {
  if ('active' in item && 'changeActiveStatus' in item) {
    return typeof item.active === 'boolean';
  }
  return false;
}

export function hasValidation(model: StockItemModel | TAdminCommonItem): model is StockItemModel {
  return (model as StockItemModel).hasValidation !== undefined;
}

export default class AdminStore {
  public constructor(public readonly rootService: RootService) {}

  @observable public roadHaulierList: RoadHaulierModel[] = [];
  @observable public stockItemList: StockItemModel[] = [];
  @observable public supplierList: SupplierModel[] = [];
  @observable public userList: UserModel<IUserModelConstructObj>[] = [];
  @observable public materialList: MaterialModel[] = [];
  @observable public printerList: PrinterModel[] = [];
  @observable public userRoleList: UserRoleModel[] = [];
  @observable public yardLocationList: IdNameActiveModel[] = [];
  @observable public machineList: IdNameActiveModel[] = [];
  @observable public targetBatchList: IdNameActiveModel[] = [];
  @observable public packageList: IdNameActiveModel[] = [];
  @observable public ewcList: IdCodeActiveModel[] = [];
  @observable public originList: IdCodeActiveModel[] = [];
  @observable public scaleList: ScaleModel[] = [];
  @observable public tenantList: UserTenantModel[] = [];
  @observable public contractList: IdNameModel[] = [];
  @observable public processing: KeyValueModel[] = [];
  @observable public supplierTypes: SupplierTypes[] = [];
  @observable public forms: IdNameActiveModel[] = [];
  @observable public qualities: IdNameActiveModel[] = [];
  @observable public productVariants: IdNameModel[][] = [];

  @computed
  public get roadHaulierListActiveFirst(): RoadHaulierModel[] {
    return sortActiveFirst<RoadHaulierModel>(this.roadHaulierList);
  }

  @computed
  public get stockItemListActiveFirst(): StockItemModel[] {
    return sortActiveFirst<StockItemModel>(this.stockItemList);
  }

  @computed
  public get supplierListActiveFirst(): SupplierModel[] {
    return sortActiveFirst<SupplierModel>(this.supplierList);
  }

  @computed
  public get userListActiveFirst(): UserModel<IUserModelConstructObj>[] {
    return sortActiveFirst<UserModel<IUserModelConstructObj>>(this.userList);
  }

  @computed
  public get materialListActiveFirst(): MaterialModel[] {
    return sortActiveFirst<MaterialModel>(this.materialList);
  }

  @computed
  public get printerListActiveFirst(): PrinterModel[] {
    return sortActiveFirst<PrinterModel>(this.printerList);
  }

  @computed
  public get yardLocationListActiveFirst(): IdNameActiveModel[] {
    return sortActiveFirst<IdNameActiveModel>(this.yardLocationList);
  }

  @computed
  public get machineListActiveFirst(): IdNameActiveModel[] {
    return sortActiveFirst<IdNameActiveModel>(this.machineList);
  }

  @computed
  public get targetBatchListActiveFirst(): IdNameActiveModel[] {
    return sortActiveFirst<IdNameActiveModel>(this.targetBatchList);
  }

  @computed
  public get packageListActiveFirst(): IdNameActiveModel[] {
    return sortActiveFirst<IdNameActiveModel>(this.packageList);
  }

  @computed
  public get ewcListActiveFirst(): IdCodeActiveModel[] {
    return sortActiveFirst<IdCodeActiveModel>(this.ewcList);
  }

  @computed
  public get originListActiveFirst(): IdCodeActiveModel[] {
    return sortActiveFirst<IdCodeActiveModel>(this.originList);
  }

  @computed
  public get scaleListActiveFirst(): ScaleModel[] {
    return sortActiveFirst<ScaleModel>(this.scaleList);
  }

  @computed
  public get formListActiveFirst(): IdNameActiveModel[] {
    return sortActiveFirst<IdNameActiveModel>(this.forms);
  }

  @computed
  public get qualityListActiveFirst(): IdNameActiveModel[] {
    return sortActiveFirst<IdNameActiveModel>(this.qualities);
  }

  // BOF SUPPLIERS
  @action private setSupplierList = (res: SupplierModel[]) => {
    this.supplierList = res.map((supplier) => new SupplierModel().update(supplier));
  };

  public supplierApi = new CommonApiCalls<SupplierModel>(this.rootService, 'suppliers', this.setSupplierList);
  // EOF SUPPLIERS

  // BOF ROAD HAULIERS
  @action
  private setRoadHaulierList = (res: RoadHaulierModel[]) => {
    this.roadHaulierList = res.map((roadHaulier) => new RoadHaulierModel().update(roadHaulier));
  };

  public roadHaulierApi = new CommonApiCalls<RoadHaulierModel>(
    this.rootService,
    'road-hauliers',
    this.setRoadHaulierList
  );
  // BOF ROAD HAULIERS

  // BOF MACHINES
  @action
  public setMachineList = (machineList: IdNameActiveModel[]) => {
    this.machineList = machineList.map((machine) => new IdNameActiveModel().update(machine));
  };

  public machineApi = new CommonApiCalls<IdNameActiveModel>(this.rootService, 'machines', this.setMachineList);
  // EOF MACHINES

  // BOF TARGET BATCH
  @action
  public setTargetBatchList = (targetBatchList: IdNameActiveModel[]) => {
    this.targetBatchList = targetBatchList.map((targetBatch) => new IdNameActiveModel().update(targetBatch));
  };

  public targetBatchApi = new CommonApiCalls<IdNameActiveModel>(
    this.rootService,
    'target-batches',
    this.setTargetBatchList
  );
  // EOF TARGET BATCH

  // BOF PACKAGING
  @action
  public setPackagingList = (packagingList: IdNameActiveModel[]) => {
    this.packageList = packagingList.map((item) => new IdNameActiveModel().update(item));
  };

  public packagingApi = new CommonApiCalls<IdNameActiveModel>(this.rootService, 'packaging', this.setPackagingList);
  // EOF PACKAGING

  // BOF EWCS

  @action
  public setEWCList = (res: IdCodeActiveModel[]) => {
    this.ewcList = res.map((ewc: IdCodeActiveModel) => new IdCodeActiveModel().update(ewc));
  };

  public ewcApi = new CommonApiCalls<IdCodeActiveModel>(this.rootService, 'ewcs', this.setEWCList);

  // EOF EWCS

  // BOF ORIGIN

  @action
  public setOriginList = (res: IdCodeActiveModel[]) => {
    this.originList = res.map((origin: IdCodeActiveModel) => new IdCodeActiveModel().update(origin));
  };

  public originApi = new CommonApiCalls<IdCodeActiveModel>(this.rootService, 'origins', this.setOriginList);

  // EOF ORIGIN

  // BOF USER ROLES
  public async getUserRoles() {
    if (this.userRoleList.length) {
      return Promise.resolve(this.userRoleList);
    }
    const roles = await this._getUserRolesRequest();
    this._setUserRoles(roles);
    return this.userRoleList;
  }

  @action private _setUserRoles = (res: UserRoleModel[]) => {
    this.userRoleList = res.map((userRole: UserRoleModel) => new UserRoleModel().update(userRole));
  };

  private _getUserRolesRequest() {
    return this.rootService.ajaxService.get('common/roles');
  }

  // EOF USER ROLES

  // BOF ACTIVITY LOG
  public getAdminActivityLog() {
    return this.rootService.ajaxService.get('activity-log');
  }

  // EOF ACTIVITY LOG

  // BOF USERS
  @action
  public setUserList = (res: UserModel<IUserModelConstructObj>[]) => {
    this.userList = res.map((user: UserModel<IUserModelConstructObj>) =>
      new UserModel<IUserModelConstructObj>().update(user)
    );
  };

  public userApi = new CommonApiCalls<UserModel<IUserModelConstructObj>>(this.rootService, 'users', this.setUserList);
  // EOF USERS

  // BOF STOCK ITEMS
  @action
  public setStockItemList = (res: StockItemModel[]) => {
    this.stockItemList = res.map((stockItem: StockItemModel) => new StockItemModel().update(stockItem));
  };

  public stockItemApi = new CommonApiCalls<StockItemModel>(this.rootService, 'stock-items', this.setStockItemList);
  // EOF STOCK ITEMS

  // BOF YARD LOCATIONS
  @action
  public setYardLocationList = (res: IdNameActiveModel[]) => {
    this.yardLocationList = res.map((yardLocation: IdNameActiveModel) => new IdNameActiveModel().update(yardLocation));
  };

  public yardLocationApi = new CommonApiCalls<IdNameActiveModel>(
    this.rootService,
    'yard-locations',
    this.setYardLocationList
  );
  // EOF YARD LOCATIONS

  // BOF MATERIALS
  @action
  public setMaterialList = (res: MaterialModel[]) => {
    this.materialList = res.map((material: MaterialModel) => this._createMaterialModel(material));
  };

  public materialApi = new CommonApiCalls<MaterialModel>(this.rootService, 'materials', this.setMaterialList);

  @action
  private _createMaterialModel = (res: MaterialModel) => {
    return new MaterialModel().update(res);
  };
  // EOF MATERIALS

  // BOF PRINTERS
  @action
  public setPrinterList = (res: PrinterModel[]) => {
    this.printerList = res.map((printer: PrinterModel) => new PrinterModel().update(printer));
  };

  public printerApi = new CommonApiCalls<PrinterModel>(this.rootService, 'printers', this.setPrinterList);
  // EOF PRINTERS

  // BOF SCALES
  @action
  public setScalesList = (res: ScaleModel[]) => {
    this.scaleList = res.map((scale: ScaleModel) => new ScaleModel().update(scale));
  };

  public scaleApi = new CommonApiCalls<ScaleModel>(this.rootService, 'scales', this.setScalesList);
  // EOF SCALES

  // BOF TENANTS
  public async getTenants() {
    if (this.tenantList.length) {
      return Promise.resolve(this.tenantList);
    }
    const tenants = await this._getTenantsRequest();
    this._setTenants(tenants);
    return this.tenantList;
  }

  @action private _setTenants = (res: UserTenantModel[]) => {
    this.tenantList = res.map((tenant: UserTenantModel) => new UserTenantModel().update(tenant));
  };

  private _getTenantsRequest() {
    return this.rootService.ajaxService.get('tenants/admin/region');
  }
  // EOF TENANTS

  // BOF CONTRACTS US

  public async getContractsUS() {
    if (this.contractList.length) {
      return Promise.resolve(this.contractList);
    }
    const contracts = await this._getContractsRequest();
    this._setContracts(contracts);
    return this.contractList;
  }

  public async refreshContractsUS() {
    const contracts = await this._getContractsRequest();
    this._setContracts(contracts);
  }

  public async triggerSMTPSyncUS() {
    return this.rootService.ajaxService.get('us-contract/import/trigger');
  }

  public async importContractFileUS(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.rootService.ajaxService.post('us-contract/import', data);
  }

  @action private _setContracts = (res: string[]) => {
    this.contractList = res.map((id: string) => {
      const idNameModel = new IdNameModel();
      idNameModel.id = id;
      idNameModel.changeName(id);
      return idNameModel;
    });
  };

  private _getContractsRequest() {
    return this.rootService.ajaxService.get('us-contract');
  }

  // EOF CONTRACTS US

  // BOF PROCESSING
  public getProcessingItems = async () => {
    const processingItems: IProcessingResponse[] = await this._getProcessingRequest();
    this._setProcessing(processingItems);
    return this.processing;
  };

  public putProcessingItem = async (item: KeyValueModel) => {
    return this.rootService.ajaxService.put(`prices/advised-goods/processing/${item.id}`, {
      name: item.name,
      price: item.value,
    });
  };

  @action private _setProcessing = (res: IProcessingResponse[]) => {
    this.processing = res.map((item: IProcessingResponse) => {
      const { price, ...rest } = item;
      return new KeyValueModel().update({ ...rest, value: price } as KeyValueModel);
    });
  };

  private _getProcessingRequest = async () => {
    return this.rootService.ajaxService.get('prices/advised-goods/processing');
  };
  // EOF PROCESSING

  // BOF SUPPLIER TYPES
  public getSupplierTypes = async () => {
    const data = await this._getSupplierTypesRequest();
    this._setSupplierTypes(data.supplierTypes);
    return this.supplierTypes;
  };

  @action private _setSupplierTypes = (res: SupplierTypes[]) => {
    this.supplierTypes = res;
  };

  private _getSupplierTypesRequest = () => {
    return this.rootService.ajaxService.get('common/supplier-types');
  };
  // EOF SUPPLIER TYPES

  // BOF FORM
  public getFormItems = async () => {
    const formItems: IdNameActiveModel[] = await this._getFormRequest();
    this._setForms(formItems);
    return this.forms;
  };

  @action private _setForms = (res: IdNameActiveModel[]) => {
    this.forms = res.map((item: IdNameActiveModel) => {
      return new IdNameActiveModel().update(item);
    });
  };

  private _getFormRequest = async () => {
    return this.rootService.ajaxService.get('product-variants/product-forms');
  };
  // EOF FORM

  // BOF QUALITY
  public getQualityItems = async () => {
    const qualityItems: IdNameActiveModel[] = await this._getQualityRequest();
    this._setQualities(qualityItems);
    return this.qualities;
  };

  @action private _setQualities = (res: IdNameActiveModel[]) => {
    this.qualities = res.map((item: IdNameActiveModel) => {
      return new IdNameActiveModel().update(item);
    });
  };

  private _getQualityRequest = async () => {
    return this.rootService.ajaxService.get('product-variants/product-qualities');
  };
  // EOF QUALITY

  // BOF PRODUCT VARIANTS
  public getProductVariantItems = async (stockItemId: string) => {
    const productVariantItems: IProductVariantResponseItem[] = await this._getProductVariantRequestRequest(stockItemId);
    const mappedItems: IdNameModel[][] = productVariantItems.map((p) => {
      return [
        new IdNameModel().update({ id: p.productForm.id, name: p.productForm.name } as IdNameModel),
        new IdNameModel().update({ id: p.productQuality.id, name: p.productQuality.name } as IdNameModel),
      ];
    });
    this._setProductVariants(mappedItems);
    return this.productVariants;
  };

  @action private _setProductVariants = (res: IdNameModel[][]) => {
    this.productVariants = res;
  };

  private _getProductVariantRequestRequest = async (stockItemId: string): Promise<IProductVariantResponseItem[]> => {
    return this.rootService.ajaxService.get(`common/stock-items/${stockItemId}/product-variants`);
  };
  // EOF PRODUCT VARIANTS

  public refreshProductVariants = async () => {
    return this.rootService.ajaxService.post('product-variants/import/trigger');
  };

  public refreshSuppliers = async () => {
    return this.rootService.ajaxService.post('suppliers/import/trigger', null, { timeout: 30000 });
  };

  public refreshYardLocations = async () => {
    return this.rootService.ajaxService.post('yard-locations/import/trigger', null, { timeout: 30000 });
  };
}
