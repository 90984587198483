import { action, computed, observable, observe } from 'mobx';

import UpdateModel from 'models/UpdateModel';
import MaterialModel from 'models/MaterialModel';
import { cloneObj } from 'util/helpers';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';

export default class MaterialsModel extends UpdateModel<MaterialsModel> implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  @observable public material?: MaterialModel;
  @observable public weight?: number = null;
  @observable public id: string = null;

  @computed
  public get shouldAddNewLine() {
    return !!this.material;
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public update = (obj: MaterialsModel) => {
    const newMaterialsModel = cloneObj(obj);

    if (newMaterialsModel) {
      newMaterialsModel.material = new MaterialModel().update(newMaterialsModel.material);
    }

    this.updater.update(this, newMaterialsModel, MaterialsModel);

    return this;
  };

  @action
  public createDefaultMaterial?(material: MaterialModel) {
    this.material = new MaterialModel().update(material);

    this.setHasChanged(false);
    return this;
  }

  @action
  public setMaterial(nonDefaultMaterials: MaterialModel[], newMaterialId: string) {
    this.material = nonDefaultMaterials.find((m: MaterialModel) => m.id === newMaterialId);
  }

  @action
  public setWeight(val: number) {
    this.weight = val;
  }
}
