import * as React from 'react';
import { Button, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import * as H from 'history';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { getTranslation, inputNumberStep, isMobile, joinWithCommaSeparator } from 'util/helpers';
import { instance as notification } from 'util/notification';
import { AttachmentType, DeliveryStatus } from 'util/enums';

import Autocomplete from 'domain/Autocomplete';

import DeliveryAdvisedGoodModel from 'models/DeliveryAdvisedGoodModel';
import DeliveryDetailLineComponent from 'pod/deliveries/DeliveryDetailLineComponent';
import DeliveryModel, { DeliveryValidatorKey } from 'models/DeliveryModel';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import ModalConfirmation from 'domain/ModalConfirmation';
import RoadHaulierModel from 'models/RoadHaulierModel';
import StickyFooter from 'domain/StickyFooter';
import SupplierModel from 'models/SupplierModel';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import RadioComponent from 'components/RadioComponent';
import PutInOrderChildren from 'domain/PutInOrderChildren';
import { action, computed, observable } from 'mobx';
import AjaxService from 'services/AjaxService';
import ContractStore, { IContract, IContractDetails } from 'stores/ContractStore';
import InputNumber from 'components/InputNumber';
import ViewStore from 'stores/ViewStore';
import {
  BORDER_DANGER,
  DELIVERY_REMARKS_MAX_LENGTH_DEFAULT,
  DELIVERY_REMARKS_MAX_LENGTH_FR,
  TEXT_DANGER,
  VEHICLE_REGISTRATION_LIMIT_ALL,
  VEHICLE_REGISTRATION_LIMIT_IT,
} from 'util/constants';
import PromiseResolver from 'models/PromiseResolver';
import InputWithNullCheck from 'components/InputWithNullCheck';
import IdCodeModel from 'models/IdCodeModel';
import DeliveryAttachments from 'pod/deliveries/DeliveryAttachments';
import BackButton from 'components/BackButton';
import IdCodeNameModel from 'models/IdCodeNameModel';
import DateFormattingService from 'services/DateFormattingService';
import { I18N } from '../../../assets/i18n/i18n';
import SortingReportDetailSection from 'pod/receivedGoods/SortingReportDetailSection';

interface IProps {
  hasPendingRequests: boolean;
  saveAttachmentsAction: (
    delivery: DeliveryModel,
    attachments?: FormData,
    radiationFiles?: FormData
  ) => Promise<string | void> | void;
  history: H.History;
  saveAction: (
    delivery: DeliveryModel,
    attachments?: FormData,
    radiationFiles?: FormData
  ) => Promise<string | void> | void;
  nextGrn?: string;
  content?: DeliveryModel;
  syncAction?: (deliveryId: string) => Promise<void>;
  printAction?: (deliveryId: string) => void;
  exportAction?: (deliveryId: string) => void;
  removeAttachment?: (attachmentId: string) => void;
  sessionStore?: SessionStore;
  contractStore?: ContractStore;
  translateService?: TranslateService;
  ajaxService?: AjaxService;
  viewStore?: ViewStore;
  commonStore?: CommonStore;
  dateFormattingService?: DateFormattingService;
  deleteDelivery?: (delivery: DeliveryModel) => Promise<void>;
}

interface IDeliveryDetailComponentModel {
  content: DeliveryModel;
  completeConfirmationModal: boolean;
  hasAttachmentsConfirmationModal: boolean;
  saveConfirmationModal: boolean;
  certificateExpirationDateModal: boolean;
  authorizationNumberModal: boolean;
  selectedAttachments: FileList;
  selectedRadiationFiles: FileList;
}

@inject(
  'sessionStore',
  'commonStore',
  'contractStore',
  'translateService',
  'ajaxService',
  'viewStore',
  'dateFormattingService'
)
@observer
export default class DeliveryDetailComponent extends React.Component<IProps> {
  @observable
  private _model: IDeliveryDetailComponentModel = {
    content: new DeliveryModel(),
    completeConfirmationModal: false,
    hasAttachmentsConfirmationModal: false,
    saveConfirmationModal: false,
    certificateExpirationDateModal: false,
    authorizationNumberModal: false,
    selectedAttachments: null,
    selectedRadiationFiles: null,
  };

  @observable
  private _showDeleteConfirmation: boolean = false;

  private _hasAttachmentsPromise: PromiseResolver<boolean> = new PromiseResolver();
  private _certificateExpirationDatePromise: PromiseResolver<boolean> = new PromiseResolver();
  private _authorizationNumberPromise: PromiseResolver<boolean> = new PromiseResolver();

  public componentDidMount() {
    const {
      contractStore,
      content,
      viewStore: { isUS },
      commonStore,
    } = this.props;

    if (isUS) {
      if (!(contractStore.contractsList && contractStore.contractsList.length)) {
        contractStore.getContractNumbers();
      }
      if (!commonStore.hasDeliveryCommon) {
        commonStore.getDeliveryCommon().then(() => {
          this._setDefaultTransactionType();
        });
      } else {
        this._setDefaultTransactionType();
      }
    }

    if (content) {
      this._changeModelContent(content);
    }

    if (!commonStore.isDataLoaded) {
      commonStore.getCommon();
    }
  }

  public componentDidUpdate(): void {
    const { content } = this.props;

    if (content) {
      this._setContent(this.props.content);
    }
  }

  @action
  public attachmentsSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    this._model.selectedAttachments = e.target.files;
  };

  @action
  public radiationFilesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    this._model.selectedRadiationFiles = e.target.files;
  };

  public render() {
    const {
      saveConfirmationModal,
      content,
      completeConfirmationModal,
      hasAttachmentsConfirmationModal,
      certificateExpirationDateModal,
      authorizationNumberModal,
    } = this._model;
    const {
      viewStore: { isUS, isDE, isIT, isUK, isFR, isAdminFromFR },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid className="padding-bottom-50 pl-0 pr-0">
        {/* BACK BUTTON, WITH DIRTY CHECK */}
        <BackButton onClick={this._goBack}>{t.GLOBAL_LABEL_DELIVERIES}</BackButton>

        <Row>
          <Col xs="12" className="mb-3">
            <h2 className="mb-0">
              {content.id ? content.grn : t.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW}
              {this.isDeleteButtonShown && (
                <Button
                  data-test="delete-delivery-button"
                  className="float-right"
                  size="md"
                  type="button"
                  color="secondary"
                  onClick={this._toggleDeleteConfirmation}
                >
                  {t.GLOBAL_LABEL_DELETE}
                </Button>
              )}
            </h2>
          </Col>
        </Row>

        {this._renderContractNumber}
        {this._renderDeliveryFields}
        {this._renderSuppliersDetails}
        {this._renderAttachments}
        {this._renderRadiationFiles}
        {this._renderResponsiblePeople}

        <h5 className="mt-4 clearfix">
          {t.GLOBAL_LABEL_ADVISED_GOODS}
          <Button
            disabled={this._isReadOnly}
            className="float-right"
            size="sm"
            type="button"
            color="success"
            onClick={() => content.pushNewAdvisedGood()}
          >
            <FontAwesome name="plus" className="mr-2" /> {t.GLOBAL_LABEL_ADD}
          </Button>
        </h5>
        <Row>
          <Col xs={12} className="pl-0 pr-0">
            <table className="delivery-detail custom-table full-width">
              <thead>
                <tr>
                  <th data-test="stock-item" className={classnames(['width-150', { 'pl-3': !isMobile() }])}>
                    {t.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM}
                  </th>
                  <th>{t.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION}</th>
                  {isFR && (
                    <React.Fragment>
                      <th data-test="adv-comment">{t.ADVISEDGOOD_ROUTE_COMMENT}</th>
                      <th data-test="adv-packaging">{t.ADVISEDGOOD_ROUTE_PACKAGING}</th>
                    </React.Fragment>
                  )}
                  {(isIT || isFR) && (
                    <th data-test="responsible" className="width-110">
                      {t.GLOBAL_LABEL_RESPONSIBLE}
                    </th>
                  )}
                  {(isFR || isUS) && (
                    <th data-test="adv-gross-weight" className="width-90">
                      {t.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT}
                    </th>
                  )}
                  {!isUS && (
                    <th data-test="adv-net-weight" className="width-90">
                      {t.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT}
                    </th>
                  )}
                  {isUS && (
                    <th data-test="adv-tare" className="width-90">
                      {t.USA_CONFIGURATION_ADV_TARE}
                    </th>
                  )}
                  {this._hasTicketNumber && (
                    <th data-test="ticket-number" className="width-100">
                      {t.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET}
                    </th>
                  )}
                  {isIT || isUK ? (
                    <th data-test="ewc-code" className="width-100">
                      {t.ADVISEDGOOD_ROUTE_EWC_NUMBER}
                    </th>
                  ) : null}
                  {isDE && (
                    <th data-test="department" className="width-110">
                      {t.GLOBAL_LABEL_DEPARTMENT}
                    </th>
                  )}
                  <th data-test="yard-location" className="width-110">
                    {t.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION}
                  </th>
                  {(isUS || isAdminFromFR) && (
                    <th data-test="order-line-sequence" className="width-80">
                      {isAdminFromFR
                        ? t.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX
                        : t.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE}
                    </th>
                  )}
                  {!isUS && (
                    <th
                      data-test="st-weight-value"
                      className="width-90"
                      dangerouslySetInnerHTML={{ __html: t.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT }}
                    />
                  )}
                  {!isUS && (
                    <th
                      data-test="nd-weight-value"
                      className="width-90"
                      dangerouslySetInnerHTML={{ __html: t.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT }}
                    />
                  )}
                  {!isUS && (
                    <th data-test="net-value" className="width-80">
                      {t.DELIVERIES_NEWDELIVERYDETAILS_NET}
                    </th>
                  )}
                  {!isMobile() && <th data-test="scale-icon" />}
                  <th data-test="info-btn-space" className={isMobile() ? 'width-60' : 'width-80'} />
                </tr>
              </thead>
              {content.advisedGoods.map((item: DeliveryAdvisedGoodModel, index: number) => (
                <DeliveryDetailLineComponent
                  delivery={content}
                  index={index}
                  isReadOnly={this._isReadOnly}
                  item={item}
                  key={index}
                />
              ))}
            </table>
          </Col>
        </Row>

        {/* SAVE BUTTON - STICKY FOOTER */}
        <StickyFooter show={this._showFooter} data-test="sticky-footer">
          {this._hasSyncButton && (
            <Button
              data-test="sync-button"
              type="button"
              onClick={this._toggleCompleteConfirmationModal}
              color="primary"
              className="mr-5"
              disabled={this._isDisableSyncButton}
            >
              <FontAwesome name="check" className="mr-1" />
              {t.GLOBAL_LABEL_SYNC}
            </Button>
          )}
          {this._hasPrintButton && (
            <Button
              data-test="print-button"
              type="button"
              onClick={this._print}
              color="primary"
              className="mr-5"
              disabled={!content.canBePrinted}
            >
              <FontAwesome name="print" className="mr-1" />
              {t.GLOBAL_LABEL_PRINT}
            </Button>
          )}
          {this._hasExportButton && (
            <Button
              data-test="export-button"
              color="primary"
              className="mr-5"
              onClick={this._export}
              disabled={!content.canBeExported}
            >
              {t.GLOBAL_LABEL_EXPORT}
            </Button>
          )}
          <Button
            data-test="save-btn"
            type="button"
            disabled={this._isDisabledSaveButton}
            color="success"
            onClick={this._validateConfirmAndSave}
          >
            {(this._isFullSave && t.GLOBAL_LABEL_SAVE) || t.GLOBAL_LABEL_SAVE_ATTACHMENTS}
          </Button>
        </StickyFooter>

        {/* MODAL CONFIRMATION FOR LEAVING PAGE WHEN IS DIRTY */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackNo={this._redirectToDeliveries}
          callbackYes={() => this._validateConfirmAndSave()}
          heading={t.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER}
          ico="floppy-o"
          isOpen={saveConfirmationModal}
          text={t.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT}
          toggleModal={this._toggleSaveConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR MARKING AS COMPLETE - TIER 3 */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackYes={() => this._submitAndComplete()}
          heading={t.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER}
          ico="check"
          isOpen={completeConfirmationModal}
          text={t.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT}
          toggleModal={this._toggleCompleteConfirmationModal}
        />

        {/* MODAL CONFIRMATION - IF DELIVERY HAS ATTACHMENTS */}
        <ModalConfirmation
          data-test="attachments-confirmation"
          buttonYesColor="info"
          callbackNo={this._resolveHasAttachmentsPromiseWithFalse}
          callbackYes={this._resolveHasAttachmentsPromiseWithTrue}
          heading={t.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER}
          ico="check"
          isOpen={hasAttachmentsConfirmationModal}
          text={t.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT}
          toggleModal={this._toggleHasAttachmentsConfirmationModal}
        />
        {/* MODAL CONFIRMATION - CERTIFICATE EXPIRED */}
        <ModalConfirmation
          data-test="certificate-confirmation"
          buttonYesColor="info"
          callbackNo={this._resolveCertificateExpirationDatePromiseWithFalse}
          callbackYes={this._resolveCertificateExpirationDatePromiseWithTrue}
          heading={t.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER}
          ico="check"
          isOpen={certificateExpirationDateModal}
          text={t.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT}
          toggleModal={this._toggleCertificateExpirationDateModal}
        />
        {/* MODAL CONFIRMATION - IF DELIVERY HAS AUTHORIZATION NUMBER */}
        <ModalConfirmation
          data-test="authorization-number-confirmation"
          buttonYesColor="info"
          callbackNo={this._resolveAuthorizationNumberPromiseWithFalse}
          callbackYes={this._resolveAuthorizationNumberPromiseWithTrue}
          heading={t.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER}
          ico="check"
          isOpen={authorizationNumberModal}
          text={t.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT}
          toggleModal={this._toggleAuthorizationNumberModal}
        />
        {/* MODAL CONFIRMATION - DELETE DELIVERY */}
        <ModalConfirmation
          data-test="delete-delivery-confirmation"
          callbackYes={this._deleteDelivery}
          heading={t.DELIVERIES_DELIVERYEDIT_DELETE}
          isOpen={this._showDeleteConfirmation}
          text={t.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION}
          toggleModal={this._toggleDeleteConfirmation}
        />
      </Container>
    );
  }

  @action
  private _setContent = (newContent: DeliveryModel) => {
    this._model.content = newContent;
  };

  @action
  private _setShowDeleteConfirmation = (val: boolean) => {
    this._showDeleteConfirmation = val;
  };

  private get _isReadOnlyStatus(): boolean {
    const {
      viewStore: { isIT, isUS, isFR },
      sessionStore: { isAdmin },
    } = this.props;
    const { content } = this._model;
    return (
      content.synced ||
      (((isIT && !isAdmin) || isUS || isFR) &&
        [DeliveryStatus.SIGNED_OFF, DeliveryStatus.COMPLETE].includes(content.status)) ||
      (isIT && content.printed && isAdmin)
    );
  }

  private get _isReadOnly(): boolean {
    const {
      sessionStore: { isAdmin, isWeightBridgeOperator },
    } = this.props;
    return (
      (isWeightBridgeOperator && this._isReadOnlyStatus) ||
      (isAdmin && !this._isReadOnlyStatus) ||
      !(isWeightBridgeOperator || isAdmin)
    );
  }

  private get _isFullSave(): boolean {
    const { isAdmin, isWeightBridgeOperator } = this.props.sessionStore;
    return isAdmin || (isWeightBridgeOperator && !this._isReadOnlyStatus);
  }

  private get _hasAttachments(): boolean {
    const { content } = this._model;
    return (
      content.attachments &&
      content.attachments.filter((item) => item.type === AttachmentType.DELIVERY_ATTACHMENT).length !== 0
    );
  }

  private get _hasRadiationFiles(): boolean {
    const { content } = this._model;
    return (
      content.attachments &&
      content.attachments.filter((item) => item.type === AttachmentType.DELIVERY_RADIATION).length !== 0
    );
  }

  private get _hasCreatedBy(): boolean {
    const { content } = this._model;
    return !!(content.createdBy && content.createdBy.date && content.createdBy.user);
  }

  private get _hasSyncedBy(): boolean {
    const { content } = this._model;
    return !!(content.syncedBy && content.syncedBy.date && content.syncedBy.user);
  }

  private get _hasSignedOffBy(): boolean {
    const { content } = this._model;
    return !!(content.signedOffBy && content.signedOffBy.date && content.signedOffBy.user);
  }

  private get _hasCompletedBy(): boolean {
    const { content } = this._model;
    return !!(content.completedBy && content.completedBy.date && content.completedBy.user);
  }

  private get _mimeTypeForUploadInput(): string {
    const {
      viewStore: { isUS },
    } = this.props;
    return `image/jpeg,image/gif,image/png${isUS ? ',application/pdf' : ''}`;
  }

  private get _renderContractNumber() {
    const {
      contractStore,
      viewStore: { isUS, isAdminFromUS },
      translateService: { t },
    } = this.props;

    return (
      isUS && (
        <Row className="bg-white padding-top-20" data-test="contract-number">
          <Col md="6">
            <FormGroup>
              <Label for="containerNumber">{t.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER}</Label>
              <Autocomplete
                data-test="contract-field"
                tabIndex={0}
                placeholder="Contract Number"
                disabled={!isAdminFromUS && this._isReadOnly}
                items={contractStore.contractsList}
                onSelect={(selectedItem: { id: number; name: string }) =>
                  this._selectContractNumber(selectedItem ? selectedItem.name : '')
                }
                selectedValue={this._model.content.contractNumber}
                hasClearButton={this._hasClearContractButton}
                onClearCallback={this._clearContract}
              />
            </FormGroup>
          </Col>
        </Row>
      )
    );
  }

  private get _hasClearContractButton(): boolean {
    const { content } = this._model;
    const {
      viewStore: { isAdminFromUS },
    } = this.props;
    return Boolean(isAdminFromUS && content.contractId);
  }

  private get _isTareWeightDisabled(): boolean {
    const { content } = this._model;
    return this._isReadOnly || content.advisedGoods.length === 0 || !content.id;
  }

  private get _renderDeliveryFields() {
    const {
      commonStore: { common, tradersList, deliveryWeatherConditionList, deliveryTypeList },
      sessionStore: { isWeightBridgeOperator, isAdmin },
      viewStore: { isUS, isDE, isIT, numberOfDecimals, isFR, isDE_D365 },
      translateService: { t },
      nextGrn,
    } = this.props;
    const { content } = this._model;

    return (
      <PutInOrderChildren className={classnames(['bg-white', { 'padding-top-20': !isUS }])}>
        <FormGroup>
          <Label for="grn">{t.DELIVERIES_NEWDELIVERYDETAILS_GRN}</Label>
          <InputWithNullCheck
            tabIndex={1}
            data-test="grn"
            className={classnames({ [BORDER_DANGER]: (isDE || isDE_D365) && this._isInvalidFieldByKey('grn') })}
            onChange={this._changeGRN}
            value={content.grn || nextGrn}
            id="grn"
            disabled={!isDE && !isFR && !isDE_D365}
            type="text"
          />
        </FormGroup>

        <FormGroup>
          <Label for="vehicleRegistrationNumber">{t.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER}</Label>
          <InputWithLimitOfCharacters
            data-test="vehicle-registration"
            tabIndex={3}
            className={classnames({
              [BORDER_DANGER]: this._isInvalidFieldWithReadOnlyByKey('vehicleRegistrationNumber'),
            })}
            maxLength={this._vehicleRegistrationLimit}
            disabled={this._isReadOnly}
            id="vehicleRegistrationNumber"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              content.setVehicleRegistrationNumber(e.currentTarget.value)
            }
            onBlur={() => content.setVehicleRegistrationNumber(content.vehicleRegistrationNumber.toUpperCase())}
            required
            type="text"
            value={content.vehicleRegistrationNumber}
          />
        </FormGroup>

        {this._renderSupplier}
        {this._hasTrader && (
          <FormGroup>
            <Label for="trader">{t.GLOBAL_USERROLE_TRADER}</Label>
            <Autocomplete
              id="trader"
              data-test="trader"
              tabIndex={7}
              placeholder={t.GLOBAL_LABEL_SELECT}
              disabled={this._isReadOnly}
              items={tradersList}
              onSelect={(selectedItem: IdCodeNameModel) => content.setTrader(selectedItem)}
              selectedValue={content.trader?.id}
              highlight
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label for="remarks">{t.GLOBAL_LABEL_REMARKS}</Label>
          <InputWithLimitOfCharacters
            data-test="remarks"
            tabIndex={9}
            disabled={this._isReadOnly}
            id="remarks"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setRemarks(e.target.value)}
            type="text"
            maxLength={isFR ? DELIVERY_REMARKS_MAX_LENGTH_FR : DELIVERY_REMARKS_MAX_LENGTH_DEFAULT}
            value={content.remarks}
          />
        </FormGroup>

        {isUS && (
          <Row>
            <Col>
              <FormGroup data-test="gross">
                <Label>{t.USA_CONFIGURATION_GROSS}</Label>
                <InputNumber
                  data-test="gross-input"
                  tabIndex={11}
                  disabled={this._isReadOnly}
                  decimalPlaces={numberOfDecimals}
                  onChangeValue={(value: number) => content.setGrossWeight(value)}
                  step={inputNumberStep(numberOfDecimals)}
                  value={content.grossWeight}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup data-test="tare">
                <Label>{t.USA_CONFIGURATION_TARE}</Label>
                <InputNumber
                  data-test="tare-input"
                  tabIndex={13}
                  disabled={this._isTareWeightDisabled}
                  decimalPlaces={numberOfDecimals}
                  onChangeValue={(value: number) => content.setTareWeight(value)}
                  step={inputNumberStep(numberOfDecimals)}
                  value={content.tareWeight}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup data-test="net">
                <Label>{t.USA_CONFIGURATION_NET}</Label>
                <InputWithNullCheck
                  data-test="net-input"
                  tabIndex={15}
                  disabled
                  type="text"
                  value={content.deliveryNetWeight}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {isUS && (
          <FormGroup data-test="weather-conditions">
            <Label
              data-test="weather-conditions-label"
              className={classnames({ [TEXT_DANGER]: this._isInvalidFieldByKey('weatherCondition') })}
            >
              {t.USA_CONFIGURATION_WEATHER_CONDITIONS}
              {this._isInvalidFieldByKey('weatherCondition') && <FontAwesome name="exclamation-triangle ml-1" />}
            </Label>
            <RadioComponent
              data-test="weather-conditions"
              selected={content.weatherCondition}
              tabIndexFrom={17}
              list={deliveryWeatherConditionList}
              onChange={(selected: string) => content.setWeatherCondition(selected)}
              disabled={this._isReadOnly}
            />
          </FormGroup>
        )}

        {!isFR && (
          <React.Fragment>
            <FormGroup>
              <Label for="deliveryNote">{t.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER}</Label>
              <InputWithNullCheck
                data-test="delivery-note"
                tabIndex={19}
                disabled={this._isReadOnly}
                id="deliveryNote"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setDeliveryNote(e.target.value)}
                onBlur={() => content.setDeliveryNote(content.deliveryNote.toUpperCase())}
                type="text"
                value={content.deliveryNote}
              />
            </FormGroup>

            <FormGroup>
              <Label for="containerNumber">{t.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER}</Label>
              <InputWithNullCheck
                data-test="container-number"
                tabIndex={21}
                disabled={this._isReadOnly}
                id="containerNumber"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setContainerNumber(e.target.value)}
                onBlur={() => content.setContainerNumber(content.containerNumber.toUpperCase())}
                type="text"
                value={content.containerNumber}
              />
            </FormGroup>
          </React.Fragment>
        )}

        {(isIT || isFR) && (
          <FormGroup>
            <Label for="origin">{t.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN}</Label>
            <Autocomplete
              data-test="origin"
              tabIndex={22}
              inputClassName={classnames({ [BORDER_DANGER]: this._isInvalidFieldWithReadOnlyByKey('origin') })}
              placeholder={t.GLOBAL_LABEL_SELECT}
              disabled={this._isReadOnly}
              items={common.origins}
              onSelect={(selectedItem: IdCodeModel) => content.setOrigin(selectedItem)}
              selectedValue={content.origin && content.origin.id}
              selectedName={content.origin && content.origin.code}
              useCodeAsValue
              highlight
            />
          </FormGroup>
        )}

        {this._renderRoadHauliers}

        {(isWeightBridgeOperator || isAdmin) && (
          <FormGroup>
            <Label for="attachments">{t.GLOBAL_LABEL_UPLOAD_ATTACHMENTS}</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">
                  <FontAwesome name="upload" />
                </span>
              </InputGroupAddon>
              <Input
                data-test="attachments-field"
                tabIndex={25}
                accept={this._mimeTypeForUploadInput}
                className="form-control"
                id="attachments"
                multiple
                name="attachments"
                onChange={this.attachmentsSelected}
                style={{ padding: '6px 0 5px 5px' }}
                type="file"
              />
            </InputGroup>
          </FormGroup>
        )}

        {isUS && (
          <FormGroup data-test="radiation-files">
            <Label for="radiationFiles">{t.USA_CONFIGURATION_RADIATION_FILE}</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">
                  <FontAwesome name="upload" />
                </span>
              </InputGroupAddon>
              <Input
                data-test="radiation-files-field"
                tabIndex={27}
                className={classnames(['form-control', { [BORDER_DANGER]: this._isInvalidRadiationFiles() }])}
                accept={this._mimeTypeForUploadInput}
                id="radiationFiles"
                multiple
                name="radiationFiles"
                onChange={this.radiationFilesSelected}
                style={{ padding: '6px 0 5px 5px' }}
                type="file"
              />
            </InputGroup>
          </FormGroup>
        )}

        {this._renderTransactionType}

        {isUS && (
          <FormGroup data-test="delivery-type">
            <Label
              data-test="delivery-type-label"
              className={classnames({ [TEXT_DANGER]: this._isInvalidFieldByKey('deliveryType') })}
            >
              {t.USA_CONFIGURATION_DELIVERY_TYPE}
              {this._isInvalidFieldByKey('deliveryType') && <FontAwesome name="exclamation-triangle ml-1" />}
            </Label>
            <RadioComponent
              selected={content.deliveryType}
              tabIndexFrom={29}
              list={deliveryTypeList}
              onChange={(selected: string) => content.setDeliveryType(selected)}
              disabled={this._isReadOnly}
            />
          </FormGroup>
        )}
      </PutInOrderChildren>
    );
  }

  private get _renderAttachments() {
    const {
      translateService: { t },
    } = this.props;
    const { content } = this._model;
    return (
      <DeliveryAttachments
        headerText={t.GLOBAL_LABEL_ATTACHMENTS}
        emptyListText={t.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS}
        attachments={content.attachments.filter((item) => item.type === AttachmentType.DELIVERY_ATTACHMENT)}
        remove={this.props.removeAttachment}
      />
    );
  }

  private get _renderRadiationFiles() {
    const {
      viewStore: { isUS },
      translateService: { t },
    } = this.props;
    const { content } = this._model;
    return (
      isUS && (
        <DeliveryAttachments
          data-test="radiation-files-view"
          headerText={t.USA_CONFIGURATION_RADIATION_FILE}
          emptyListText={t.USA_CONFIGURATION_NO_RADIATION_FILE}
          attachments={content.attachments.filter((item) => item.type === AttachmentType.DELIVERY_RADIATION)}
          remove={this.props.removeAttachment}
        />
      )
    );
  }

  private get _renderResponsiblePeople() {
    const { content } = this._model;
    const {
      translateService: { t },
      viewStore: { timezone },
      dateFormattingService: { formatDateTime },
    } = this.props;

    return (
      this._hasCreatedBy && (
        <div>
          <h5 className="mt-4">{t.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE}</h5>
          <Row className="bg-white border-top border-bottom">
            <Col xs={12} className="pt-1 pb-1">
              <table>
                <tbody>
                  <tr>
                    <td className="min-width-120">{t.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY}</td>
                    <td>
                      <strong className="pr-3">{content.createdBy.user.fullName}</strong>{' '}
                      {formatDateTime(content.createdBy.date, timezone)}
                    </td>
                  </tr>
                  {this._hasSignedOffBy && (
                    <tr>
                      <td>{t.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY}</td>
                      <td>
                        <strong className="pr-3">{content.signedOffBy.user.fullName}</strong>
                        {formatDateTime(content.signedOffBy.date, timezone)}
                      </td>
                    </tr>
                  )}
                  {this._hasCompletedBy && (
                    <tr>
                      <td>{t.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY}</td>
                      <td>
                        <strong className="pr-3">{content.completedBy.user.fullName}</strong>{' '}
                        {formatDateTime(content.completedBy.date, timezone)}
                      </td>
                    </tr>
                  )}
                  {this._hasSyncedBy && (
                    <tr>
                      <td>{t.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY}</td>
                      <td>
                        <strong className="pr-3">{content.syncedBy.user.fullName}</strong>{' '}
                        {formatDateTime(content.syncedBy.date, timezone)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      )
    );
  }

  private get _renderSuppliersDetails() {
    const {
      viewStore: { isIT },
      translateService: { t },
      dateFormattingService: { formatDate },
    } = this.props;
    const { content } = this._model;

    return isIT ? (
      <SortingReportDetailSection
        data-test="suppliers-details-section"
        title={t.GLOBAL_LABEL_SUPPLIERS_DETAILS}
        isOpen
        hasFieldWithError={content.supplier?.isCertificateExpired || content.supplier?.hasNotCertificate}
        errorTrianglePosition="left"
      >
        {!!content.supplier && (
          <Row className="margin-0-auto">
            <Col lg="4" md="6">
              <FormGroup>
                <Label for="supplier-type">{`${t.GLOBAL_LABEL_SUPPLIER} ${t.GLOBAL_LABEL_TYPE}`}</Label>
                <InputWithNullCheck
                  data-test="supplier-type"
                  value={content.supplier.type}
                  id="supplier-type"
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col lg="2" md="6">
              <FormGroup>
                <Label for="supplier-revision-request">
                  {t.GLOBAL_LABEL_REVISION_REQUEST}
                  {(content.supplier.isRevisionRequestExpired || !content.supplier.revisionRequest) && (
                    <span className="margin-left-1rem text-danger" data-test="supplier-revision-request-error">
                      <FontAwesome name="exclamation-triangle" />
                      {content.supplier.isRevisionRequestExpired && (
                        <span className="margin-left-0-5rem">{t.GLOBAL_LABEL_EXPIRED}</span>
                      )}
                    </span>
                  )}
                </Label>
                <InputWithNullCheck
                  data-test="supplier-revision-request"
                  value={formatDate(content.supplier.formattedRevisionRequest)}
                  id="supplier-revision-request"
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col lg="2" md="6">
              <FormGroup>
                <Label for="supplier-cert-71513">
                  {t.GLOBAL_LABEL_CERT_715_13}
                  {(content.supplier.isCert71513DateExpired || content.supplier.hasNot71513DateCertificate) && (
                    <span className="margin-left-1rem text-danger" data-test="supplier-71513-date-error">
                      <FontAwesome name="exclamation-triangle" />
                      {content.supplier.isCert71513DateExpired && (
                        <span className="margin-left-0-5rem">{t.GLOBAL_LABEL_EXPIRED}</span>
                      )}
                    </span>
                  )}
                </Label>
                <InputWithNullCheck
                  data-test="supplier-cert-71513"
                  value={formatDate(content.supplier.formattedCert71513Date)}
                  id="supplier-cert-71513"
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col lg="2" md="6">
              <FormGroup>
                <Label for="supplier-aut-rif">
                  {t.GLOBAL_LABEL_CERT_AUT_RIF_DATE}
                  {(content.supplier.isCertAutRifDateExpired || content.supplier.hasNotAutRifDateCertificate) && (
                    <span className="margin-left-1rem text-danger" data-test="supplier-aut-rif-date-error">
                      <FontAwesome name="exclamation-triangle" />
                      {content.supplier.isCertAutRifDateExpired && (
                        <span className="margin-left-0-5rem">{t.GLOBAL_LABEL_EXPIRED}</span>
                      )}
                    </span>
                  )}
                </Label>
                <InputWithNullCheck
                  data-test="supplier-aut-rif"
                  value={formatDate(content.supplier.formattedCertAutRifDate)}
                  id="supplier-aut-rif"
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col lg="2" md="6">
              <FormGroup>
                <Label for="supplier-exp-date">
                  {t.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE}
                  {(content.supplier.isExpirationDateExpired || content.supplier.hasNotExpirationDateCertificate) && (
                    <span className="margin-left-1rem text-danger" data-test="supplier-expiration-date-error">
                      <FontAwesome name="exclamation-triangle" />
                      {content.supplier.isExpirationDateExpired && (
                        <span className="margin-left-0-5rem">{t.GLOBAL_LABEL_EXPIRED}</span>
                      )}
                    </span>
                  )}
                </Label>
                <InputWithNullCheck
                  data-test="supplier-exp-date"
                  value={formatDate(content.supplier.formattedExpirationDate)}
                  id="supplier-exp-date"
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </SortingReportDetailSection>
    ) : null;
  }

  private get _renderSupplier() {
    const {
      viewStore: { isIT },
      translateService: { t },
    } = this.props;
    const { content } = this._model;

    return (
      <FormGroup>
        <Label for="supplierId" data-test="supplier-label">
          {t.GLOBAL_LABEL_SUPPLIER}
          {isIT && (content.supplier?.isCertificateExpired || content.supplier?.hasNotCertificate) && (
            <span className="margin-left-0-5rem text-danger" data-test="supplier-expiration-date-no-certificate">
              <FontAwesome name="exclamation-triangle" />
            </span>
          )}
        </Label>
        <Autocomplete
          id="supplierId"
          data-test="supplier"
          tabIndex={5}
          inputClassName={classnames({ [BORDER_DANGER]: this._isInvalidFieldByKey('supplier') })}
          placeholder={t.GLOBAL_LABEL_SELECT}
          disabled={this._isReadOnly}
          items={this._suppliersByWorkflow}
          onSelect={(selectedItem: SupplierModel) => content.setSupplier(selectedItem)}
          selectedValue={content.supplier && content.supplier.id}
          selectedName={content.supplier && content.supplier.name}
          highlight
        />
      </FormGroup>
    );
  }

  private get _suppliersByWorkflow() {
    const {
      viewStore: { isUS },
      commonStore: { common },
    } = this.props;
    if (isUS) {
      return common.suppliersWithCodes;
    }
    return common.suppliers;
  }

  private get _renderRoadHauliers() {
    const {
      viewStore: { isIT },
      translateService: { t },
      commonStore: { common },
    } = this.props;
    const { content } = this._model;

    return (
      <FormGroup>
        <Label for="roadHaulierId" data-test="road-haulier-label">
          {isIT
            ? `${t.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER} [${t.GLOBAL_LABEL_AUTH_NUMBER}] (${t.GLOBAL_LABEL_EXP_DATE})`
            : t.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER}
          {isIT && content.roadHaulier && !content.roadHaulier.authorizationNumber && (
            <span className="margin-left-1rem text-danger" data-test="road-haulier-no-certificate">
              <FontAwesome name="exclamation-triangle" />
              {t.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER}
            </span>
          )}
          {isIT && content.roadHaulier && content.roadHaulier.isCertificateExpired && (
            <span className="margin-left-1rem text-danger" data-test="road-haulier-certificate-expired">
              <FontAwesome name="exclamation-triangle" />
              {t.GLOBAL_LABEL_AUTHORIZATION_EXPIRED}
            </span>
          )}
          {isIT &&
            content.roadHaulier &&
            content.roadHaulier.authorizationNumber &&
            content.roadHaulier.hasNotCertificate && (
              <span className="margin-left-1rem text-danger" data-test="road-haulier-no-expiration-date">
                <FontAwesome name="exclamation-triangle" />
                {t.GLOBAL_LABEL_NO_EXPIRATION_DATE}
              </span>
            )}
        </Label>
        <Autocomplete
          id="roadHaulierId"
          data-test="road-haulier"
          tabIndex={23}
          inputClassName={classnames({ [BORDER_DANGER]: this._isInvalidFieldWithReadOnlyByKey('roadHaulier') })}
          placeholder={t.GLOBAL_LABEL_SELECT}
          disabled={this._isReadOnly}
          items={common.roadHauliersWithCertificateDates}
          onSelect={(selectedItem: RoadHaulierModel) => content.setRoadHaulier(selectedItem)}
          selectedValue={content.roadHaulier && content.roadHaulier.id}
          selectedName={content.roadHaulier && content.roadHaulier.name}
          highlight
        />
      </FormGroup>
    );
  }

  private get _renderTransactionType() {
    const {
      viewStore: { isUS },
      commonStore: { deliveryTransactionTypeList },
      translateService: { t },
    } = this.props;
    const { content } = this._model;
    return isUS ? (
      <FormGroup>
        <Label for="transaction-type">{t.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE}</Label>
        <InputWithNullCheck
          data-test="transaction-type"
          id="transaction-type"
          type="select"
          disabled={this._isReadOnly}
          value={content.transactionType}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            content.setTransactionType(e.target.value);
          }}
        >
          {deliveryTransactionTypeList.map((item: string) => {
            return (
              <option key={item} value={item}>
                {getTranslation(
                  `DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_${item}` as keyof I18N,
                  this.props.translateService.t
                )}
              </option>
            );
          })}
        </InputWithNullCheck>
      </FormGroup>
    ) : null;
  }

  private get _vehicleRegistrationLimit(): number {
    const {
      viewStore: { isIT, isFR },
    } = this.props;

    return isIT || isFR ? VEHICLE_REGISTRATION_LIMIT_IT : VEHICLE_REGISTRATION_LIMIT_ALL;
  }

  private get _hasSyncButton(): boolean {
    const {
      viewStore: { isUK, isUS },
      sessionStore: { isWeightBridgeOperator },
    } = this.props;
    return !this._model.content.synced && isWeightBridgeOperator && (isUK || isUS);
  }

  private get _isDisableSyncButton(): boolean {
    const {
      viewStore: { isUS },
    } = this.props;
    const { content } = this._model;
    return isUS ? !content.canBeSyncedWhenSaved : !content.canBeSynced;
  }

  private get _hasPrintButton(): boolean {
    const {
      viewStore: { isIT },
      sessionStore: { isWeightBridgeOperator },
    } = this.props;
    return isIT && isWeightBridgeOperator;
  }

  private get _hasExportButton(): boolean {
    const {
      viewStore: { isFR },
      sessionStore: { isWeightBridgeOperator, isAdmin },
    } = this.props;
    return !isMobile() && isFR && (isWeightBridgeOperator || isAdmin);
  }

  private get _showFooter(): boolean {
    const { content, selectedAttachments, selectedRadiationFiles } = this._model;
    return (
      content.isDirty ||
      this._hasSyncButton ||
      this._hasPrintButton ||
      this._hasExportButton ||
      !!selectedAttachments ||
      !!selectedRadiationFiles
    );
  }

  private get _hasTrader(): boolean {
    const {
      viewStore: { isIT, isUS, isFR },
    } = this.props;
    return isUS || isIT || isFR;
  }

  private get _hasTicketNumber(): boolean {
    const {
      viewStore: { isUS, isFR },
    } = this.props;
    return !(isUS || isFR);
  }

  // EXECUTE CHECK BEFORE QUITING PAGE
  private _goBack = () => {
    if (this._model.content.isDirty) {
      this._toggleSaveConfirmationModal();
    } else {
      this._redirectToDeliveries();
    }
  };

  private _redirectToDeliveries = () => {
    this.props.history.push('/deliveries');
  };

  @action
  private _changeModelContent = (newContent: DeliveryModel) => {
    this._model.content.update(newContent);
  };

  // TOGGLE SAVE PROMPT MODAL
  @action
  private _toggleSaveConfirmationModal = () => {
    this._model.saveConfirmationModal = !this._model.saveConfirmationModal;
  };

  // TOGGLE COMPLETE CONFIRMATION MODAL
  @action
  private _toggleCompleteConfirmationModal = () => {
    this._model.completeConfirmationModal = !this._model.completeConfirmationModal;
  };

  // TOGGLE HAS ATTACHMENTS CONFIRMATION MODAL
  @action
  private _toggleHasAttachmentsConfirmationModal = () => {
    this._model.hasAttachmentsConfirmationModal = !this._model.hasAttachmentsConfirmationModal;
  };

  // TOGGLE CERTIFICATE EXPIRATION MODAL
  @action
  private _toggleCertificateExpirationDateModal = () => {
    this._model.certificateExpirationDateModal = !this._model.certificateExpirationDateModal;
  };

  // TOGGLE AUTHORIZATION NUMBER MODAL
  @action
  private _toggleAuthorizationNumberModal = () => {
    this._model.authorizationNumberModal = !this._model.authorizationNumberModal;
  };

  private get _getValidationMessageMap(): Map<DeliveryValidatorKey, string> {
    const {
      translateService: { t },
    } = this.props;
    return new Map<DeliveryValidatorKey, string>([
      ['advisedGoods', t.GLOBAL_LABEL_ADVISED_GOODS],
      ['roadHaulier', t.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER],
      ['supplier', t.GLOBAL_LABEL_SUPPLIER],
      ['vehicleRegistrationNumber', t.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER],
      ['grn', t.GLOBAL_LABEL_GRN],
      ['origin', t.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN],
      ['deliveryType', t.USA_CONFIGURATION_DELIVERY_TYPE],
      ['weatherCondition', t.USA_CONFIGURATION_WEATHER_CONDITIONS],
      ['AGDescription', t.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION],
      ['AGYardLocation', t.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION],
      ['AGStockItem', t.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM],
      ['AGTicketNumber', t.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET],
      ['AGDepartmentCode', t.GLOBAL_LABEL_DEPARTMENT],
      ['AGFirstWeight', t.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT],
      ['AGProductForm', t.GLOBAL_LABEL_FORM],
      ['AGProductQuality', t.GLOBAL_LABEL_QUALITY],
    ]);
  }

  private _validate = (): boolean => {
    const {
      viewStore: { countryCode, isUS },
      translateService: { t },
    } = this.props;
    const { content, selectedRadiationFiles } = this._model;
    let result = true;

    if (!content.isValid(countryCode)) {
      const invalidKeyLabels: string[] = content.invalidValidatorKeys
        .get(countryCode)
        .map((key: DeliveryValidatorKey) => this._getValidationMessageMap.get(key));

      notification.error(
        t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS(joinWithCommaSeparator(invalidKeyLabels).toLowerCase())
      );
      result = false;
    }
    if (isUS && !(selectedRadiationFiles || this._hasRadiationFiles)) {
      notification.error(t.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG);
      result = false;
    }
    return result;
  };

  private _validateAndSave = async () => {
    const { content } = this._model;
    const validate = this._validate();
    if (!validate) {
      content.setNeedToCheck(true);
      return false;
    }
    content.setNeedToCheck(false);
    return this._submitForm();
  };

  private _validateConfirmAndSave = async () => {
    const {
      viewStore: { isIT },
      sessionStore: { tenant },
    } = this.props;
    const { content } = this._model;

    const validate = this._validate();
    if (!validate) {
      content.setNeedToCheck(true);
      return;
    }
    content.setNeedToCheck(false);
    const attachmentsCondition =
      tenant.isNeedCheckDeliveryAttachments && !(this._hasAttachments || this._model.selectedAttachments);
    const certificateCondition =
      isIT && (content.supplier?.isCertificateExpired || content.supplier?.hasNotCertificate);
    const authorizationNumberCondition =
      isIT &&
      (!content.roadHaulier?.authorizationNumber ||
        content.roadHaulier?.hasNotCertificate ||
        content.roadHaulier?.isCertificateExpired);

    if (
      !(await this._modalConfirmationCheck(
        attachmentsCondition,
        this._hasAttachmentsPromise,
        this._toggleHasAttachmentsConfirmationModal
      ))
    ) {
      return;
    }
    if (
      !(await this._modalConfirmationCheck(
        certificateCondition,
        this._certificateExpirationDatePromise,
        this._toggleCertificateExpirationDateModal
      ))
    ) {
      return;
    }
    if (
      !(await this._modalConfirmationCheck(
        authorizationNumberCondition,
        this._authorizationNumberPromise,
        this._toggleAuthorizationNumberModal
      ))
    ) {
      return;
    }

    this._submitForm();
  };

  private _resolveHasAttachmentsPromiseWithTrue = () => {
    this._hasAttachmentsPromise.resolvePromise(true);
  };

  private _resolveHasAttachmentsPromiseWithFalse = () => {
    this._hasAttachmentsPromise.resolvePromise(false);
  };

  private _resolveCertificateExpirationDatePromiseWithTrue = () => {
    this._certificateExpirationDatePromise.resolvePromise(true);
  };

  private _resolveCertificateExpirationDatePromiseWithFalse = () => {
    this._certificateExpirationDatePromise.resolvePromise(false);
  };

  private _resolveAuthorizationNumberPromiseWithTrue = () => {
    this._authorizationNumberPromise.resolvePromise(true);
  };

  private _resolveAuthorizationNumberPromiseWithFalse = () => {
    this._authorizationNumberPromise.resolvePromise(false);
  };

  private _submitForm = (): Promise<string | void> | void => {
    const { selectedAttachments, selectedRadiationFiles, content } = this._model;

    const radiationFiles: FormData = this._createFilesFormData(selectedRadiationFiles);
    const attachments: FormData = this._createFilesFormData(selectedAttachments);

    if (this._isFullSave) {
      return this.props.saveAction(content, attachments, radiationFiles);
    } else {
      return this.props.saveAttachmentsAction(content, attachments, radiationFiles);
    }
  };

  private async _submitAndComplete(): Promise<void> {
    const {
      content: { id: deliveryId },
    } = this._model;
    const res = await this._validateAndSave();
    if (res !== false) {
      await this.props.syncAction(deliveryId);
    }
  }

  private _selectContractNumber = async (contractNumber: string) => {
    const {
      viewStore: { isAdminFromUS },
    } = this.props;
    const { content } = this._model;
    if (!contractNumber) {
      return;
    }

    const { contractStore } = this.props;
    this._model.content.setContractNumber(contractNumber);
    const contract = await contractStore.getCurrentContractNumber(contractNumber);
    const needAutoFill = !!contract && !isAdminFromUS;
    if (needAutoFill) {
      this._autofillDeliveryDetails(contract);
    } else if (isAdminFromUS && !!contract) {
      content.setContractId(contract.id);
    }
  };

  @action
  private _autofillDeliveryDetails = (contract: IContract) => {
    const { content } = this._model;
    const {
      commonStore: {
        common: { findStockItemByCode, findSupplierByCode, findTraderByCode },
      },
      translateService: { t },
    } = this.props;
    const incorrectContractAGsCode: string[] = [];

    content.setContractId(contract.id);
    const supplier = findSupplierByCode(contract.supplierNumber);
    if (supplier && supplier.id) {
      content.setSupplier(supplier);
    }

    const trader = findTraderByCode(contract.traderCode);
    if (trader && trader.id) {
      content.setTrader(trader);
    }

    content.setRemarks(contract.weight);

    const contractAG = contract.details.reduce((res: DeliveryAdvisedGoodModel[], item: IContractDetails) => {
      const addedStockItem = findStockItemByCode(item.materialNumber);
      if (addedStockItem) {
        const newAdviseGood: DeliveryAdvisedGoodModel = new DeliveryAdvisedGoodModel().update({
          stockItem: addedStockItem,
          advisedDescription: addedStockItem.name,
          advisedGrossWeight: Number(item.weight),
          advisedGoodExternalIndex: item.recordSequence,
        } as DeliveryAdvisedGoodModel);
        res.push(newAdviseGood);
      } else {
        incorrectContractAGsCode.push(item.materialNumber);
      }

      return res;
    }, []);

    if (incorrectContractAGsCode.length) {
      notification.error(
        t.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG(incorrectContractAGsCode.join(', '))
      );
    }

    content.advisedGoods = contractAG.length ? contractAG : [new DeliveryAdvisedGoodModel()];
  };
  // this method uses only for test purposes Germany workflow.
  private _changeGRN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      viewStore: { isDE, isFR, isDE_D365 },
    } = this.props;
    if (isDE || isFR || isDE_D365) {
      this._model.content.setGRN(e.target.value);
    }
  };

  private _print = async () => {
    const {
      content: { id: deliveryId },
    } = this._model;
    const { printAction } = this.props;
    await this._validateAndSave();
    return printAction(deliveryId);
  };

  private _export = async () => {
    const {
      content: { id: deliveryId },
    } = this._model;
    const { exportAction } = this.props;
    const res = await this._validateAndSave();
    if (res !== false) {
      return exportAction(deliveryId);
    }
  };

  private _createFilesFormData(files: FileList): FormData {
    if (!files) {
      return null;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files.item(i));
    }
    return formData;
  }

  private _isInvalidFieldWithReadOnlyByKey(key: DeliveryValidatorKey): boolean {
    return !this._isReadOnly && this._isInvalidFieldByKey(key);
  }

  private _isInvalidFieldByKey(key: DeliveryValidatorKey): boolean {
    const { content } = this._model;
    return content.needToCheck && !content.isValidByKey(key);
  }

  private _isInvalidRadiationFiles(): boolean {
    const { content } = this._model;
    return content.needToCheck && !(this._model.selectedRadiationFiles || this._hasRadiationFiles);
  }

  private _modalConfirmationCheck = async (
    condition: boolean,
    promiseResolver: PromiseResolver<boolean>,
    toggleModal: () => void
  ): Promise<boolean> => {
    if (condition) {
      toggleModal();
      const shouldContinue = await promiseResolver.promise;
      promiseResolver.createNewPromise();

      if (!shouldContinue) {
        return false;
      }
    }
    return true;
  };

  private get _isDisabledSaveButton(): boolean {
    const {
      viewStore: { isIT, isFR },
      hasPendingRequests,
    } = this.props;
    const { content, selectedAttachments } = this._model;
    return hasPendingRequests || ((isIT || isFR) && !this._isFullSave && !content.isDirty && !selectedAttachments);
  }

  private _setDefaultTransactionType = () => {
    // for new delivery and US only
    if (this._isNeedSetDefaultTransactionType) {
      const wasChanged = this._model.content.hasChanged;
      this._model.content.setTransactionType(this.props.commonStore.defaultTransactionType);
      if (!wasChanged) {
        this._model.content.setHasChanged(false);
      }
    }
  };

  private _clearContract = () => {
    this._model.content.clearContract();
  };

  @computed
  private get _isNeedSetDefaultTransactionType() {
    const {
      content,
      viewStore: { isUS },
    } = this.props;
    return (
      isUS &&
      !content &&
      this._model.content &&
      !this._model.content.id &&
      this.props.commonStore.defaultTransactionType &&
      !this._model.content.transactionType
    );
  }

  private _toggleDeleteConfirmation = () => {
    this._setShowDeleteConfirmation(!this._showDeleteConfirmation);
  };

  private get isDeleteButtonShown() {
    const {
      sessionStore: { isAdmin },
      deleteDelivery,
    } = this.props;
    return isAdmin && !!deleteDelivery;
  }

  private _deleteDelivery = () => {
    const { deleteDelivery } = this.props;
    if (!!deleteDelivery) {
      deleteDelivery(this._model.content);
    }
  };
}
