import * as React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import * as classnames from 'classnames';
import Autocomplete from 'domain/Autocomplete';
import StockItemModel from 'models/StockItemModel';
import { BORDER_DANGER } from 'util/constants';
import InputWithNullCheck from 'components/InputWithNullCheck';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import ModalConfirmation from 'domain/ModalConfirmation';

interface IStockItemProps extends IStockItemDefaultProps {
  isAutocomplete: boolean;
  item: ReceivedGoodsModel;
  isInvalid: boolean;
  isDisabled: boolean;
  translateService?: TranslateService;
  commonStore?: CommonStore;
  overwriteRgDescriptionDialogEnabled?: boolean;
}

interface IStockItemDefaultProps {
  isAutocompleteListInTop?: boolean;
  isAutoChangeDescription?: boolean;
}
@inject('commonStore', 'translateService')
@observer
export class StockItem extends React.Component<IStockItemProps> {
  public static defaultProps: IStockItemDefaultProps = {
    isAutocompleteListInTop: true,
    isAutoChangeDescription: true,
  };

  @observable
  private _isModalOpen: boolean = false;

  @observable
  private _selectedItem: StockItemModel | null = null;

  public render() {
    const {
      isAutocomplete,
      item,
      isDisabled,
      isInvalid,
      isAutocompleteListInTop,
      translateService: { t },
      commonStore: { common },
    } = this.props;

    return isAutocomplete ? (
      <>
        <Autocomplete
          data-test="stock-item-autocomplete"
          disabled={isDisabled}
          showOnTop={isAutocompleteListInTop}
          placeholder={t.GLOBAL_LABEL_SELECT}
          items={common.stockItems}
          onSelect={this._onSelectHandler}
          onChange={this._onChangeHandler}
          selectedValue={item.stockCode}
          selectedName={item.stockCode}
          inputClassName={classnames({ [BORDER_DANGER]: isInvalid })}
          useCodeAsValue
          displayCodeAndName
          changeValueOnSelectOnly={this._isOverwriteDescriptionEnabled}
        />
        {this._isOverwriteDescriptionEnabled && (
          <ModalConfirmation
            data-test="overwrite-rg-description-confirmation"
            buttonYesColor="success"
            callbackYes={() => this._updateStockItem(this._selectedItem, true)}
            callbackNo={() => this._updateStockItem(this._selectedItem, false)}
            heading={t.GLOBAL_LABEL_CONFIRM}
            ico="check"
            isOpen={this._isModalOpen}
            text={t.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT}
            toggleModal={this._toggleModal}
            customButtons={this._modalCustomButtons}
          />
        )}
      </>
    ) : (
      <InputWithNullCheck
        data-test="stock-item"
        disabled={isDisabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setStockCode(e.target.value)}
        className={classnames({ [BORDER_DANGER]: isInvalid })}
        type="text"
        value={item.stockCode}
      />
    );
  }

  @action
  private _toggleModal = () => {
    if (this._isModalOpen && this._selectedItem) {
      this._setSelectedItem(null); // clear selected item on modal close
    }
    this._isModalOpen = !this._isModalOpen;
  };

  @action
  private _setSelectedItem = (val: StockItemModel | null) => {
    this._selectedItem = val;
  };

  private _onChangeHandler = (value: string) => {
    if (!value) {
      const { item } = this.props;
      item.setStockCode('');
      item.setStockItem(null);
    }
  };

  private _onSelectHandler = (selectedItem: StockItemModel) => {
    const { item, isAutoChangeDescription } = this.props;
    if (this._isOverwriteDescriptionEnabled && !!item.description && selectedItem.name !== item.description) {
      this._setSelectedItem(selectedItem);
      this._toggleModal();
    } else {
      this._updateStockItem(selectedItem, isAutoChangeDescription);
    }
  };

  private _updateStockItem = (stockItem: StockItemModel, changeDescription: boolean) => {
    const { item } = this.props;
    item.setStockCode(stockItem.code);
    item.setStockItem(stockItem);
    if (changeDescription) {
      item.setDescription(stockItem.name);
    }
  };

  private get _modalCustomButtons(): React.ReactNode {
    const {
      translateService: { t },
    } = this.props;
    return (
      <Button className="-align-left" data-test="cancel" color="info" onClick={this._toggleModal}>
        {t.GLOBAL_LABEL_CANCEL}
      </Button>
    );
  }

  private get _isOverwriteDescriptionEnabled() {
    const { overwriteRgDescriptionDialogEnabled, isAutoChangeDescription } = this.props;
    return overwriteRgDescriptionDialogEnabled && isAutoChangeDescription;
  }
}
