import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { IName } from 'models/ModelInterfaces';

export default class IdNameModel extends UpdateModel<IdNameModel> implements IName {
  @observable public id?: string = null;
  @observable public name: string = '';

  @action
  public update(obj: IdNameModel) {
    this.updater.update(this, obj, IdNameModel);
    return this;
  }

  @action
  public changeName = (newName: string) => (this.name = newName);
}
