import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Col, FormGroup, Label } from 'reactstrap';

import TranslateService from 'services/TranslateService';
import * as FontAwesome from 'react-fontawesome';
import Autocomplete from 'domain/Autocomplete';
import UserModel from 'models/UserModel';
import IdCodeNameModel from 'models/IdCodeNameModel';
import { instance as notification } from 'util/notification';
import EmployeeStore from 'stores/EmployeeStore';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  content: UserModel<IUserModelConstructObj>;
  translateService?: TranslateService;
  employeeStore?: EmployeeStore;
}

const Employees: React.FC<IProps> = (props) => {
  const {
    translateService: { t },
    content,
    employeeStore,
  } = props;

  const onExternalEmployeeIdSelect = (item: IdCodeNameModel) => {
    const employee = employeeStore.getEmployeeById(item.id);
    if (!!employee) {
      content.setFirstname(employee.firstName);
      content.setLastname(employee.lastName);
      content.setExternalEmployeeId(employee.externalId);
    }
  };

  const onTrigger = async () => {
    try {
      await employeeStore.triggerImportEmployee();
      notification.success(t.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE);
    } catch (e) {
      notification.success(t.ADMIN_REFRESH_IN_PROGRESS_MESSAGE(t.GLOBAL_LABEL_EMPLOYEE_ID));
    } finally {
      employeeStore.getEmployeeItems();
    }
  };

  return (
    <FormGroup row className="remaining-padding">
      <Label for="external-employee-id" sm={4}>
        {t.GLOBAL_LABEL_EMPLOYEE_ID}
        <Button
          data-test="trigger-external-employee"
          className="float-right refresh-btn-admin-margin-top-for-mobile"
          color="false"
          onClick={onTrigger}
          size="sm"
        >
          <FontAwesome name="refresh" />
        </Button>
      </Label>
      <Col sm={8}>
        <Autocomplete
          data-test="external-employee-id"
          placeholder={t.GLOBAL_LABEL_SELECT}
          items={employeeStore.employeeList}
          onSelect={onExternalEmployeeIdSelect}
          selectedValue={employeeStore.getEmployeeByCode(content.externalEmployeeId)?.id}
          selectedName={employeeStore.getEmployeeByCode(content.externalEmployeeId)?.externalId}
          useCodeAsValue
          displayCodeAndName
        />
      </Col>
    </FormGroup>
  );
};

export default inject('translateService', 'employeeStore')(observer(Employees));
