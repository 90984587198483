import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';

import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { AdminRootUrl } from 'util/enums';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetPrinterModel from 'models/LasernetPrinterModel';
import LasernetPrinterDetailComponent from 'pod/admin/lasernetPrinters/printers/LasernerPrinterDetailComponent';

interface IProps {
  lasernetPrinterStore?: LasernetPrinterStore;
  translateService?: TranslateService;
}

@inject('lasernetPrinterStore', 'translateService')
@observer
export default class LasernetPrinterDetailRoute extends React.Component<IProps> {
  @observable
  public content: LasernetPrinterModel = null;

  @action
  public updateContent = (newPrinter: LasernetPrinterModel) => {
    this.content = new LasernetPrinterModel().update(newPrinter);
  };

  public render() {
    const {
      translateService: { t },
      lasernetPrinterStore: { printerApi, printers },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.LASERNET_PRINTERS}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_PRINTER}
        itemsList={printers}
        api={printerApi}
        updateItem={this.updateContent}
      >
        <LasernetPrinterDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}
