import * as React from 'react';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import StockItemModel from 'models/StockItemModel';
import TranslateService from 'services/TranslateService';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import { action, observable } from 'mobx';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import StockItemDetailRoute from 'pod/admin/stock-items/StockItemDetailRoute';
import StockItemDetailRouteD365 from 'pod/admin/stock-items/StockItemDetailRouteD365';
import * as classnames from 'classnames';
import NavlinkCustom from 'domain/NavlinkCustom';
import ViewStore from 'stores/ViewStore';
import { Button } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import RefreshConfirmation from 'pod/admin/commonComponents/RefreshConfirmation';
import { instance as notification } from 'util/notification';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

interface IState {
  showUpload: boolean;
}

@inject('adminStore', 'viewStore', 'translateService')
@observer
export default class StockItemListRoute extends React.Component<IProps, IState> {
  @observable public content: StockItemModel = new StockItemModel({ code: this.props.viewStore.stockCodeFormat });
  @observable public showConfirmation: boolean = false;

  @action
  public clearModel = () => {
    this.content = new StockItemModel({ code: this.props.viewStore.stockCodeFormat });
  };

  @action
  public toggleConfirmation = () => {
    this.showConfirmation = !this.showConfirmation;
  };

  public get stockItemList() {
    const {
      translateService: { t },
      adminStore: { stockItemListActiveFirst },
    } = this.props;
    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.GLOBAL_LABEL_CODE}</th>
            <th className="pr-3">{t.GLOBAL_LABEL_NAME}</th>
          </tr>
        </thead>
        {stockItemListActiveFirst.map((stockItem) => this._renderItem(stockItem))}
      </table>
    );
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { stockItemApi },
      viewStore: { isDE_D365 },
    } = this.props;

    return (
      <React.Fragment>
        <CommonAdminListRoute
          api={stockItemApi}
          rootPath={AdminRootPath.STOCK_ITEMS}
          itemsTable={this.stockItemList}
          itemCreateForm={null}
          componentRoute={isDE_D365 ? StockItemDetailRouteD365 : StockItemDetailRoute}
          clearModel={this.clearModel}
          content={this.content}
          sectionNameTranslation={t.ADMIN_SECTIONENTITY_STOCK_ITEM}
          exampleFileNames={ExampleFileNames.STOCK_ITEMS}
          header={t.MENU_ADMIN_STOCK_ITEMS}
          hasUploadBtn={!isDE_D365}
          hasCreateBtn={!isDE_D365}
          buttons={
            isDE_D365 ? (
              <Button
                data-test="trigger-button"
                className="float-right"
                color="success"
                onClick={this.toggleConfirmation}
              >
                <FontAwesome name="refresh" className="mr-2" />
                {t.GLOBAL_LABEL_REFRESH_COMMON(t.GLOBAL_LABEL_PRODUCT_VARIANTS)}
              </Button>
            ) : null
          }
        />
        {isDE_D365 && (
          <RefreshConfirmation
            callbackYes={this._onRefresh}
            itemName={t.GLOBAL_LABEL_PRODUCT_VARIANTS}
            isOpen={this.showConfirmation}
            toggleConfirmation={this.toggleConfirmation}
          />
        )}
      </React.Fragment>
    );
  }

  private _renderItem = (stockItem: StockItemModel) => {
    return (
      <tbody key={stockItem.id}>
        <NavlinkCustom
          to={`/admin/stock-items/${stockItem.id}`}
          tagName="tr"
          className={classnames(['pointer', { 'bg-secondary': !stockItem.active }])}
          key={stockItem.id}
        >
          <td className="pl-3" data-test="stock-code">
            {stockItem.code}
          </td>
          <td className="pr-3" data-test="stock-name">
            {stockItem.name}
          </td>
        </NavlinkCustom>
      </tbody>
    );
  };

  private _onRefresh = async () => {
    const {
      translateService: { t },
      adminStore: { stockItemApi },
    } = this.props;
    try {
      await this.props.adminStore.refreshProductVariants();
      notification.success(t.ADMIN_REFRESH_SUCCESS_MESSAGE(t.GLOBAL_LABEL_PRODUCT_VARIANTS));
    } catch (e) {
      notification.success(t.ADMIN_REFRESH_IN_PROGRESS_MESSAGE(t.GLOBAL_LABEL_PRODUCT_VARIANTS));
    } finally {
      stockItemApi.getAllItems();
    }
  };
}
