import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as H from 'history';
import storage from 'util/storage';

import { elgLogo } from 'util/images';

import SessionStore from 'stores/SessionStore';
import LayoutStore from 'stores/LayoutStore';
import QrCodeButtonComponent from 'components/QrCodeButtonComponent';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import TranslateService from 'services/TranslateService';
import UserContentMenu from 'domain/layout/UserContentMenu';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import UserRoleModel from 'models/UserRoleModel';
import { ACTIVE_TENANT_ID_STORAGE_KEY, ACTIVE_USER_ROLE_NAME_STORAGE_KEY } from 'util/constants';
import { UserRoles } from 'util/enums';
import { reloadPage } from 'util/helpers';

interface IProps {
  pathName: string;
  history?: H.History;
  layoutStore?: LayoutStore;
  sessionStore?: SessionStore;
  receivedGoodsStore?: ReceivedGoodsStore;
  translateService?: TranslateService;
}

@inject('sessionStore', 'layoutStore', 'receivedGoodsStore', 'translateService')
@observer
export default class Header extends React.Component<IProps> {
  @observable public showLogoutModal: boolean = false;
  @observable public userMenuIsOpen: boolean = false;
  @observable public roleToSwitch: UserRoleModel = null;
  @observable public tenantIdToSwitch: string = null;

  public onQrCodeRead = (value: string) => {
    const {
      sessionStore: { isLab },
      receivedGoodsStore,
    } = this.props;

    receivedGoodsStore.getReceivedGood(value).then((receivedGood: ReceivedGoodsModel) => {
      if (isLab) {
        this.props.history.push(`/advised-good-lab/${receivedGood.advisedGoodId}/${receivedGood.id}`);
      } else {
        this.props.history.push(`/advised-good/${receivedGood.advisedGoodId}/${receivedGood.id}`);
      }
    });
  };

  public changeUserMenuIsOpen = (value: boolean) => {
    this._setUserMenuIsOpen(value);
  };

  public toggleLogoutModal = () => {
    this._setShowLogoutModal(!this.showLogoutModal);
  };

  public toggleSwitchRoleModal = () => {
    if (!!this.roleToSwitch) {
      this._setRoleToSwitch(null);
    }
  };

  public toggleSwitchTenantModal = () => {
    if (!!this.tenantIdToSwitch) {
      this._setTenantIdToSwitch(null);
    }
  };

  public onUserRoleChange = (newRole: UserRoleModel) => {
    this._setRoleToSwitch(newRole);
  };

  public onTenantChange = (id: string) => {
    this._setTenantIdToSwitch(id);
  };

  public render() {
    const {
      sessionStore: { isAdmin, isSuperAdmin, isWeightBridgeOperator, tenant, user },
      layoutStore,
      translateService: { t },
    } = this.props;

    const tenantToDisplay = isAdmin && !!user.activeTenant ? user.activeTenant : tenant;

    return (
      <div id="header" className={classnames(['header', 'clearfix', { 'no-menu': !isAdmin && !isSuperAdmin }])}>
        {/* SHOW MENU BUTTON FOR ADMIN */}
        {(isAdmin || isSuperAdmin) && (
          <button data-test="menu-button" className="float-left header-button" onClick={() => layoutStore.toggleMenu()}>
            <FontAwesome name="bars" />
          </button>
        )}

        <div className="logo float-left">
          <img src={elgLogo} alt="elg" />
        </div>

        <UserContentMenu
          show={this.userMenuIsOpen}
          toggleLogoutModal={this.toggleLogoutModal}
          onUserRoleChange={this.onUserRoleChange}
          onTenantChange={this.onTenantChange}
          changeUserMenuIsOpen={this.changeUserMenuIsOpen}
          history={this.props.history}
        />

        {tenantToDisplay && tenantToDisplay.name && (
          <div data-test="tenant-info" className="tenant-info">
            <div>Tenant: {tenantToDisplay.name}</div>
            <div>Region: {tenantToDisplay.regionCode}</div>
          </div>
        )}
        {/* MODAL CONFIRMATION FOR LOGOUT */}
        <ModalConfirmation
          callbackYes={this._logout}
          buttonYesColor="success"
          heading={`${t.HEADER_LABEL_LOGOUT}?`}
          isOpen={this.showLogoutModal}
          text={t.HEADER_MODAL_LOGOUT}
          toggleModal={this.toggleLogoutModal}
        />
        {/* MODAL CONFIRMATION FOR SWITCH ROLE */}
        <ModalConfirmation
          data-test="switch-role-confirmation"
          callbackYes={this._switchRole}
          callbackNo={() => this._setRoleToSwitch(null)}
          buttonYesColor="success"
          heading={`${t.HEADER_LABEL_SWITCH_ROLE}?`}
          // display modal only if we have role to switch
          isOpen={!!this.roleToSwitch}
          text={t.HEADER_MODAL_SWITCH_ROLE}
          toggleModal={this.toggleSwitchRoleModal}
        />
        {/* MODAL CONFIRMATION FOR SWITCH TENANT */}
        <ModalConfirmation
          data-test="switch-tenant-confirmation"
          callbackYes={this._switchTenant}
          callbackNo={() => this._setTenantIdToSwitch(null)}
          buttonYesColor="success"
          heading={`${t.HEADER_LABEL_SWITCH_TENANT}?`}
          isOpen={!!this.tenantIdToSwitch}
          text={t.HEADER_MODAL_SWITCH_TENANT}
          toggleModal={this.toggleSwitchTenantModal}
        />

        {!isWeightBridgeOperator && <QrCodeButtonComponent onQrCodeRead={this.onQrCodeRead} />}

        {/*(isAdmin || isTier1 || isTrader || isManager) && (*/}
        {/*<Link to="dashboard" className="dashboard-link">*/}
        {/*<FontAwesome name="line-chart" />*/}
        {/*</Link>*/}
        {/*)}*/}
      </div>
    );
  }

  private _logout = () => {
    this.props.sessionStore.logout();
    this.props.history.push('/auth/login');
  };

  private _switchRole = () => {
    const {
      sessionStore: { user },
      history,
    } = this.props;

    if (!!this.roleToSwitch) {
      storage.set(ACTIVE_USER_ROLE_NAME_STORAGE_KEY, this.roleToSwitch.name);
      user.setActiveRole(this.roleToSwitch);

      const redirect = this.roleToSwitch.name === UserRoles.Lab ? '/deliveries/lab' : '/deliveries';
      history.push(redirect);
      reloadPage();
    }
  };

  private _switchTenant = () => {
    const {
      sessionStore: { user },
      history,
    } = this.props;

    if (!!this.tenantIdToSwitch) {
      storage.set(ACTIVE_TENANT_ID_STORAGE_KEY, this.tenantIdToSwitch);
      user.setActiveTenant(user.getTenantById(this.tenantIdToSwitch));

      history.push('/admin/user-management');
      reloadPage();
    }
  };

  @action
  private _setShowLogoutModal(val: boolean) {
    this.showLogoutModal = val;
  }

  @action
  private _setUserMenuIsOpen(val: boolean) {
    this.userMenuIsOpen = val;
  }

  @action
  private _setRoleToSwitch(role: UserRoleModel) {
    this.roleToSwitch = role;
  }

  @action
  private _setTenantIdToSwitch(id: string) {
    this.tenantIdToSwitch = id;
  }
}
