import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

export default class UserConfigurationModel extends UpdateModel<UserConfigurationModel> {
  public id: string;
  @observable public numberOfDecimals: number = 0;
  @observable public stockCodeFormat: string = '';
  @observable public weighbridgeTolerance: number = 0;
  @observable public dateFormat: string = '';

  @action
  public update = (obj: UserConfigurationModel) => {
    this.updater.update(this, obj, UserConfigurationModel);
    return this;
  };

  @action
  public setStockCodeFormat(val: string) {
    this.stockCodeFormat = val;
  }

  @action
  public setWeighbridgeTolerance(val: string) {
    this.weighbridgeTolerance = parseInt(val, 10);
  }

  @action
  public setNumberOfDecimals(val: string) {
    this.numberOfDecimals = parseInt(val, 10);
  }

  @action
  public setDateFormat(val: string) {
    this.dateFormat = val;
  }
}
