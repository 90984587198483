import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import Sidebar from 'domain/layout/Sidebar';
import ModalConfirmation from 'domain/ModalConfirmation';
import TenantDetailComponent from 'pod/superAdmin/tenants/TenantDetailComponent';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import { Button } from 'reactstrap';
import TenantModel from 'models/TenantModel';
import UserModel from 'models/UserModel';
import TranslateService from 'services/TranslateService';
import * as H from 'history';
import UserTenantModel from 'models/UserTenantModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IQuery {
  id: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  history: H.History;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

interface IState {
  content: TenantModel;
  showDeleteConfirmationModal: boolean;
  workflowTenants: UserTenantModel[];
}

@inject('superAdminStore', 'translateService')
@observer
export default class TenantDetailRoute extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      content: null,
      showDeleteConfirmationModal: false,
      workflowTenants: [],
    };
  }

  public componentDidMount() {
    const {
      superAdminStore,
      match: {
        params: { id: tenantId },
      },
    } = this.props;

    superAdminStore.getWorkflows();

    this._setContent(tenantId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      match: {
        params: { id: newTenantId },
      },
    } = this.props;
    const {
      match: {
        params: { id: tenantId },
      },
    } = prevProps;

    if (tenantId !== newTenantId) {
      this._setContent(newTenantId);
    }
  }

  public updateTenantDetail = (tenant: TenantModel) => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;

    return superAdminStore.putTenant(tenant.constructSaveObj, tenant.id).then(() => {
      notification.success(t.SUPERADMIN_TENANTADMIN_UPDATE_MSG);
      this.props.history.push('/super-admin/tenants');
    });
  };

  public saveAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.putAdmin(user).then(() => {
      notification.success(this.props.translateService.t.SUPERADMIN_TENANTADMIN_UPDATE_MSG);
    });
  };

  public postAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.postAdmin(user).then((res) => {
      notification.success(this.props.translateService.t.SUPERADMIN_TENANTADMIN_ADD_MSG);
      this.state.content.pushAdmin(res);
    });
  };

  public hideAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.hideAdminById(this.state.content.admins, user.id).then(() => {
      notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_HIDE_MSG(user.fullName));
    });
  };

  public unhideAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.unhideAdminById(this.state.content.admins, user.id).then(() => {
      notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_UNHIDE_MSG(user.fullName));
    });
  };

  public render() {
    const { content, showDeleteConfirmationModal, workflowTenants } = this.state;
    const {
      superAdminStore: { workflowList },
      translateService: { t },
    } = this.props;

    if (!content) {
      return <div />;
    }

    return (
      <Sidebar heading={t.SUPERADMIN_TENANTDETAIL_HEADER} close="/super-admin/tenants">
        <TenantDetailComponent
          saveAction={this.updateTenantDetail}
          saveAdmin={this.saveAdmin}
          postAdmin={this.postAdmin}
          hideAdmin={this.hideAdmin}
          unhideAdmin={this.unhideAdmin}
          tenant={content}
          workflowList={workflowList}
          workflowTenants={workflowTenants}
        />

        <Button
          data-test="delete-btn"
          color="danger"
          className="button-move-up"
          onClick={this._toggleDeleteConfirmationModal}
        >
          {t.GLOBAL_LABEL_DELETE}
        </Button>

        {content && showDeleteConfirmationModal && (
          <ModalConfirmation
            isOpen={showDeleteConfirmationModal}
            toggleModal={this._toggleDeleteConfirmationModal}
            heading={t.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER}
            text={t.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT(content.name)}
            callbackYes={this._deleteTenant}
          />
        )}
      </Sidebar>
    );
  }

  private _setContent = async (tenantId: string) => {
    const { superAdminStore } = this.props;
    const content = await superAdminStore.getTenantById(tenantId);
    const workflowTenants = await superAdminStore.getTenantsForTheSameWorkflow(content);
    this.setState({ content, workflowTenants });
  };

  private _toggleDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmationModal: !this.state.showDeleteConfirmationModal,
    });
  };

  private _deleteTenant = () => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    const { content } = this.state;
    superAdminStore.deleteTenantById(content.id).then(() => {
      notification.success(t.SUPERADMIN_TENANTDETAIL_DELETE_MSG);
      this.props.history.push('/super-admin/tenants');
    });
  };
}
