import { action, computed, observable, reaction, toJS } from 'mobx';
import {
  ALL,
  CREATED_ASC,
  CREATED_DESC,
  DATE_ASC,
  DATE_DESC,
  GRN_DESC,
  IN_LAB,
  IN_LAB_VALUE,
  IN_PROGRESS,
  IN_WORK,
  READY_FOR_SIGN_OFF_VALUE,
  READY_FOR_SIGNOFF,
  READY_FOR_TIER_2,
  READY_FOR_TIER_2_VALUE,
  SIGNED_OFF,
  SIGNED_OFF_VALUE,
  SORT_BY_QP_KEY,
} from 'util/constants';
import RootService from 'services/RootService';
import RootStore from 'stores/RootStore';
import FilterAbstractStore from 'stores/FilterAbstractStore';
import DeliveryCommonFilterStore, {
  ICommonFiltersApplied,
  ICommonQueryParams,
} from 'pod/deliveries/DeliveryCommonFilterStore';
import TenantFilterStore, { ITenantFilters, ITenantFilterStore, ITenantQueryParams } from 'stores/TenantFilterStore';
import { TDepartmentCodes } from 'util/enums';
import storage from 'util/storage';

export type FilterByStatus =
  | typeof ALL
  | typeof IN_PROGRESS
  | typeof READY_FOR_TIER_2
  | typeof READY_FOR_SIGNOFF
  | typeof IN_LAB
  | typeof SIGNED_OFF;
export type FilterByStatusValue =
  | ''
  | typeof IN_WORK
  | typeof READY_FOR_TIER_2_VALUE
  | typeof READY_FOR_SIGN_OFF_VALUE
  | typeof IN_LAB_VALUE
  | typeof SIGNED_OFF_VALUE;
export type SortByQp = typeof CREATED_ASC | typeof CREATED_DESC | typeof GRN_DESC;
type SortByValue = typeof DATE_DESC | typeof DATE_ASC;

interface IDeliveryFilters extends ICommonFiltersApplied, ITenantFilters {
  filterByStatusValue: boolean;
  filterByStatus: boolean;
  sortByQp: boolean;
  sortByValue: boolean;
  filterByGrnValue: boolean;
  supplierId: boolean;
  locationId: boolean;
  traderId: boolean;
  departmentCodes: boolean;
}

interface IDeliveryQueryParams extends ICommonQueryParams, ITenantQueryParams {
  grn: string;
  sort: string;
  status: string;
  supplierId: string;
  locationId: string;
  traderId: string;
  departmentCodes: TDepartmentCodes[];
}

export default class DeliveryFiltersStore extends FilterAbstractStore<IDeliveryFilters, IDeliveryQueryParams>
  implements ITenantFilterStore {
  public deliveryCommonFilterStore: DeliveryCommonFilterStore;

  public tenantFilterStore: TenantFilterStore;

  public constructor(private readonly rootService: RootService, private readonly rootStore: RootStore) {
    super();

    this.deliveryCommonFilterStore = new DeliveryCommonFilterStore(this.rootService);
    this.tenantFilterStore = new TenantFilterStore(this.rootService, this);

    reaction(
      () => this.hasFiltersApplied,
      () => {
        this.deliveryCommonFilterStore.changeOffset(0);
        this.deliveryCommonFilterStore.setShouldScrollToTop(true);
      }
    );

    reaction(
      () => this.sortByQp,
      () => {
        this.deliveryCommonFilterStore.changeOffset(0);
        this.deliveryCommonFilterStore.setShouldScrollToTop(true);
      }
    );
  }

  public onTenantChange = (tenantId: string) => {
    this.rootStore.commonStore.getCommon(tenantId);
    this.supplierId = '';
    this.locationId = '';
  };

  @observable
  public filterByGrnValue: string = '';

  @observable
  public filterByStatus: FilterByStatus = ALL;

  @observable
  public filterByStatusValue: FilterByStatusValue = '';

  @observable
  public sortByQp: SortByQp = (storage.get(SORT_BY_QP_KEY) as SortByQp) ?? CREATED_DESC;

  @observable
  public sortByValue: SortByValue = DATE_DESC;

  @observable
  public supplierId: string = '';

  @observable
  public locationId: string = '';

  @observable
  public traderId: string = '';

  @observable
  public departmentCode: TDepartmentCodes[] = [];

  @computed
  public get getFiltersApplied(): IDeliveryFilters {
    return {
      filterByStatusValue: this.filterByStatusValue !== '',
      filterByStatus: this.filterByStatus !== ALL,
      filterByGrnValue: this.filterByGrnValue !== '',
      sortByQp: this.sortByQp !== CREATED_DESC,
      sortByValue: this.sortByValue !== DATE_DESC,
      locationId: this.locationId !== '',
      traderId: this.traderId !== '',
      supplierId: this.supplierId !== '',
      departmentCodes: !!this.departmentCode.length,
      ...this.deliveryCommonFilterStore.getFiltersApplied,
      ...this.tenantFilterStore.getFiltersApplied,
    };
  }

  @computed
  public get getQueryParams(): IDeliveryQueryParams {
    return {
      grn: this.filterByGrnValue,
      sort: this.sortByQp,
      status: this.filterByStatusValue,
      supplierId: this.supplierId,
      locationId: this.locationId,
      traderId: this.traderId,
      departmentCodes: this.departmentCode.length ? toJS(this.departmentCode) : null,
      ...this.deliveryCommonFilterStore.getQueryParams,
      ...this.tenantFilterStore.getQueryParams,
    };
  }

  @computed
  public get isSortingGrnDesc(): boolean {
    return this.sortByQp === GRN_DESC;
  }

  @computed
  public get isSortingCreatedDesc(): boolean {
    return this.sortByQp === CREATED_DESC;
  }

  @action
  public changeFilterByGrnValue = (newValue: string) => (this.filterByGrnValue = newValue);

  @action
  public changeFilterByStatus = (newValue: FilterByStatus) => (this.filterByStatus = newValue);

  @action
  public changeFilterByStatusValue = (newValue: FilterByStatusValue) => (this.filterByStatusValue = newValue);

  @action
  public changeSortByQp = (newValue: SortByQp = CREATED_DESC) => {
    this.sortByQp = newValue;
    storage.set(SORT_BY_QP_KEY, newValue);
  };

  @action
  public changeSortByValue = (newValue: SortByValue) => (this.sortByValue = newValue);

  @action
  public changeSupplier = (newValue: string) => (this.supplierId = newValue);

  @action
  public changeLocation = (newValue: string) => (this.locationId = newValue);

  @action
  public changeTrader = (newValue: string) => (this.traderId = newValue);

  @action
  public changeDepartment = (newValue: TDepartmentCodes[]) => (this.departmentCode = newValue);
}
