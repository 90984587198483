import { action, observable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import { ICode } from 'models/ModelInterfaces';

export default class IdCodeNameModel extends IdNameModel implements ICode {
  @observable code: string = '';

  @action
  public changeCode = (newCode: string) => {
    this.code = newCode;
  };
}
