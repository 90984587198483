import * as React from 'react';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import { action, observable } from 'mobx';
import IdCodeActiveModel from 'models/IdCodeActiveModel';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { ORIGIN_CODE_MAX_LENGTH_DEFAULT } from 'util/constants';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

@inject('adminStore', 'translateService')
@observer
export default class OriginDetailRoute extends React.Component<IProps> {
  @observable public content: IdCodeActiveModel = null;

  @action
  public updateContent = (ewc: IdCodeActiveModel) => {
    this.content = new IdCodeActiveModel().update(ewc);
  };

  public render() {
    const {
      translateService: { t },
      adminStore: { originApi, originList },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.ORIGIN}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_ORIGIN}
        itemsList={originList}
        api={originApi}
        updateItem={this.updateContent}
        limit={ORIGIN_CODE_MAX_LENGTH_DEFAULT}
      />
    );
  }
}
