import FileModel from 'models/FileModel';
import SupplierModel from 'models/SupplierModel';
import UpdateModel from 'models/UpdateModel';
import { action, observable } from 'mobx';
import UserModel from 'models/UserModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class AdvisedGoodDeliveryModel extends UpdateModel<AdvisedGoodDeliveryModel> {
  @observable public attachments?: FileModel[] = [];
  @observable public containerNumber?: string = '';
  @observable public deliveryGrossWeight?: number = null;
  @observable public deliveryTareWeight?: number = null;
  @observable public driverName?: string = '';
  @observable public grn?: string = '';
  @observable public id?: string = '';
  @observable public supplier?: SupplierModel;
  @observable public supplierCity?: string = '';
  @observable public trader?: UserModel<IUserModelConstructObj>;
  @observable public deliveryNote?: string = '';
  @observable public deliveryType?: string = '';
  @observable public weatherCondition?: string = '';
  @observable public advisedGoodsCount?: number = null;
  @observable public transactionType?: string = '';

  @action
  public update = (obj: AdvisedGoodDeliveryModel) => {
    const newAdvisedGoodDeliveryModel = cloneObj(obj);

    if (newAdvisedGoodDeliveryModel) {
      newAdvisedGoodDeliveryModel.attachments = newAdvisedGoodDeliveryModel.attachments.map((r: FileModel) =>
        new FileModel().update(r)
      );

      if (newAdvisedGoodDeliveryModel.supplier) {
        newAdvisedGoodDeliveryModel.supplier = new SupplierModel().update(newAdvisedGoodDeliveryModel.supplier);
      }
      if (newAdvisedGoodDeliveryModel.trader) {
        newAdvisedGoodDeliveryModel.trader = new UserModel<IUserModelConstructObj>().update(
          newAdvisedGoodDeliveryModel.trader
        );
      }
    }

    this.updater.update(this, newAdvisedGoodDeliveryModel, AdvisedGoodDeliveryModel);
    return this;
  };
}
