import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Form } from 'reactstrap';

import TranslateService from 'services/TranslateService';
import { AdminRootUrl } from 'util/enums';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetPrinterConfigurationModel from 'models/LasernetPrinterConfigurationModel';
import Sidebar from 'domain/layout/Sidebar';
import { instance as notification } from 'util/notification';
import LasernetPrinterConfigurationDetailComponent from 'pod/admin/lasernetPrinters/printerConfigurations/LasernetPrinterConfigurationDetailComponent';
import ModalConfirmation from 'domain/ModalConfirmation';

interface IQuery {
  id: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  lasernetPrinterStore?: LasernetPrinterStore;
  translateService?: TranslateService;
}

@inject('lasernetPrinterStore', 'translateService')
@observer
class LasernetPrinterConfigurationDetailRoute extends React.Component<IProps> {
  @observable
  public content: LasernetPrinterConfigurationModel = null;
  @observable
  public _showDeleteConfirmationModal: boolean = false;

  public componentDidMount() {
    const {
      match: {
        params: { id: typeId },
      },
    } = this.props;
    this._setContent(typeId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      match: {
        params: { id: newItem },
      },
    } = this.props;
    const {
      match: {
        params: { id: prevItem },
      },
    } = prevProps;

    if (newItem !== prevItem) {
      this._setContent(newItem);
    }
  }

  public render() {
    const {
      translateService: { t },
    } = this.props;

    return (
      <Sidebar
        heading={t.ADMIN_LASERNET_CONFIGURATION_DETAILS}
        close={AdminRootUrl.LASERNET_PRINTER_CONFIGURATIONS}
        ico="print"
      >
        <Form onSubmit={this._submitForm}>
          <LasernetPrinterConfigurationDetailComponent content={this.content} />
          <div className="clearfix">
            <Button
              onClick={this._toggleShowDeleteConfirmationModal}
              type="button"
              className="pull-left"
              color="danger"
              data-test="delete-btn"
            >
              {t.GLOBAL_LABEL_DELETE}
            </Button>
            <Button type="submit" className="pull-right" color="success" data-test="save-btn">
              {t.GLOBAL_LABEL_SAVE}
            </Button>
          </div>
        </Form>

        <ModalConfirmation
          callbackYes={this._deleteConfiguration}
          heading={t.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER}
          isOpen={this._showDeleteConfirmationModal}
          text={t.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT}
          toggleModal={this._toggleShowDeleteConfirmationModal}
        />
      </Sidebar>
    );
  }

  @action
  private _updateContent = (newConfiguration: LasernetPrinterConfigurationModel) => {
    this.content = new LasernetPrinterConfigurationModel().update(newConfiguration);
  };

  @action
  private _toggleShowDeleteConfirmationModal = () => {
    this._showDeleteConfirmationModal = !this._showDeleteConfirmationModal;
  };

  private _setContent = (itemId: string) => {
    this.props.lasernetPrinterStore.getConfigurationById(itemId).then(this._updateContent);
  };

  private _submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      translateService: { t },
      lasernetPrinterStore,
      history,
    } = this.props;
    return lasernetPrinterStore.updateConfiguration(this.content).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_UPDATE_MSG(t.USA_CONFIGURATION_CONFIGURATION.toLowerCase()));
      history.push(AdminRootUrl.LASERNET_PRINTER_CONFIGURATIONS);
    });
  };

  private _deleteConfiguration = () => {
    const {
      translateService: { t },
      lasernetPrinterStore,
      history,
    } = this.props;
    lasernetPrinterStore.deleteConfiguration(this.content).then(() => {
      notification.success(t.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE);
      history.push(AdminRootUrl.LASERNET_PRINTER_CONFIGURATIONS);
    });
  };
}

export default withRouter(LasernetPrinterConfigurationDetailRoute);
