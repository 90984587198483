import { action, observable } from 'mobx';
import IdNameModel from 'models/IdNameModel';

export default class UserRoleModel extends IdNameModel {
  @observable public isDefault: boolean = false;

  @action
  public update(obj: UserRoleModel) {
    this.updater.update(this, obj, UserRoleModel);
    return this;
  }

  @action
  public changeIsDefault = (val: boolean) => (this.isDefault = val);
}
