import { computed } from 'mobx';
import UserModel from 'models/UserModel';
import { IAppUserModelConstructObj } from 'models/ModelInterfaces';

export default class AppUserModel extends UserModel<IAppUserModelConstructObj> {
  @computed
  public get constructSaveObj(): IAppUserModelConstructObj {
    return {
      clientId: this.username,
      clientSecret: this.password,
      title: this.firstname,
      description: this.lastname,
      active: this.active,
    };
  }
}
