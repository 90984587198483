import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Label } from 'reactstrap';

import TranslateService from 'services/TranslateService';
import { AdminRootUrl } from 'util/enums';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetDocumentTypeModel from 'models/LasernetDocumentTypeModel';
import Sidebar from 'domain/layout/Sidebar';
import { instance as notification } from 'util/notification';
import InputWithNullCheck from 'components/InputWithNullCheck';
import InputNumber from 'components/InputNumber';

interface IQuery {
  id: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  lasernetPrinterStore?: LasernetPrinterStore;
  translateService?: TranslateService;
}

@inject('lasernetPrinterStore', 'translateService')
@observer
class LasernetDocumentTypeDetailRoute extends React.Component<IProps> {
  @observable
  public content: LasernetDocumentTypeModel = new LasernetDocumentTypeModel();

  public componentDidMount() {
    const {
      match: {
        params: { id: typeId },
      },
    } = this.props;
    this._setContent(typeId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      match: {
        params: { id: newItem },
      },
    } = this.props;
    const {
      match: {
        params: { id: prevItem },
      },
    } = prevProps;

    if (newItem !== prevItem) {
      this._setContent(newItem);
    }
  }

  public render() {
    const {
      translateService: { t },
    } = this.props;

    return (
      <Sidebar heading={t.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL} close={AdminRootUrl.LASERNET_DOCUMENT_TYPES} ico="print">
        <Form onSubmit={this._submitForm}>
          <FormGroup row>
            <Label for="report-name" sm={4}>
              {t.ADMIN_LASERNET_REPORT_NAME}
            </Label>
            <Col sm={8}>
              <InputWithNullCheck
                data-test="report-name"
                value={this.content.lasernetReportName}
                name="report-name"
                id="report-name"
                maxLength={255}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.content.changeReportName(e.target.value)}
                type="text"
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="num-of-copies" sm={4}>
              {t.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES}
            </Label>
            <Col sm={8}>
              <InputNumber
                data-test="num-of-copies"
                value={this.content.defaultNumberOfCopies}
                name="num-of-copies"
                id="num-of-copies"
                onChangeValue={(val: number) => this.content.changeNumberOfCopies(val)}
                type="number"
                allowNegativeValue={false}
                decimalPlaces={0}
              />
            </Col>
          </FormGroup>
          <div className="clearfix">
            <Button type="submit" className="pull-right" color="success" data-test="save-btn">
              {t.GLOBAL_LABEL_SAVE}
            </Button>
          </div>
        </Form>
      </Sidebar>
    );
  }

  @action
  private _updateContent = (newType: LasernetDocumentTypeModel) => {
    this.content = new LasernetDocumentTypeModel().update(newType);
  };

  private _setContent = (itemId: string) => {
    this.props.lasernetPrinterStore.fetchDocumentTypeById(itemId).then(this._updateContent);
  };

  private _submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      translateService: { t },
      lasernetPrinterStore,
    } = this.props;
    return lasernetPrinterStore.putDocumentType(this.content).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_UPDATE_MSG(t.ADMIN_LASERNET_DOCUMENT_TYPE));
      this.props.history.push(AdminRootUrl.LASERNET_DOCUMENT_TYPES);
    });
  };
}

export default withRouter(LasernetDocumentTypeDetailRoute);
