import * as React from 'react';

import jsQR from 'jsqr';
import { instance as notification } from 'util/notification';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import { isMobile } from 'util/helpers';
import { logger } from 'util/logger';
import reactNative from 'util/react-native';
import { QRCODE_ERROR } from 'util/constants';
import { ReactNativeActionType } from 'util/enums';

interface IProps {
  onQrCodeRead: (value: string) => void;
  closeModal: () => void;
  translateService?: TranslateService;
}

interface IState {
  showJavascriptQRCodeScanner: boolean;
}

@inject('translateService')
@observer
export default class QrCodeReaderComponent extends React.Component<IProps, IState> {
  private _offscreenCanvas: HTMLCanvasElement = null;
  private _qrCodeInterval: number = null;
  private _videoPreview: HTMLVideoElement = null;
  private _offscreenCanvasContext: CanvasRenderingContext2D = null;

  public constructor(props: IProps) {
    super(props);
    this.state = { showJavascriptQRCodeScanner: false };
  }

  public componentDidMount() {
    if (!this._tryNativeQRCodeScanner()) {
      this.setState({ showJavascriptQRCodeScanner: true }, () => {
        requestAnimationFrame(() => {
          this._startJavascriptQRCodeScanner();
        });
      });
    } else {
      this.props.closeModal();
    }
  }

  public componentWillUnmount() {
    if (this._videoPreview != null) {
      const srcObject = this._videoPreview.srcObject as MediaStream;
      if (srcObject != null) {
        srcObject.getTracks().forEach((track) => track.stop());
      }

      this._videoPreview.srcObject = null;
      this._videoPreview = null;
    }

    if (this._qrCodeInterval != null) {
      clearTimeout(this._qrCodeInterval);
    }

    this._offscreenCanvas = null;
    this._offscreenCanvasContext = null;
  }

  public render() {
    return this.state.showJavascriptQRCodeScanner ? (
      <video className="qr-code-video" ref={(r) => (this._videoPreview = r)}></video>
    ) : (
      <div />
    );
  }

  private _tryNativeQRCodeScanner(): boolean {
    // Code for native Android App
    window.qrCodeCallback = (qrCode: string) => {
      if (qrCode !== null) {
        this.props.onQrCodeRead(qrCode);
      } else {
        logger.error(QRCODE_ERROR, 'qr code is null');
      }
    };

    // Code for native Android App
    if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.readScale) {
      try {
        JSInterface.startQRScanner('qrCodeCallback');
        return true;
      } catch (err) {
        logger.error(QRCODE_ERROR, err);
        notification.error(this.props.translateService.t.HEADER_QRCODE_ERROR_MSG);
      }
      // Code for React Native App
    } else if (isMobile()) {
      window.onQrNativeResult = this.props.onQrCodeRead;

      try {
        reactNative.send({
          command: ReactNativeActionType.SHOW_QR,
        });

        return true;
      } catch (err) {
        logger.error(QRCODE_ERROR, err);
        notification.error(this.props.translateService.t.HEADER_QRCODE_ERROR_MSG);
      }
    }

    return false;
  }

  private _startJavascriptQRCodeScanner() {
    const { _videoPreview } = this;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this._getCameras().then((cameras) => {
        const [frontCamera, rearCamera] = cameras;

        const camera = rearCamera || frontCamera;
        if (!camera) {
          notification.error('No camera found');
          return;
        }
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: rearCamera ? 'environment' : 'user',
              deviceId: camera.deviceId,
            },
          })
          .then(this._onMediaSuccess)
          .catch(this._onMediaFailure);

        if (!rearCamera) {
          _videoPreview.style.webkitTransform = 'scaleX(-1)';
          _videoPreview.style.transform = 'scaleX(-1)';
          _videoPreview.style.filter = 'FlipH';
        } else {
          _videoPreview.style.webkitTransform = null;
          _videoPreview.style.transform = null;
          _videoPreview.style.filter = null;
        }
      });
    }
  }

  private async _getCameras() {
    return (await navigator.mediaDevices.enumerateDevices()).filter((d) => d.kind === 'videoinput');
  }

  private _onMediaSuccess = (stream: MediaStream) => {
    this._videoPreview.srcObject = stream;
    this._videoPreview.onloadedmetadata = () => {
      this._videoPreview.play();

      this._offscreenCanvas = document.createElement('canvas');
      this._offscreenCanvas.width = this._videoPreview.width - 2;
      this._offscreenCanvas.height = this._videoPreview.height - 2;
      this._offscreenCanvasContext = this._offscreenCanvas.getContext('2d');

      this._startQrCodeReader();
    };
  };

  private _startQrCodeReader = () => {
    this._qrCodeInterval = window.setTimeout(this._readImage, 500);
  };

  private _readImage = () => {
    this._offscreenCanvasContext.drawImage(
      this._videoPreview,
      0,
      0,
      this._offscreenCanvas.width,
      this._offscreenCanvas.height
    );

    try {
      const imageData = this._offscreenCanvasContext.getImageData(
        0,
        0,
        this._offscreenCanvas.width,
        this._offscreenCanvas.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code && code.data) {
        this.props.onQrCodeRead(code.data);
      }
    } catch (error) {
      logger.error('QRCODE - Error while reading qr code', error);
    }

    this._startQrCodeReader();
  };

  private _onMediaFailure = (ex: Error) => {
    logger.error('QRCODE - Error while accessing media stream', ex);
    notification.error(this.props.translateService.t.HEADER_QRCODE_ERROR_MSG);
  };
}
