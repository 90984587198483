import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { inject } from 'mobx-react';
import SessionStore from 'stores/SessionStore';

interface IProps {
  path: string;
  component: React.ComponentType;
  sessionStore?: SessionStore;
}

interface IState {
  authenticated: boolean;
  isSessionRestored: boolean;
}

@inject('sessionStore')
class AuthRoute extends React.Component<IProps, IState> {
  public state = {
    authenticated: false,
    isSessionRestored: false,
  };

  public componentDidMount() {
    this.authenticate();
  }

  public authenticate() {
    return this.props.sessionStore
      .restoreSession()
      .then(() => this.setState({ authenticated: true, isSessionRestored: true }))
      .catch(() => this.setState({ authenticated: false, isSessionRestored: true }));
  }

  public render() {
    const { authenticated, isSessionRestored } = this.state;
    const { component } = this.props;

    if (!isSessionRestored) {
      return <div />;
    }

    return authenticated ? <Route {...this.props} component={component} /> : <Redirect to={'/auth/login'} />;
  }
}

export default AuthRoute;
