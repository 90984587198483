import { action, observable, computed } from 'mobx';
import RootStore from 'stores/RootStore';
import TenantModel from 'models/TenantModel';
import WorkflowModel from 'models/WorkflowModel';
import UserConfigurationModel from 'models/UserConfigurationModel';
import { CountryCode } from 'util/enums';
import { DATE_FORMAT_DEFAULT } from 'util/constants';

export default class ViewStore {
  @observable public tenant: TenantModel = null;

  public constructor(private readonly rootStore: RootStore) {}

  @action
  public setTenant(tenant: TenantModel) {
    this.tenant = tenant;
  }

  @computed
  public get activeTenant(): TenantModel {
    return this.tenant || this.rootStore.sessionStore.tenant;
  }

  @computed
  public get countryCode(): CountryCode {
    return this._activeWorkflow && this.activeTenant.workflow.code;
  }

  @computed
  public get isUK() {
    return this._activeWorkflow.isUK;
  }

  @computed
  public get isUS() {
    return this._activeWorkflow.isUS;
  }

  @computed
  public get isDE() {
    return this._activeWorkflow.isDE;
  }

  @computed
  public get isDE_D365() {
    return this._activeWorkflow.isDE_D365;
  }

  @computed
  public get isIT() {
    return this._activeWorkflow.isIT;
  }

  @computed
  public get isFR() {
    return this._activeWorkflow.isFR;
  }

  @computed
  public get isAdminFromUS() {
    return this.isUS && this.rootStore.sessionStore.isAdmin;
  }

  @computed
  public get isAdminFromFR() {
    return this.isFR && this.rootStore.sessionStore.isAdmin;
  }

  @computed
  public get isAdminFromDE() {
    return this.isDE && this.rootStore.sessionStore.isAdmin;
  }

  @computed
  public get isAdminFromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isAdmin;
  }

  @computed
  public get isLabFromDE() {
    return this.isDE && this.rootStore.sessionStore.isLab;
  }

  @computed
  public get isLabFromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isLab;
  }

  @computed
  public get isLabFromUS() {
    return this.isUS && this.rootStore.sessionStore.isLab;
  }

  @computed
  public get isTier2FromDE() {
    return this.isDE && this.rootStore.sessionStore.isTier2;
  }

  @computed
  public get isTier2FromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isTier2;
  }

  @computed
  public get isTier2FromUS() {
    return this.isUS && this.rootStore.sessionStore.isTier2;
  }

  @computed
  public get isTier1FromDE() {
    return this.isDE && this.rootStore.sessionStore.isTier1;
  }

  @computed
  public get isTier1FromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isTier1;
  }

  @computed
  public get isTraderFromIT() {
    return this.isIT && this.rootStore.sessionStore.isTrader;
  }

  @computed
  public get isTraderFromFR() {
    return this.isFR && this.rootStore.sessionStore.isTrader;
  }

  @computed
  public get isTier3FromIT() {
    return this.isIT && this.rootStore.sessionStore.isTier3;
  }

  @computed
  public get isTier2FromIT() {
    return this.isIT && this.rootStore.sessionStore.isTier2;
  }

  @computed
  public get isTier1FromIT() {
    return this.isIT && this.rootStore.sessionStore.isTier1;
  }

  @computed
  public get isTier3FromFR() {
    return this.isFR && this.rootStore.sessionStore.isTier3;
  }

  @computed
  public get isTier2FromFR() {
    return this.isFR && this.rootStore.sessionStore.isTier2;
  }

  @computed
  public get isTier1FromFR() {
    return this.isFR && this.rootStore.sessionStore.isTier1;
  }

  @computed
  public get numberOfDecimals(): number {
    return this._activeConfiguration ? this._activeConfiguration.numberOfDecimals : 0;
  }

  @computed
  public get dateFormat(): string {
    return this._activeConfiguration ? this._activeConfiguration.dateFormat : DATE_FORMAT_DEFAULT;
  }

  @computed
  public get stockCodeFormat(): string {
    return this._activeConfiguration ? this._activeConfiguration.stockCodeFormat : '';
  }

  @computed
  public get weighbridgeTolerance(): number {
    return this._activeConfiguration ? this._activeConfiguration.weighbridgeTolerance : 0;
  }

  @computed
  public get timezone(): string {
    return this.activeTenant && this.activeTenant.timezone;
  }

  @computed
  public get hasAgSignOff(): boolean {
    return this.activeTenant?.agSignOffEnabled;
  }

  @computed
  public get hasOverwriteRgDescriptionDialogEnabled(): boolean {
    return this.activeTenant.overwriteRgDescriptionDialogEnabled;
  }

  @computed
  public get rgClassificationsSectionRequired(): boolean {
    return this.activeTenant?.rgClassificationsSectionRequired;
  }

  @computed
  public get rgContaminationsSectionRequired(): boolean {
    return this.activeTenant?.rgContaminationsSectionRequired;
  }

  @computed
  private get _activeConfiguration(): UserConfigurationModel {
    return this._activeWorkflow && this._activeWorkflow.configuration;
  }

  @computed
  private get _activeWorkflow(): WorkflowModel {
    return this.activeTenant && this.activeTenant.workflow;
  }
}
