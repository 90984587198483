import { action, computed, observable } from 'mobx';

import UpdateModel from 'models/UpdateModel';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { cloneObj } from 'util/helpers';

export default class CertificateModel extends UpdateModel<CertificateModel> {
  @observable public expirationDate?: Moment = null;

  @computed
  public get isCertificateExpired(): boolean {
    return this.expirationDate?.isBefore() && !moment().isSame(this.expirationDate, 'day');
  }

  @computed
  public get hasNotCertificate(): boolean {
    return !this.expirationDate;
  }

  @computed
  public get formattedExpirationDate(): string {
    return this.expirationDate && this.expirationDate.toISOString();
  }

  @action
  public update(obj: CertificateModel) {
    const newCertificateModel = cloneObj(obj);

    if (newCertificateModel && newCertificateModel.expirationDate) {
      newCertificateModel.expirationDate = moment(newCertificateModel.expirationDate);
    }

    this.updater.update(this, newCertificateModel, CertificateModel);
    return this;
  }

  @action public changeExpirationDate = (newDate: Moment): void => {
    this.expirationDate = newDate;
  };
}
