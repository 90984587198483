import * as React from 'react';
import * as H from 'history';
import { Container } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';

import DeliveryDetailComponent from 'pod/deliveries/DeliveryDetailComponent';
import DeliveryModel from 'models/DeliveryModel';
import DeliveryStore from 'pod/deliveries/DeliveryStore';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import SessionStore from 'stores/SessionStore';
import AjaxService from 'services/AjaxService';
import FileModel from 'models/FileModel';
import { SocketServiceTopic, Urls } from 'util/enums';
import SocketService from 'services/SocketService';
import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import DeliveryAdvisedGoodModel from 'models/DeliveryAdvisedGoodModel';
import ViewStore from 'stores/ViewStore';
import IdNameModel from 'models/IdNameModel';

interface IQuery {
  id: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  history: H.History;
  commonStore?: CommonStore;
  deliveryStore?: DeliveryStore;
  translateService?: TranslateService;
  ajaxService?: AjaxService;
  sessionStore?: SessionStore;
  socketService?: SocketService;
  viewStore?: ViewStore;
}

interface IState {
  content: DeliveryModel;
}

@inject('deliveryStore', 'translateService', 'commonStore', 'sessionStore', 'ajaxService', 'socketService', 'viewStore')
@observer
export default class DeliveryEditRoute extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      content: null,
    };
  }

  public componentDidMount() {
    const {
      deliveryStore,
      commonStore,
      match: { params },
      socketService,
    } = this.props;

    if (!commonStore.isDataLoaded) {
      commonStore.getCommon();
    }

    deliveryStore.getDeliveryById(params.id).then((res: DeliveryModel) => {
      this.setState({ content: new DeliveryModel().update(res) });
    });

    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_CLAIMED, this._onSocketAdvisedGoodUpdate);
  }

  public componentWillUnmount(): void {
    const { socketService } = this.props;
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_CLAIMED, this._onSocketAdvisedGoodUpdate);
  }

  public editDelivery = async (delivery: DeliveryModel, attachments?: FormData, radiationFiles?: FormData) => {
    await this._uploadAllAttachments(delivery, attachments, radiationFiles);
    const newDelivery = await this.props.deliveryStore.putDelivery(delivery.constructSaveObj());

    if (newDelivery) {
      this._editDeliverySuccess();
    }
  };

  public editAttachments = async (delivery: DeliveryModel, attachments?: FormData, radiationFiles?: FormData) => {
    await this._uploadAllAttachments(delivery, attachments, radiationFiles);

    const newDelivery = await this.props.deliveryStore.putAttachments(
      delivery.id,
      delivery.constructSaveAttachmentsObj()
    );

    if (newDelivery) {
      this._editAttachmentsSuccess();
    }
  };

  public syncChanges = (deliveryId: string): Promise<void> => {
    return this.props.deliveryStore.syncDelivery(deliveryId);
  };

  public printChanges = (deliveryId: string): void => {
    return this.props.deliveryStore.printDelivery(deliveryId);
  };

  public exportChanges = (deliveryId: string): void => {
    return this.props.deliveryStore.exportDeliveryToCSV(deliveryId);
  };

  // REMOVE ATTACHMENT
  public removeAttachment = (attachmentId: string) => {
    const { content } = this.state;
    content.removeAttachment(attachmentId);
    notification.success(this.props.translateService.t.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG);
  };

  public render() {
    const { content } = this.state;

    return (
      <Container fluid>
        {content && this._isCommonReady && (
          <DeliveryDetailComponent
            content={content}
            history={this.props.history}
            removeAttachment={this.removeAttachment}
            saveAction={this.editDelivery}
            syncAction={this.syncChanges}
            printAction={this.printChanges}
            exportAction={this.exportChanges}
            saveAttachmentsAction={this.editAttachments}
            hasPendingRequests={this.props.ajaxService.hasPendingRequests}
            deleteDelivery={this._deleteDelivery}
          />
        )}
      </Container>
    );
  }

  private get _isCommonReady(): boolean {
    const {
      commonStore: { common },
    } = this.props;
    return !!(
      common.roadHauliers.length &&
      common.stockItems.length &&
      common.suppliers.length &&
      common.yardLocations.length
    );
  }

  private _uploadRadiationFiles = (newDeliveryId: string, radiationFiles: FormData): Promise<FileModel[] | void> => {
    const {
      translateService: { t },
    } = this.props;

    return radiationFiles
      ? this.props.deliveryStore.uploadRadiationFiles(newDeliveryId, radiationFiles).catch(() => {
          notification.error(t.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR);
        })
      : null;
  };

  private _uploadAttachments = (deliveryId: string, attachments: FormData): Promise<FileModel[] | void> => {
    const {
      translateService: { t },
      deliveryStore,
    } = this.props;

    return attachments
      ? deliveryStore.uploadAttachments(deliveryId, attachments).catch(() => {
          notification.error(t.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG);
        })
      : null;
  };

  private _editDeliverySuccess = () => {
    notification.success(this.props.translateService.t.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG);
    this.props.history.push(Urls.DELIVERIES);
  };

  private _editAttachmentsSuccess = () => {
    notification.success(this.props.translateService.t.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG);
    this.props.history.push(Urls.DELIVERIES);
  };

  private _onSocketAdvisedGoodUpdate = (data: DeliveryListAdvisedGoodModel) => {
    this.state.content.advisedGoods.forEach((ag: DeliveryAdvisedGoodModel) => {
      if (ag.id === data.id) {
        ag.setClaimBy(data.claimedBy.user);
        if (this.props.viewStore.isIT) {
          ag.setResponsible({ id: ag.claimedBy.id, name: ag.claimedBy.fullNameWithShortName } as IdNameModel);
        }
        notification.success(
          this.props.translateService.t.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG(this.state.content.grn)
        );
      }
    });
  };

  private _uploadAllAttachments = async (
    delivery: DeliveryModel,
    attachments?: FormData,
    radiationFiles?: FormData
  ): Promise<void> => {
    const newAttachments = await this._uploadAttachments(delivery.id, attachments);
    delivery.changeAttachments(newAttachments);
    const newRadiationFiles = await this._uploadRadiationFiles(delivery.id, radiationFiles);
    delivery.changeAttachments(newRadiationFiles);
  };

  private _deleteDelivery = async (delivery: DeliveryModel) => {
    const {
      deliveryStore,
      translateService: { t },
    } = this.props;
    deliveryStore.deleteDelivery(delivery.id).then(() => {
      notification.success(t.DELIVERIES_DELIVERYEDIT_DELETED(delivery.grn));
      this.props.history.push(Urls.DELIVERIES);
    });
  };
}
