import * as React from 'react';
import * as classnames from 'classnames';
import { isMobile } from 'util/helpers';
import { logger } from 'util/logger';
import reactNative from 'util/react-native';
import { IMAGE_ERROR } from 'util/constants';
import TranslateService from 'services/TranslateService';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import { ReactNativeActionType } from 'util/enums';
import TakeFileCommon from 'components/TakeFileCommon';

interface IProps {
  isContentSaved: boolean;
  onChangeFunc: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onPictureTaken: (imageBase64: string) => void;
  buttonClassName?: string;
  translateService?: TranslateService;
}
@inject('translateService')
@observer
export default class TakePicture extends React.Component<IProps> {
  public render() {
    const { buttonClassName, onChangeFunc } = this.props;

    return (
      <TakeFileCommon
        buttonClassName={classnames([buttonClassName, 'take-picture'])}
        onChangeFunc={onChangeFunc}
        fileAccept="image/*"
        isRenderInput={this._showInput}
        onButtonClick={this._takePictureClickHandler}
      />
    );
  }

  private get _showInput(): boolean {
    return !((typeof JSInterface !== 'undefined' && JSInterface && JSInterface.captureImage) || isMobile());
  }

  private _getImage = async (): Promise<void | boolean | undefined> => {
    if (!this.props.isContentSaved) {
      notification.warn(this.props.translateService.t.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG);
      return;
    }

    // Code for native Android App
    window.captureImageCallback = (data: string) => {
      if (data !== null) {
        this.props.onPictureTaken(data);
      } else {
        logger.error(IMAGE_ERROR, 'image is null');
      }
    };

    // Code for native Android App
    if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.captureImage) {
      try {
        logger.info('Capture image');
        JSInterface.captureImage('captureImageCallback');
      } catch (err) {
        logger.error(IMAGE_ERROR, err);
      }
      // Code for React Native App
    } else if (isMobile()) {
      window.onCameraNativeResult = this.props.onPictureTaken;

      try {
        reactNative.send({
          command: ReactNativeActionType.SHOW_CAMERA,
        });

        return true;
      } catch (err) {
        logger.error(IMAGE_ERROR, err);
      }
    } else {
      logger.info('JSInterface not set!');
    }
  };

  private _takePictureClickHandler = () => {
    return this._showInput ? undefined : this._getImage();
  };
}
