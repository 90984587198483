import { action, computed, observable } from 'mobx';
import IdCodeModel from 'models/IdCodeModel';
import { IActive, IConstructSaveObj } from 'models/ModelInterfaces';

export default class IdCodeActiveModel extends IdCodeModel implements IActive, IConstructSaveObj<IdCodeActiveModel> {
  @observable
  public active: boolean;

  @action
  public update = (obj: IdCodeActiveModel) => {
    this.updater.update(this, super.update(obj), IdCodeActiveModel);
    return this;
  };

  @computed
  public get constructSaveObj(): IdCodeActiveModel {
    return {
      code: this.code,
      active: this.active,
    } as IdCodeActiveModel;
  }

  @action
  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
}
