import * as React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import StockItemModel from 'models/StockItemModel';
import { AdminRootUrl } from 'util/enums';
import Sidebar from 'domain/layout/Sidebar';
import IdNameModel from 'models/IdNameModel';
import ViewStore from 'stores/ViewStore';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

@inject('adminStore', 'viewStore', 'translateService')
@observer
class StockItemDetailRouteD365 extends React.Component<IProps> {
  @observable
  public content: StockItemModel = null;

  @action
  public updateContent = (newStockItem: StockItemModel) => {
    this.content = new StockItemModel({ code: this.props.viewStore.stockCodeFormat }).update(newStockItem);
  };

  componentDidMount(): void {
    const {
      params: { id },
    } = this.props.match;
    this._fetchData(id);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      match: {
        params: { id: newItem },
      },
    } = this.props;
    const {
      match: {
        params: { id: prevItem },
      },
    } = prevProps;

    if (newItem !== prevItem) {
      this._fetchData(newItem);
    }
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { productVariants },
    } = this.props;

    return (
      <Sidebar heading={t.GLOBAL_LABEL_PRODUCT_VARIANTS} close={AdminRootUrl.STOCK_ITEM} ico="print">
        <table className="custom-table mb-3">
          <thead>
            <tr>
              <th className="pl-3">{t.GLOBAL_LABEL_CODE}</th>
              <th className="pl-3">{t.GLOBAL_LABEL_FORM}</th>
              <th className="pr-3">{t.GLOBAL_LABEL_QUALITY}</th>
            </tr>
          </thead>
          {productVariants.map((item: IdNameModel[]) => this._renderItem(item))}
        </table>
      </Sidebar>
    );
  }

  private _renderItem = (item: IdNameModel[]) => {
    return (
      <tbody key={`${item[1].id}-${item[0].id}`}>
        <tr>
          <td className="pl-3" data-test="pv-code">
            {this.content?.code}
          </td>
          <td className="pl-3" data-test="pv-form">
            {item[0].name}
          </td>
          <td className="pr-3" data-test="pv-quality">
            {item[1].name}
          </td>
        </tr>
      </tbody>
    );
  };

  private _fetchData = (id: string) => {
    const { adminStore } = this.props;
    adminStore.getProductVariantItems(id);
    adminStore.stockItemApi.getItemById(id).then((res) => {
      this.updateContent(res);
    });
  };
}

export default withRouter(StockItemDetailRouteD365);
