import * as React from 'react';
import * as H from 'history';
import { Container } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';

import DeliveryDetailComponent from 'pod/deliveries/DeliveryDetailComponent';
import DeliveryStore, { IGrnModelResponse } from 'pod/deliveries/DeliveryStore';

import DeliveryModel from 'models/DeliveryModel';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import AjaxService from 'services/AjaxService';
import ViewStore from 'stores/ViewStore';

interface IProps {
  history: H.History;
  commonStore?: CommonStore;
  deliveryStore?: DeliveryStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  ajaxService?: AjaxService;
}

interface IState {
  deliveryCreated: boolean;
  nextGrn: string;
}

@inject('commonStore', 'deliveryStore', 'translateService', 'ajaxService', 'viewStore')
@observer
export default class DeliveryNewRoute extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      deliveryCreated: false,
      nextGrn: null,
    };
  }

  public componentDidMount() {
    const {
      commonStore,
      viewStore: { isIT },
      deliveryStore,
    } = this.props;
    if (!commonStore.isDataLoaded) {
      commonStore.getCommon();
    }

    if (isIT) {
      deliveryStore.getNextGrn().then((res: IGrnModelResponse) => {
        this.setState({ nextGrn: res.nextGrn });
      });
    }
  }

  public createNewDelivery = async (
    deliveryModel: DeliveryModel,
    attachments?: FormData,
    radiationFiles?: FormData
  ) => {
    const {
      translateService: { t },
      deliveryStore,
      history,
    } = this.props;

    if (this.state.deliveryCreated) {
      notification.error(t.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG);
      history.push(`/deliveries/`);
      return;
    }

    const newDeliveryId = await deliveryStore.postDelivery(deliveryModel.constructSaveObj());
    this.setState({
      // PREVENT TO EXECUTE POST DELIVERY MORE THAN ONCE
      deliveryCreated: true,
    });

    await Promise.all([
      this._uploadAttachment(deliveryModel, newDeliveryId, attachments),
      this._uploadRadiationFiles(newDeliveryId, radiationFiles),
    ]).then(() => {
      this._createDeliverySuccess();
    });
  };

  public render() {
    const {
      commonStore: { common },
    } = this.props;

    return (
      <Container fluid>
        {common.roadHauliers && common.stockItems && common.suppliers && common.yardLocations && (
          <DeliveryDetailComponent
            history={this.props.history}
            saveAction={this.createNewDelivery}
            saveAttachmentsAction={this.createNewDelivery}
            hasPendingRequests={this.props.ajaxService.hasPendingRequests}
            nextGrn={this.state.nextGrn}
          />
        )}
      </Container>
    );
  }

  private _createDeliverySuccess = () => {
    notification.success(this.props.translateService.t.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG);
    this.props.history.push('/deliveries');
  };

  private _uploadRadiationFiles = (newDeliveryId: string, radiationFiles: FormData) => {
    const {
      translateService: { t },
    } = this.props;

    return radiationFiles
      ? this.props.deliveryStore.uploadRadiationFiles(newDeliveryId, radiationFiles).catch(() => {
          notification.error(t.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR);
          this.props.history.push(`/deliveries/${newDeliveryId}`);
        })
      : null;
  };

  private _uploadAttachment = (deliveryModel: DeliveryModel, newDeliveryId: string, attachmentFile: FormData) => {
    const {
      translateService: { t },
    } = this.props;

    return attachmentFile
      ? this.props.deliveryStore
          .uploadAttachments(newDeliveryId, attachmentFile)
          .then((newAttachments) => {
            deliveryModel.changeAttachments(newAttachments);
          })
          .catch(() => {
            notification.error(t.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG);
            this.props.history.push(`/deliveries/${newDeliveryId}`);
          })
      : null;
  };
}
