import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as classnames from 'classnames';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import NavlinkCustom from 'domain/NavlinkCustom';
import IdNameActiveModel from 'models/IdNameActiveModel';
import TargetBatchDetailRoute from 'pod/admin/target-batches/TargetBatchDetailRoute';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

interface IState {
  showUpload: boolean;
}

@inject('adminStore', 'translateService')
@observer
export default class TargetBatchListRoute extends React.Component<IProps, IState> {
  @observable public content: IdNameActiveModel = new IdNameActiveModel();

  @action
  public clearModel = () => {
    this.content = new IdNameActiveModel();
  };

  public get targetBatchList() {
    const {
      translateService: { t },
      adminStore: { targetBatchListActiveFirst },
    } = this.props;
    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.GLOBAL_LABEL_NAME}</th>
          </tr>
        </thead>
        {targetBatchListActiveFirst.map((targetBatch) => this._renderItem(targetBatch))}
      </table>
    );
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { targetBatchApi },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={targetBatchApi}
        rootPath={AdminRootPath.TARGET_BATCHES}
        itemsTable={this.targetBatchList}
        itemCreateForm={null}
        componentRoute={TargetBatchDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_TARGET_BATCH}
        exampleFileNames={ExampleFileNames.TARGET_BATCHES}
        header={t.MENU_ADMIN_TARGET_BATCHES}
      />
    );
  }

  private _renderItem = (targetBatch: IdNameActiveModel) => {
    return (
      <tbody key={targetBatch.id}>
        <NavlinkCustom
          to={`/admin/target-batches/${targetBatch.id}`}
          tagName="tr"
          key={targetBatch.id}
          className={classnames(['pointer', { 'bg-secondary': !targetBatch.active }])}
        >
          <td className="pl-3">{targetBatch.name}</td>
        </NavlinkCustom>
      </tbody>
    );
  };
}
