import { action, autorun, computed, observable, observe, reaction } from 'mobx';
import { autoAddNewLine } from 'util/mobx-utils';

import DeliverySubAdvisedGoodModel from 'models/DeliverySubAdvisedGoodModel';
import IdNameModel from 'models/IdNameModel';
import StockItemModel from 'models/StockItemModel';
import UserModel from 'models/UserModel';
import { AdvisedGoodStatus, AdvisedGoodWeighingTypes, CountryCode, DepartmentCode } from 'util/enums';
import IdCodeModel from 'models/IdCodeModel';
import { cloneObj } from 'util/helpers';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import ValidateModel from 'models/ValidateModel';
import FileModel from 'models/FileModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export interface IDeliveryAdvisedGoodConstructSaveObj {
  advisedDescription: string;
  firstWeight: number;
  advisedNetWeight: number;
  id: string;
  stockItemCode: string;
  subAdvisedGoods: DeliverySubAdvisedGoodModel[];
  advisedTareWeight: number;
  secondWeight: number;
  ticketNumber: string;
  ewcCode: string;
  yardLocationName: string;
  responsibleId: string;
  advisedGoodExternalIndex: string;
  productFormId?: string;
  productQualityId?: string;
  departmentCode?: DepartmentCode;
  advisedGrossWeight?: number;
  packaging?: string;
  comment?: string;
}

interface IDeliveryAdvisedGoodModelValidationKeys {
  advisedDescription: boolean;
  yardLocation: boolean;
  stockItem: boolean;
  ticketNumber: boolean;
  departmentCode: boolean;
  firstWeight: boolean;
  productForm: boolean;
  productQuality: boolean;
}

export type DeliveryAGValidatorKey = Partial<keyof IDeliveryAdvisedGoodModelValidationKeys>;

export default class DeliveryAdvisedGoodModel extends ValidateModel<IDeliveryAdvisedGoodModelValidationKeys>
  implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
    autorun(() => this.shouldTrigger);
    reaction(
      () => this.stockItem,
      () => {
        this.setProductForm(null);
      }
    );
    reaction(
      () => this.productForm,
      () => {
        this.setProductQuality(null);
      }
    );
  }

  @observable public advisedDescription?: string = '';
  @observable public advisedNetWeight?: number = null;
  @observable public advisedGrossWeight?: number = null;
  @observable public claimedBy?: UserModel<IUserModelConstructObj> = null;
  @observable public firstWeight?: number = null;
  @observable public isFlagged?: boolean;
  @observable public stockItem?: StockItemModel = null;
  @observable public subAdvisedGoods?: DeliverySubAdvisedGoodModel[] = [];
  @observable public advisedTareWeight?: number = null;
  @observable public secondWeight?: number = null;
  @observable public ticketNumber?: string = '';
  @observable public ewcCode?: string = null;
  @observable public yardLocation?: IdNameModel = null;
  @observable public createdAt?: string = '';
  @observable public id?: string = null;
  @observable public nonAdvisedGood?: boolean = false;
  @observable public responsible?: IdNameModel = null;
  @observable public departmentCode?: DepartmentCode = null;
  @observable public status?: AdvisedGoodStatus = null;
  @observable public advisedGoodExternalIndex?: string = null;
  @observable public packaging?: string = null;
  @observable public comment?: string = null;
  @observable public attachments?: FileModel[] = [];
  @observable public productForm?: IdNameModel = null;
  @observable public productQuality?: IdNameModel = null;
  @observable public weighingType?: string = AdvisedGoodWeighingTypes.WEIGHBRIDGE;

  public generalValidatorKeys: Array<keyof Partial<IDeliveryAdvisedGoodModelValidationKeys>> = [
    'advisedDescription',
    'yardLocation',
    'stockItem',
  ];
  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<IDeliveryAdvisedGoodModelValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<IDeliveryAdvisedGoodModelValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys.concat(['firstWeight', 'ticketNumber'])],
    [CountryCode.DE, this.generalValidatorKeys.concat(['departmentCode', 'ticketNumber'])],
    [CountryCode.DE_D365, this.generalValidatorKeys.concat(['ticketNumber', 'productForm', 'productQuality'])],
    [CountryCode.UK, this.generalValidatorKeys.concat(['ticketNumber'])],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  @computed
  public get netWeight(): number {
    if (this.nonAdvisedGood) {
      // IF ADVISED GOOD WAS CREATED BY TRANSFERING, RETURN GROSS WEIGHT
      return this.firstWeight;
    } else {
      return this.firstWeight && this.secondWeight ? this.firstWeight - this.secondWeight : null;
    }
  }

  @computed
  public get netWeightWithZeroSecond(): number {
    return this.firstWeight && typeof this.secondWeight === 'number' ? this.firstWeight - this.secondWeight : null;
  }

  @computed
  public get isDirty() {
    return this.hasChanged || this.subAdvisedGoods.some((x) => x.hasChanged);
  }

  @computed
  public get validators(): IDeliveryAdvisedGoodModelValidationKeys {
    return {
      advisedDescription: this.advisedDescription !== '',
      yardLocation: this.yardLocation !== null,
      stockItem: this.stockItem !== null,
      departmentCode: this.departmentCode !== null,
      ticketNumber: this.ticketNumber !== '',
      firstWeight: this.firstWeight !== null,
      productForm: this.productForm !== null || !this.stockItem?.requiresFormAndQuality,
      productQuality: this.productQuality !== null || !this.stockItem?.requiresFormAndQuality,
    };
  }

  @computed
  public get isScaleWeights(): boolean {
    return this.weighingType === AdvisedGoodWeighingTypes.SCALE;
  }

  public get shouldTrigger() {
    return autoAddNewLine(this.subAdvisedGoods, () => this.pushSubAdvisedGood());
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public isInsideWeightTolerance(weighbridgeTolerance: number) {
    if (!this.advisedNetWeight || !this.netWeight) {
      return true;
    }

    const diff = this.advisedNetWeight - this.netWeight;

    return diff > -weighbridgeTolerance && diff < weighbridgeTolerance;
  }

  @action
  public update = (obj: DeliveryAdvisedGoodModel) => {
    const newDeliveryAdvisedGoodModel = cloneObj(obj);

    if (newDeliveryAdvisedGoodModel) {
      newDeliveryAdvisedGoodModel.subAdvisedGoods = newDeliveryAdvisedGoodModel.subAdvisedGoods
        ? newDeliveryAdvisedGoodModel.subAdvisedGoods.map((r: DeliverySubAdvisedGoodModel) =>
            new DeliverySubAdvisedGoodModel().update(r)
          )
        : [];
      if (newDeliveryAdvisedGoodModel.attachments && newDeliveryAdvisedGoodModel.attachments.length !== 0) {
        newDeliveryAdvisedGoodModel.attachments = newDeliveryAdvisedGoodModel.attachments.map((r: FileModel) =>
          new FileModel().update(r)
        );
      }
    }

    this.updater.update(this, newDeliveryAdvisedGoodModel, DeliveryAdvisedGoodModel);

    return this;
  };

  @action
  public pushSubAdvisedGood() {
    const newSubAdvisedGood = new DeliverySubAdvisedGoodModel();
    this.subAdvisedGoods.push(newSubAdvisedGood);

    return newSubAdvisedGood;
  }

  @action
  public setFirstWeight(val: number) {
    this.firstWeight = val;
  }

  @action
  public setSecondWeight(val: number) {
    this.secondWeight = val;
  }

  @action
  public setAdvisedDescription(val: string) {
    this.advisedDescription = val;
  }

  @action
  public setAdvisedGrossWeight(val: number) {
    this.advisedGrossWeight = val;
  }

  @action
  public setAdvisedNetWeight(val: number) {
    this.advisedNetWeight = val;
  }

  @action
  public setAdvisedTareWeight(val: number) {
    this.advisedTareWeight = val;
  }

  @action
  public setStockItem(val: StockItemModel) {
    this.stockItem = val;
  }

  @action
  public setTicketNumber(val: string) {
    this.ticketNumber = val;
  }

  @action
  public setEwcCode(val: IdCodeModel) {
    this.ewcCode = val ? val.code : '';
  }

  @action
  public setYardLocation(val: IdNameModel) {
    this.yardLocation = val;
  }

  @action
  public setResponsible(val: IdNameModel) {
    this.responsible = new IdNameModel().update(val);
  }

  @action
  public setClaimBy(val: UserModel<IUserModelConstructObj>) {
    this.claimedBy = new UserModel<IUserModelConstructObj>().update(val);
  }

  @action
  public setDepartmentCode(code: DepartmentCode) {
    this.departmentCode = code;
  }

  @action
  public setAdvisedGoodExternalIndex(val: string) {
    this.advisedGoodExternalIndex = val;
  }

  @action
  public setProductForm = (val: IdNameModel) => {
    this.productForm = val;
  };

  @action
  public setProductQuality = (val: IdNameModel) => {
    this.productQuality = val;
  };

  @action
  public setPackaging(val: string) {
    this.packaging = val;
  }

  @action
  public setComment(val: string) {
    this.comment = val;
  }

  @action
  public pushNewAttachment(obj: FileModel) {
    this.attachments.push(new FileModel().update(obj));
  }

  @action
  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att) => att.id !== attachmentId);
  }

  @action
  public cleanSubAdvisedGoods() {
    this.subAdvisedGoods = this.subAdvisedGoods.filter(
      (subAdvisedGood) => subAdvisedGood.shouldAddNewLine && subAdvisedGood.constructSaveObj()
    );
  }

  // CONSTRUCT OBJ FOR POST/PUT DELIVERY
  @action
  public constructSaveObj(): IDeliveryAdvisedGoodConstructSaveObj {
    this.cleanSubAdvisedGoods();
    const res: IDeliveryAdvisedGoodConstructSaveObj = {
      advisedDescription: this.advisedDescription,
      advisedNetWeight: this.advisedNetWeight,
      firstWeight: this.firstWeight,
      id: this.id,
      stockItemCode: this.stockItem ? this.stockItem.code : '',
      subAdvisedGoods: this.subAdvisedGoods.filter((sag) => !!sag),
      advisedTareWeight: this.advisedTareWeight,
      secondWeight: this.secondWeight,
      ticketNumber: this.ticketNumber,
      ewcCode: this.ewcCode ? this.ewcCode : null,
      yardLocationName: this.yardLocation ? this.yardLocation.name : '',
      responsibleId: this.responsible?.id,
      departmentCode: this.departmentCode,
      advisedGoodExternalIndex: this.advisedGoodExternalIndex,
    };

    if (this.departmentCode) {
      res.departmentCode = this.departmentCode;
    }
    if (this.advisedGrossWeight) {
      res.advisedGrossWeight = this.advisedGrossWeight;
    }
    if (this.packaging) {
      res.packaging = this.packaging;
    }
    if (this.comment) {
      res.comment = this.comment;
    }
    if (this.productForm) {
      res.productFormId = this.productForm.id;
    }
    if (this.productQuality) {
      res.productQualityId = this.productQuality.id;
    }
    return res;
  }

  public isFirstWeightMoreThanMaxWeight(maxWeight: number): boolean {
    return this.firstWeight > maxWeight;
  }
}
