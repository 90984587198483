import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

export default class SharepointModel extends UpdateModel<SharepointModel> {
  @observable public id?: string = null;
  @observable public path: string = '';
  @observable public site: string = '';

  @action
  public update(obj: SharepointModel) {
    this.updater.update(this, obj, SharepointModel);
    return this;
  }

  @action
  public changeId = (id: string) => (this.id = id);
}
