import { action, computed, observable } from 'mobx';
import DeliveryLabModel from 'models/DeliveryLabModel';
import RootService from 'services/RootService';
import env from 'env';
import DeliveryLabReceivedGoodModel from 'models/DeliveryLabReceivedGoodModel';
import LabStatsModel from 'models/LabStatsModel';

export interface IGetDeliveryLabResponse {
  values: DeliveryLabModel[];
  offset: number;
}

export default class LabStore {
  public constructor(private readonly rootService: RootService) {}

  @observable
  public deliveriesLabList: DeliveryLabModel[] = [];

  @computed
  public get amountNotifications(): number {
    return this.deliveriesLabList.reduce((amount: number, delivery: DeliveryLabModel) => {
      delivery.receivedGoods.forEach((rg: DeliveryLabReceivedGoodModel) => {
        rg.stats.forEach((st: LabStatsModel) => {
          if (st.hasNotification) {
            amount += 1;
          }
        });
      });

      return amount;
    }, 0);
  }

  @computed
  public get deliveryLabListSortedByNotification(): DeliveryLabModel[] {
    return this.deliveriesLabList.sort(
      (first: DeliveryLabModel, second: DeliveryLabModel) =>
        Number(second.hasNotification) - Number(first.hasNotification)
    );
  }

  public getDeliveriesLabs(params: string = ''): Promise<IGetDeliveryLabResponse> {
    return this.rootService.ajaxService.get(`deliveries/lab${params}`);
  }

  public getDeliveriesLabList(params: string = '') {
    return this.getDeliveriesLabs(params).then(this._setDeliveriesLab);
  }

  public downloadLabList(params: string = '') {
    const url = `${
      env.apiUrl
    }/api/export/lab-list${params}&x-auth-token=${this.rootService.ajaxService.getSessionId()}&x-client-version=${
      env.version
    }`;
    window.open(url);
  }

  @action
  public changeDeliveryLabList(newDeliveryLabList: DeliveryLabModel[]): void {
    if (!this.deliveriesLabList.length) {
      this.deliveriesLabList = newDeliveryLabList.map((deliveryItem: DeliveryLabModel) =>
        new DeliveryLabModel().update(deliveryItem)
      );
    } else {
      this.deliveriesLabList = newDeliveryLabList.map((deliveryItem: DeliveryLabModel) => {
        const newDeliveryLab = new DeliveryLabModel().update(deliveryItem);
        const oldDeliveryLab = this.deliveriesLabList.find(
          (deliveryLab: DeliveryLabModel) => newDeliveryLab.id === deliveryLab.id
        );
        this.addNotificationFlagToDeliveryLab(newDeliveryLab, oldDeliveryLab);
        return newDeliveryLab;
      });
    }
  }

  @action
  private _setDeliveriesLab = (res: IGetDeliveryLabResponse) => {
    if (res.offset) {
      this.deliveriesLabList.push(...res.values.map((item: DeliveryLabModel) => new DeliveryLabModel().update(item)));
    } else {
      this.changeDeliveryLabList(res.values);
    }
    return Promise.resolve(!!res.values.length);
  };

  @action
  public updateLabList = (data: DeliveryLabModel): void => {
    const updatedLab = this.deliveriesLabList.find((item: DeliveryLabModel) => item.id === data.id);
    const dataWithNotification = this.addNotificationFlagToDeliveryLab(data, updatedLab);
    // if data is existing item - we will update it, instead - add to start
    updatedLab ? updatedLab.update(dataWithNotification) : this.deliveriesLabList.unshift(dataWithNotification);
  };

  @action
  public addNotificationFlagToDeliveryLab = (
    newItem: DeliveryLabModel,
    oldItem: DeliveryLabModel
  ): DeliveryLabModel => {
    const newDeliveryLabModel = new DeliveryLabModel().update(newItem);
    if (!oldItem) {
      newDeliveryLabModel.receivedGoods.forEach((rg: DeliveryLabReceivedGoodModel) => {
        rg.addNotificationFlagToRequestedLabAnalysis();
      });
    } else {
      newDeliveryLabModel.receivedGoods.forEach((rg: DeliveryLabReceivedGoodModel) => {
        const oldReceivedGood = oldItem.receivedGoods.find(
          (oldRg: DeliveryLabReceivedGoodModel) => rg.receivedGoodId === oldRg.receivedGoodId
        );
        // we need merge new and old receivedGood to save old hasNotification flags for this receivedGood
        if (
          !oldReceivedGood ||
          rg.amountOfRequestedLabAnalysis > oldReceivedGood.amountOfRequestedLabAnalysis ||
          oldReceivedGood.hasNotification
        ) {
          rg.addNotificationFlagToRequestedLabAnalysis();
        }
      });
    }

    return newDeliveryLabModel;
  };

  @action
  public removeNotificationFlagFromReceivedGood = (rgId: string): void => {
    this.deliveriesLabList.forEach((deliveryLab: DeliveryLabModel) => {
      const receivedGood = deliveryLab.receivedGoods.find(
        (rg: DeliveryLabReceivedGoodModel) => rg.receivedGoodId === rgId
      );
      if (receivedGood) {
        receivedGood.stats.forEach((st: LabStatsModel) => st.updateHasNotification(false));
      }
    });
  };
}
