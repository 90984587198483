import * as moment from 'moment';
import { action, computed, observable } from 'mobx';

import { cloneObj, isMobile } from 'util/helpers';
import { IActive, ICode, IConstructSaveObj, UserModelConstructObj } from 'models/ModelInterfaces';
import UserRoleModel from 'models/UserRoleModel';
import IdActiveModel from 'models/IdActiveModel';
import UserTenantModel from 'models/UserTenantModel';
import {
  PASSWORD_EXPIRATION_DATE_DAYS_LIMIT,
  PASSWORD_EXPIRATION_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from 'util/constants';
import TenantModel from 'models/TenantModel';

export default class UserModel<T extends UserModelConstructObj> extends IdActiveModel
  implements IActive, ICode, IConstructSaveObj<T> {
  @observable public email: string = '';
  @observable public firstname: string = '';
  @observable public lastname: string = '';
  @observable public password: string = null;
  @observable public roles: UserRoleModel[] = [];
  @observable public activeRole: UserRoleModel = null;
  @observable public username: string = '';
  @observable public requiresPasswordChange: boolean = false;
  @observable public code: string = '';
  @observable public tenants: UserTenantModel[] = [];
  @observable public activeTenant: UserTenantModel = null;
  @observable public passwordExpirationDate: string = '';
  @observable public externalEmployeeId: string = '';

  @computed get fullName(): string {
    if (!this.isFirstNameAndLastNameExist) {
      return '';
    }
    return isMobile() ? this.fullNameWithShortName : `${this.firstname} ${this.lastname}`;
  }

  @computed get fullNameWithShortName(): string {
    return this.isFirstNameAndLastNameExist ? `${this.firstname[0]}. ${this.lastname}` : '';
  }

  @computed get isFirstNameAndLastNameExist(): boolean {
    return !!(this.firstname && this.lastname);
  }

  @computed get hasDefaultRole(): boolean {
    return !!this.roles.find((role: UserRoleModel) => role.isDefault);
  }

  @computed get hasDefaultTenant(): boolean {
    return !!this.tenants.find((tenant: UserTenantModel) => tenant.isDefault);
  }

  @computed get passwordExpirationDateMoment(): moment.Moment {
    return moment(this.passwordExpirationDate, SERVER_DATE_FORMAT);
  }

  @computed get passwordExpirationDateMomentWithFormat(): string {
    return this.passwordExpirationDateMoment?.format(PASSWORD_EXPIRATION_DATE_FORMAT);
  }

  @computed get shouldShowPasswordExpirationMessage(): boolean {
    const notificationDate = moment(this.passwordExpirationDate).subtract(PASSWORD_EXPIRATION_DATE_DAYS_LIMIT, 'days');

    return this.passwordExpirationDateMoment && moment().isAfter(notificationDate, 'day');
  }

  @computed get isPasswordExpired(): boolean {
    return moment(this.passwordExpirationDate).isBefore();
  }

  @computed
  public get constructSaveObj(): T {
    return {
      email: this.email,
      firstname: this.firstname,
      id: this.id,
      lastname: this.lastname,
      password: this.password,
      roles: this.roles.map((item: UserRoleModel) => ({ id: item.id, isDefault: item.isDefault })),
      username: this.username,
      active: this.active,
      code: this.code,
      tenants: this.tenants.map((item: UserTenantModel) => ({ id: item.id, isDefault: item.isDefault })),
      externalEmployeeId: this.externalEmployeeId,
    } as T;
  }
  @computed
  public get isNew(): boolean {
    return !this.id;
  }

  public getRoleByName = (name: string): UserRoleModel => {
    return this.roles.find((role: UserRoleModel) => role.name === name) || null;
  };

  public getTenantById = (id: string): UserTenantModel => {
    return this.tenants.find((tenant: UserTenantModel) => tenant.id === id) || null;
  };

  @action public update(obj: UserModel<T>) {
    const newUserModel = cloneObj(obj);

    if (newUserModel && newUserModel.roles && newUserModel.roles.length !== 0) {
      newUserModel.roles = newUserModel.roles.map((r: UserRoleModel) => new UserRoleModel().update(r));

      if (!newUserModel.activeRole) {
        const defaultRole = newUserModel.roles.find((role: UserRoleModel) => role.isDefault);
        newUserModel.activeRole = defaultRole ? new UserRoleModel().update(defaultRole) : null;
      }
    }

    if (newUserModel && newUserModel.tenants && newUserModel.tenants.length !== 0) {
      newUserModel.tenants = newUserModel.tenants.map((t: UserTenantModel) => new UserTenantModel().update(t));

      if (!newUserModel.activeTenant) {
        const defaultTenant = newUserModel.tenants.find((tenant: UserTenantModel) => tenant.isDefault);
        newUserModel.activeTenant = !!defaultTenant ? new UserTenantModel().update(defaultTenant) : null;
      }
    }

    this.updater.update(this, super.update(newUserModel), UserModel);
    return this;
  }

  @action public setFirstname(val: string) {
    this.firstname = val;
  }
  @action public setLastname(val: string) {
    this.lastname = val;
  }
  @action public setUsername(val: string) {
    this.username = val;
  }
  @action public setEmail(val: string) {
    this.email = val;
  }
  @action public setPassword(val: string) {
    this.password = val;
  }
  @action public addRole(userRole: UserRoleModel) {
    const newRole = new UserRoleModel().update(userRole);
    this.roles.push(newRole);
  }
  @action public removeRole(id: string) {
    this.roles = this.roles.filter((r) => r.id !== id);
  }
  @action public clearRoles() {
    this.roles = [];
  }
  @action public changeCode(newCode: string) {
    this.code = newCode;
  }
  @action public setActiveRole(role: UserRoleModel) {
    this.activeRole = role;
  }

  @action public setIsDefaultRole(id: string) {
    this.roles.forEach((role: UserRoleModel) => {
      role.changeIsDefault(role.id === id);
    });
  }

  @action public addTenant(tenant: UserTenantModel | TenantModel, isDefault?: boolean) {
    const newTenant = new UserTenantModel();
    if (!!tenant) {
      newTenant.id = tenant.id;
      newTenant.code = tenant.code;
      newTenant.regionCode = tenant.regionCode;
      newTenant.changeName(tenant.name);
      if (isDefault) {
        newTenant.changeIsDefault(true);
      }
    }
    this.tenants.push(newTenant);
  }

  @action public setActiveTenant(tenant: UserTenantModel) {
    this.activeTenant = tenant;
  }

  @action public setIsDefaultTenant(id: string) {
    this.tenants.forEach((tenant: UserTenantModel) => {
      tenant.changeIsDefault(tenant.id === id);
    });
  }

  @action public setExternalEmployeeId(id: string) {
    this.externalEmployeeId = id;
  }

  @action public removeTenant(id: string) {
    this.tenants = this.tenants.filter((item: UserTenantModel) => item.id !== id);
  }

  @action public clearTenants() {
    this.tenants = [];
  }
}
