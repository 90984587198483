import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import QrCodeReaderComponent from 'components/QrCodeReaderComponent';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';

interface IProps {
  onQrCodeRead: (value: string) => void;
  translateService?: TranslateService;
}

interface IState {
  qrModalOpen: boolean;
}

@inject('translateService')
@observer
export default class QrCodeButtonComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      qrModalOpen: false,
    };
  }

  public toggleModal = () => {
    this.setState({
      qrModalOpen: !this.state.qrModalOpen,
    });
  };

  public render() {
    const {
      translateService: { t },
    } = this.props;

    return (
      <div className="qrCodeReaderComponent pull-right">
        <Button className="qr-button header-button" onClick={this.toggleModal}>
          <FontAwesome name="qrcode" />
        </Button>
        <Modal isOpen={this.state.qrModalOpen} toggle={this.toggleModal} size="lg" backdrop="static">
          <ModalHeader toggle={this.toggleModal}>{t.HEADER_QRCODE_HEADER}</ModalHeader>
          <ModalBody>
            <QrCodeReaderComponent onQrCodeRead={this._onQrCodeRead} closeModal={this.toggleModal} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleModal}>{t.GLOBAL_LABEL_CLOSE}</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  private _onQrCodeRead = (result: string) => {
    this.setState(
      {
        qrModalOpen: false,
      },
      () => {
        this.props.onQrCodeRead(result);
      }
    );
  };
}
