import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as FontAwesome from 'react-fontawesome';
import { Button, Label } from 'reactstrap';
import { instance as notification } from 'util/notification';
import TranslateService from 'services/TranslateService';
import { action, computed, observable } from 'mobx';
import LasernetDocumentTypeModel from 'models/LasernetDocumentTypeModel';
import InputNumber from 'components/InputNumber';
import * as classnames from 'classnames';
import { BORDER_DANGER } from 'util/constants';
import CommonStore from 'stores/CommonStore';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';

interface IProps {
  receivedGoodId?: string;
  documentType?: LasernetDocumentTypeModel;
  translateService?: TranslateService;
  lasernetPrinterStore?: LasernetPrinterStore;
  commonStore?: CommonStore;
}

@inject('translateService', 'lasernetPrinterStore')
@observer
export default class LasernetPrintComponent extends React.Component<IProps> {
  @observable
  public numberOfCopies: number = this.props.documentType?.defaultNumberOfCopies || 0;

  public render() {
    const {
      translateService: { t },
    } = this.props;

    return (
      <div className="position-center" data-test="lasernet-print-component">
        <Button
          data-test="lasernet-print-button"
          type="button"
          onClick={this._print}
          color="primary"
          className="mr-2"
          disabled={!this._isValidNumberOfCopies}
        >
          <FontAwesome name="print" className="mr-1" />
          {t.GLOBAL_LABEL_PRINT}
        </Button>
        <Label className="mr-1">{`${t.SORTINGREPORT_LASERNET_COPIES}:`}</Label>
        <InputNumber
          data-test="lasernet-number-of-copies"
          decimalPlaces={0}
          onChangeValue={(value: number) => this._onNumberOfCopiesChange(value)}
          step={1}
          value={this.numberOfCopies}
          allowNegativeValue={false}
          className={classnames('width-50', { [BORDER_DANGER]: !this._isValidNumberOfCopies })}
        />
      </div>
    );
  }

  private _print = () => {
    const {
      translateService: { t },
    } = this.props;

    this.props.lasernetPrinterStore
      .printReceivedGood(this.props.receivedGoodId, { numberOfCopies: this.numberOfCopies })
      .then(() => {
        notification.success(t.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE(`${this.numberOfCopies}`));
      })
      .catch(() => {
        notification.error(t.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE);
      });
  };

  @action
  private _onNumberOfCopiesChange = (val: number) => {
    this.numberOfCopies = val;
  };

  @computed
  private get _isValidNumberOfCopies() {
    return !!this.numberOfCopies;
  }
}
