import LabStatsStatusModel from 'models/LabStatsStatusModel';
import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

export default class LabStatsModel extends UpdateModel<LabStatsModel> {
  @observable public count: string;
  @observable public status: LabStatsStatusModel;
  @observable public hasNotification?: boolean;

  @action
  public update = (obj: LabStatsModel) => {
    this.updater.update(this, obj, LabStatsModel);
    return this;
  };

  @action
  public updateHasNotification = (newValue: boolean) => (this.hasNotification = newValue);
}
