import { action, computed, observable, observe } from 'mobx';

import UpdateModel from 'models/UpdateModel';
import IdNameModel from 'models/IdNameModel';
import LabTrackingModel from 'models/LabTrackingModel';
import { cloneObj } from 'util/helpers';
import MaterialModel from 'models/MaterialModel';
import MaterialsListModel from 'models/MaterialsListModel';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';
import FileModel from 'models/FileModel';
import MaterialsModel from 'models/MaterialsModel';

export interface ILabAnalysisModelSaveObject {
  dryWeight: number;
  ingotWeight: number;
  remarks: string;
  wetWeight: number;
  stockCode: string;
  spectroAnalysis: MaterialsModel[];
  forMeltingWeight: number;
  files: { id: string }[];
}

export default class LabAnalysisModel extends UpdateModel<LabAnalysisModel> implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  @observable public dryWeight?: number = null;
  @observable public ingotWeight?: number = null;
  @observable public labInputType?: IdNameModel;
  @observable public remarks?: string = '';
  @observable public stockCode?: string = '';
  @observable public tracking?: LabTrackingModel[];
  @observable public wetWeight?: number = null;
  @observable public createdAt?: Date;
  @observable public id?: string;
  @observable public code?: string;
  @observable public labInputTypeId?: string;
  @observable public deliveryStatus?: string = '';
  @observable public spectroAnalysis?: MaterialsListModel;
  @observable public forMeltingWeight?: number = null;
  @observable public attachments?: FileModel[] = [];

  // CALCULATE YIELD
  public get yield(): string {
    if (this.ingotWeight && this.wetWeight) {
      return `${((this.ingotWeight / this.wetWeight) * 100).toFixed(1)}%`;
    } else if (this.ingotWeight && this.dryWeight) {
      return `${((this.ingotWeight / this.dryWeight) * 100).toFixed(1)}%`;
    } else {
      return 'Not calculated';
    }
  }

  public get yieldUS(): number {
    return this.forMeltingWeight && this.ingotWeight && (this.ingotWeight / this.forMeltingWeight) * 100;
  }

  @computed
  public get humidity() {
    return this.wetWeight && this.dryWeight && (1 - this.dryWeight / this.wetWeight) * 100;
  }

  @computed
  public get isDirty() {
    return this.hasChanged;
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public update = (obj: LabAnalysisModel, defaultMaterials: MaterialModel[], nonDefaultMaterials: MaterialModel[]) => {
    const newLabAnalysisModel = cloneObj(obj);

    if (newLabAnalysisModel) {
      if (!newLabAnalysisModel.remarks) {
        newLabAnalysisModel.remarks = '';
      }
      if (newLabAnalysisModel.labInputType && newLabAnalysisModel.labInputType.id) {
        // NEEDED FOR API PUT LAB ANALYSIS
        this.labInputTypeId = newLabAnalysisModel.labInputType.id;
      }
      if (newLabAnalysisModel.tracking) {
        newLabAnalysisModel.tracking = newLabAnalysisModel.tracking.map((item: LabTrackingModel) =>
          new LabTrackingModel().update(item)
        );
      }

      if (!newLabAnalysisModel.code) {
        newLabAnalysisModel.code = '';
      }
      if (newLabAnalysisModel.spectroAnalysis) {
        newLabAnalysisModel.spectroAnalysis = new MaterialsListModel().update(
          newLabAnalysisModel.spectroAnalysis,
          defaultMaterials,
          nonDefaultMaterials
        );
      }
      if (newLabAnalysisModel.attachments) {
        newLabAnalysisModel.attachments = newLabAnalysisModel.attachments.map((r: FileModel) =>
          new FileModel().update(r)
        );
      }
    }

    this.updater.update(this, newLabAnalysisModel, LabAnalysisModel);

    return this;
  };

  @action
  public setDryWeight(val: number) {
    this.dryWeight = val;
  }

  @action
  public setIngotWeight(val: number) {
    this.ingotWeight = val;
  }

  @action
  public setRemarks(val: string) {
    this.remarks = val;
  }

  @action
  public setTracking(tracking: LabTrackingModel[]) {
    this.tracking = tracking;
  }

  @action
  public setWetWeight(val: number) {
    this.wetWeight = val;
  }

  @action
  public setForMelting(val: number) {
    this.forMeltingWeight = val;
  }

  @action
  public setStockCode(val: string) {
    this.stockCode = val;
  }

  @action
  public saveObject?(): ILabAnalysisModelSaveObject {
    return {
      dryWeight: this.dryWeight,
      ingotWeight: this.ingotWeight,
      remarks: this.remarks,
      wetWeight: this.wetWeight,
      stockCode: this.stockCode,
      spectroAnalysis: this.spectroAnalysis.filledMaterials,
      forMeltingWeight: this.forMeltingWeight,
      files: this.attachments.map((file: FileModel) => ({ id: file.id })),
    };
  }

  @action
  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att: FileModel) => att.id !== attachmentId);
  }

  @action
  public pushNewAttachment(obj: FileModel) {
    this.attachments.push(new FileModel().update(obj));
  }
}
