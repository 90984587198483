import { action, computed, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

import LabStatsModel from 'models/LabStatsModel';
import { LabAnalysisStatus } from 'util/enums';
import { cloneObj } from 'util/helpers';

export default class DeliveryLabReceivedGoodModel extends UpdateModel<DeliveryLabReceivedGoodModel> {
  @observable public advisedGoodId: string;
  @observable public description: string;
  @observable public agTitle: string;
  @observable public oldestInLabDate: Date;
  @observable public receivedGoodId: string;
  @observable public stats?: LabStatsModel[];

  @computed
  public get hasNotification(): boolean {
    return this.stats.some((st: LabStatsModel) => st.hasNotification);
  }

  @computed
  public get amountOfRequestedLabAnalysis(): number {
    if (!this.stats || !this.stats.length) {
      return 0;
    }

    const requestedLabAnalysis = this.stats.find((st: LabStatsModel) => st.status.name === LabAnalysisStatus.REQUESTED);
    return requestedLabAnalysis ? Number(requestedLabAnalysis.count) : 0;
  }

  @action
  public addNotificationFlagToRequestedLabAnalysis = () => {
    this.stats.forEach((st: LabStatsModel) => {
      if (st.status.name === LabAnalysisStatus.REQUESTED) {
        st.updateHasNotification(true);
      }
    });
  };

  @action
  public update = (obj: DeliveryLabReceivedGoodModel) => {
    const newDeliveryLabReceivedGoodModel = cloneObj(obj);

    if (newDeliveryLabReceivedGoodModel) {
      newDeliveryLabReceivedGoodModel.stats = newDeliveryLabReceivedGoodModel.stats.map((st: LabStatsModel) =>
        new LabStatsModel().update(st)
      );
    }

    this.updater.update(this, newDeliveryLabReceivedGoodModel, DeliveryLabReceivedGoodModel);
    return this;
  };
}
