import * as React from 'react';
import * as classnames from 'classnames';
import * as FontAwesome from 'react-fontawesome';
import * as H from 'history';
import { inject, observer } from 'mobx-react';
import { Button, Collapse } from 'reactstrap';
import { getTranslation, isMobile } from 'util/helpers';
import { instance as notification } from 'util/notification';

import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import DeliveryListModel from 'models/DeliveryListModel';
import ViewStore from 'stores/ViewStore';
import DeliveryStore from 'pod/deliveries/DeliveryStore';
import ModalConfirmation from 'domain/ModalConfirmation';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import { I18N } from '../../../assets/i18n/i18n';
import { TEXT_DANGER } from 'util/constants';
import { AdvisedGoodStatus, DeliveryStatus } from 'util/enums';
import DateFormattingService from 'services/DateFormattingService';
import AdvisedGoodSignOffButton from 'pod/advisedGoods/AdvisedGoodSignOffButton';

interface IProps {
  adminResyncWb: (deliveryId: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  adminResyncSr: (deliveryId: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  adminReexport: (deliveryId: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  claimAdvisedGood: (advisedGood: DeliveryListAdvisedGoodModel, e: React.MouseEvent<HTMLButtonElement>) => void;
  delivery: DeliveryListModel;
  forceOpenList: boolean;
  history?: H.History;
  key?: string;
  prevDeliveryId?: string;
  refreshData: () => void;
  deliveryStore?: DeliveryStore;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  dateFormattingService?: DateFormattingService;
  isTraderInfoShown?: boolean;
}

interface IState {
  confirmationModalComplete: boolean;
  confirmationModalSignOff: boolean;
  confirmationModalWeightDiscrepancy: boolean;
  confirmationModalExport: boolean;
  isOpen: boolean;
}

@inject('translateService', 'sessionStore', 'deliveryStore', 'viewStore', 'dateFormattingService')
@observer
export default class DeliveryListComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      confirmationModalComplete: false,
      confirmationModalSignOff: false,
      confirmationModalWeightDiscrepancy: false,
      confirmationModalExport: false,
      isOpen: false,
    };
  }

  public componentDidMount() {
    // PRE-OPEN DELIVERY COLLAPSABLE DIV
    this._preOpenDelivery();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { forceOpenList: newForceOpenList, prevDeliveryId: newPrevDeliveryId } = this.props;
    const { forceOpenList: oldForceOpenList, prevDeliveryId: oldPrevDeliveryId } = prevProps;
    if (newForceOpenList !== oldForceOpenList) {
      this.setState({ isOpen: newForceOpenList });
    }
    if (newPrevDeliveryId !== oldPrevDeliveryId) {
      this._preOpenDelivery();
    }
  }

  // TIER 1 - SIGN-OFF DELIVERY
  public signOffDelivery = () => {
    this.props.deliveryStore.signOffDelivery(this.props.delivery.id).then(() => {
      this.props.refreshData();
      notification.success(this.props.translateService.t.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG);
    });
  };

  public checkAndSignOffDelivery = () => {
    const {
      delivery,
      viewStore: { isIT },
    } = this.props;
    if (isIT && delivery.isAGWeightDiscrepancy) {
      this._toggleConfirmationModalWeightDiscrepancySignOff();
    } else {
      this.signOffDelivery();
    }
  };

  public setDeliveryStatus = (newStatus: DeliveryStatus) => {
    this.props.delivery.setStatus(newStatus);
  };

  public render() {
    const { isOpen } = this.state;
    const {
      delivery,
      sessionStore: { isTier3, isWeightBridgeOperator },
      viewStore: { timezone, isIT },
      translateService: { t },
      dateFormattingService: { formatDate, formatTime },
      key,
    } = this.props;

    return (
      <React.Fragment>
        <tr
          data-test="delivery"
          onClick={() => this._toggleCollapse(delivery.id)}
          className={classnames(['pointer', 'bg-white', { 'border-bottom-0': isOpen }])}
          key={key}
        >
          {!isWeightBridgeOperator && (
            <td className="text-center">
              <FontAwesome name={isOpen ? 'angle-up' : 'angle-down'} />
            </td>
          )}
          <td
            className={classnames(
              { [`${TEXT_DANGER} min-width-50`]: delivery.flagged },
              { 'pl-2': isWeightBridgeOperator }
            )}
          >
            <span className="mr-2">{delivery.grn}</span>
            {(delivery.flagged || delivery.wasFlagged) && (
              <FontAwesome data-test="flagged" name="flag" title={t.GLOBAL_LABEL_FLAGGED} className="mr-2" />
            )}
            {delivery.synced && (
              <FontAwesome
                data-test="sync"
                name="refresh"
                title={isIT ? t.GLOBAL_LABEL_EXPORTED : t.GLOBAL_LABEL_SYNCED}
                className="text-success"
              />
            )}
          </td>
          <td className="delivery-supplier">{delivery.supplierInfo}</td>
          {!!this.props.isTraderInfoShown && (
            <td className="min-width-55" data-test="trader-full-name">
              {delivery.traderFullName}
            </td>
          )}
          {(isWeightBridgeOperator || isTier3) && <td>{delivery.vehicleRegistrationNumber}</td>}
          <td className="min-width-55 word-break-all">
            {getTranslation(`STATUS_DELIVERYSTATUSES_${delivery.status.toUpperCase()}` as keyof I18N, t)}
          </td>
          <td className="text-center">{delivery.advisedGoodsToClam}</td>
          <td className="font-light font-12">
            {formatTime(delivery.createdAt, timezone)}
            <br />
            {formatDate(delivery.createdAt, timezone)}
          </td>
          {this._hasUserActionButtons && <td className="min-width-80">{this._renderButtons}</td>}
          {isWeightBridgeOperator && (
            <td className="text-center">
              <FontAwesome name="angle-right" />
            </td>
          )}
        </tr>
        <tr>
          <td colSpan={8}>
            {!isWeightBridgeOperator && (
              <Collapse isOpen={isOpen} className="full-width">
                <table data-test="expand-list-table" className="delivery-list-expand">
                  <thead>
                    <tr>
                      <th className="width-30" />
                      <th className="width-30">{t.GLOBAL_LABEL_GRN}</th>
                      <th>{t.GLOBAL_LABEL_DESCRIPTION}</th>
                      <th>{t.GLOBAL_LABEL_LOCATION}</th>
                      <th>{t.GLOBAL_LABEL_STATUS}</th>
                      <th>{t.DELIVERIES_DELIVERYLIST_RESP}</th>
                      <th className="width-30" />
                    </tr>
                  </thead>
                  <tbody>
                    {delivery.advisedGoods.map((ag: DeliveryListAdvisedGoodModel) => (
                      <tr
                        key={ag.id}
                        onClick={() => this.props.history.push(`/advised-good/${ag.id}`)}
                        className={classnames('pointer', { scale: ag.isScaleWeights })}
                      >
                        <td className="width-30" />
                        <td data-test="ag-flag-field" className={classnames({ [TEXT_DANGER]: ag.flagged })}>
                          <span className="mr-2">{`/${ag.index}`}</span>
                          {(ag.flagged || ag.wasFlagged) && (
                            <FontAwesome data-test="ag-flag" name="flag" title="flagged" />
                          )}
                        </td>
                        <td>{ag.advisedDescription}</td>
                        <td>{ag.yardLocation.name}</td>
                        <td>{getTranslation(`STATUS_ADVISEDGOOD_${ag.status}` as keyof I18N, t)}</td>
                        <td data-test="responsible">
                          {this._renderFirstAndLastName(ag)}
                          {this._renderClaimButton(ag)}
                          {this._renderAgSignoffButton(ag)}
                          {this._renderNotClaimed(ag)}
                        </td>
                        <td className="text-center">
                          <FontAwesome name="angle-right" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Collapse>
            )}

            {/* MODAL CONFIRMATION FOR MARK AS VERIFIED - TIER 2 */}
            <ModalConfirmation
              data-test="confirmation-sign-off"
              buttonYesColor="success"
              callbackYes={this.checkAndSignOffDelivery}
              heading={t.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER}
              ico="power-off"
              isOpen={this.state.confirmationModalSignOff}
              text={t.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT(delivery.grn)}
              toggleModal={this._toggleConfirmationModalSignOff}
            />

            {/* MODAL CONFIRMATION FOR WEIGHT DISCREPANCY - TIER 1 FOR DE */}
            <ModalConfirmation
              data-test="confirmation-weight-discrepancy"
              buttonYesColor="success"
              callbackYes={this.signOffDelivery}
              heading={t.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER}
              ico="power-off"
              isOpen={this.state.confirmationModalWeightDiscrepancy}
              body={this._weightDiscrepancyConfirmationBody}
              toggleModal={this._toggleConfirmationModalWeightDiscrepancySignOff}
            />

            {/* MODAL CONFIRMATION FOR MARK AS COMPLETE - TRADER */}
            <ModalConfirmation
              buttonYesColor="success"
              callbackYes={this._traderMarkAsComplete}
              heading={t.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER}
              ico="check"
              isOpen={this.state.confirmationModalComplete}
              text={t.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT(delivery.grn)}
              toggleModal={this._toggleConfirmationModalComplete}
            />

            {/* MODAL CONFIRMATION EXPORT - TIER1 */}
            <ModalConfirmation
              buttonYesColor="success"
              callbackYes={this._tier1ExportDelivery}
              heading={t.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER}
              ico="check"
              isOpen={this.state.confirmationModalExport}
              text={t.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT(delivery.grn)}
              toggleModal={this._toggleConfirmationModalExport}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }

  private get _hasUserActionButtons(): boolean {
    const {
      sessionStore: { isTier1, isTraderOrBackOffice, isAdmin },
    } = this.props;
    return isTier1 || isTraderOrBackOffice || isAdmin;
  }

  private get _hasSignoffButton(): boolean {
    const {
      sessionStore: { isTier1 },
    } = this.props;
    return isTier1 && this._isDeliveryReadyForSignoff;
  }

  private get _isDeliveryReadyForSignoff(): boolean {
    const {
      delivery: { advisedGoods, status },
    } = this.props;
    return (
      status === DeliveryStatus.READY_FOR_SIGN_OFF ||
      (advisedGoods.every(
        (ag) => ag.status === AdvisedGoodStatus.READY_FOR_SIGN_OFF || ag.status === AdvisedGoodStatus.SIGNED_OFF
      ) &&
        !advisedGoods.every((ag) => ag.status === AdvisedGoodStatus.SIGNED_OFF))
    );
  }

  private get _hasExportButton(): boolean {
    const {
      delivery: { status, synced },
      sessionStore: { isTier1 },
      viewStore: { isIT },
    } = this.props;
    return isIT && isTier1 && status === DeliveryStatus.SIGNED_OFF && !synced && !isMobile();
  }

  private get _hasCompleteButton(): boolean {
    const {
      delivery: { status },
      sessionStore: { isTrader },
    } = this.props;
    return isTrader && status === DeliveryStatus.SIGNED_OFF;
  }

  private get _hasResyncWbButton(): boolean {
    const {
      delivery: { synced },
      sessionStore: { isAdmin },
      viewStore: { isUK, isUS },
    } = this.props;
    return isAdmin && synced && (isUK || isUS);
  }

  private _resyncWb = (e: React.MouseEvent<HTMLButtonElement>) => this.props.adminResyncWb(this.props.delivery.id, e);

  private get _hasResyncSrButton(): boolean {
    const {
      delivery: { status },
      sessionStore: { isAdmin },
      viewStore: { isUK, isUS },
    } = this.props;
    return (isUK || isUS) && isAdmin && (status === DeliveryStatus.SIGNED_OFF || status === DeliveryStatus.COMPLETE);
  }

  private get _hasReexportButton(): boolean {
    const {
      delivery: { synced },
      sessionStore: { isAdmin },
      viewStore: { isIT },
    } = this.props;
    return isIT && isAdmin && synced && !isMobile();
  }

  private get _renderButtons(): React.ReactNode {
    const {
      translateService: { t },
      sessionStore: { isAdmin },
    } = this.props;

    return (
      <React.Fragment>
        {this._hasSignoffButton && (
          <Button
            data-test="signoff"
            className="font-12"
            type="button"
            size="sm"
            onClick={this._toggleConfirmationModalSignOff}
            color="success"
          >
            {t.DELIVERIES_DELIVERYLIST_SIGN_OFF}
          </Button>
        )}
        {this._hasExportButton && (
          <Button
            data-test="export-button"
            type="button"
            onClick={this._toggleConfirmationModalExport}
            color="success"
            className="font-12"
            size="sm"
          >
            {t.GLOBAL_LABEL_EXPORT}
          </Button>
        )}
        {this._hasCompleteButton && (
          <Button
            data-test="complete"
            className="font-12"
            type="button"
            size="sm"
            onClick={this._toggleConfirmationModalComplete}
            color="success"
          >
            {t.GLOBAL_FILTERKEY_COMPLETE}
          </Button>
        )}
        {this._hasResyncWbButton && (
          <Button
            data-test="resync-wb"
            className="font-12"
            style={{ marginRight: '3px' }}
            color="success"
            type="button"
            size="sm"
            onClick={this._resyncWb}
          >
            {t.DELIVERIES_DELIVERYLIST_RESYNC_WB}
          </Button>
        )}
        {this._hasResyncSrButton && (
          <Button
            data-test="resync-sr"
            className="font-12"
            style={{ marginRight: '3px' }}
            color="success"
            type="button"
            size="sm"
            onClick={this._resyncSr}
          >
            {t.DELIVERIES_DELIVERYLIST_RESYNC_SR}
          </Button>
        )}
        {this._hasReexportButton && (
          <Button
            data-test="reexport"
            className="font-12"
            style={{ marginRight: '3px' }}
            color="success"
            type="button"
            size="sm"
            onClick={this._reexport}
          >
            {t.GLOBAL_LABEL_REEXPORT}
          </Button>
        )}
        {isAdmin && (
          <Button
            data-test="eye"
            className="font-12"
            type="button"
            size="sm"
            onClick={this._openDeliveryDetailsPage}
            color="success"
          >
            <FontAwesome name="eye" />
          </Button>
        )}
      </React.Fragment>
    );
  }

  private get _weightDiscrepancyConfirmationBody(): React.ReactNode {
    return (
      <div className={TEXT_DANGER}>
        {this.props.translateService.t.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT}
      </div>
    );
  }

  private _resyncSr = (e: React.MouseEvent<HTMLButtonElement>) => this.props.adminResyncSr(this.props.delivery.id, e);

  private _reexport = (e: React.MouseEvent<HTMLButtonElement>) => this.props.adminReexport(this.props.delivery.id, e);

  private _renderFirstAndLastName = (ag: DeliveryListAdvisedGoodModel) => {
    const {
      viewStore: { isIT },
    } = this.props;
    return ag.claimedBy?.user?.fullName || (isIT && !this._hasClaimButton(ag) && ag.responsible?.fullName);
  };

  private _hasClaimButton(ag: DeliveryListAdvisedGoodModel): boolean {
    const {
      sessionStore: { isWeightBridgeOperator, isAdmin, isLab, isTraderOrBackOffice, isManager, isSameUser },
      viewStore: { isDE, isIT, isDE_D365 },
    } = this.props;
    return !(
      ag.claimedBy?.user ||
      isAdmin ||
      isLab ||
      isWeightBridgeOperator ||
      isTraderOrBackOffice ||
      isManager ||
      isDE ||
      isDE_D365 ||
      (isIT && !isSameUser(ag.responsible?.id))
    );
  }

  private _renderClaimButton = (ag: DeliveryListAdvisedGoodModel) => {
    const {
      claimAdvisedGood,
      translateService: { t },
    } = this.props;
    return (
      this._hasClaimButton(ag) && (
        <Button
          data-test="claim-btn"
          size="sm"
          color="success"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => claimAdvisedGood(ag, e)}
        >
          {t.GLOBAL_LABEL_CLAIM}
        </Button>
      )
    );
  };

  private _renderAgSignoffButton = (ag: DeliveryListAdvisedGoodModel) => {
    return <AdvisedGoodSignOffButton ag={ag} setDeliveryStatus={this.setDeliveryStatus} />;
  };

  private _renderNotClaimed = (ag: DeliveryListAdvisedGoodModel) => {
    const {
      sessionStore: { isWeightBridgeOperator, isLab },
      translateService: { t },
    } = this.props;
    return !ag.claimedBy?.user && (isWeightBridgeOperator || isLab) ? t.GLOBAL_LABEL_NOT_CLAIMED : '';
  };

  // TRADER - MARK DELIVERY AS COMPLETE
  private _traderMarkAsComplete = () => {
    this.props.deliveryStore.traderMarkDeliveryComplete(this.props.delivery).then(() => {
      this.props.refreshData();
      notification.success(this.props.translateService.t.DELIVERIES_DELIVERYLIST_COMPLETED_MSG);
    });
  };

  // TIER1 - EXPORT DELIVERY
  private _tier1ExportDelivery = () => {
    this.props.deliveryStore.exportDelivery(this.props.delivery.id, this.props.refreshData);
  };

  // TOGGLE MODAL CONFIRMATION - TIER 1 - SIGN OFF
  private _toggleConfirmationModalSignOff = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      confirmationModalSignOff: !this.state.confirmationModalSignOff,
    });
  };

  // TOGGLE MODAL CONFIRMATION WEIGHT DISCREPANCY - TIER 1 - SIGN OFF - DE
  private _toggleConfirmationModalWeightDiscrepancySignOff = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      confirmationModalWeightDiscrepancy: !this.state.confirmationModalWeightDiscrepancy,
    });
  };

  // TOGGLE VISIBILITY OF COLLAPSABLE BAR
  private _toggleCollapse = (deliveryId: string) => {
    const {
      sessionStore: { isWeightBridgeOperator },
    } = this.props;
    if (isWeightBridgeOperator) {
      this.props.history.push(`/deliveries/${deliveryId}`);
    } else {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  };

  // TOGGLE MODAL CONFIRMATION - TRADER - COMPLETE
  private _toggleConfirmationModalComplete = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      confirmationModalComplete: !this.state.confirmationModalComplete,
    });
  };

  // TOGGLE MODAL CONFIRMATION - TIER1 - EXPORT
  private _toggleConfirmationModalExport = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      confirmationModalExport: !this.state.confirmationModalExport,
    });
  };

  private _openDeliveryDetailsPage: () => void = () => {
    this.props.history.push(`/deliveries/${this.props.delivery.id}`);
  };

  private _preOpenDelivery = () => {
    if (this.props.prevDeliveryId && this.props.delivery.id === this.props.prevDeliveryId) {
      this.setState({
        isOpen: true,
      });
    }
  };
}
