import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import FontAwesome = require('react-fontawesome');
import * as classnames from 'classnames';
import * as H from 'history';
import TranslateService from 'services/TranslateService';
import DateRangePickerComponent from 'components/DateRangePickerComponent';
import { isMobile } from 'util/helpers';
import DeliveryLabFiltersStore from 'pod/deliveries/DeliveryLabFiltersStore';
import Bell from 'components/Bell';
import LabStore from 'pod/deliveries/LabStore';
import SessionStore from 'stores/SessionStore';

interface IProps {
  getListData: (queryParams: string) => void;
  changeCompleteTrue: () => void;
  changeCompleteFalse: () => void;
  isFlaggedChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
  downloadList: (queryParams: string) => void;
  history: H.History;
  deliveryLabFiltersStore?: DeliveryLabFiltersStore;
  labStore?: LabStore;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
}

@inject('translateService', 'deliveryLabFiltersStore', 'labStore', 'sessionStore')
@observer
export default class DeliveryListLabFilterComponent extends React.Component<IProps> {
  @observable
  private _filterIsOpen: boolean = false;

  public render() {
    const {
      getListData,
      changeCompleteTrue,
      changeCompleteFalse,
      isFlaggedChange,
      deliveryLabFiltersStore,
      deliveryLabFiltersStore: {
        filterShowCompleted,
        deliveryCommonFilterStore: { isFlagged },
      },
      labStore: { amountNotifications },
      translateService: { t },
    } = this.props;
    const filterBy = !filterShowCompleted ? t.GLOBAL_LABEL_IN_PROGRESS : t.GLOBAL_LABEL_COMPLETED;

    return (
      <div className="btn-container">
        <Dropdown isOpen={this._filterIsOpen} toggle={this._toggleFilters} className="float-left mr-2">
          <DropdownToggle color="false" size="sm" caret className="width-170 text-left">
            {filterBy}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              name="inProgress"
              onClick={changeCompleteFalse}
              className={classnames({ active: !filterShowCompleted })}
            >
              {t.GLOBAL_LABEL_IN_PROGRESS}
            </DropdownItem>
            <DropdownItem
              name="completed"
              onClick={changeCompleteTrue}
              className={classnames({ active: filterShowCompleted })}
            >
              {t.GLOBAL_LABEL_COMPLETED}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <div className="float-left">
          <DateRangePickerComponent
            deliveryCommonFilterStore={deliveryLabFiltersStore.deliveryCommonFilterStore}
            getListData={getListData}
          />
        </div>

        <Button data-test="isFlagged" color={isFlagged ? 'danger' : 'false'} onClick={isFlaggedChange} size="sm">
          <FontAwesome name="flag" />
        </Button>

        {!isMobile() && (
          <Button
            data-test="download-btn"
            className="ml-2 float-left"
            color="false"
            onClick={this._downloadList}
            size="sm"
          >
            <FontAwesome name="download" />
          </Button>
        )}

        <Bell amountUnreadMessages={amountNotifications} />
        {this._renderDeliveryList}
      </div>
    );
  }

  @action
  private _toggleFilters = () => {
    this._filterIsOpen = !this._filterIsOpen;
  };

  private _downloadList = () => {
    const { deliveryLabFiltersStore, downloadList } = this.props;

    downloadList(deliveryLabFiltersStore.buildQueryString);
  };

  private _goToDeliveries = () => {
    this.props.history.push('/deliveries');
  };

  private get _renderDeliveryList() {
    const {
      sessionStore: {
        tenant: {
          workflow: { isDE },
        },
      },
      translateService: { t },
    } = this.props;

    return isDE ? (
      <Button
        data-test="deliveries-btn"
        size="sm"
        className="pull-right margin-left-auto"
        color="success"
        onClick={this._goToDeliveries}
      >
        {t.GLOBAL_LABEL_DELIVERIES}
      </Button>
    ) : null;
  }
}
