import { inject, observer } from 'mobx-react';

import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import LabAnalysisModel from 'models/LabAnalysisModel';
import PrinterModel from 'models/PrinterModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import env from 'env';
import storage from 'util/storage';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import LayoutStore from 'stores/LayoutStore';
import { instance as notification } from 'util/notification';
import TranslateService from 'services/TranslateService';
import { logger } from 'util/logger';
import SessionStore from 'stores/SessionStore';
import { processDecimals } from 'util/helpers';
import ViewStore from 'stores/ViewStore';

interface IProps {
  advisedGood?: AdvisedGoodsModel;
  printers: PrinterModel[];
  selectedReceivedGood?: ReceivedGoodsModel;
  selectedReceivedGoodLab?: ReceivedGoodsLabModel;
  labInput?: LabAnalysisModel;
  translateService?: TranslateService;
  layoutStore?: LayoutStore;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
}

interface IState {
  dropDownIsOpen: boolean;
}

@inject('translateService', 'layoutStore', 'sessionStore', 'viewStore')
@observer
export default class PrintComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      dropDownIsOpen: false,
    };
  }

  public render() {
    const { dropDownIsOpen } = this.state;
    const {
      printers,
      translateService: { t },
    } = this.props;

    return (
      <Dropdown isOpen={dropDownIsOpen} toggle={this._dropdownToggle} data-test="print-component">
        <DropdownToggle caret size="sm" className="text-left min-width-100">
          <FontAwesome className="mr-0" name="print" /> {t.LABORATORY_PRINTER_LABEL}
        </DropdownToggle>
        <DropdownMenu right>
          {printers && printers.length !== 0 ? (
            printers.map((printer: PrinterModel) => (
              <DropdownItem key={printer.id} onClick={() => this._executePrint(printer)}>
                {printer.name}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem>{t.LABORATORY_PRINTER_NO_PRINTERS}</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  private _executePrint = (printer: PrinterModel) => {
    const {
      translateService: { t },
    } = this.props;

    switch (printer.model) {
      case 'Lab':
        this._printForLab(printer);
        break;
      case 'Yard':
        this._printForYard(printer);
        break;
      default:
        notification.warn(t.LABORATORY_PRINTER_WARN_MSG);
    }
  };

  private _determineWeightLabel(): string {
    const {
      advisedGood,
      selectedReceivedGood,
      viewStore: { numberOfDecimals },
    } = this.props;

    if (selectedReceivedGood.grossWeight) {
      return processDecimals(selectedReceivedGood.grossWeight, numberOfDecimals);
    }

    const advisedGoodWeight = advisedGood.deliveryReceivedWeight;
    if (advisedGoodWeight) {
      return `ADV ${processDecimals(advisedGoodWeight, numberOfDecimals)}`;
    }

    return null;
  }

  private _printForYard(printer: PrinterModel): Promise<void> {
    const {
      advisedGood,
      selectedReceivedGood,
      translateService: { t },
    } = this.props;

    if (!selectedReceivedGood.id || selectedReceivedGood.isDirty) {
      notification.warn(t.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG);
      return Promise.reject();
    }

    const weightLabel = this._determineWeightLabel();

    const template = `CLS
          SET RIBBON ON
          SET CUTTER OFF
          SET PEEL OFF
          SET PARTIAL_CUTTER OFF
          SET TEAR ON
          SPEED 4.0
          OFFSET 0.00 mm
          DIRECTION 1,0
          REFERENCE 16,0
          SIZE 100.00 mm,134.70 mm
          GAP 5.00 mm,0.00 mm
          CODEPAGE 1252
          CLS
          PUTBMP 165,4,"ELG.BMP"
          QRCODE 140,154,H,14,A,0,M2,S3,"${selectedReceivedGood.id}"
          BLOCK 40,720,720,120,"0",0,60,60,0,2,1,"${advisedGood.agTitle}"
          BLOCK 40,856,720,80,"0",0,40,40,0,2,1,"${selectedReceivedGood.description}"
          ${weightLabel !== null ? `BLOCK 40,952,720,80,"0",0,40,40,0,2,1,"${weightLabel}"` : ''}
          PRINT 1,1
          CODEPAGE 850
            `;

    if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.printForYard) {
      this.props.layoutStore.setIsLoading(true);

      return new Promise((resolve, reject) => {
        requestAnimationFrame(() => {
          try {
            JSInterface.printForYard(printer.ip, template);
            this.props.layoutStore.setIsLoading(false);
            notification.success(t.LABORATORY_PRINTER_SUCCESS_MSG);
            resolve();
          } catch (err) {
            this.props.layoutStore.setIsLoading(false);
            logger.error('yard printer error', err);
            notification.error(t.LABORATORY_PRINTER_ERROR_MSG);
            reject(err);
          }
        });
      });
    }

    if (navigator.userAgent.toLowerCase().indexOf(' electron/') > -1 || !env.isProduction) {
      const blob = new Blob([template], { type: 'text/plain;charset=utf-8' });

      const formData = new FormData();
      formData.append('myFile', blob);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `http://${printer.ip}/admin/cgi-bin/function.cgi`);
      xhr.send(formData);
      notification.success(t.LABORATORY_PRINTER_SUCCESS_MSG);
      return Promise.resolve();
    }

    logger.info('skipping printing. application environment not suitable for printing.');
    return Promise.resolve();
  }

  private _printForLab(printer: PrinterModel): Promise<void> | undefined {
    if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.printForLab) {
      const {
        selectedReceivedGoodLab,
        translateService: { t },
      } = this.props;
      if (!selectedReceivedGoodLab.id || selectedReceivedGoodLab.isDirty) {
        notification.warn(this.props.translateService.t.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG);
        return Promise.reject();
      }

      try {
        JSInterface.printForLab(printer.ip, storage.get('sid'), this.props.labInput.id);
        notification.success(t.LABORATORY_PRINTER_SUCCESS_MSG);
      } catch (err) {
        logger.error('lab printer error', err);
        notification.error(t.LABORATORY_PRINTER_ERROR_MSG);
      }
    } else {
      notification.error(`JSInterface was not found`);
    }
  }

  private _dropdownToggle = () => {
    this.setState({
      dropDownIsOpen: !this.state.dropDownIsOpen,
    });
  };
}
