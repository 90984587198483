import { action, observable } from 'mobx';
import RootService from 'services/RootService';

export interface IContract {
  id: string;
  companyNumber: string;
  locationNumber: string;
  materialNumber: string;
  supplierNumber: string;
  orderNumber: string;
  recordSequence: string;
  weight: string;
  orderDate: string;
  traderCode: string;
  details: IContractDetails[];
}

export interface IContractDetails {
  id: string;
  companyNumber: string;
  appliedWeight: number;
  locationNumber: string;
  supplierNumber: string;
  orderNumber: string;
  recordSequence: string;
  weight: string;
  materialNumber: string;
  orderDate: string;
  traderCode: string;
}

export interface IContractNumber {
  id: number;
  name: string;
}

export default class ContractStore {
  public constructor(private readonly rootService: RootService) {}

  @observable public contractsList?: IContractNumber[] = [];

  @action
  public setContractsList(list: string[]) {
    this.contractsList = list.map((name: string, index: number) => ({ id: index, name }));
  }

  @action
  public getContractNumbers() {
    this._getContractNumbersRequest().then((res) => {
      this.setContractsList(res);
    });
  }

  @action
  public getCurrentContractNumber(number: string): Promise<IContract> {
    return this.rootService.ajaxService.get(`us-contract/${number}`);
  }

  private _getContractNumbersRequest = (): Promise<string[]> => {
    return this.rootService.ajaxService.get('us-contract');
  };
}
