import { action, observable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import { CountryCode } from '../util/enums';

export default class UserTenantModel extends IdNameModel {
  @observable public isDefault: boolean = false;
  @observable public regionCode?: string;
  @observable public code?: string;
  @observable public workflowCode?: CountryCode;

  @action
  public update(obj: UserTenantModel) {
    this.updater.update(this, obj, UserTenantModel);
    return this;
  }

  @action
  public changeIsDefault = (val: boolean) => (this.isDefault = val);
}
