import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';
import { action, observable, runInAction } from 'mobx';

import TranslateService from 'services/TranslateService';
import SessionStore from 'stores/SessionStore';
import IdNameModel from 'models/IdNameModel';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../assets/i18n/i18n';
import UserRoleModel from 'models/UserRoleModel';

interface IChangeUserRoleProps {
  onUserRoleChange: (newRole: IdNameModel) => void;
  translateService?: TranslateService;
  sessionStore?: SessionStore;
}

@inject('translateService', 'sessionStore')
@observer
export default class ChangeUserRole extends React.Component<IChangeUserRoleProps> {
  @observable
  private _isOpen: boolean = false;

  public render() {
    const {
      translateService: { t },
      sessionStore: { user },
    } = this.props;

    return !!user && user.roles.length > 1 ? (
      <Dropdown isOpen={this._isOpen} toggle={this._toggleIsOpen}>
        <DropdownToggle
          outline={false}
          size="sm"
          caret
          className={classnames(['btn btn-secondary btn-block margin-bottom change-user-role'])}
        >
          {t.HEADER_LABEL_SWITCH_ROLE}
        </DropdownToggle>
        <DropdownMenu>
          {user.roles.map((role: UserRoleModel) => {
            return (
              <DropdownItem
                key={role.id}
                onClick={() => {
                  this._onDropDownItemClick(role);
                }}
                data-user-role={role.name}
                className={classnames({ active: this._isDropDownItemActive(role.id) })}
              >
                {getTranslation(`GLOBAL_USERROLE_${role.name.toUpperCase()}` as keyof I18N, t)}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    ) : null;
  }

  private _onDropDownItemClick = (role: UserRoleModel) => {
    const { onUserRoleChange } = this.props;
    if (!this._isDropDownItemActive(role.id)) {
      onUserRoleChange(role);
    }
  };

  private _isDropDownItemActive = (roleId: string) => {
    const {
      sessionStore: { user },
    } = this.props;
    return user.activeRole && user.activeRole.id === roleId;
  };

  @action
  private _toggleIsOpen: () => void = () => {
    this._isOpen = !this._isOpen;
  };
}
