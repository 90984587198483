import { action, computed, observable } from 'mobx';

import IdNameActiveModel from 'models/IdNameActiveModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export default class LasernetPrinterModel extends IdNameActiveModel implements IConstructSaveObj<LasernetPrinterModel> {
  @observable public address: string = '';
  @observable public description?: string = '';

  @computed
  public get constructSaveObj(): LasernetPrinterModel {
    return {
      name: this.name,
      description: this.description,
      address: this.address,
      active: this.active,
    } as LasernetPrinterModel;
  }

  @action
  public update = (obj: LasernetPrinterModel) => {
    this.updater.update(this, super.update(obj), LasernetPrinterModel);
    return this;
  };

  @action public changeDescription = (newDescription: string) => (this.description = newDescription);
  @action public changeAddress = (newAddress: string) => (this.address = newAddress);
}
