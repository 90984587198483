import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, Label } from 'reactstrap';

import MaterialModel from 'models/MaterialModel';
import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';

interface IMaterialDetailComponentProps {
  content: MaterialModel;
  translateService?: TranslateService;
}

const MaterialDetailComponent: React.FunctionComponent<IMaterialDetailComponentProps> = ({
  content,
  translateService: { t },
}) =>
  content && (
    <React.Fragment>
      <FormGroup row>
        <Label for="isDefault" sm={4}>
          {t.GLOBAL_LABEL_IS_DEFAULT}
        </Label>
        <Col sm={8}>
          <InputWithNullCheck
            data-test="isDefault"
            checked={content.isDefault}
            id="isDefault"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setIsDefault(e.target.checked)}
            type="checkbox"
            className="ml-1"
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );

export default inject('translateService')(observer(MaterialDetailComponent));
