import * as React from 'react';
import * as classnames from 'classnames';
import { Container } from 'reactstrap';

import NavlinkCustom from 'domain/NavlinkCustom';
import { observer } from 'mobx-react';

interface IProps {
  heading?: string;
  close?: string; // PASS CLOSE URL PATH
  ico?: string; // ICON DISPLAYED BEFORE HEADING TEXT
}

@observer
export default class Sidebar extends React.Component<IProps> {
  public render() {
    const { heading, close } = this.props;
    return (
      <div className="sidebar">
        {heading && (
          <div className={classnames(['sidebar-heading', { 'with-close-button': !!close }])}>
            <h5 className="mb-0">{heading}</h5>
            {close && (
              <NavlinkCustom to={close} tagName="button" className="close">
                &times;
              </NavlinkCustom>
            )}
          </div>
        )}
        <Container fluid>{this.props.children}</Container>
      </div>
    );
  }
}
