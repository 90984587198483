import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router';
import { action, observable, reaction } from 'mobx';

import { TAdminCommonItem } from 'pod/admin/AdminStore';
import AdminListUploadComponent from 'pod/admin/commonComponents/AdminListUploadComponent';
import TranslateService from 'services/TranslateService';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import CommonApiCalls from 'pod/admin/commonComponents/CommonApiCalls';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';
import { instance as notification } from 'util/notification';

interface ICommonAdminListRouteDefaultProps {
  hasUploadBtn?: boolean;
  hasCreateBtn?: boolean;
}

interface ICommonAdminListRouteProps extends ICommonAdminListRouteDefaultProps {
  rootPath: AdminRootPath;
  api: CommonApiCalls<TAdminCommonItem>;
  itemsTable: React.ReactNode;
  itemCreateForm: React.ReactNode;
  componentRoute: React.ComponentClass;
  content: TAdminCommonItem;
  clearModel: () => void;
  sectionNameTranslation: string;
  header: string;
  limit?: number;
  exampleFileNames?: ExampleFileNames;
  translateService?: TranslateService;
  isCodeRequired?: boolean;
  buttons?: React.ReactNode;
}

@inject('translateService')
@observer
export default class CommonAdminListRoute extends React.Component<ICommonAdminListRouteProps> {
  public static defaultProps: ICommonAdminListRouteDefaultProps = {
    hasUploadBtn: true,
    hasCreateBtn: true,
  };

  public constructor(props: ICommonAdminListRouteProps) {
    super(props);
    reaction(() => !this.showCreate, this.props.clearModel);
  }

  @observable public showUpload: boolean = false;
  @observable public showCreate: boolean = false;

  public componentDidMount() {
    const { api } = this.props;
    api.getAllItems();
  }

  @action
  public toggleUpload = () => {
    this.showUpload = !this.showUpload;
  };

  @action
  public toggleCreate = () => {
    this.showCreate = !this.showCreate;
  };

  public uploadList = (file: File) => {
    const { api } = this.props;

    const formData = new FormData();
    // IE support only FormData.append
    formData.append('file', file);

    return api.uploadItemList(formData);
  };

  public createItem = (item: TAdminCommonItem) => {
    const {
      api,
      translateService: { t },
      sectionNameTranslation,
    } = this.props;
    return api.postItem(item).then(() => {
      notification.success(t.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG(sectionNameTranslation));
      this.toggleCreate();
    });
  };

  public render() {
    const {
      itemsTable,
      itemCreateForm,
      translateService: { t },
      rootPath,
      componentRoute,
      content,
      header,
      exampleFileNames,
      sectionNameTranslation,
      hasUploadBtn,
      limit,
      isCodeRequired,
      hasCreateBtn,
      buttons,
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {header}
              {hasCreateBtn && (
                <Button data-test="create-btn" className="float-right" color="success" onClick={this.toggleCreate}>
                  <FontAwesome name="plus" className="mr-2" />
                  {t.GLOBAL_LABEL_CREATE}
                </Button>
              )}
              {hasUploadBtn && (
                <Button
                  className="float-right margin-right-1rem"
                  color="success"
                  onClick={this.toggleUpload}
                  data-test="upload"
                >
                  <FontAwesome name="plus" className="mr-2" />
                  {t.GLOBAL_LABEL_UPLOAD}
                </Button>
              )}
              {!!buttons ? buttons : null}
            </h2>
            {itemsTable}
          </Col>
        </Row>
        <AdminListUploadComponent
          name={rootPath}
          uploadFile={this.uploadList}
          toggleUpload={this.toggleUpload}
          showUpload={this.showUpload}
          heading={t.ADMIN_COMMONLIST_UPLOAD_HEADER(header)}
          exampleFileName={exampleFileNames}
        />
        {/* MODAL - CREATE NEW ITEM */}
        <Modal isOpen={this.showCreate} toggle={this.toggleCreate} backdrop="static">
          <ModalHeader toggle={this.toggleCreate}>
            {t.ADMIN_COMMONLIST_CREATE_HEADER(sectionNameTranslation)}
          </ModalHeader>
          <ModalBody>
            <CommonAdminDetailComponent
              content={content}
              saveAction={this.createItem}
              limit={limit}
              isCodeRequired={isCodeRequired}
            >
              {itemCreateForm}
            </CommonAdminDetailComponent>
          </ModalBody>
        </Modal>

        <Route path={`/admin/${rootPath}/:id`} component={componentRoute} />
      </Container>
    );
  }
}
