import { action, computed, observable, observe } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { InputType } from 'reactstrap/lib/Input';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import { CountryCode } from 'util/enums';

export interface IKeyValueModelConstructObject {
  id?: string;
  name: string;
  value: number;
}

export default class KeyValueModel extends UpdateModel<KeyValueModel> implements ISetHasChanged {
  constructor() {
    super();
    observe(this, this.onChange);
  }

  public id: string = '';

  @observable public name: string = '';
  @observable public value: number = null;
  // needs for UK for processing types
  @observable public units: string = null;
  @observable public inputType: Map<CountryCode, InputType> = null;
  @observable public type: string = '';

  @computed
  public get constructObject(): IKeyValueModelConstructObject {
    const res = {
      name: this.name,
      value: this.value,
    } as IKeyValueModelConstructObject;

    if (this.id) {
      res.id = this.id;
    }

    return res;
  }

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  @action
  public changeName = (newName: string) => {
    this.name = newName;
  };

  @action
  public changeValue = (newValue: number) => {
    this.value = newValue;
  };

  @action
  public update = (obj: KeyValueModel) => {
    this.updater.update(this, obj, KeyValueModel);

    return this;
  };
}
