import { action, autorun, computed, observable, observe } from 'mobx';
import ValidateModel from 'models/ValidateModel';
import ReceivedGoodsModel, {
  IReceivedGoodsModelConstructSaveObj,
  IReceivedGoodsParams,
} from 'models/ReceivedGoodsModel';
import { autoAddNewLine, autoRemoveLine } from 'util/mobx-utils';
import MaterialModel from 'models/MaterialModel';
import { cloneObj } from 'util/helpers';
import { ISetHasChangedAndClearIsDirty } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import { CountryCode } from 'util/enums';

export interface ISubAdvisedGoodsModelConstructObj {
  id: string;
  receivedGoods: IReceivedGoodsModelConstructSaveObj[];
}

interface ISubAdvisedGoodsModelValidationKeys {
  receivedGoods: boolean;
  RGDescription: boolean;
  RGGrossWeight: boolean;
  RGBulkDensity: boolean;
  RGFoundQuality: boolean;
  RGMainType: boolean;
  RGMaterialDescription: boolean;
  RGWiDone: boolean;
  RGContamination: boolean;
  RGProductForm: boolean;
  RGProductQuality: boolean;
}

export type SubAdvisedGoodsValidatorKey = keyof Partial<ISubAdvisedGoodsModelValidationKeys>;

export interface ISubAdvisedGoodsParams {
  receivedGoodsParams: IReceivedGoodsParams;
  defaultMaterials: MaterialModel[];
}

export default class SubAdvisedGoodsModel
  extends ValidateModel<ISubAdvisedGoodsModelValidationKeys, MaterialModel[], ISubAdvisedGoodsParams>
  implements ISetHasChangedAndClearIsDirty {
  constructor(public readonly subAdvisedGoodsParams: ISubAdvisedGoodsParams) {
    super();
    observe(this, this.onChange);
    autorun(() => this.shouldTrigger);
  }

  @observable public hasChanged?: boolean = false;
  @observable public advisedDescription?: string = '';
  @observable public advisedWeight?: number = null;
  @observable public receivedGoods?: ReceivedGoodsModel[] = [];
  @observable public id?: string = null;
  @observable public sagTitle?: string = null;

  public generalValidatorKeys: Array<keyof Partial<ISubAdvisedGoodsModelValidationKeys>> = [
    'receivedGoods',
    'RGDescription',
    'RGGrossWeight',
  ];

  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<ISubAdvisedGoodsModelValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<ISubAdvisedGoodsModelValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys],
    [
      CountryCode.DE,
      this.generalValidatorKeys.concat([
        ...this._getRGContaminationValidationFields(CountryCode.DE),
        ...this._getRGClassificationValidationFields(CountryCode.DE),
        'RGWiDone',
      ]),
    ],
    [
      CountryCode.DE_D365,
      this.generalValidatorKeys.concat([
        ...this._getRGContaminationValidationFields(CountryCode.DE_D365),
        ...this._getRGClassificationValidationFields(CountryCode.DE_D365),
        'RGProductForm',
        'RGProductQuality',
      ]),
    ],
    [CountryCode.UK, this.generalValidatorKeys],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  private _getRGContaminationValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof ISubAdvisedGoodsModelValidationKeys>> {
    const { receivedGoodsParams } = this.subAdvisedGoodsParams;
    return receivedGoodsParams.countryCode === countryCode && receivedGoodsParams.rgContaminationsSectionRequired
      ? ['RGContamination']
      : [];
  }

  private _getRGClassificationValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof ISubAdvisedGoodsModelValidationKeys>> {
    const { receivedGoodsParams } = this.subAdvisedGoodsParams;
    return receivedGoodsParams.countryCode === countryCode && receivedGoodsParams.rgClassificationsSectionRequired
      ? ['RGBulkDensity', 'RGFoundQuality', 'RGMainType', 'RGMaterialDescription']
      : [];
  }

  public get validators(): ISubAdvisedGoodsModelValidationKeys {
    return {
      receivedGoods: this.filledOutRGs.length !== 0,
      RGDescription: this.filledOutRGs.every((r) => r.validators.description),
      RGGrossWeight: this.filledOutRGs.every((r) => r.validators.grossWeight),
      RGBulkDensity: this.filledOutRGs.every((r) => r.validators.bulkDensity),
      RGFoundQuality: this.filledOutRGs.every((r) => r.validators.foundQuality),
      RGMainType: this.filledOutRGs.every((r) => r.validators.mainType),
      RGMaterialDescription: this.filledOutRGs.every((r) => r.validators.materialDescription),
      RGWiDone: this.filledOutRGs.every((r) => r.validators.wiDone),
      RGContamination: this.filledOutRGs.every((r) => r.validators.contamination),
      RGProductForm: this.filledOutRGs.every((r) => r.validators.productForm),
      RGProductQuality: this.filledOutRGs.every((r) => r.validators.productQuality),
    };
  }

  public get filledOutRGs(): ReceivedGoodsModel[] {
    return this.receivedGoods.filter((rg) => rg.shouldAddNewLine);
  }

  @computed
  public get isDirty() {
    return this.hasChanged || this.receivedGoods.some((x) => x.isDirty);
  }

  @computed
  public get amountOfActiveRG(): number {
    return this.receivedGoods.filter((rg) => rg.isFilledOut).length;
  }

  @computed
  public get amountOfEmptyRG(): number {
    return this.receivedGoods.length - this.amountOfActiveRG;
  }

  @computed
  public get shouldAddNewLine(): boolean {
    return this.amountOfEmptyRG < 1;
  }

  @computed
  public get shouldRemoveLine(): boolean {
    return this.amountOfEmptyRG > 1;
  }

  public get shouldTrigger(): void | undefined {
    if (this.amountOfEmptyRG === 1) {
      return;
    }

    if (!this.receivedGoods.length) {
      this.pushNewReceivedGood();
      return;
    }
    if (this.shouldAddNewLine) {
      autoAddNewLine(this.receivedGoods, () => this.pushNewReceivedGood());
    }
    if (this.shouldRemoveLine) {
      autoRemoveLine(this.receivedGoods, this.updateReceivedGoods);
    }
  }

  @action
  public update = (obj: SubAdvisedGoodsModel, nonDefaultMaterial: MaterialModel[]) => {
    const newSubAdvisedGoodsModel = cloneObj(obj);
    if (newSubAdvisedGoodsModel && newSubAdvisedGoodsModel.receivedGoods) {
      newSubAdvisedGoodsModel.receivedGoods = newSubAdvisedGoodsModel.receivedGoods.map((r: ReceivedGoodsModel) =>
        new ReceivedGoodsModel({ ...this.subAdvisedGoodsParams.receivedGoodsParams }).update(
          r,
          this.subAdvisedGoodsParams.defaultMaterials ?? [],
          nonDefaultMaterial
        )
      );
    }

    this.updater.update(this, newSubAdvisedGoodsModel, SubAdvisedGoodsModel, { ...this.subAdvisedGoodsParams });

    return this;
  };

  @action
  public pushNewReceivedGood() {
    // CREATE NEW RECEIVED GOOD FROM UI
    const hasChanged = this.hasChanged;
    const newReceivedGood = new ReceivedGoodsModel({ ...this.subAdvisedGoodsParams.receivedGoodsParams });
    newReceivedGood.createDefaultMaterials(this.subAdvisedGoodsParams.defaultMaterials ?? []); // ADD DEFAULT MATERIALS
    newReceivedGood.materials.pushNewMaterial();
    const nextIndex = Math.max(...this.receivedGoods.map((item) => item.index), this.receivedGoods.length) + 1;
    const sagTitle = this.sagTitle?.split('/') || [];
    sagTitle.pop();
    newReceivedGood.rgTitle = `${sagTitle.join('/')}/${nextIndex}`;
    this.receivedGoods.push(newReceivedGood);

    if (!hasChanged) {
      // IF AUTO CREATING NEW LINES ON PAGE LOAD, DON'T TRIGGER ISDIRTY
      newReceivedGood.clearIsDirty();
    }

    return newReceivedGood;
  }

  @action
  public clearIsDirty = () => {
    this.setHasChanged(false);
    this.receivedGoods.forEach((receivedGood) => receivedGood.clearIsDirty());
  };

  // CONSTRUT OBJ FOR PUT ADVISED GOOD
  @action
  public constructSaveObj(): ISubAdvisedGoodsModelConstructObj {
    // TODO: REFACTOR const receivedGoods
    const receivedGoods = this.receivedGoods.map(
      (receivedGood) => receivedGood.shouldAddNewLine && receivedGood.constructSaveObj()
    );
    return {
      id: this.id,
      receivedGoods: receivedGoods.filter((rg) => !!rg),
    };
  }

  @action
  public updateReceivedGoods = (newRG: ReceivedGoodsModel[]) => {
    this.receivedGoods = newRG;
  };

  public onChange = (change: { name: string }) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };
}
