import { action, computed, observable } from 'mobx';

import CertificateModel from 'models/CertificateModel';
import { ICode, IConstructSaveObj, IName } from 'models/ModelInterfaces';

export default class RoadHaulierModel extends CertificateModel
  implements IName, ICode, IConstructSaveObj<RoadHaulierModel> {
  @observable public code: string = '';
  @observable public name: string = '';
  @observable public id: string = null;
  @observable public authorizationNumber?: string = '';
  @observable public active: boolean;

  @computed
  public get constructSaveObj(): RoadHaulierModel {
    return {
      name: this.name,
      code: this.code,
      active: this.active,
      expirationDate: this.expirationDate,
      authorizationNumber: this.authorizationNumber,
    } as RoadHaulierModel;
  }

  @action
  public update = (obj: RoadHaulierModel) => {
    super.update(obj);
    return this;
  };

  @action public changeAuthorizationNumber = (newNumber: string): void => {
    this.authorizationNumber = newNumber;
  };

  @action
  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);

  @action
  public changeName = (newName: string) => (this.name = newName);

  @action
  public changeCode = (newCode: string) => (this.code = newCode);
}
