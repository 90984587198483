import { action, autorun, computed, observable, observe } from 'mobx';

import UpdateModel from 'models/UpdateModel';
import MaterialsModel from 'models/MaterialsModel';
import MaterialModel from 'models/MaterialModel';
import { cloneObj } from 'util/helpers';
import { autoAddNewLine } from 'util/mobx-utils';
import { sortBy } from 'lodash';

export default class MaterialsListModel extends UpdateModel<MaterialsListModel> {
  constructor() {
    super();
    observe(this, this.onChange);
    autorun(() => this.shouldTrigger);
  }

  @observable public items: MaterialsModel[] = [];

  public onChange = (change: { name: string }) => {
    if (change.name !== 'hasChanged') {
      this.hasChanged = true;
    }
  };

  @action
  public update = (
    newData: MaterialsListModel | MaterialsModel[],
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) => {
    const newMaterialsListModel = newData instanceof MaterialsListModel ? cloneObj(newData) : new MaterialsListModel();

    if (!(newData instanceof MaterialsListModel) && newData && newData.length) {
      newMaterialsListModel.items = newData.map((item) => new MaterialsModel().update(item));
    }
    newMaterialsListModel.items = newMaterialsListModel.items.filter((i) => i.material);

    for (const commonMaterial of defaultMaterials) {
      if (!newMaterialsListModel.items.find((m) => m.material.id === commonMaterial.id)) {
        newMaterialsListModel.items.push(new MaterialsModel().createDefaultMaterial(commonMaterial));
      }
    }

    newMaterialsListModel.items = sortBy(newMaterialsListModel.items, (el: MaterialsModel) => el.material.position);

    // PUSH ONE BLANK MATERIALS, FOR USER TO BE ABLE TO CREATE CUSTOM MATERIAL
    if (nonDefaultMaterials && nonDefaultMaterials.length !== 0) {
      newMaterialsListModel.items.push(new MaterialsModel());
    }

    this.updater.update(this, newMaterialsListModel, MaterialsListModel);
    return this;
  };

  public get shouldTrigger() {
    return autoAddNewLine(this.items, () => this.pushNewMaterial());
  }

  @action
  public pushNewMaterial() {
    const newMaterial = new MaterialsModel();
    this.items.push(newMaterial);

    return newMaterial;
  }

  @computed
  public get filledMaterials() {
    return this.items.filter((material: MaterialsModel) => material.weight);
  }

  @action
  public clearIsDirty = () => {
    this.items.forEach((item) => item.setHasChanged(false));
  };

  @computed
  public get isDirty(): boolean {
    return this.hasChanged || this.items.some((x) => x.hasChanged);
  }

  @computed
  public get hasItems(): boolean {
    return this.filledMaterials.length > 0;
  }

  @action
  public createDefaultMaterials(defaultMaterials: MaterialModel[]) {
    this.items = defaultMaterials.map((dm) => {
      return new MaterialsModel().createDefaultMaterial(dm);
    });
    this.setHasChanged(false);
    return this;
  }
}
