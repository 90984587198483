import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { Alert, Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import { getTranslation, isMobile, joinWithCommaSeparator, processDecimals } from 'util/helpers';

import AdvisedGoodsModel, {
  AdvisedGoodsValidatorKey,
  IEwcCodeUpdateSocketResponse,
  IWeightUpdateSocketResponse,
} from 'models/AdvisedGoodsModel';
import AdvisedGoodsStore, { IFlaggedResponse, IUnloadedResponse } from 'pod/advisedGoods/AdvisedGoodsStore';
import CommonStore from 'stores/CommonStore';
import LabAnalysisModel from 'models/LabAnalysisModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import SessionStore from 'stores/SessionStore';
import SortingReportLineComponent from 'pod/advisedGoods/SortingReportLineComponent';
import StickyFooter from 'domain/StickyFooter';
import SubAdvisedGoodsModel from 'models/SubAdvisedGoodsModel';
import TranslateService from 'services/TranslateService';
import { I18N } from '../../../assets/i18n/i18n';
import { action, computed, observable } from 'mobx';
import SocketService from 'services/SocketService';
import { AdvisedGoodStatus, DeductionsList, SocketServiceTopic } from 'util/enums';
import PicturesComponent from 'components/PicturesComponent';
import IdNameModel from 'models/IdNameModel';
import Autocomplete from 'domain/Autocomplete';
import InputNumber from 'components/InputNumber';
import OperatorModel from 'models/OperatorModel';
import ViewStore from 'stores/ViewStore';
import {
  ADVISED_COMMENT_MAX_LENGTH_DEFAULT,
  ADVISED_COMMENT_MAX_LENGTH_FR,
  ADVISED_PACKAGING_MAX_LENGTH_FR,
  TEXT_DANGER,
  TRANSFER_ID_NULL_ERROR_MSG,
  TRANSFER_WEIGHT_ERROR_MSG,
} from 'util/constants';
import { ITransferData } from 'models/TransferModel';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import InputWithNullCheck from 'components/InputWithNullCheck';
import AdvisedGoodDeliveryDetailsComponent from 'pod/advisedGoods/AdvisedGoodDeliveryDetailsComponent';
import AdvisedGoodWeightDetailsComponent from 'pod/advisedGoods/AdvisedGoodWeightDetailsComponent';
import AllWeightsModel from 'models/AllWeightsModel';
import BackButton from 'components/BackButton';
import ProcessingTimeComponent from 'pod/receivedGoods/ProcessingTimeComponent';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import AdvisedGoodSignOffButton from 'pod/advisedGoods/AdvisedGoodSignOffButton';
import PromiseResolver from 'models/PromiseResolver';

interface IQuery {
  id: string;
  deliveredGoodId?: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  advisedGoodsStore?: AdvisedGoodsStore;
  commonStore?: CommonStore;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  socketService?: SocketService;
  receivedGoodsStore?: ReceivedGoodsStore;
}

interface IState {
  attachmentsToUpload: FileList;
  completeConfirmationModal: boolean;
  dirty: boolean;
  flagConfirmationModal: boolean;
  receivedWeight: number;
  saveConfirmationModal: boolean;
  saveConfirmationModalForLab: boolean;
  statusRollbackConfirmationModal: boolean;
  verifyConfirmationModal: boolean;
  claimConfirmationModal: boolean;
  unclaimConfirmationModal: boolean;
  unloadConfirmationModal: boolean;
  isTareNetWeightFieldsShown: boolean;
}

@inject(
  'advisedGoodsStore',
  'receivedGoodsStore',
  'commonStore',
  'sessionStore',
  'viewStore',
  'translateService',
  'socketService'
)
@observer
export default class AdvisedGoodRoute extends React.Component<IProps, IState> {
  private _initialYardLocation: IdNameModel;
  private _hasAttachmentsPromise: PromiseResolver<boolean> = new PromiseResolver();

  @observable
  private _hasAttachmentsConfirmationModal = false;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      attachmentsToUpload: null,
      completeConfirmationModal: false,
      dirty: false,
      flagConfirmationModal: false,
      receivedWeight: 0,
      saveConfirmationModal: false,
      saveConfirmationModalForLab: false,
      statusRollbackConfirmationModal: false,
      verifyConfirmationModal: false,
      claimConfirmationModal: false,
      unclaimConfirmationModal: false,
      unloadConfirmationModal: false,
      isTareNetWeightFieldsShown: false,
    };
  }

  // LOAD DETAIL AND LOAD COMMON FOR SELECTS
  public componentDidMount() {
    const { socketService } = this.props;
    this._loadData();
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_UPDATED, this._onSocketAdvisedGoodUpdate);
    socketService.subscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_GROSS_WEIGHT_UPDATED,
      this._updateAdvisedGoodGrossWeight
    );
    socketService.subscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_TARE_WEIGHT_UPDATED,
      this._updateAdvisedGoodTareWeight
    );
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_EWC_CODE, this._updateEwcCode);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_WEIGHTS_UPDATED, this._updateWeights);
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
    socketService.subscribeTopic(SocketServiceTopic.RECEIVED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
  }

  public componentWillUnmount(): void {
    const { socketService, advisedGoodsStore } = this.props;

    advisedGoodsStore.setContent(null);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_UPDATED, this._onSocketAdvisedGoodUpdate);
    socketService.unsubscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_GROSS_WEIGHT_UPDATED,
      this._updateAdvisedGoodGrossWeight
    );
    socketService.unsubscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_TARE_WEIGHT_UPDATED,
      this._updateAdvisedGoodTareWeight
    );
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_EWC_CODE, this._updateEwcCode);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_WEIGHTS_UPDATED, this._updateWeights);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
    socketService.unsubscribeTopic(SocketServiceTopic.RECEIVED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
  }

  public preOpenModal = (itemId: string) => {
    return itemId === this.props.match.params.deliveredGoodId;
  };

  // TRANSFER DELIVERED GOOD
  public transferDeliveredGoodWithIdCheck = async (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => {
    const {
      translateService: { t },
      viewStore: { isDE, isDE_D365 },
    } = this.props;
    if (!deliveredGood.id) {
      notification.warn(this.props.translateService.t.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG);
      return Promise.reject(TRANSFER_ID_NULL_ERROR_MSG);
    }

    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      if (!isDE && !isDE_D365 && transfer.weight > deliveredGood.grossWeight) {
        notification.error(t.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG);
        return Promise.reject(TRANSFER_WEIGHT_ERROR_MSG);
      }

      return this._transferDeliveredGood(deliveredGood, transfer);
    }
  };

  // CLAIM ADVISED GOOD
  public claimAdvisedGood = () => {
    const {
      advisedGoodsStore: { postClaimAdvisedGood, content },
      viewStore: { isDE, isDE_D365 },
    } = this.props;
    postClaimAdvisedGood(content.id, isDE || isDE_D365 ? content.yardLocation.id : null).then(
      (res: { newStatus: AdvisedGoodStatus; claimedBy: OperatorModel }) => {
        content.setStatus(res.newStatus);
        content.setClaimedBy(res.claimedBy);
        // this needed to clear hasChanged status after claim AG for Germany(user can change location and hasChanged will be true)
        content.clearIsDirty();
        notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG);
      }
    );
  };

  // UPDATE FUNCTION FOR RECEIVED GOOD - GROSS WEIGHT
  public updateReceivedGoodGrossWeight = (item: ReceivedGoodsModel, newVal: number) => {
    item.setGrossWeight(newVal);
  };

  // TIER 2 OR TIER 1 - CONFIRM DELIVERY UNLOAD COMPLETION
  public confirmAdvisedGoodUnloaded = async () => {
    const {
      viewStore: { isIT, isFR },
      translateService: { t },
    } = this.props;
    if ((isIT || isFR) && !this.props.advisedGoodsStore.content.validators.RGsContamination) {
      notification.error(
        t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS(t.RECEIVED_GOOD_CONTAMINATION_TITLE.toLowerCase())
      );
      return false;
    }
    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      const {
        advisedGoodsStore: { content, postAdvisedGoodUnload },
      } = this.props;
      postAdvisedGoodUnload(content).then((res: IUnloadedResponse) => {
        content.setStatus(res.status);
        if (res.deduction) {
          content.deduction.update(res.deduction);
        }
        content.clearIsDirty();
        notification.success(t.ADVISEDGOOD_UNLOAD_UPDATE_MSG);
      });
    }
  };

  public get deliveryReceivedWeight(): number {
    return this.props.advisedGoodsStore.content.deliveryReceivedWeight;
  }

  public get isSortingWeightMoreThanReceived(): boolean {
    const {
      advisedGoodsStore: {
        content: { totalAdvisedGoodWeightWithDeductions },
      },
    } = this.props;
    return this.deliveryReceivedWeight && totalAdvisedGoodWeightWithDeductions > this.deliveryReceivedWeight;
  }

  public render() {
    const { flagConfirmationModal, isTareNetWeightFieldsShown } = this.state;
    const {
      viewStore: { numberOfDecimals, isFR },
      commonStore: { stockItems },
      translateService: { t },
      advisedGoodsStore: { content, downloadAttachments, uploadAttachments, uploadAttachment },
    } = this.props;

    if (!content || !stockItems) {
      return null;
    }

    return (
      <Container fluid className="padding-bottom-50">
        {/* BACK BUTTON, WITH DIRTY CHECK */}
        <BackButton onClick={this._goBack}>{t.GLOBAL_LABEL_DELIVERIES}</BackButton>

        <Row>{this._renderButtons}</Row>

        <Row className="bg-white border-top border-bottom pt-3 pb-3">
          <Col className="min-width-385">
            <AdvisedGoodDeliveryDetailsComponent />
          </Col>
          <Col className="min-width-385">
            <AdvisedGoodWeightDetailsComponent />
          </Col>
        </Row>

        {this._renderLocation}
        {this._renderDeduction}
        <Row>
          <Col size={12} className="pt-1 pb-1 pl-0 pr-0">
            {this._hasSubAdvisedGoods &&
              content.subAdvisedGoods.map((subAdvisedGood: SubAdvisedGoodsModel) => (
                <div key={subAdvisedGood.id}>
                  {/* SORTING REPORT */}
                  <h5 className="mt-4 clearfix pl-3 pr-3 flex">
                    {content.subAdvisedGoods.length === 1 ? (
                      t.LABORATORY_ADVISEGOODS_SORTING_REPORT
                    ) : (
                      <span>
                        {subAdvisedGood.advisedDescription}
                        <span className="pl-3 font-light">
                          {processDecimals(subAdvisedGood.advisedWeight, numberOfDecimals)}
                        </span>
                      </span>
                    )}
                    {this._hasShowHideTareNetWeightButton && (
                      <Button
                        data-test="show-tare-net-weight"
                        className="transparent-btn margin-left-1rem"
                        color="false"
                        onClick={this._toggleIsTareNetWeightFieldsShown}
                      >
                        {isTareNetWeightFieldsShown ? (
                          <React.Fragment>
                            <FontAwesome className="margin-right-10" name="angle-left" />
                            {t.GLOBAL_LABEL_HIDE}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <FontAwesome className="margin-right-10" name="angle-right" />
                            {t.GLOBAL_LABEL_SHOW}
                          </React.Fragment>
                        )}
                      </Button>
                    )}
                    {content.canNotCreateNewRG && (
                      <span data-test="rg-warning" className="color-warning margin-left-1rem">
                        <FontAwesome name="exclamation-triangle" />
                        {t.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE}
                      </span>
                    )}
                  </h5>

                  {this._hasBalanceCheckbox && (
                    <FormGroup check data-test="balance">
                      <Label check className="pl-3">
                        <InputWithNullCheck
                          checked={content.balance}
                          disabled={this._isReadOnly}
                          type="checkbox"
                          onChange={(e) => this._processBalance(e.currentTarget.checked)}
                        />{' '}
                        {t.ADVISEDGOOD_ROUTE_BALANCED}
                      </Label>
                    </FormGroup>
                  )}

                  <table className="custom-table srl-table">
                    <thead>
                      <tr>
                        {this._isStockItemShown && (
                          <th className="pl-3 srl-stock-code-width srl-stock-code-padding">
                            {t.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM}
                          </th>
                        )}
                        <th
                          className={classnames(
                            { 'pl-3': !this._isStockItemShown },
                            'srl-description-width',
                            'srl-description-padding'
                          )}
                        >
                          {t.GLOBAL_LABEL_DESCRIPTION}
                        </th>
                        <th className="srl-weight-width srl-weight-align-padding">
                          {isFR ? t.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT : t.ADVISEDGOOD_ROUTE_GROSS_WEIGHT}
                        </th>
                        {this._isTareNetFieldsShown && (
                          <React.Fragment>
                            <th className="srl-weight-tare-width srl-weight-align-padding">
                              {t.ADVISEDGOOD_ROUTE_TARE_WEIGHT}
                            </th>
                            <th className="srl-weight-width srl-weight-align-padding">
                              {t.ADVISEDGOOD_ROUTE_NET_WEIGHT}
                            </th>
                          </React.Fragment>
                        )}
                        <th className="srl-info-button text-right pr-3" />
                      </tr>
                    </thead>
                    {this._hasSubAdvisedGoods &&
                      subAdvisedGood.receivedGoods.map((item: ReceivedGoodsModel, index: number) => (
                        <SortingReportLineComponent
                          advisedGood={content}
                          index={index}
                          isReadOnly={this._isSortingReportLineReadOnly(item)}
                          isRemarksReadOnly={this._isRemarksReadOnly}
                          item={item}
                          key={`${item.id}-${index}`}
                          labAnalysisCreate={this._labAnalysisCreate}
                          preOpenModal={this.preOpenModal}
                          transferDeliveredGood={this.transferDeliveredGoodWithIdCheck}
                          updateReceivedGoodGrossWeight={this.updateReceivedGoodGrossWeight}
                          isTareNetFieldsShown={this._isTareNetFieldsShown}
                          isStockItemShown={this._isStockItemShown}
                          isNeedToValidateRGs={this._isNeedToValidateRGs}
                        />
                      ))}
                  </table>
                </div>
              ))}
          </Col>
        </Row>

        {/* PICTURES */}
        {
          <PicturesComponent
            data-test="pictures"
            isReadOnly={this._isReadOnly}
            content={content}
            uploadAttachment={uploadAttachment}
            uploadAttachments={uploadAttachments}
            downloadAttachments={downloadAttachments}
          />
        }

        {/* Processing component */}
        {this._renderProcessingComponent}

        {/* PACKAGING */}
        {isFR && (
          <React.Fragment>
            <h5 className="mt-4 clearfix">{t.ADVISEDGOOD_ROUTE_PACKAGING}</h5>
            {this._renderPackaging}
          </React.Fragment>
        )}

        {/* COMMENT */}
        <h5 className="mt-4 clearfix">{t.ADVISEDGOOD_ROUTE_COMMENT}</h5>
        {this._renderComment}

        {/* SAVE BUTTON - STICKY FOOTER */}
        <StickyFooter data-test="sticky-footer" show={this._isShowFooter}>
          <Button data-test="ag-save-btn" type="button" color="success" onClick={this._saveAdvisedGoodIfDirty}>
            {t.GLOBAL_LABEL_SAVE}
          </Button>
        </StickyFooter>

        {/* MODAL CONFIRMATION FOR FLAGGING */}
        <ModalConfirmation
          data-test="flag-modal"
          buttonYesColor={content.flagged ? 'success' : 'danger'}
          callbackYes={this._flagAdvisedGood}
          heading={t.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER(this._hasFlag)}
          ico="flag"
          isOpen={flagConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT(
            content.flagged ? 'text-success' : TEXT_DANGER,
            this._hasFlag
          )}
          toggleModal={this._toggleFlagConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR LEAVING PAGE WHEN IS DIRTY */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackNo={this._redirectToDeliveries}
          callbackYes={this._saveAdvisedGoodAndGoToDeliveriesList}
          heading={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER}
          ico="floppy-o"
          isOpen={this.state.saveConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT}
          toggleModal={this._toggleSaveConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR MARKING AS COMPLETE - TIER 1 */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackYes={this._tier3Complete}
          heading={t.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER}
          ico="check"
          isOpen={this.state.completeConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT}
          toggleModal={this._toggleCompleteConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR MARKING AS VERIFIED - TIER 2 */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackYes={this._tier2Verify}
          heading={t.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER}
          ico="check"
          isOpen={this.state.verifyConfirmationModal}
          body={this._getVerifyConfirmationModalBody()}
          toggleModal={this._toggleVerifyConfirmationModal}
          data-test="verify-confirmation"
        />

        {/* MODAL CONFIRMATION FOR STATUS ROLLBACK TO CLAIMED - TIER 2*/}
        <ModalConfirmation
          data-test="rollback-modal"
          buttonYesColor="success"
          callbackYes={this._statusRollback}
          heading={
            this._isRollbackToUnloaded
              ? t.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER
              : t.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER
          }
          ico="backward"
          isOpen={this.state.statusRollbackConfirmationModal}
          text={
            this._isRollbackToUnloaded
              ? t.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT
              : t.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT
          }
          toggleModal={this._toggleStatusRollbackConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR LAB LIST - LAB FROM DE */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackNo={this._goToLabList}
          callbackYes={this._saveAdvisedGoodAndGoToLabList}
          heading={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER}
          ico="floppy-o"
          isOpen={this.state.saveConfirmationModalForLab}
          text={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT}
          toggleModal={this._toggleSaveConfirmationModalForLab}
        />

        {/* MODAL CONFIRMATION FOR CLAIM AG */}
        <ModalConfirmation
          data-test="claim-confirmation"
          buttonYesColor="success"
          buttonNo={t.GLOBAL_LABEL_CANCEL}
          buttonYes={t.GLOBAL_LABEL_CONFIRM}
          callbackNo={this._cancelClaimAG}
          callbackYes={this.claimAdvisedGood}
          heading={t.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER}
          isOpen={this.state.claimConfirmationModal}
          body={this._claimModalBody}
          toggleModal={this._toggleClaimConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR UNCLAIM AG */}
        <ModalConfirmation
          data-test="unclaim-modal"
          buttonYesColor="success"
          callbackYes={this._unclaimAdvisedGood}
          heading={t.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER}
          ico="check"
          isOpen={this.state.unclaimConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT}
          toggleModal={this._toggleUnclaimConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR UNLOAD AG */}
        <ModalConfirmation
          buttonYesColor="success"
          buttonNo={t.GLOBAL_LABEL_CANCEL}
          buttonYes={t.GLOBAL_LABEL_CONFIRM}
          callbackNo={this._toggleUnloadConfirmationModal}
          callbackYes={this.confirmAdvisedGoodUnloaded}
          heading={t.ADVISEDGOOD_DEDUCTION_MODAL_HEADER}
          isOpen={this.state.unloadConfirmationModal}
          body={this._unloadModalBody}
          toggleModal={this._toggleUnloadConfirmationModal}
        />

        {/* MODAL CONFIRMATION - IF AG NOT HAS ATTACHMENTS */}
        <ModalConfirmation
          data-test="ag-attachments-confirmation"
          buttonYesColor="info"
          callbackNo={this._resolveHasAttachmentsPromiseWithFalse}
          callbackYes={this._resolveHasAttachmentsPromiseWithTrue}
          heading={t.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER}
          ico="check"
          isOpen={this._hasAttachmentsConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT}
          toggleModal={this._toggleHasAttachmentsConfirmationModal}
        />
      </Container>
    );
  }

  private get _renderButtons() {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    const {
      translateService: { t },
      viewStore: { isLabFromDE, isLabFromDE_D365 },
      advisedGoodsStore: { hasAgSignOff },
    } = this.props;
    return (
      <Col sm="12" className="mb-3">
        <div className="flex-wrap-space-between">
          <h2 className="mb-0 clearfix">{content.agTitle}</h2>

          <div className="btn-container">
            {/* CLAIM BUTTON */}
            {this._hasClaimButton && (
              <Button data-test="claim-btn" color="success" onClick={this._claimAdvisedGood}>
                {t.GLOBAL_LABEL_CLAIM}
              </Button>
            )}

            {/* SIGN OFF BUTTON */}
            {hasAgSignOff(content) && <AdvisedGoodSignOffButton ag={content} validate={this._validate} />}

            {/* UNCLAIM BUTTON */}
            {this._hasUnclaimButton && (
              <Button data-test="unclaim-btn" color="success" onClick={this._toggleUnclaimConfirmationModal}>
                {t.GLOBAL_LABEL_UNCLAIM}
              </Button>
            )}

            {/* UNLOAD BUTTON */}
            {this._hasUnloadedButton && (
              <Button
                data-test="button-unloaded"
                type="button"
                size="sm"
                color="success"
                onClick={this._unloadAdvisedGood}
              >
                {t.ADVISEDGOOD_UNLOAD_TITLE}
              </Button>
            )}

            {/* FLAG ADVISED GOOD BUTTON */}
            {this._showFlagButton && (
              <Button
                data-test="flag-btn"
                color={content.flagged ? 'danger' : 'success'}
                onClick={this._toggleFlagConfirmationModal}
              >
                <FontAwesome name="flag" className="mr-0" />
              </Button>
            )}

            {/* TIER 3 COMPLETE BUTTON */}
            {this._hasCompleteButton && (
              <Button
                color="success"
                disabled={!content.subAdvisedGoods}
                onClick={this._toggleCompleteConfirmationModal}
              >
                {t.GLOBAL_FILTERKEY_COMPLETE}
              </Button>
            )}

            {/* TIER 2 VERIFY BUTTON */}
            {this._hasVerifyButton && (
              <Button
                data-test="verify-btn"
                color="success"
                disabled={this._isVerifyButtonDisabled}
                onClick={this._toggleVerifyConfirmationModal}
              >
                {t.GLOBAL_LABEL_VERIFY}
              </Button>
            )}

            {/* TIER 2 ROLLBACK BUTTON */}
            {this._hasStatusRollbackButton && (
              <Button data-test="rollback-btn" color="secondary" onClick={this._toggleStatusRollbackConfirmationModal}>
                {t.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK}
              </Button>
            )}

            {/* LAB FROM DE LABLIST BUTTON */}
            {(isLabFromDE || isLabFromDE_D365) && (
              <Button color="success" onClick={this._toggleSaveConfirmationModalForLab}>
                {t.LABORATORY_ADVISEGOODS_LAB_LIST}
              </Button>
            )}
          </div>
        </div>

        {content.flagged && (
          <Alert color="danger" className="text-center mt-4">
            <strong>{t.GLOBAL_LABEL_ALERT}</strong>
          </Alert>
        )}
      </Col>
    );
  }

  private get _renderComment() {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
      viewStore: { isFR },
    } = this.props;
    const isCommentReadOnly = this._isCommentReadOnly;

    return (
      <Row className="bg-white border-top border-bottom">
        <Col xs={12} className="pt-1 pb-1">
          {isCommentReadOnly &&
            (content.comment ? (
              <div data-test="comment-div">{content.comment}</div>
            ) : (
              <div className="text-center font-light">{t.ADVISEDGOOD_ROUTE_NO_COMMENT}</div>
            ))}
          {!isCommentReadOnly && (
            <InputWithLimitOfCharacters
              data-test="comment-input"
              disabled={isCommentReadOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setComment(e.target.value)}
              type="textarea"
              value={content.comment}
              maxLength={isFR ? ADVISED_COMMENT_MAX_LENGTH_FR : ADVISED_COMMENT_MAX_LENGTH_DEFAULT}
            />
          )}
        </Col>
      </Row>
    );
  }

  private get _renderPackaging() {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;
    const isPackagingReadOnly = this._isPackagingReadOnly;

    return (
      <Row data-test="packaging-container" className="bg-white border-top border-bottom">
        <Col xs={12} className="pt-1 pb-1">
          {isPackagingReadOnly &&
            (content.packaging ? (
              <div data-test="packaging-div">{content.packaging}</div>
            ) : (
              <div className="text-center font-light">{t.ADVISEDGOOD_ROUTE_NO_PACKAGING}</div>
            ))}
          {!isPackagingReadOnly && (
            <InputWithLimitOfCharacters
              data-test="packaging-input"
              disabled={isPackagingReadOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setPackaging(e.target.value)}
              type="textarea"
              value={content.packaging}
              maxLength={ADVISED_PACKAGING_MAX_LENGTH_FR}
            />
          )}
        </Col>
      </Row>
    );
  }

  private get _renderLocation() {
    const {
      translateService: { t },
      commonStore: { yardLocations },
      viewStore: { isDE, isUS, isDE_D365 },
      advisedGoodsStore: { content },
    } = this.props;

    return isDE || isUS || isDE_D365 ? (
      <Row className="flex mt-4">
        <h5 className="pl-3 pr-3">{t.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION}</h5>
        <Autocomplete
          data-test="unload-location"
          disabled={this._isLocationDisabled}
          placeholder={t.GLOBAL_LABEL_SELECT}
          items={yardLocations}
          onSelect={(selectedItem: IdNameModel) => content.setYardLocation(selectedItem)}
          selectedValue={content.yardLocation && content.yardLocation.id}
          selectedName={this._selectedUnloadLocationName}
        />
      </Row>
    ) : null;
  }

  private get _renderDeduction() {
    const {
      translateService: { t },
      viewStore: { isDE, isDE_D365 },
      advisedGoodsStore: { content },
    } = this.props;
    return (isDE || isDE_D365) && content.isRequiredGrossAndTareWeights ? (
      <Row className="flex mt-4" data-test="deduction-section">
        <h5 className="pl-3 pr-3">{t.ADVISEDGOOD_DEDUCTION_DEDUCTION}</h5>
        {this._unloadInputGroup}
      </Row>
    ) : null;
  }

  private get _renderProcessingComponent() {
    const {
      viewStore: { isUK },
      advisedGoodsStore: { content },
      translateService: { t },
    } = this.props;

    return isUK ? (
      <ProcessingTimeComponent
        data-test="processing-types"
        processingTimes={content.processingData}
        advisedGood={content}
        isReadOnly={this._isReadOnly}
        columns={2}
        title={t.SORTINGREPORT_PROCESSING_TITLE}
        isValid={this._isProcessingValid}
        decimalPlaces={0}
      />
    ) : null;
  }

  private get _isProcessingValid() {
    const {
      advisedGoodsStore: { content },
      sessionStore: { isTier2 },
    } = this.props;
    const needValidate =
      isTier2 && [AdvisedGoodStatus.READY_FOR_TIER_2, AdvisedGoodStatus.CLAIMED].includes(content.status);
    return needValidate ? content.validators.processingData : true;
  }

  private get _isLocationDisabled() {
    const {
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;
    return status !== AdvisedGoodStatus.CLAIMED || this._isReadOnly;
  }

  private get _selectedUnloadLocationName() {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.yardLocation && (content.yardLocation.name || content.yardLocation.id);
  }

  private _goToLabList = () => {
    this.props.history.push('/deliveries/lab');
  };

  private get _hasFlag(): string {
    const {
      translateService: { t },
    } = this.props;
    const {
      advisedGoodsStore: { content },
    } = this.props;

    return getTranslation(`GLOBAL_LABEL_${content.flagged ? 'UN_FLAG' : 'FLAG'}` as keyof I18N, t) as string;
  }

  private get _hasSubAdvisedGoods(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.subAdvisedGoods && content.subAdvisedGoods.length !== 0;
  }

  @action
  private _toggleHasAttachmentsConfirmationModal = () => {
    this._hasAttachmentsConfirmationModal = !this._hasAttachmentsConfirmationModal;
  };

  @computed
  private get _hasClaimButton(): boolean {
    const {
      advisedGoodsStore: {
        content: { claimedBy, status },
      },
    } = this.props;

    return (
      !(claimedBy && claimedBy.user && claimedBy.user.id) &&
      status === AdvisedGoodStatus.WAITING &&
      this._isUserRoleThatCanClaim
    );
  }

  @computed
  private get _hasUnclaimButton(): boolean {
    const {
      sessionStore: { user, isAdmin },
      advisedGoodsStore: {
        content: { claimedBy, status },
      },
    } = this.props;
    return (
      ((claimedBy && claimedBy.user && claimedBy.user.id && claimedBy.user.id === user.id) || isAdmin) &&
      status === AdvisedGoodStatus.CLAIMED &&
      this._isRoleForClaimOrUnclaim
    );
  }

  private get _isRoleForClaimOrUnclaim(): boolean {
    const {
      sessionStore: { isLab, isWeightBridgeOperator, isTraderOrBackOffice, isManager },
      viewStore: { isTier1FromDE, isTier1FromIT, isTier1FromFR, isTier1FromDE_D365 },
    } = this.props;

    return (
      !isLab &&
      !isWeightBridgeOperator &&
      !isTraderOrBackOffice &&
      !isTier1FromDE &&
      !isTier1FromDE_D365 &&
      !isTier1FromIT &&
      !isTier1FromFR &&
      !isManager
    );
  }

  private get _isUserRoleThatCanClaim(): boolean {
    const {
      sessionStore: { isAdmin },
    } = this.props;

    return this._isRoleForClaimOrUnclaim && !isAdmin;
  }

  @computed
  private get _hasUnloadedButton(): boolean {
    const {
      viewStore: { isTier3FromIT, isTier2FromIT, isTier2FromDE, isTier3FromFR, isTier2FromFR, isTier2FromDE_D365 },
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;

    return (
      (isTier2FromDE || isTier2FromDE_D365 || isTier2FromIT || isTier2FromFR || isTier3FromIT || isTier3FromFR) &&
      status === AdvisedGoodStatus.CLAIMED
    );
  }

  @computed
  private get _hasStatusRollbackButton() {
    const {
      sessionStore: { isTier2, isTier1, isAdmin },
      viewStore: { isUK, isUS, isAdminFromDE_D365 },
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;

    return (
      (isTier1 && (status === AdvisedGoodStatus.READY_FOR_TIER_2 || status === AdvisedGoodStatus.READY_FOR_SIGN_OFF)) ||
      (isTier2 && (isUK || isUS) && status === AdvisedGoodStatus.READY_FOR_TIER_2) ||
      (isAdmin &&
        !isAdminFromDE_D365 &&
        [AdvisedGoodStatus.IN_LAB, AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(status))
    );
  }

  @computed
  private get _hasCompleteButton() {
    const {
      sessionStore: { isTier3 },
      advisedGoodsStore: {
        content: { status },
      },
      viewStore: { isIT, isFR },
    } = this.props;

    return (
      !this._isReadOnly &&
      isTier3 &&
      ((!isIT && !isFR && status === AdvisedGoodStatus.CLAIMED) ||
        ((isIT || isFR) && status === AdvisedGoodStatus.UNLOADED))
    );
  }

  @computed
  private get _hasVerifyButton() {
    const {
      sessionStore: { isTier2, isTier1 },
      viewStore: { isDE, isIT, isFR, isTier1FromDE, isTier1FromIT, isTier1FromFR, isDE_D365, isTier1FromDE_D365 },
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;

    return (
      !this._isReadOnly &&
      (isTier2 || isTier1) &&
      !(isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365) &&
      (status === AdvisedGoodStatus.READY_FOR_TIER_2 ||
        (!(isDE || isIT || isFR || isDE_D365) && status === AdvisedGoodStatus.CLAIMED) ||
        ((isDE || isIT || isFR || isDE_D365) && status === AdvisedGoodStatus.UNLOADED))
    );
  }

  @computed
  private get _isVerifyButtonDisabled() {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.isRequiredGrossAndTareWeights && !this.deliveryReceivedWeight;
  }

  private get _claimModalBody(): React.ReactNode {
    const {
      translateService: { t },
      commonStore: { yardLocations },
      advisedGoodsStore: { content },
    } = this.props;

    return (
      <div className="flex">
        <span className="margin-right-1rem">{t.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT}</span>
        <Autocomplete
          placeholder={t.GLOBAL_LABEL_SELECT}
          items={yardLocations}
          onSelect={(selectedItem: IdNameModel) => content.setYardLocation(selectedItem)}
          selectedValue={content.yardLocation && content.yardLocation.id}
        />
      </div>
    );
  }

  private get _unloadInputGroup(): React.ReactNode {
    const {
      translateService: { t },
      viewStore: { numberOfDecimals },
      advisedGoodsStore: { content },
    } = this.props;
    const deductionsList = Object.keys(DeductionsList).map((deduction) => ({
      id: deduction,
      name: getTranslation(`ADVISEDGOOD_DEDUCTION_${deduction}` as keyof I18N, t) as string,
    }));
    const isDisabled =
      this._isReadOnly || ![AdvisedGoodStatus.CLAIMED, AdvisedGoodStatus.UNLOADED].includes(content.status);

    return (
      <div className="unload-input-group">
        <Autocomplete
          data-test="unload-select"
          disabled={isDisabled}
          placeholder={t.ADVISEDGOOD_DEDUCTION_DEDUCTION}
          items={deductionsList}
          onSelect={(selectedItem: IdNameModel) => content.setNameOfDeduction(selectedItem)}
          selectedValue={content.deduction.name}
          inputClassName="deduction-autocomplete-width"
        />
        <InputNumber
          data-test="unload-input"
          placeholder={t.GLOBAL_LABEL_WEIGHT}
          className="width-100 margin-left-1rem"
          disabled={content.isUserChoseNoDeductionOption || isDisabled}
          value={content.deduction.value}
          onChangeValue={this._changeDeduction}
          decimalPlaces={numberOfDecimals}
        />
      </div>
    );
  }

  private get _unloadModalBody() {
    const {
      viewStore: { isDE_D365 },
      translateService: { t },
    } = this.props;
    return (
      <>
        {this._unloadInputGroup}
        {isDE_D365 && (
          <div data-test="unload-comment">
            <h5 className="mt-4 clearfix">{t.ADVISEDGOOD_ROUTE_COMMENT}</h5>
            {this._renderComment}
          </div>
        )}
      </>
    );
  }

  private get _isTareNetFieldsShown() {
    const {
      viewStore: { isDE, isUS, isDE_D365 },
    } = this.props;
    const isShownInCurrentWorkflow = isDE || isUS || isDE_D365;
    return isMobile() ? isShownInCurrentWorkflow && this.state.isTareNetWeightFieldsShown : isShownInCurrentWorkflow;
  }

  private get _hasShowHideTareNetWeightButton() {
    const {
      viewStore: { isDE, isUS, isDE_D365 },
    } = this.props;
    return isMobile() && (isDE || isUS || isDE_D365);
  }

  private get _isStockItemShown(): boolean {
    const {
      sessionStore: { isTier3 },
      viewStore: { isTier3FromFR },
    } = this.props;
    const { isTareNetWeightFieldsShown } = this.state;
    const permittedRole = !isTier3 || isTier3FromFR;
    return isMobile() ? permittedRole && !isTareNetWeightFieldsShown : permittedRole;
  }

  private _toggleIsTareNetWeightFieldsShown = () => {
    this.setState({ isTareNetWeightFieldsShown: !this.state.isTareNetWeightFieldsShown });
  };

  private async _loadData() {
    const {
      advisedGoodsStore,
      match: { params },
      commonStore,
      viewStore: { isUK },
      sessionStore: { isManager, isTraderOrBackOffice },
    } = this.props;

    if (!commonStore.isLabDataLoaded) {
      commonStore.getCommonLab();
    }

    if (isUK) {
      await advisedGoodsStore.getCommonData();
    }

    const content = await advisedGoodsStore.getAdvisedGoodsById(params.id);
    this._setContent(content);
    const allWeights = await advisedGoodsStore.getAllWeightsByAGId(advisedGoodsStore.content.id);
    advisedGoodsStore.setAllWeights(allWeights);

    const tenantId = isManager || isTraderOrBackOffice ? advisedGoodsStore.content?.tenant?.id : undefined;
    if (!commonStore.isDataLoaded || tenantId) {
      commonStore.getCommon(tenantId);
    }

    this.props.socketService.setIsContentReadyToUpdate(true);
  }

  private _changeDeduction = (newValue: number) => {
    this.props.advisedGoodsStore.content.setValueOfDeduction(newValue);
  };

  private _setContent = (res: AdvisedGoodsModel) => {
    const { advisedGoodsStore } = this.props;

    advisedGoodsStore.setContent(res);
    this._changeBalanceForOneLine();
    this._initialYardLocation = advisedGoodsStore.content.yardLocation;
  };

  private _changeBalanceForOneLine = () => {
    const {
      advisedGoodsStore: {
        content: { subAdvisedGoods, hasOnlyOneSubAdvisedGoodWithReceivedGoods },
      },
    } = this.props;
    if (
      hasOnlyOneSubAdvisedGoodWithReceivedGoods &&
      !subAdvisedGoods[0].receivedGoods[0].grossWeight &&
      this.deliveryReceivedWeight
    ) {
      this.props.advisedGoodsStore.content.setBalance(true);
    }
  };

  // EXECUTE CHECK BEFORE QUITING PAGE
  private _goBack = () => {
    if (this._isNeedToSave) {
      this.props.viewStore.isLabFromDE || this.props.viewStore.isLabFromDE_D365
        ? this._toggleSaveConfirmationModalForLab()
        : this._toggleSaveConfirmationModal();
    } else {
      this._redirectToDeliveries();
    }
  };

  private _redirectToDeliveries = () => {
    const {
      history,
      advisedGoodsStore: { content },
    } = this.props;
    const prevDelivery = content.delivery && content.delivery.id ? `?prevDeliveryId=${content.delivery.id}` : '';
    history.push(`/deliveries${prevDelivery}`);
  };

  private _saveAdvisedGoodAndGoToDeliveriesList = async () => {
    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      this.props.history.push('/deliveries');
    }
  };

  private _saveAdvisedGoodAndGoToLabList = async () => {
    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      notification.success(this.props.translateService.t.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG);
      this._goToLabList(); // REDIRECT TO LAB LIST
    }
  };

  private _saveAdvisedGoodIfDirty = async (): Promise<boolean> => {
    const {
      advisedGoodsStore: { content, putAdvisedGoodsById },
      sessionStore: { tenant },
    } = this.props;
    if (content.isDirty) {
      const attachmentsCondition = tenant.isNeedCheckAGAttachments && !this._hasAttachments;
      if (
        !(await this._modalConfirmationCheck(
          attachmentsCondition,
          this._hasAttachmentsPromise,
          this._toggleHasAttachmentsConfirmationModal
        ))
      ) {
        return false;
      }
      const newAG = await putAdvisedGoodsById(content.constructPutAdvisedGood());
      await this._setContent(newAG);
      notification.success(this.props.translateService.t.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG);
      return true;
    } else {
      return true;
    }
  };

  // MARK ADVISED GOOD AS FLAGGED
  private _flagAdvisedGood = () => {
    const {
      translateService: { t },
      advisedGoodsStore: { content, postFlagAdvisedGood },
    } = this.props;
    return postFlagAdvisedGood(content.id, !content.flagged).then((res: IFlaggedResponse) => {
      content.setFlagged(res.isFlagged);
      if (content.flagged) {
        notification.error(t.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG);
        content.setFlaggedBy(new OperatorModel().update(res.flaggedBy));
      } else {
        notification.success(t.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG);
        content.setUnflaggedBy(new OperatorModel().update(res.unflaggedBy));
      }
    });
  };

  // ON FLAG CLICK - SHOW FLAG CONFIRMATION WINDOW
  private _toggleFlagConfirmationModal = () => {
    this.setState({
      flagConfirmationModal: !this.state.flagConfirmationModal,
    });
  };

  // TOGGLE SAVE PROMPT MODAL
  private _toggleSaveConfirmationModal = () => {
    this.setState({
      saveConfirmationModal: !this.state.saveConfirmationModal,
    });
  };

  // TOGGLE SAVE PROMPT MODAL FOR LAB FROM DE
  private _toggleSaveConfirmationModalForLab = () => {
    this.setState({
      saveConfirmationModalForLab: !this.state.saveConfirmationModalForLab,
    });
  };

  // TOGGLE COMPLETE - TIER 3
  private _toggleCompleteConfirmationModal = () => {
    this.setState({
      completeConfirmationModal: !this.state.completeConfirmationModal,
    });
  };

  // TOGGLE VERIFY - TIER 2
  private _toggleVerifyConfirmationModal = () => {
    this.setState({
      verifyConfirmationModal: !this.state.verifyConfirmationModal,
    });
  };

  // TOGGLE STATUS ROLLBACK - TIER 2
  private _toggleStatusRollbackConfirmationModal = () => {
    this.setState({
      statusRollbackConfirmationModal: !this.state.statusRollbackConfirmationModal,
    });
  };

  private _toggleClaimConfirmationModal = () => {
    this.setState({ claimConfirmationModal: !this.state.claimConfirmationModal });
  };

  private _toggleUnclaimConfirmationModal = () => {
    this.setState({ unclaimConfirmationModal: !this.state.unclaimConfirmationModal });
  };

  private _toggleUnloadConfirmationModal = () => {
    this.setState({ unloadConfirmationModal: !this.state.unloadConfirmationModal });
  };

  // TIER 3 - MARK AS COMPLETE
  private _tier3Complete = async () => {
    const {
      translateService: { t },
      advisedGoodsStore: { content, postTier3Complete },
    } = this.props;

    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      return postTier3Complete(content.id).then(() => {
        this.props.advisedGoodsStore.content.setStatus(AdvisedGoodStatus.READY_FOR_TIER_2);
        notification.success(t.ADVISEDGOOD_ROUTE_COMPLETED_MSG);
        this._redirectToDeliveries(); // REDIRECT TO LIST
      });
    }
  };

  private get _getValidationMessageMap(): Map<AdvisedGoodsValidatorKey, string> {
    const {
      translateService: { t },
    } = this.props;
    return new Map<AdvisedGoodsValidatorKey, string>([
      ['RGsDescription', t.GLOBAL_LABEL_DESCRIPTION],
      ['RGsGrossWeight', t.GLOBAL_LABEL_WEIGHT],
      ['RGsBulkDensity', t.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY],
      ['RGsFoundQuality', t.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY],
      ['RGsMainType', t.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE],
      ['RGsMaterialDescription', t.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION],
      ['RGsWiDone', t.ADVISEDGOOD_SORTINGREPORT_WI_DONE],
      ['RGsContamination', t.RECEIVED_GOOD_CONTAMINATION_TITLE],
      ['RGsProductForm', t.GLOBAL_LABEL_FORM],
      ['RGsProductQuality', t.GLOBAL_LABEL_QUALITY],
      ['processingData', t.SORTINGREPORT_PROCESSING_TITLE],
    ]);
  }

  private _validate = (): boolean => {
    const {
      advisedGoodsStore: { content },
      viewStore: { countryCode },
      translateService: { t },
    } = this.props;
    if (content.flagged) {
      notification.error(t.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF);
      return false;
    }
    if (!content.validators.subAdvisedGoods) {
      notification.error(t.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD);
      return false;
    }
    if (!content.isValid(countryCode)) {
      const invalidKeyLabels: string[] = content.invalidValidatorKeys
        .get(countryCode)
        .map((key: AdvisedGoodsValidatorKey) => this._getValidationMessageMap.get(key))
        .filter((f) => f);
      notification.error(
        t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS(joinWithCommaSeparator(invalidKeyLabels).toLowerCase())
      );
      return false;
    }
    return true;
  };

  // TIER 2 - MARK AS VERIFIED
  private _tier2Verify = async () => {
    const {
      translateService: { t },
      advisedGoodsStore: { content, postTier2Verify },
    } = this.props;

    if (!this._validate()) {
      return false;
    }

    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      return postTier2Verify(content.id).then(() => {
        this.props.advisedGoodsStore.content.setStatus(AdvisedGoodStatus.READY_FOR_SIGN_OFF);
        notification.success(t.ADVISEDGOOD_ROUTE_VERIFIED_MSG);
        this._redirectToDeliveries(); // REDIRECT TO LIST
      });
    }
  };

  // TIER 2 OR TIER 1 - STATUS ROLLBACK
  private _statusRollback = async () => {
    const {
      advisedGoodsStore: { content, postStatusRollback },
    } = this.props;
    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      const res: { newStatus: AdvisedGoodStatus; rolledbackBy: OperatorModel } = await postStatusRollback(content.id);
      this.props.advisedGoodsStore.content.setStatus(res.newStatus);
      this.props.advisedGoodsStore.content.setRollbackBy(res.rolledbackBy);
      this.props.advisedGoodsStore.content.clearIsDirty();

      return res;
    }
  };

  private get _showFlagButton() {
    const {
      sessionStore: { isTier3 },
      advisedGoodsStore: {
        content: { flagged },
      },
      viewStore: {
        isTier2FromDE,
        isTier2FromIT,
        isTier1FromDE,
        isTier1FromIT,
        isTier2FromFR,
        isTier1FromFR,
        isTier1FromDE_D365,
        isTier2FromDE_D365,
        isTraderFromFR,
      },
    } = this.props;
    // Tier1 from Germany, Italy, France or DE_D365 and Traders have readOnly mode always, because of this we have to exclude them from this._isReadOnly
    return !(
      ((isTier3 || isTier2FromDE || isTier2FromIT || isTier2FromFR || isTier2FromDE_D365) && flagged) ||
      (this._isReadOnly && !(isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365 || isTraderFromFR))
    );
  }

  private get _hasBalanceCheckbox() {
    const {
      advisedGoodsStore: { content },
      viewStore: { isUS },
    } = this.props;
    return Boolean(
      content.isRequiredGrossAndTareWeights &&
        ((!isUS && content.subAdvisedGoods.length === 1) || content.hasOnlyOneDeliveryAGForUS) &&
        this.deliveryReceivedWeight
    );
  }

  // CREATE NEW LAB ANALYSIS
  private _labAnalysisCreate = (receivedGood: ReceivedGoodsModel, labStatusSelected: string) => {
    if (!receivedGood.id) {
      notification.warn(this.props.translateService.t.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG);
      return;
    }
    this.props.receivedGoodsStore
      .postLabAnalysis(receivedGood.id, labStatusSelected)
      .then((labInputs: LabAnalysisModel[]) => {
        receivedGood.replaceLabInputs(
          labInputs,
          this.props.commonStore.defaultMaterials,
          this.props.commonStore.nonDefaultMaterial
        );
        notification.success(this.props.translateService.t.ADVISEDGOOD_ROUTE_LAB_MSG);
      });
  };

  // ON CONTENT.BALANCE CHANGE
  private _processBalance = (isBalanceChecked: boolean) => {
    this.props.advisedGoodsStore.content.setBalance(isBalanceChecked);
  };

  private get _isReadOnly() {
    const {
      sessionStore: { isTraderOrBackOffice, isTier3, isTier2, isTier1, isManager },
      viewStore: {
        isLabFromDE,
        isTier1FromDE,
        isTier1FromIT,
        isTier1FromFR,
        isIT,
        isFR,
        isTier1FromDE_D365,
        isLabFromDE_D365,
      },
      advisedGoodsStore: { content },
    } = this.props;

    // Trader or Manager has read-only view
    if (isTraderOrBackOffice || isManager) {
      return true;
    }

    // TIER 3 CAN EDIT, ONLY IF ADVISED GOOD IS CLAIMED OR TIER 3 FROM IT AND STATUS IS UNLOADED
    if (
      isTier3 &&
      content.status !== AdvisedGoodStatus.CLAIMED &&
      !((isIT || isFR) && content.status === AdvisedGoodStatus.UNLOADED)
    ) {
      return true;
    }

    if (
      isTier2 &&
      ![
        AdvisedGoodStatus.CLAIMED,
        AdvisedGoodStatus.UNLOADED,
        AdvisedGoodStatus.READY_FOR_TIER_2,
        AdvisedGoodStatus.IN_LAB,
      ].includes(content.status)
    ) {
      return true;
    }

    if (isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365) {
      return true;
    }

    if (
      isTier1 &&
      ![
        AdvisedGoodStatus.CLAIMED,
        AdvisedGoodStatus.UNLOADED,
        AdvisedGoodStatus.READY_FOR_TIER_2,
        AdvisedGoodStatus.READY_FOR_SIGN_OFF,
        AdvisedGoodStatus.IN_LAB,
      ].includes(content.status)
    ) {
      return true;
    }

    return !!isLabFromDE || !!isLabFromDE_D365;
  }

  private _onSocketAdvisedGoodUpdate = (data: AdvisedGoodsModel[]) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    for (const advisedGood of data) {
      if (content.id === advisedGood.id) {
        this._setContent(advisedGood);
        notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG);
      }
    }
  };

  private _updateAdvisedGoodGrossWeight = (data: IWeightUpdateSocketResponse) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    if (content.id === data.advisedGoodId) {
      content.setFirstWeight(data.weight);
      this._setContent(content);
      notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG);
    }
  };

  private _updateAdvisedGoodTareWeight = (data: IWeightUpdateSocketResponse) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    if (content.id === data.advisedGoodId) {
      content.setSecondWeight(data.weight);
      this._setContent(content);
      notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG);
    }
  };

  private _updateEwcCode = (data: IEwcCodeUpdateSocketResponse) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    content.setEwcCode(data.ewcCode);
    this._setContent(content);
    notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG);
  };

  private _updateWeights = (data: AllWeightsModel) => {
    const {
      translateService: { t },
      advisedGoodsStore,
    } = this.props;

    advisedGoodsStore.setAllWeights(data);
    notification.info(t.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED(data.name));
  };

  private _claimAdvisedGood = () => {
    const {
      viewStore: { isDE, isDE_D365 },
    } = this.props;

    if (isDE || isDE_D365) {
      this._toggleClaimConfirmationModal();
    } else {
      this.claimAdvisedGood();
    }
  };

  private _unclaimAdvisedGood = async () => {
    const result = await this._saveAdvisedGoodIfDirty();
    if (result) {
      const res: {
        newStatus: AdvisedGoodStatus;
        claimedBy: OperatorModel;
      } = await this.props.advisedGoodsStore.postUnclaimAdvisedGood(this.props.advisedGoodsStore.content.id);
      const {
        advisedGoodsStore: { content },
        translateService: { t },
      } = this.props;

      content.setStatus(res.newStatus);
      content.setClaimedBy(res.claimedBy);
      content.clearIsDirty();
      notification.success(t.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG);
    }
  };

  private _cancelClaimAG = () => {
    const {
      advisedGoodsStore: {
        content: { setYardLocation, clearIsDirty },
      },
    } = this.props;
    setYardLocation(this._initialYardLocation);
    clearIsDirty();
    this._toggleClaimConfirmationModal();
  };

  private _unloadAdvisedGood = () => {
    const {
      viewStore: { isDE, isDE_D365 },
      advisedGoodsStore: { content },
    } = this.props;

    if ((isDE || isDE_D365) && content.isRequiredGrossAndTareWeights) {
      this._toggleUnloadConfirmationModal();
    } else {
      this.confirmAdvisedGoodUnloaded();
    }
  };

  private _transferDeliveredGood = async (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => {
    const updatedAG = await this.props.receivedGoodsStore.postTransferDeliveredGood(deliveredGood, transfer);
    await this._setContent(updatedAG);
  };

  private get _isCommentReadOnly(): boolean {
    const {
      viewStore: {
        isTier2FromIT,
        isTier1FromIT,
        isTier3FromIT,
        isTier3FromFR,
        isTier1FromFR,
        isTier2FromFR,
        isDE_D365,
      },
      advisedGoodsStore: { content },
    } = this.props;

    return (
      (isDE_D365 && content.status !== AdvisedGoodStatus.CLAIMED) ||
      ((this._isReadOnly ||
        ((isTier2FromIT || isTier3FromIT || isTier2FromFR || isTier3FromFR) &&
          !content.secondWeight &&
          [AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(content.status))) &&
        !((isTier1FromIT || isTier1FromFR) && content.status === AdvisedGoodStatus.READY_FOR_SIGN_OFF))
    );
  }

  private get _isRemarksReadOnly(): boolean {
    const {
      viewStore: { isTier2FromIT, isTier1FromIT, isTier3FromIT, isTier3FromFR, isTier1FromFR, isTier2FromFR, isIT },
      advisedGoodsStore: { content },
    } = this.props;

    return (
      (this._isReadOnly ||
        ((isTier2FromIT || isTier3FromIT || isTier2FromFR || isTier3FromFR) &&
          !content.secondWeight &&
          isIT &&
          [AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(content.status))) &&
      !((isTier1FromIT || isTier1FromFR) && content.status === AdvisedGoodStatus.READY_FOR_SIGN_OFF)
    );
  }

  private get _isPackagingReadOnly(): boolean {
    const {
      viewStore: { isTier3FromFR, isTier2FromFR },
      advisedGoodsStore: { content },
    } = this.props;

    return (
      this._isReadOnly ||
      [AdvisedGoodStatus.WAITING, AdvisedGoodStatus.READY_FOR_SIGN_OFF].includes(content.status) ||
      (!isTier2FromFR && !isTier3FromFR)
    );
  }

  private _isSortingReportLineReadOnly(item: ReceivedGoodsModel) {
    return this._isReadOnly || (!item.isFilledOut && this.props.advisedGoodsStore.content.canNotCreateNewRG);
  }

  private _getVerifyConfirmationModalBody(): React.ReactNode {
    const {
      viewStore: { isDE, isDE_D365 },
      translateService: { t },
    } = this.props;

    return (isDE || isDE_D365) && this.isSortingWeightMoreThanReceived ? (
      <div className={classnames(`${TEXT_DANGER} flex`)}>
        <FontAwesome
          name="exclamation-triangle"
          className={classnames('font-17 mr-3')}
          data-test="verify-danger-triangle"
        />
        <span data-test="danger-verify-text">{t.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT}</span>
      </div>
    ) : (
      <div
        data-test="success-verify-text"
        dangerouslySetInnerHTML={{ __html: t.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT }}
      />
    );
  }

  private get _isNeedToValidateRGs(): boolean {
    const {
      advisedGoodsStore: { content },
      viewStore: { isIT, isTier2FromIT, isTier2FromFR, isFR },
    } = this.props;
    const { status } = content;
    return (
      // if status is READY_FOR_TIER_2 and it is Tier2 from IT, no need to validate, it was done by Tier 3
      (status === AdvisedGoodStatus.READY_FOR_TIER_2 && !isTier2FromIT && !isTier2FromFR) ||
      (status === AdvisedGoodStatus.UNLOADED && !isIT && !isFR) || // no need validate on verify for IT
      ((isIT || isFR) && status === AdvisedGoodStatus.CLAIMED && !content.validators.RGsContamination)
    ); // for IT validate on unload
  }

  private get _isNeedToSave(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.isDirty;
  }

  private get _isShowFooter(): boolean {
    return this._isNeedToSave;
  }

  private get _isRollbackToUnloaded(): boolean {
    const {
      viewStore: { isDE_D365, isDE, isFR, isIT },
    } = this.props;
    return isDE_D365 || isDE || isFR || isIT;
  }

  private get _hasAttachments(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.attachments?.length > 0;
  }

  private _modalConfirmationCheck = async (
    condition: boolean,
    promiseResolver: PromiseResolver<boolean>,
    toggleModal: () => void
  ): Promise<boolean> => {
    if (condition) {
      toggleModal();
      const shouldContinue = await promiseResolver.promise;
      promiseResolver.createNewPromise();

      if (!shouldContinue) {
        return false;
      }
    }
    return true;
  };

  private _resolveHasAttachmentsPromiseWithTrue = () => {
    this._hasAttachmentsPromise.resolvePromise(true);
  };

  private _resolveHasAttachmentsPromiseWithFalse = () => {
    this._hasAttachmentsPromise.resolvePromise(false);
  };
}
