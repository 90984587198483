import * as React from 'react';
import * as classnames from 'classnames';
import * as FontAwesome from 'react-fontawesome';
import { action, observable } from 'mobx';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup } from 'reactstrap';
import SessionStore from 'stores/SessionStore';
import NavlinkCustom from 'domain/NavlinkCustom';
import { inject, observer } from 'mobx-react';
import SupplierModel from 'models/SupplierModel';
import IdNameModel from 'models/IdNameModel';
import TranslateService from 'services/TranslateService';
import { getTranslation, isMobile, transformKey } from 'util/helpers';
import { I18N } from '../../../assets/i18n/i18n';
import DeliveryFiltersStore from 'pod/deliveries/DeliveryFiltersStore';
import StockItemModel from 'models/StockItemModel';
import Autocomplete from 'domain/Autocomplete';
import * as H from 'history';
import DateRangePickerComponent from 'components/DateRangePickerComponent';
import {
  ALL_ROLES_DELIVERY_FILTER,
  ALL_VALUE,
  ASC,
  COMPLETE_VALUE,
  CREATED_AT,
  DATE_ASC,
  DATE_DESC,
  DepartmentsMap,
  DESC,
  IN_LAB_VALUE,
  IN_PROGRESS_VALUE,
  IN_WORK,
  ONLY_MY_VALUE,
  ONLY_UNCLAIMED_VALUE,
  ONLY_UNSYNCED_VALUE,
  PARTIALLY_SIGNED_OFF_VALUE,
  READY_FOR_SIGN_OFF_VALUE,
  READY_FOR_SIGNOFF_VALUE,
  READY_FOR_TIER_2_VALUE,
  SIGNED_OFF_VALUE,
  STATUS_ASC,
  STATUS_DESC,
} from 'util/constants';
import { UserRoles, DepartmentCode, DepartmentGroupCode, CountryCode } from 'util/enums';
import ViewStore from 'stores/ViewStore';
import TenantPickerComponent from 'components/TenantPickerComponent';
import InputWithNullCheck from 'components/InputWithNullCheck';
import { instance as notification } from 'util/notification';
import DeliveryStore from 'pod/deliveries/DeliveryStore';
import UploadFileButton from 'components/UploadFileButton';

interface IAllowedRoles {
  UK: UserRoles[];
  US: UserRoles[];
  DE: UserRoles[];
  DE_D365: UserRoles[];
  IT: UserRoles[];
  FR: UserRoles[];
}

interface IProps {
  canCreateDeliveries: boolean;
  filterGrnOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  forceOpenList: boolean;
  isFlaggedChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
  downloadList: (queryParams: string) => void;
  refreshData: () => void;
  selectFilterBy: (e: React.MouseEvent<HTMLInputElement>) => void;
  selectSortBy: (e: React.MouseEvent<HTMLInputElement>) => void;
  showSortByFilter: boolean;
  toggleForceOpenList: () => void;
  getListData: (queryParams: string) => void;
  suppliers: SupplierModel[];
  changeSupplier: (supplier: string) => void;
  locations: IdNameModel[];
  changeLocation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tradersForDeliveryFilterList: IdNameModel[];
  changeTrader: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeTenant: (e: React.ChangeEvent<HTMLInputElement>) => void;
  departmentCodes: DepartmentCode[];
  changeDepartment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  history: H.History;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  deliveryFiltersStore?: DeliveryFiltersStore;
  viewStore?: ViewStore;
  deliveryStore?: DeliveryStore;
}

@inject('translateService', 'deliveryFiltersStore', 'sessionStore', 'viewStore', 'deliveryStore')
@observer
export default class DeliveryListFilterComponent extends React.Component<IProps> {
  @observable
  public filterFilterByIsOpen: boolean = false;

  @observable
  public filterSortByIsOpen: boolean = false;

  @action
  public toggleSortBy = () => (this.filterSortByIsOpen = !this.filterSortByIsOpen);

  @action
  public toggleFilterBy = () => (this.filterFilterByIsOpen = !this.filterFilterByIsOpen);

  public render() {
    const {
      filterGrnOnChange,
      showSortByFilter,
      getListData,
      forceOpenList,
      toggleForceOpenList,
      refreshData,
      canCreateDeliveries,
      deliveryFiltersStore,
      deliveryFiltersStore: { sortByValue, filterByStatus, filterByGrnValue },
      sessionStore: { isWeightBridgeOperator },
      viewStore: { isLabFromDE, isFR, isLabFromDE_D365 },
      translateService: { t },
    } = this.props;
    const sortBy = `GLOBAL_FILTERKEY_${transformKey(sortByValue)}` as keyof I18N;

    return (
      <div className="btn-container place-holder-robotoregular-400">
        {showSortByFilter && (
          <Dropdown isOpen={this.filterSortByIsOpen} toggle={this.toggleSortBy}>
            <DropdownToggle color="false" size="sm" caret>
              {t.DELIVERIES_FILTER_SORT_BY(sortByValue && `: ${getTranslation(sortBy, t)}`)}
            </DropdownToggle>
            <DropdownMenu>
              {this._renderSortByItem(ASC, CREATED_AT, t.GLOBAL_FILTERKEY_DATE_ASC, DATE_ASC)}
              {this._renderSortByItem(DESC, CREATED_AT, t.GLOBAL_FILTERKEY_DATE_DESC, DATE_DESC)}
              {this._renderSortByItem(ASC, 'status', t.GLOBAL_FILTERKEY_STATUS_ASC, STATUS_ASC)}
              {this._renderSortByItem(DESC, 'status', t.GLOBAL_FILTERKEY_STATUS_DESC, STATUS_DESC)}
            </DropdownMenu>
          </Dropdown>
        )}

        <Dropdown isOpen={this.filterFilterByIsOpen} toggle={this.toggleFilterBy} data-test="delivery-filter">
          <DropdownToggle color="false" className="min-width-200 text-left" size="sm" caret>
            {getTranslation(`GLOBAL_FILTERKEY_${transformKey(filterByStatus)}` as keyof I18N, t)}
          </DropdownToggle>
          <DropdownMenu>
            {this._renderAllValue}
            {this._renderOnlyMyValue}
            {this._renderOnlyUnclaimedValue}
            {this._renderOnlyUnsyncedValue}
            {this._renderOnlyInProgressValue}
            {this._renderOnlyInLabValue}
            {this._renderOnlyReadyForTier2Value}
            {this._renderOnlyReadyForSignOffValue}
            {this._renderOnlyPartiallySignOffValue}
            {this._renderOnlySignOffValue}
            {this._renderOnlyCompletedValue}
          </DropdownMenu>
        </Dropdown>

        <InputWithNullCheck
          data-test="grn-filter"
          placeholder={t.DELIVERIES_FILTER_GRN_SEARCH}
          bsSize="sm"
          value={filterByGrnValue}
          onChange={filterGrnOnChange}
          className="max-width-120"
        />

        <DateRangePickerComponent
          deliveryCommonFilterStore={deliveryFiltersStore.deliveryCommonFilterStore}
          getListData={getListData}
        />

        {this._renderSupplierFilter}

        {this._renderLocationFilter}

        {this._renderTraderFilter}

        {this._renderTenantFilter}

        {this._renderDepartmentFilter}

        {this._renderFlagFilter}

        {this._renderDownloadButton}

        {!isWeightBridgeOperator && (
          <Button color={forceOpenList ? 'success' : 'false'} onClick={toggleForceOpenList} size="sm">
            <FontAwesome name={forceOpenList ? 'compress' : 'expand'} />
          </Button>
        )}

        <Button color="false" onClick={refreshData} size="sm">
          <FontAwesome name="refresh" />
        </Button>

        {canCreateDeliveries && (
          <NavlinkCustom to="/deliveries/new" tagName="button" className="margin-left-auto btn btn-success btn-sm">
            <FontAwesome name="plus" className="mr-2" /> {t.GLOBAL_LABEL_ADD}
          </NavlinkCustom>
        )}

        {isFR && isWeightBridgeOperator && (
          <UploadFileButton
            data-test="upload-delivery-button"
            onChangeFunc={this._importDeliverySCV}
            label={t.GLOBAL_LABEL_IMPORT}
          />
        )}

        {(isLabFromDE || isLabFromDE_D365) && (
          <Button
            data-test="lab-list-button"
            color="success"
            size="sm"
            className="margin-left-auto"
            onClick={this._redirectToDeliveryLabList}
          >
            {t.LABORATORY_ADVISEGOODS_LAB_LIST}
          </Button>
        )}
      </div>
    );
  }

  private get _renderAllValue() {
    const {
      translateService: { t },
    } = this.props;

    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) && this._renderFilterByItem(ALL_VALUE, t.GLOBAL_FILTERKEY_ALL, '')
    );
  }

  private get _renderOnlyMyValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: [UserRoles.Tier3, UserRoles.Tier2, UserRoles.Tier1],
        US: [UserRoles.Tier3, UserRoles.Tier2, UserRoles.Tier1, UserRoles.Trader],
        DE: [UserRoles.Tier2],
        DE_D365: [UserRoles.Tier2],
        IT: [UserRoles.Tier3, UserRoles.Tier2, UserRoles.Trader],
        FR: [UserRoles.Tier3, UserRoles.Tier2, UserRoles.Trader],
      }) && this._renderFilterByItem(ONLY_MY_VALUE, t.GLOBAL_FILTERKEY_ONLY_MY)
    );
  }

  private get _renderOnlyUnclaimedValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) && this._renderFilterByItem(ONLY_UNCLAIMED_VALUE, t.GLOBAL_FILTERKEY_ONLY_UNCLAIMED)
    );
  }

  private get _renderOnlyUnsyncedValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: [],
        DE_D365: [],
        IT: [],
        FR: [],
      }) && this._renderFilterByItem(ONLY_UNSYNCED_VALUE, t.GLOBAL_FILTERKEY_ONLY_UNSYNCED)
    );
  }

  private get _renderOnlyInProgressValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) && this._renderFilterByItem(IN_PROGRESS_VALUE, t.GLOBAL_FILTERKEY_IN_PROGRESS, IN_WORK)
    );
  }

  private get _renderOnlyInLabValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: [],
        DE_D365: [],
        IT: [],
        FR: [],
      }) && this._renderFilterByItem(IN_LAB_VALUE, t.GLOBAL_FILTERKEY_IN_LAB)
    );
  }

  private get _renderOnlyReadyForTier2Value() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: [],
        DE_D365: [],
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) && this._renderFilterByItem(READY_FOR_TIER_2_VALUE, t.GLOBAL_FILTERKEY_READY_FOR_TIER_2)
    );
  }

  private get _renderOnlyReadyForSignOffValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) &&
      this._renderFilterByItem(READY_FOR_SIGNOFF_VALUE, t.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF, READY_FOR_SIGN_OFF_VALUE)
    );
  }

  private get _renderOnlySignOffValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) && this._renderFilterByItem(SIGNED_OFF_VALUE, t.GLOBAL_FILTERKEY_SIGNED_OFF)
    );
  }

  private get _renderOnlyPartiallySignOffValue() {
    const {
      translateService: { t },
      viewStore: { hasAgSignOff },
    } = this.props;
    return (
      hasAgSignOff &&
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) &&
      this._renderFilterByItem(PARTIALLY_SIGNED_OFF_VALUE, t.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF)
    );
  }

  private get _renderOnlyCompletedValue() {
    const {
      translateService: { t },
    } = this.props;
    return (
      this._hasFilter({
        UK: ALL_ROLES_DELIVERY_FILTER,
        US: ALL_ROLES_DELIVERY_FILTER,
        DE: ALL_ROLES_DELIVERY_FILTER,
        DE_D365: ALL_ROLES_DELIVERY_FILTER,
        IT: ALL_ROLES_DELIVERY_FILTER,
        FR: ALL_ROLES_DELIVERY_FILTER,
      }) && this._renderFilterByItem(COMPLETE_VALUE, t.GLOBAL_FILTERKEY_COMPLETE)
    );
  }

  private get _renderSupplierFilter() {
    const {
      sessionStore: { isLab },
      translateService: { t },
      suppliers,
      changeSupplier,
      deliveryFiltersStore: { supplierId },
    } = this.props;

    return (
      !isLab && (
        <InputGroup size="sm" className="max-width-170">
          <Autocomplete
            data-test="supplier-filter"
            inputClassName="input-small-height padding_left_0_5_rem"
            placeholder={t.GLOBAL_LABEL_SUPPLIER}
            items={suppliers}
            onSelect={(selectedItem: StockItemModel) => changeSupplier(selectedItem ? selectedItem.id : '')}
            selectedValue={supplierId}
            hasClearButton={true}
          />
        </InputGroup>
      )
    );
  }

  private get _renderLocationFilter() {
    const {
      sessionStore: { isLab },
      translateService: { t },
      locations,
      changeLocation,
      deliveryFiltersStore: { locationId },
    } = this.props;

    return (
      !isLab && (
        <InputGroup size="sm" className="max-width-170">
          <InputWithNullCheck data-test="location-filter" onChange={changeLocation} type="select" value={locationId}>
            <option value="" disabled>
              {t.GLOBAL_LABEL_LOCATION}
            </option>
            <option value="">{t.GLOBAL_LABEL_NONE}</option>
            {locations &&
              locations.map((yardLocation: IdNameModel) => (
                <option key={yardLocation.id} value={yardLocation.id}>
                  {yardLocation.name}
                </option>
              ))}
          </InputWithNullCheck>
        </InputGroup>
      )
    );
  }

  private get _renderTraderFilter() {
    const {
      translateService: { t },
      deliveryFiltersStore: { traderId },
      tradersForDeliveryFilterList,
      viewStore: { isUK, isDE },
      changeTrader,
    } = this.props;

    return (
      !isUK &&
      !isDE && (
        <InputGroup size="sm" className="max-width-170">
          <InputWithNullCheck data-test="trader-filter" onChange={changeTrader} type="select" value={traderId}>
            <option value="" disabled>
              {t.GLOBAL_USERROLE_TRADER}
            </option>
            <option value="">{t.GLOBAL_LABEL_NONE}</option>
            {tradersForDeliveryFilterList &&
              tradersForDeliveryFilterList.map((trader: IdNameModel) => (
                <option key={trader.id} value={trader.id}>
                  {trader.name}
                </option>
              ))}
          </InputWithNullCheck>
        </InputGroup>
      )
    );
  }

  private get _renderDepartmentFilter() {
    const {
      viewStore: { isDE },
      translateService: { t },
      changeDepartment,
      departmentCodes,
      deliveryFiltersStore: { departmentCode },
    } = this.props;

    return (
      isDE && (
        <InputGroup size="sm" className="max-width-170">
          <InputWithNullCheck
            data-test="department-filter"
            onChange={changeDepartment}
            type="select"
            value={departmentCode}
          >
            <option value="" disabled>
              {t.GLOBAL_LABEL_DEPARTMENT}
            </option>
            <option value="">{t.GLOBAL_LABEL_NONE}</option>
            {departmentCodes &&
              departmentCodes.map((department: DepartmentCode) => (
                <option key={department} value={department}>
                  {DepartmentsMap.get(department)}
                </option>
              ))}
            <option key={DepartmentGroupCode.EUA_SOLIDS} value={DepartmentGroupCode.EUA_SOLIDS}>
              {DepartmentsMap.get(DepartmentGroupCode.EUA_SOLIDS)}
            </option>
            <option key={DepartmentGroupCode.EUA_TOTAL} value={DepartmentGroupCode.EUA_TOTAL}>
              {DepartmentsMap.get(DepartmentGroupCode.EUA_TOTAL)}
            </option>
          </InputWithNullCheck>
        </InputGroup>
      )
    );
  }

  private get _renderFlagFilter() {
    const {
      isFlaggedChange,
      deliveryFiltersStore: {
        deliveryCommonFilterStore: { isFlagged },
      },
    } = this.props;

    return (
      <Button data-test="isFlagged" color={isFlagged ? 'danger' : 'false'} onClick={isFlaggedChange} size="sm">
        <FontAwesome name="flag" />
      </Button>
    );
  }

  private get _renderDownloadButton() {
    return (
      !isMobile() && (
        <Button data-test="download-btn" color="false" onClick={this._downloadList} size="sm">
          <FontAwesome name="download" />
        </Button>
      )
    );
  }

  private get _renderTenantFilter() {
    const {
      sessionStore: { isManager, isTraderOrBackOffice },
      changeTenant,
      deliveryFiltersStore: { tenantFilterStore },
    } = this.props;
    return isManager || isTraderOrBackOffice ? (
      <TenantPickerComponent changeTenant={changeTenant} tenantFilterStore={tenantFilterStore} />
    ) : null;
  }

  private _renderSortByItem = (direction: string, key: string, label: string, value: string) => {
    const {
      selectSortBy,
      deliveryFiltersStore: { sortByValue },
    } = this.props;
    return (
      <DropdownItem
        data-value={value}
        data-direction={direction}
        data-key={key}
        onClick={selectSortBy}
        className={classnames({ active: sortByValue === label })}
      >
        {label}
      </DropdownItem>
    );
  };

  private _renderFilterByItem = (status: string, label: string, value?: string) => {
    const {
      selectFilterBy,
      deliveryFiltersStore: { filterByStatus },
    } = this.props;
    return (
      <DropdownItem
        data-value={value !== undefined ? value : status}
        data-status={status}
        onClick={selectFilterBy}
        className={classnames({ active: filterByStatus === label })}
      >
        {label}
      </DropdownItem>
    );
  };

  private _getFilterRolesForActiveWorkflow(allowedRoles: IAllowedRoles): UserRoles[] {
    const {
      viewStore: { activeTenant },
    } = this.props;

    switch (activeTenant.workflow.code) {
      case CountryCode.UK: {
        return allowedRoles.UK;
      }
      case CountryCode.US: {
        return allowedRoles.US;
      }
      case CountryCode.DE: {
        return allowedRoles.DE;
      }
      case CountryCode.DE_D365: {
        return allowedRoles.DE_D365;
      }
      case CountryCode.IT: {
        return allowedRoles.IT;
      }
      case CountryCode.FR: {
        return allowedRoles.FR;
      }
      default:
        return [];
    }
  }

  private _hasFilter(allowedRoles: IAllowedRoles): boolean {
    const {
      sessionStore: { hasRole },
    } = this.props;

    return this._getFilterRolesForActiveWorkflow(allowedRoles).some((role) => hasRole(role));
  }

  private _redirectToDeliveryLabList = () => {
    this.props.history.push('deliveries/lab');
  };

  private _downloadList = () => {
    const { deliveryFiltersStore, downloadList } = this.props;

    downloadList(deliveryFiltersStore.buildQueryString);
  };

  private _importDeliverySCV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      translateService: { t },
      deliveryStore,
    } = this.props;
    const file = e.target.files[0];
    if (!!file) {
      await deliveryStore.importDelivery(file);
      notification.success(t.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG);
      await this.props.getListData('');
    }
  };
}
