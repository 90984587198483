import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import IdCodeActiveModel from 'models/IdCodeActiveModel';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

@inject('adminStore', 'translateService')
@observer
export default class EwcDetailRoute extends React.Component<IProps> {
  @observable public content: IdCodeActiveModel = null;

  @action
  public updateContent = (ewc: IdCodeActiveModel) => {
    this.content = new IdCodeActiveModel().update(ewc);
  };

  public render() {
    const {
      translateService: { t },
      adminStore: { ewcApi, ewcList },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.EWC}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_EWC}
        itemsList={ewcList}
        api={ewcApi}
        updateItem={this.updateContent}
      />
    );
  }
}
