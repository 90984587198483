import IdNameModel from 'models/IdNameModel';
import UserModel from 'models/UserModel';
import { action, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class LabTrackingModel extends UpdateModel<LabTrackingModel> {
  @observable public active?: boolean;
  @observable public createdAt?: Date;
  @observable public createdBy?: UserModel<IUserModelConstructObj>;
  @observable public id?: string;
  @observable public location?: IdNameModel;
  @observable public status?: IdNameModel;

  @action public update = (obj: LabTrackingModel) => {
    const newLabTrackingModel = cloneObj(obj);

    if (newLabTrackingModel) {
      newLabTrackingModel.createdBy = newLabTrackingModel.createdBy
        ? new UserModel<IUserModelConstructObj>().update(newLabTrackingModel.createdBy)
        : new UserModel<IUserModelConstructObj>();
    }

    this.updater.update(this, newLabTrackingModel, LabTrackingModel);
    return this;
  };
}
