import { Component, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

interface IPortal {
  children: ReactNode;
  node?: Element;
  nodeId?: string;
}

@observer
export default class Portal extends Component<IPortal> {
  @observable
  private _node: Element = this.props.node || null;

  public componentDidMount(): void {
    const { nodeId } = this.props;

    if (nodeId) {
      this._changeNode(document.getElementById(nodeId));
    }
  }

  public render() {
    const { children } = this.props;

    return this._node ? createPortal(children, this._node) : null;
  }

  @action
  private _changeNode = (node: Element) => {
    this._node = node;
  };
}
