import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Ja';
lang.GLOBAL_LABEL_YES_SMALL = 'ja';
lang.GLOBAL_LABEL_NO = 'Nein';
lang.GLOBAL_LABEL_NO_SMALL = 'nein';
lang.GLOBAL_LABEL_SUBMIT = 'Absenden';
lang.GLOBAL_LABEL_RESULT = 'Ergebnis';
lang.GLOBAL_LABEL_ERROR = 'Fehler';
lang.GLOBAL_LABEL_REFRESH = 'Aktualisieren';
lang.GLOBAL_LABEL_ADD_NEW = 'Neuen hinzufügen';
lang.GLOBAL_LABEL_NAME = 'Name';
lang.GLOBAL_LABEL_CODE = 'Code';
lang.GLOBAL_LABEL_UNIQUE_ID = 'Eindeutige ID';
lang.GLOBAL_LABEL_LOCATION = 'Lagerort';
lang.GLOBAL_LABEL_ONLINE = 'Online';
lang.GLOBAL_LABEL_IS_DEFAULT = 'Standard';
lang.GLOBAL_LABEL_MODEL = 'Modell';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = 'Speichern';
lang.GLOBAL_LABEL_DELETE = 'Löschen';
lang.GLOBAL_LABEL_CANCEL = 'Stornieren';
lang.GLOBAL_LABEL_UPLOAD = 'Hochladen';
lang.GLOBAL_LABEL_FILE = 'Datei';
lang.GLOBAL_LABEL_PORT = 'Port';
lang.GLOBAL_LABEL_FIRST_NAME = 'Vorname';
lang.GLOBAL_LABEL_LAST_NAME = 'Nachname';
lang.GLOBAL_LABEL_USERNAME = 'Benutzername';
lang.GLOBAL_LABEL_PASSWORD = 'Passwort';
lang.GLOBAL_LABEL_EMAIL = 'Email';
lang.GLOBAL_LABEL_ROLE = 'Benutzerrolle';
lang.GLOBAL_LABEL_STATUS = 'Status';
lang.GLOBAL_LABEL_REMARKS = 'Bemerkungen';
lang.GLOBAL_LABEL_SELECT = 'Auswählen';
lang.GLOBAL_LABEL_GRN = 'WE-Nr.';
lang.GLOBAL_LABEL_IN_PROGRESS = 'In Bearbeitung';
lang.GLOBAL_LABEL_COMPLETED = 'Abgeschlossen';
lang.GLOBAL_LABEL_REGISTER = 'Registrieren';
lang.GLOBAL_LABEL_OPERATION = 'Betrieb';
lang.GLOBAL_LABEL_SUPPLIER = 'Lieferant';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'Ort';
lang.GLOBAL_LABEL_DRIVER = 'Fahrer';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'LKW';
lang.GLOBAL_LABEL_TO_CLAIM = 'Offen';
lang.GLOBAL_LABEL_CREATED = 'Erstellt';
lang.GLOBAL_LABEL_ACTIONS = 'Aktionen';
lang.GLOBAL_LABEL_CLAIM = 'Annehmen';
lang.GLOBAL_LABEL_DESCRIPTION = 'Material';
lang.GLOBAL_LABEL_ADD = 'Hinzufügen';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'Offen';
lang.GLOBAL_LABEL_DELIVERIES = 'Lieferungen';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = 'Anhänge hochladen';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = 'Anhänge hochladen';
lang.GLOBAL_LABEL_ATTACHMENTS = 'Anhänge';
lang.GLOBAL_LABEL_ATTACHMENT = 'Anhang';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'Dekl. Ware';
lang.GLOBAL_LABEL_SYNC = 'Sync';
lang.GLOBAL_LABEL_CLOSE = 'Schließen';
lang.GLOBAL_LABEL_VERIFY = 'Freigeben';
lang.GLOBAL_LABEL_ALERT = 'PARTIE GESPERRT';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = '!!! ERHALTENES GUT IST FLAGGT !!!';
lang.GLOBAL_LABEL_CLAIM_BY = 'Warenannehmer';
lang.GLOBAL_LABEL_CLASSIFIED_BY = 'Klassifiziert nach';
lang.GLOBAL_LABEL_VERIFIED_BY = 'Geprüft von';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = 'Zurückgesetzt von';
lang.GLOBAL_LABEL_FLAGGED_BY = 'Angegeben von';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'Unermüdlich von';
lang.GLOBAL_LABEL_TRANSFERS = 'Transfers';
lang.GLOBAL_LABEL_TRANSFERED = 'Übertragen';
lang.GLOBAL_LABEL_WEIGHT = 'Gewicht';
lang.GLOBAL_LABEL_CREATE = 'Erstellen';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `Verbleibende Zeichen: ${number}`;
lang.GLOBAL_LABEL_HIDE = 'Verbergen';
lang.GLOBAL_LABEL_UNHIDE = 'Einblenden';
lang.GLOBAL_LABEL_SHOW = 'Anzeigen';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `Sprache: ${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'Datei heruntergeladen';
lang.GLOBAL_LABEL_DECIMALS = 'Dezimalstellen';
lang.GLOBAL_LABEL_GO_BACK = 'Gehe zurück';
lang.GLOBAL_LABEL_VERSION = (version) => `Version - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = 'Bestätigen';
lang.GLOBAL_LABEL_FIELD = 'Feld';
lang.GLOBAL_LABEL_FIELDS = 'Felder';
lang.GLOBAL_LABEL_NONE = 'Keiner';
lang.GLOBAL_LABEL_ALL_TENANTS = 'Alle Mieter';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = 'Name des Eingabefeldes ist nicht definiert';
lang.GLOBAL_LABEL_UNCLAIM = 'Ann. rückg.';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = 'Cert. 333/EU';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = 'Cert. aut. rif.';
lang.GLOBAL_LABEL_CERT_715_13 = 'Cert. 715/13';
lang.GLOBAL_LABEL_TYPE = 'Art';
lang.GLOBAL_LABEL_REVISION_REQUEST = 'Revisionsanfrage';
lang.GLOBAL_LABEL_EXPIRED = 'Abgelaufen';
lang.GLOBAL_LABEL_NO_CERTIFICATE = 'Kein Zertifikat';
lang.GLOBAL_LABEL_RESPONSIBLE = 'Verantwortlich';
lang.GLOBAL_LABEL_DEPARTMENT = 'Abteilung';
lang.GLOBAL_LABEL_EXPORT = 'Export';
lang.GLOBAL_LABEL_REEXPORT = 'Reexport';
lang.GLOBAL_LABEL_FLAGGED = 'Gekennzeichnet';
lang.GLOBAL_LABEL_EXPORTED = 'Exportiert';
lang.GLOBAL_LABEL_SYNCED = 'Synchronisiert';
lang.GLOBAL_LABEL_PRINT = 'Drucken';
lang.GLOBAL_LABEL_FLAG = 'SPERREN';
lang.GLOBAL_LABEL_UN_FLAG = 'FREISCHALTEN';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = '.Keine Autorisierungsnummer';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = '.Autorisierung abgelaufen';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = '.Kein Ablaufdatum';
lang.GLOBAL_LABEL_AUTH_NUMBER = '.Auth. nummer';
lang.GLOBAL_LABEL_EXP_DATE = '.Exp. datum';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `.${name} ist ungültig`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `.Ungültige Felder: ${name}`;
lang.GLOBAL_LABEL_USER_ROLES = '.Benutzerregeln';
lang.GLOBAL_LABEL_DEFAULT_ROLE = '.Standardrolle';
lang.GLOBAL_LABEL_DEFAULT_TENANT = '.Standardmieter';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = '.Neue Version wurde veröffentlicht.';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = '.Um Änderungen zu übernehmen, aktualisieren Sie die Seite. Alle nicht gespeicherten Änderungen gehen verloren!';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = '.Mieter zur Verfügung';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `.Mieter für ${roleName} verfügbar`;
lang.GLOBAL_LABEL_IMPORT = '.Importieren';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `.Ihr Passwort läuft am ${date} ab. Bitte ändern Sie das Passwort so schnell wie möglich, um eine Kontosperrung zu vermeiden`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'Dein Passwort ist abgelaufen. Bitte setzen Sie eine neue';
lang.GLOBAL_LABEL_PROCESSING = 'Wird bearbeitet';
lang.GLOBAL_LABEL_PRICE = 'Preis';
lang.GLOBAL_LABEL_UNIT = 'Einheit';
lang.GLOBAL_LABEL_BREAKDOWN = 'Nervenzusammenbruch';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'Passwort exp. Datum';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'Italia';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'Estero';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'Ditta';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'Lieferantendetails';
lang.GLOBAL_LABEL_FORM = 'Bilden';
lang.GLOBAL_LABEL_QUALITY = 'Qualität';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = 'Produktvarianten aktualisieren';
lang.GLOBAL_LABEL_WEIGHT_UNITS = 'Gewichtseinheiten';
lang.GLOBAL_LABEL_EMPLOYEE_ID = 'Angestellten ID';
lang.GLOBAL_LABEL_LEGAL_ENTITY = 'Rechtsperson';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = 'Produktvarianten';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `Aktualisierung ${name}`;
lang.GLOBAL_LABEL_TOTAL = 'Gesamt';
lang.GLOBAL_LABEL_SHAREPOINT = 'Sharepoint';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = '.Teilweise Abmeldung';
lang.GLOBAL_LABEL_TITLE = 'Titel';
lang.GLOBAL_LABEL_CLIENT_ID = 'Kunden ID';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'Client Sercet';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = 'Verbindungsadresse';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'Saldoabzug standardmäßig aktivieren';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = 'Detailliertes Wiegen';
lang.GLOBAL_FILTERKEY_DATE_ASC = 'Datum ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = 'Datum DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = 'Status ASC';
lang.GLOBAL_FILTERKEY_STATUS_DESC = 'Status DESC';
lang.GLOBAL_FILTERKEY_ALL = 'Alle';
lang.GLOBAL_FILTERKEY_ONLY_MY = 'Nur meine';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = 'Nur Wartende';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = '-';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = 'In Bearbeitung';
lang.GLOBAL_FILTERKEY_IN_LAB = '-';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = '-';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'Bereit für Stufe 1';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'Endgültig freigegeben';
lang.GLOBAL_FILTERKEY_COMPLETE = 'Abgeschlossen';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = 'Teilweise unterschrieben';
lang.GLOBAL_RADIOBUTTON_DRY = 'Trocken';
lang.GLOBAL_RADIOBUTTON_RAIN = 'Regen';
lang.GLOBAL_RADIOBUTTON_SNOW = 'Schnee';
lang.GLOBAL_RADIOBUTTON_DUMP = 'Kipper';
lang.GLOBAL_RADIOBUTTON_VAN = 'Sprinter';
lang.GLOBAL_RADIOBUTTON_FLATBED = 'Flachbett';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = '.Abrollbox';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'Bahnwaggon';
lang.GLOBAL_RADIOBUTTON_OTHER = 'Andere';
lang.GLOBAL_USERROLE_SUPERADMIN = 'Super Admin';
lang.GLOBAL_USERROLE_ADMIN = 'Admin';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = 'Waage';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = '.Externe Waage';
lang.GLOBAL_USERROLE_TIER3 = 'Stufe 3';
lang.GLOBAL_USERROLE_TIER2 = 'Stufe 2';
lang.GLOBAL_USERROLE_TIER1 = 'Stufe 1';
lang.GLOBAL_USERROLE_MANAGER = 'Manager';
lang.GLOBAL_USERROLE_LAB = 'Labor';
lang.GLOBAL_USERROLE_TRADER = 'Händler';
lang.GLOBAL_USERROLE_BACKOFFICE = '.Backoffice';
lang.GLOBAL_USERROLE_DATAANALYZER = 'Datenanalysator';
lang.GLOBAL_LOGIN_HEADER = 'Anmeldung';
lang.GLOBAL_LOGIN_TITLE = 'Melden Sie sich bei Ihrem Konto an';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'Sie wurden ausgeloggt';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = 'Anfangsdatum';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = 'Enddatum';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'Passwort festlegen';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'Passwort wurde aktualisiert';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'Passwort festlegen';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = 'Aktuelles Passwort';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = 'Neues Passwort';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = 'Neues Passwort erneut eingeben';
lang.GLOBAL_FILE_SELECT_ERROR = '.Fehler bei der Dateiauswahl';
lang.GLOBAL_SERVERERROR_DEFAULT = 'Etwas ist schief gelaufen…';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = 'Fehler im externen Dienst...';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2 kann gesperrte Partie nicht freigeben.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = '.T2 kann nicht gut erhalten.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2 kann den Status nicht zurücksetzen.';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = 'Trader kann gesperrte Partie nicht freigeben.';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = '.Der Administrator kann den empfohlenen Status nicht rückgängig machen.';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1 kann keinen Anspruch auf Partie erheben';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1 kann Partie nicht entladen';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1 kann Partie nicht freigeben';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = 'Partie kann nicht freigegeben werden, da die Lieferung noch nicht synchronisiert wurde';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'Gesperrt! Kann derzeit nicht freigegeben werden';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = '.Die empfohlene Ware ist nicht zur Abmeldung bereit';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = '.Alle Artikel müssen lagercodiert sein, bevor avisierte Ware abgezeichnet werden kann';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = '.Sie können sich nicht gut beraten abmelden, wenn Sie es bereits verifiziert haben';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = '.Empfohlene Ware hat kein Taragewicht eingefüllt';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = '.Abmeldung auf empfohlenem Gut-Niveau ist für den Mieter deaktiviert';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = '.Kann nicht aktualisiert werden, wenn die Lieferung bereits abgemeldet ist';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = '.Kommentar kann nicht aktualisiert werden, wenn AG entladen ist';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = '.Partie Taragewicht fehlt! Kann derzeit nicht freigegeben werden';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = '.Lieferung Taragewicht fehlt! Kann derzeit nicht freigegeben werden';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = 'Alle Kontonummern müssen eingetragen sein! Kann derzeit nicht freigegeben werden';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'Es sind noch Partien im Labor';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `Wird bereits bearbeitet von ${fullname}`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = '"Status zurücksetzen" nicht mehr möglich, sobald Lieferung freigegeben ist';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = '.Rollback kann nicht durchgeführt werden, sobald die empfohlene Ware abgemeldet wurde';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = '.Lieferung mit Laborproben kann nicht gelöscht werden';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = '.Die Vorlage unterstützt nicht mehr als 11 erhaltene Waren innerhalb einer empfohlenen Ware.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = '.Maximal 10 erhaltene Waren innerhalb einer empfohlenen Ware.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = '.Maximal 19 erhaltene Waren innerhalb einer empfohlenen Ware.';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'Zeitüberschreitung während Fotoabfrage';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = 'Bitte kalibrieren Sie die Kamera vor der Verwendung';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'Zeitüberschreitung während ocr Abfrage';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = 'Es existiert kein Admin';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = '.AppRegistration-Rolle ist nicht vorhanden';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = '.Nur das Bearbeiten von Konten vom Typ AppRegistration ist erlaubt';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = '.Client Secret erfordert mindestens 20 Zeichen, einen Großbuchstaben, eine Ziffer, ein Sonderzeichen';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = '.Client-ID existiert bereits';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = '.Der Titel der App-Registrierung ist ungültig';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = '.Die Beschreibung der App-Registrierung ist ungültig';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = '.Client Secret erfordert mindestens 20 Zeichen';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = 'Mandant kann nicht entfernt werden, da ihm User zugeordnet sind';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = '.Die Reihenfolge für den Mieter kann nicht festgelegt werden, da bereits einige Lieferungen erstellt wurden';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = '.Für den Mieter kann keine juristische Person festgelegt werden, da bereits einige Lieferungen erstellt wurden';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = '.Diese Seite kann nicht verwendet werden, da sie bereits von anderen Mietern verwendet wird';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = '.Bitte Seite angeben';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = '.Bitte geben Sie den SharePoint-Ordner für das Hochladen von Anhängen an';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = '.Ausgewählter SharePoint-Ordner existiert nicht';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = '.SharePoint-Ordner zum Hochladen von Anhängen nicht für den Mandanten ausgewählt. Bitte kontaktieren Sie Ihren SuperAdmin';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = '.Die dOps-Anwendung darf keine Dateien auf die ausgewählte SharePoint-Site hochladen. Bitte kontaktieren Sie Ihren SuperAdmin';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = '.Externer Dienstfehler: Fehler beim Aktualisieren der Anhangsmetadaten in SharePoint Online';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = '.Externer Dienstfehler: Anmeldung bei SharePoint Online nicht möglich';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = 'Lieferung kann nach der Synchronisierung nicht mehr aktualisiert werden';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = '.Die Nummer des Lieferscheins ist für die Synchronisierung der Lieferung obligatorisch';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = '"Deklarierte Partie in Bearbeitung" kann nicht gelöscht werden';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = '.Das Taragewicht ist für die Liefersynchronisation obligatorisch';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = 'Die untergeordnete Ware muss mindestens eine empfangene Ware enthalten';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = 'Die Partie muss mindestens einen Artikel in der Avisierung enthalten';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = 'Die Lieferung muss mindestens eine dekl. Partie enthalten enthalten';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = 'Das Taragewicht kann nicht gespeichert werden, bis die Partie "in Bearbeitung" ist';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = 'Alle Partien müssen einem Konto zugeordnet sein, bevor die Lieferung bestätigt werden kann';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = 'Alle Laborartikel müssen fertiggestellt sein, bevor die Lieferung abgemeldet werden kann';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '.Einige deklarierte Partien haben kein Taragewicht';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = '.Es gibt einige erhaltene Waren, bei denen kein Bruttogewicht angegeben ist';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = '.Es gibt einige empfohlene Waren, bei denen der AVV-Code nicht ausgefüllt ist';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.Die Lieferung sollte vor der Abmeldung synchronisiert werden';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.Abmeldung nicht möglich, gut beraten, Lieferung sollte synchronisiert werden';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = '.Alle avisierten Waren müssen abmeldebereit sein, bevor die Lieferung abgemeldet werden kann';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '.Die Lieferung kann erst abgeschlossen werden, wenn alle Taragewichte eingetragen wurden';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'Lieferung kann nicht abgeschlossen werden, bevor sie freigegeben wurde';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'Die Lieferung kann nicht erneut synchronisiert werden, da sie noch nicht an der Waage synchronisiert wurde';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'Die Sortierung kann nicht erneut synchronisiert werden, da sie noch nicht freigegeben wurde';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = 'Material mit demselben Code ist bereits vorhanden';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `Das Material wird von der Zustellung verwendet ${grn}`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = '"Deklarierte Ware" muss angenommen werden, bevor sie bearbeitet werden kann';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'Laboranalyse kann für abgeschlossene Sortierberichte nicht hinzugefügt werden';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `Die angegebene Kontonummer ${stockCode} ist ungültig`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `.Die angegebene Benutzerrollen-ID ${userRole} wurde nicht gefunden`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'Laboranalyse kann nicht für nicht angenommene Partien hinzugefügt werden';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'Beim Generieren des QR-Code ist ein Problem aufgetreten';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = 'Eingangsgewicht kann beim Übertragen nicht NULL sein';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = 'Es kann höchstens das Gesamtgewicht der Partie übertragen werden';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = 'Vor dem Transfer muss ein Gewicht eingegeben werden';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = 'Das zu transferrierende Gewicht kann nicht größer sein als das Gewicht der Partie';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = 'Negatives Gewicht kann nicht transferiert werden';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = 'Gesamte Menge kann nicht transferiert werden';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = 'Es kann keine komplette Partie transferiert werden.';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = 'Partie kann nicht transferiert werden.';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'Dem Benutzer kann keine Super-Administratorrolle zugewiesen werden';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = '.Das Passwort erfordert mindestens 10 Zeichen, einen Großbuchstaben, eine Ziffer und ein Sonderzeichen';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'Sie haben keine Berechtigung einen anderen Admin zu löschen';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = 'Unbekannter Fehler';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'Fehler beim Senden der E-Mail';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = 'Fehler bei Bearbeitung der Anfrage';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'Datei nicht gefunden';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = 'Deklarierte Partie nicht gefunden';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = 'Anhang der deklarierten Partie nicht gefunden';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = 'Verschmutzung nicht gefunden';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = 'Detail der deklarierten Partie nicht gefunden';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = 'Wareneingang nicht gefunden';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = '.Wareneingang Beschreibung darf nicht länger als 79 Zeichen sein';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'Kamera nicht gefunden';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'Kamera inaktiv';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'Tenant nicht gefunden';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = 'Admin nicht gefunden';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'Benutzer nicht gefunden';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = 'Lagerort nicht gefunden';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = 'Lagerort existiert bereits';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = 'Lieferung nicht gefunden';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = 'Anhang zur Lieferung nicht gefunden';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'Lieferant nicht gefunden';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'Lieferantentyp nicht gefunden';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = 'Spediteur nicht gefunden';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = 'Material nicht gefunden';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'Dieser Labor-Eingabetyp wurde nicht gefunden';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'Lab-Eingabetyp für Workflow nicht gefunden';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'Labor-Eingabe wurde nicht gefunden';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'Laborstatus wird nicht unterstützt';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'Laborstatus für Workflow nicht gefunden';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = 'Partie nicht gefunden';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = '.Lagerartikel existiert bereits';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = '.Die Länge des Lagerartikels sollte 5 Stellen betragen';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'Drucker nicht gefunden';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = 'Waage nicht gefunden';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = 'Druckvorlage nicht gefunden';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = '.Produktform nicht gefunden';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = '.Das bereitgestellte Produktformular bezieht sich nicht auf den bereitgestellten Lagerartikel';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = '.Produktqualität nicht gefunden';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = '.Produktvariante nicht gefunden';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = '.Produktvarianten sind für die Abmeldung erforderlich';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'Benutzername existiert bereits';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = 'Sich selber löschen ist nicht möglich';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = 'Ungültiger Benutzername oder Passwort';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = '.Bitte wählen Sie ein anderes Passwort. Dieser wurde bereits zuvor verwendet';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = '.Das aktuelle Client-Geheimnis ist seit 2 Jahren Benutzer und abgelaufen. Bitte wenden Sie sich an den Superadministrator';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = 'Bitte geben Sie eine gültige E-Mail an';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'Bitte geben Sie den Benutzernamen an';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'Please provide password';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'Die minimale Passwortlänge beträgt 10 Zeichen';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = '.Ungültige Client-ID';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = '.Ungültiger geheimer Clientschlüssel';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'Bitte geben Sie den Vornamen an';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'Bitte geben Sie den Nachnamen an';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'Bitte geben Sie Benutzerrollen an';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = '.Bitte geben Sie Benutzermieter an';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = '.Die ID des externen Mitarbeiters muss aus mindestens 6 Symbolen bestehen';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = '.Das Feld für die externe Mitarbeiter-ID ist erforderlich';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = '.Externer Mitarbeiter mit angegebener ID nicht gefunden';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `.Die ausgewählte Mitarbeiter-ID ist nicht berechtigt, für den Mandanten zu arbeiten: ${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = '.Bitte geben Sie nur eine Standardrolle für den Benutzer an';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = '.Bitte geben Sie nur einen Standardmandanten für den Benutzer an';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = '.Die aktive Benutzerrolle muss mit einer der vorhandenen Benutzerrollen übereinstimmen';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = '.Sie haben keine Berechtigung, auf den ausgewählten Mandanten zuzugreifen';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = '.Nur Super Admin kann Benutzer mit der Rolle "Admin" erstellen oder bearbeiten.';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = '.Benutzer können nicht gleichzeitig die Rollen Trader und BackOffice haben';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = '.Benutzer mit der Rolle DataAnalyzer können nicht mehrere Rollen haben';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = '.Mehrere Mandanten können nur zugewiesen werden, wenn der Benutzer die Rolle Trader, BackOffice oder Admin hat';
lang.GLOBAL_SERVERERROR_INVALID_NAME = 'Bitte geben Sie einen Namen an';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'Der Zugriff wurde verweigert';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'Sitzung nicht gefunden';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'Sitzung abgelaufen';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = '.Bitte versuchen Sie es in 1 Minute erneut';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = '** Please provide stock item name';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = '** You cannot sign off delivery if you already verified one of advised goods';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = '** Supplier with provided code already exists';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'Bitte geben Sie den Lieferantennamen an';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'Bitte geben Sie die Lieferantennummer an';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'Bitte geben Sie den Sperrgrund an';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = 'Bitte geben Sie eine Beschreibung an';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = '.Wareneingang Beschreibung darf nicht länger als 255 Zeichen sein';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = 'Bitte geben Sie Materialinformationen an';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = 'Bitte geben Sie die Material ID an';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = 'Bitte geben Sie das Materialgewicht an';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = 'Bitte geben Sie eine ID für zusätzlich deklarierte Ware an';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'Bitte geben Sie den Wert für p1 an';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'Bitte geben Sie den Wert für p2 an';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'Bitte geben Sie den Wert für p3 an';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'Bitte geben Sie den Wert für p4 an';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'Bitte geben Sie den Kontrast an';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = 'Bitte geben Sie den Anzeigetyp an';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'Bitte geben Sie den Namen der Kamera an';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'Bitte geben Sie einen Platz für die Kamera an';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'Bitte geben Sie die eindeutige ID der Kamera an';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'Bitte geben Sie den Lieferanten an';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = 'Bitte geben Sie den Spediteur an';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = 'Bitte Abteilung angeben.';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = 'Bemerkungen können maximal 30 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = 'Bitte geben Sie das Kennzeichen an';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = 'Bitte benennen Sie das Material';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = 'Bitte geben Sie eine Beschreibung an';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = 'Die Beschreibung darf nicht länger als 30 Zeichen sein';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = 'Die Beschreibung darf nicht länger als 50 Zeichen sein';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = 'Die Beschreibung darf nicht länger als 100 Zeichen sein';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = 'Bitte geben Sie eine Wiegescheinnummer an';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = 'Bitte geben Sie einen Lagerort an';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = 'Bitte geben Sie das Materialkonto an';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = 'Bitte geben Sie Materialnamen an';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'Bitte geben Sie den Druckernamen an';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'Bitte geben Sie die IP-Adresse des Druckers an';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'Bitte geben Sie das Druckermodell an';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = '.Bitte geben Sie die Verbindungsadresse des Druckers an';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = '.Die Beschreibung darf maximal 255 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = '.Die Adresse darf maximal 255 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = '.Der Name darf maximal 255 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = '.Dokumententyp nicht gefunden';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = '.Bitte geben Sie den Namen des Lasernet-Berichts an';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = '.Der Name des Lasernet-Berichts darf maximal 255 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = '.Bitte geben Sie die Standardanzahl der Kopien an';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = '.Bitte geben Sie die Drucker-ID an';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = '.Der Drucker mit dem angegebenen Namen existiert bereits';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = '.Druckerkonfiguration nicht gefunden';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = '.Druckerkonfiguration mit bereitgestellten Parametern ist bereits vorhanden';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = '.Bitte geben Sie die Anzahl der Exemplare an';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = 'Bitte geben Sie das zuübertragende Gewicht';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = 'Bitte geben Sie einen neuen Status an';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = 'Bitte geben Sie den Namen der Waage an';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = 'Bitte geben Sie die IP-Adresse der Waage an';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = 'Bitte geben Sie den Port der Waage an';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = '.Bitte geben Sie die Versandart an';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = '.Bitte geben Sie die Wetterbedingungen an';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = '.Bitte geben Sie die Transaktionsart an';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = '.Bitte geben Sie den Bearbeitungspreis an';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = '.Bearbeitungspreis nicht gefunden';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = 'Die Bildauflösung ist zu hoch';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = '.Nur PDF-Dateien und Bilder sind als Anhänge zulässig';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'GRN darf nicht leer sein';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `Waren, die sich auf Transferpositionen beziehen, können nicht gelöscht werden: ${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = 'Nicht unterstützte Aktion';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = '.Auf diesem Server sind keine D365-Mandanten verfügbar';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = 'Laboreingabe kann nicht erstellt werden, da bereits eine andere Laboreingabe desselben Typs vorhanden ist';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = 'Abzug darf nicht leer sein';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = 'Gerät nicht gefunden';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'Zielcharge nicht gefunden';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `Bitte geben Sie "Gewicht" für an: : ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "Gerät" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "WI durchgeführt" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "Schüttdichte" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "Materialbeschreibung" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "Gefundene Qualität" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "Hauptform" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `Bitte geben Sie "Zielcharge" an in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'Bitte geben Sie den Namen des Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'Bitte geben Sie den Mandantcode an';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'Bitte geben Sie den Standortcode des Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'Der Mandant-Standortcode darf maximal 10 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'Bitte geben Sie das GRN-Präfix des Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = 'Bitte geben Sie den aktuellen GRN-Wert für den Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'Bitte geben Sie den Arbeitsablauf des Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'Bitte geben Sie die Zeitzone des Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'Bitte geben Sie den Regionalcode des Mandant an';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'Der Mandant-Regioncode darf maximal 10 Zeichen lang sein';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = '.Bitte geben Sie die juristische Person des Mieters an';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = 'Nur der Besitzer eines Anhangs oder der Administrator kann den Anhang löschen';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = 'Die Konfiguration des Integrations Endpunkts fehlt. Bitte wenden Sie sich an Ihren Administrator.';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = 'Annahme kann nur rückgängig gemacht werden, wenn die Partie bereits angenommen wurde';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = 'Annahme kann nur von der Person rückgängig gemacht werden, die die Partie angenommen hat oder einem Admin';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = 'Annahme kann nur rückgängig gemacht werden, wenn keine Laboreingaben erstellt wurden';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = 'Verpackung existiert bereits';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = 'Verpackung nicht gefunden';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = 'Die Summe der "Größen" der Kontaminationsfelder muss 100% betragen.';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = 'Bearbeitungszeit nicht gefunden';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `Bitte Kontamination angeben in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'Bitte Code eingeben';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = 'Bitte Lieferursprung angeben';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = 'Herkunft nicht gefunden';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = '.Die Herkunftslänge sollte 35 Buchstaben betragen';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'AVV nicht gefunden';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = '.AVV existiert bereits';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = '.GRN existiert bereits';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = 'Die Lieferung kann nicht exportiert werden, da sie noch nicht freigegeben wurde';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = '.Bitte verwenden Sie einen Filter, um die Anzahl der zu exportierenden Lieferungen zu reduzieren. Die ausgewählte Lieferliste ist zu lang. ';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = 'Messung nicht gefunden';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = '.Spektroanalyse nicht gefunden.';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `.Bitte Code: ${joinedlabelslist}`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `.Die Länge des Benutzercodes sollte ${allowedLength} Buchstaben betragen`;
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = '.Bitte geben Sie das Erstellungsdatum der Lieferung an';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = '.Bitte geben Sie die empfohlene gute Zeilennummer an';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = '.Bitte geben Sie den Händlercode an';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = '.Bitte geben Sie das angegebene Nettogewicht an';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `.Lieferung ${grn} bereits abgeschlossen und kann nicht aktualisiert werden`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `.Lieferung ${grn} bereits abgemeldet und kann nicht aktualisiert werden`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = '.Datei ist leer';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = '.Vertrag konnte nicht importiert werden';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = '.Die Anzahl der Spalten entspricht nicht der Vorlage';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = '.Die Reihenfolge der Spalten entspricht nicht der Vorlage';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = '.Fehler beim Hochladen von Werftstandorten, falscher Spaltenname angegeben name';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = '.Fehler beim Hochladen der Lieferanten, falsche Spaltennamen angegeben';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = '.Fehler beim Hochladen der Transportunternehmen, falsche Spaltennamen angegeben';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = '.Fehler beim Hochladen von Lagerartikeln, falsche Spaltennamen angegeben';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = 'Bitte geben Sie Gewichtseinheiten an';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = '.Bitte geben Sie die juristische Person an';
lang.GLOBAL_COPY_TITLE = (itemname) => `.Klicken Sie hier, um ${itemname} in die Zwischenablage zu kopieren`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `.${name} wurde in die Zwischenablage kopiert`;
lang.HEADER_QRCODE_HEADER = 'QR-Code scannen';
lang.HEADER_QRCODE_ERROR_MSG = 'Beim Öffnen des QR-Code Scanners ist ein Fehler aufgetreten';
lang.HEADER_LABEL_LOGOUT = 'Ausloggen';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'Ändere das Passwort';
lang.HEADER_LABEL_SWITCH_ROLE = '.Rolle wechseln';
lang.HEADER_LABEL_SWITCH_TENANT = '.Mieter wechseln';
lang.HEADER_MODAL_LOGOUT = 'Sind Sie sicher, dass Sie sich ausloggen wollen? Alle nicht gespeicherten Daten gehen dabei verloren.';
lang.HEADER_MODAL_SWITCH_ROLE = '.Möchten Sie die Rolle wechseln? Alle nicht gespeicherten Änderungen gehen verloren.';
lang.HEADER_MODAL_SWITCH_TENANT = '.Möchten Sie den Mieter wechseln? Alle nicht gespeicherten Änderungen gehen verloren.';
lang.ADMIN_SECTIONENTITY_USER = 'Benutzer';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = 'Konto';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'Lieferant';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = '.Spediteur';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = '.Lagerort';
lang.ADMIN_SECTIONENTITY_PRINTER = '.Drucker';
lang.ADMIN_SECTIONENTITY_SCALE = '.Waage';
lang.ADMIN_SECTIONENTITY_MATERIAL = 'Nitonanalyse';
lang.ADMIN_SECTIONENTITY_MACHINE = 'Gerät';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'Zielcharge';
lang.ADMIN_SECTIONENTITY_PACKAGE = 'Pakete';
lang.ADMIN_SECTIONENTITY_EWC = 'AVV Nummer';
lang.ADMIN_SECTIONENTITY_ORIGIN = 'Ursprung';
lang.ADMIN_ACTIVITYLOG_TITLE = 'Aktivitätsprotokoll';
lang.ADMIN_ACTIVITYLOG_ACTION = 'Aktion';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = 'Erstellt in';
lang.ADMIN_ACTIVITYLOG_USER = 'Benutzer';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'Nachricht';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = 'Neues Material wurde erstellt';
lang.ADMIN_MATERIALS_TITLE = 'Materialzusammensetzung';
lang.ADMIN_MATERIALS_CREATE_NEW = 'Erstelle neues Material';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = 'Materialien wurden aktualisiert';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = 'Materialien wurden gelöscht';
lang.ADMIN_MATERIALDETAIL_HEADER = 'Materialdetail';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = 'Material löschen bestätigen';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `Möchten Sie das Material <strong>${name}</strong> löschen ?`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = 'Neuer Drucker wurde erstellt';
lang.ADMIN_PRINTERLIST_HEADER = 'Drucker';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'Drucker erstellen';
lang.ADMIN_PRINTERLIST_NAME = 'Druckername';
lang.ADMIN_PRINTERLIST_CREATE_NEW = 'Neuen Drucker erstellen';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'Der Drucker wurde aktualisiert';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'Der Drucker wurde gelöscht';
lang.ADMIN_PRINTERDETAIL_HEADER = 'Details zum Druckerstandort';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'Drucker löschen bestätigen';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `Möchten Sie den Drucker <strong>${name}</strong> löschen?`;
lang.ADMIN_ROADHAULIERLIST_HEADER = 'Spediteure';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = 'Spediteure hochladen';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = '.Zulassungsnummer';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = '.Haltbarkeitsdatum';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `.Neuen ${name} erstellen`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `.${name} hochladen`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `.Neue ${name} wurde erstellt`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `.${name} wurde aktualisiert`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `.${name} wurde ausgeblendet`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `.${name} wieder eingeblendet`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `.Details ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `.${name}bestätigung ausblenden`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Möchten Sie ausblenden <strong> ${name} </strong>?`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `.${name} bestätigung wieder einblenden`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Möchten Sie wieder einblenden <strong> ${name} </strong>?`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `.${name} nicht gefunden`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = 'Liste erfolgreich hochgeladen!';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'Beim Hochladen der Liste ist ein Fehler aufgetreten.';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = 'Sehen Sie sich die Excel-Beispieldatei an:';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = 'Neue Waage wurde erstellt';
lang.ADMIN_SCALELIST_HEADER = 'Waage';
lang.ADMIN_SCALELIST_ADD_BTN = 'Waage erstellen';
lang.ADMIN_SCALELIST_NAME = 'Name der Waage';
lang.ADMIN_SCALELIST_CREATE_NEW = 'Neue Waage erstellen';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = 'Waage wurde aktualisiert';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = 'Waage wurde gelöscht';
lang.ADMIN_SCALEDETAIL_HEADER = 'Details zur Waage';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = 'Waage löschen bestätigen';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `Möchten Sie die Waage <strong>${name}</strong> löschen ?`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = 'Name des Eingabefeldes ist nicht definiert';
lang.ADMIN_STOCKEITEMLIST_HEADER = 'Warenbestandskonten';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = 'Warenbestandskonten hochladen';
lang.ADMIN_SUPPLIERLIST_HEADER = 'Lieferanten';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'Lieferanten hochladen';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'Lieferant wurde aktualisiert';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'Lieferant wurde ausgeblendet';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'Lieferant wieder eingeblendet';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'Details zum Lieferanten';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'Lieferantenbestätigung ausblenden';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie den Lieferanten ausblenden <strong> ${name} </strong>?`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Lieferantenbestätigung wieder einblenden';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie den Lieferanten wieder einblenden <strong> ${name} </strong>?`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'Lieferant nicht gefunden';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = 'Gerät wurde aktualisiert';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = 'Gerät wurde versteckt';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = 'Gerät wurde ausgeblendet';
lang.ADMIN_MACHINEDETAIL_HEADER = 'Gerätedetail';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = 'Gerätebestätigung ausblenden';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie das Gerät <strong>${name}</strong> ausblenden?`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Gerätebestätigung einblenden';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie das Gerät <strong>${name}</strong> einblenden?`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = 'Gerät wurde nicht gefunden';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = 'Verpackung wurde aktualisiert';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = 'Verpackung wurde versteckt';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = 'Die Verpackung war nicht versteckt';
lang.ADMIN_PACKAGING_DETAIL_HEADER = 'Verpackungsdetail';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = 'Verpackungsbestätigung ausblenden';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie die Verpackung <strong>${name}</strong> verstecken?`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = 'Verpackungsbestätigung einblenden';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie die Verpackung einblenden <strong>${name}</strong> einblenden?`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = 'Verpackung nicht gefunden';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'Zielcharge wurde aktualisiert';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'Die Zielcharge wurde ausgeblendet';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'Die Zielcharge wurde nicht ausgeblendet';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'Detail der Zielcharge';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'Zielchargenbestätigung ausblenden';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie den Zielstapel <strong>${name}</strong> ausblenden?`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Blenden Sie die Ziel-Chargenbestätigung ein';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Möchten Sie den Zielstapel <strong>${name}</strong> einblenden?`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'Zielcharge wurde nicht gefunden';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = 'Neuer Benutzer wurde erstellt';
lang.ADMIN_USERLIST_HEADER = 'Benutzerverwaltung';
lang.ADMIN_USERLIST_ADD_BTN = 'Neuen Benutzer erstellen';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'Benutzer wurde aktualisiert';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'Benutzer wurde gelöscht';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'Benutzer löschen';
lang.ADMIN_USERDETAIL_HEADER = 'Benutzerdetails';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'Benutzer löschen bestätigen';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `Möchten Sie den Benutzer <strong>${name}</strong> löschen?`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = 'Neuer Lagerort wurde erstellt';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'Lagerorte';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'Lagerort erstellen';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'Lagerort hochladen';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'Name des Lagerortes';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = 'Neuen Lagerort erstellen';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'Lagerort wurde aktualisiert';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'Lagerort wurde gelöscht';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'Details zum Lagerort';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = 'Benutzer löschen bestätigen';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `Möchten Sie den Lagerort <strong>${name}</strong> löschen?`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'Lagerort';
lang.ADMIN_MACHINE_LIST_HEADER = 'Geräte';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = 'Geräte Hochladen';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'Zielchargen';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'Zielchargen hochladen';
lang.ADMIN_PACKAGING_ADD_BTN = 'Verpackung hinzufügen';
lang.ADMIN_PACKAGING_UPLOAD_BTN = 'Verpackung hochladen';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = 'Neue Verpackung erstellen';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = 'Neue Verpackung wurde erstellt';
lang.ADMIN_EWCLIST_HEADER = 'AVVen';
lang.ADMIN_EWCLIST_ADD_BTN = '.AVV rstellen';
lang.ADMIN_EWCLIST_UPLOAD_BTN = '.AVV hochladen';
lang.ADMIN_EWCLIST_CODE = '.Code';
lang.ADMIN_EWCLIST_CREATE_NEW = '.Erstellen Sie einen neuen AVV';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = '.Neuer AVV-Code wurde hinzugefügt';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = '.Der AVV-Code wurde aktualisiert';
lang.ADMIN_EWCDETAIL_HEADER = '.AVV-Detail';
lang.ADMIN_EWCDETAIL_NAME = '.Code';
lang.ADMIN_EWCDETAIL_HIDE_MSG = 'Der AVV war versteckt';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = '.Der AVV war nicht versteckt';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = '.AVV-Bestätigung ausblenden';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Möchten Sie den AVV-Code ausblenden <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = '.AVV-Bestätigung einblenden';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Möchten Sie den EWC-Code einblenden <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = '.AVV nicht gefunden';
lang.ADMIN_ORIGINLIST_HEADER = '.Ursprünge';
lang.ADMIN_ORIGINLIST_ADD_BTN = '.Erstellen Sie den Ursprungscode';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = '.Ursprung hochladen';
lang.ADMIN_ORIGINLIST_CODE = '.Code';
lang.ADMIN_ORIGINLIST_CREATE_NEW = '.Neuen Ursprung erstellen';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = '.Neuer Ursprungscode wurde hinzugefügt';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = '.Der Ursprungscode wurde aktualisiert';
lang.ADMIN_ORIGINDETAIL_HEADER = '.Ursprungsdetail';
lang.ADMIN_ORIGINDETAIL_NAME = '.Code';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = '.Herkunft wurde versteckt';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = '.Herkunft war nicht versteckt';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = '.Ursprungsbestätigung ausblenden';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Möchten Sie den Ursprungscode ausblenden <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Ursprungsbestätigung einblenden';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Möchten Sie den Ursprungscode einblenden <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = '.Herkunft nicht gefunden';
lang.ADMIN_CONTRACTS_TITLE = '.Verträge';
lang.ADMIN_CONTRACTS_FILE_IMPORT = '.Import importieren';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = '.SFTP-Synchronisierung auslösen';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = '.Datei importiert';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = '.SFTP-Import ausgelöst';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '£/Stunde';
lang.ADMIN_PROCESSING_UNIT_TONS = '£/Tonne';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = 'Bestätigung der Produktvarianten aktualisieren';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = 'Der Aktualisierungsvorgang kann bis zu einigen Minuten dauern. Möchten Sie fortfahren?';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = 'Produktvarianten erfolgreich aktualisiert';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'Aktualisieren Sie noch laufende Produktvarianten. Aktualisieren Sie die Seite nach 2 Minuten';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `Bestätigung ${name} aktualisieren`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name} erfolgreich aktualisiert`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = 'Der Aktualisierungsvorgang kann bis zu einigen Minuten dauern. Möchten Sie fortfahren?';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `Aktualisieren Sie ${name} noch in Bearbeitung. Aktualisieren Sie die Seite nach 2 Minuten`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = 'Mitarbeiter erfolgreich aktualisiert';
lang.ADMIN_LASERNET_REPORT_NAME = 'Name des Laserner-Berichts';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'Standardanzahl der Kopien';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'Dokumentenart';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'Dokumenttyp-Detail';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'Dokumenttyp -ID';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'Bitte geben Sie den Namen des Lasernet-Berichts an';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'Druckerkonfigurationen';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = 'Konfigurationsdetails';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = 'Bestätigung löschen';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = 'Möchten Sie die Konfiguration löschen?';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = 'Die Bestätigung wurde erfolgreich gelöscht';
lang.ADMIN_LASERNET_PRINTER_ID = 'Drucker -ID';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = 'Neuer Mandant wurde erstellt';
lang.SUPERADMIN_TENANTLIST_HEADER = 'Mandanten';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = 'Neuen hinzufügen';
lang.SUPERADMIN_TENANTLIST_GRN = 'Wareneingangsnr. Präfix';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = 'Neuen Mandant erstellen';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'Mandant wurde aktualisiert';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'Mandant wurde gelöscht';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'Mandant - Details';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'Mandant löschen bestätigen';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `Möchten Sie den Mandant <strong>${name}</strong> löschen?`;
lang.SUPERADMIN_TENANTDETAIL_SITE = 'Seite';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = 'Suchen Sie nach fehlenden Anhängen';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'Auf empfohlenem gutem Niveau';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = 'Auf Auslieferungsebene';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = 'Klassifikation erforderlich';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = 'Kontamination erforderlich';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'Bestätigen Sie das Überschreiben der Beschreibung im Sortierbericht';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'Mandant aktualisiert';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = 'Admin hinzugefügt';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = 'Admin gelöscht';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'Mandant';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = 'Code Lagerort';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = 'Regional Code';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = 'Aktuelle Wareneingangsnummer';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = 'Aktueller Lab-Sequenzwert';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'Arbeitsablauf';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'Wählen Sie Arbeitsablauf';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = 'Zeitzone';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = 'Integrations-API-Basis';
lang.SUPERADMIN_TENANTADMIN_ADMINS = 'Admins';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = 'Admin hinzufügen';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = 'Keine Admins';
lang.SUPERADMIN_TENANTADMIN_HEADER = 'Admin hinzufügen';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = 'Administrator';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = '.Admin ausblenden';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `.Möchtest du dich <strong>${name}</strong> verstecken?`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = '.Admin einblenden';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `.Voulez-vous afficher <strong>${name}</strong>?`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = '.Sharepoint-Site auswählen';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = '.Bildordnerpfad';
lang.SUPERADMIN_SHAREPOINT_SITES = '.Sharepoint-Sites';
lang.SUPERADMIN_APP_USERS_TITLE = '.App-Registrierungen';
lang.SUPERADMIN_APP_USERS_ADD_MSG = '.App-Registrierung hinzugefügt';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = '.App-Registrierung hinzufügen';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = '.App-Registrierung aktualisiert';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'Zufälliges Geheimnis erzeugen';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'Kopieren Sie diesen Wert, da Sie den Schlüssel nach dem Speichern und Schließen dieser App -Benutzer nicht mehr zurückziehen können';
lang.MENU_ADMIN_TITLE = 'Verwaltung';
lang.MENU_ADMIN_USER_MANAGEMENT = 'Benutzerverwaltung';
lang.MENU_ADMIN_ACTIVITY_LOG = 'Aktivitätsprotokoll';
lang.MENU_ADMIN_STOCK_ITEMS = 'Warenbestandsnummern';
lang.MENU_ADMIN_SUPPLIERS = 'Lieferanten';
lang.MENU_ADMIN_ROAD_HAULIERS = 'Spediteure';
lang.MENU_ADMIN_YARD_LOCATION = 'Lagerort';
lang.MENU_ADMIN_PRINTERS = 'Drucker';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'Lasernet -Drucker';
lang.MENU_ADMIN_DOCUMENT_TYPES = 'Dokumenttypen';
lang.MENU_ADMIN_CONFIGURATIONS = 'Konfigurationen';
lang.MENU_ADMIN_SCALES = 'Waagen';
lang.MENU_ADMIN_MATERIALS = 'Elemente';
lang.MENU_ADMIN_APPLICATION = 'Anwendung';
lang.MENU_ADMIN_DELIVERY_LIST = 'Lieferliste';
lang.MENU_ADMIN_MACHINES = 'Geräte';
lang.MENU_ADMIN_TARGET_BATCHES = 'Zielchargen';
lang.MENU_ADMIN_PACKAGING = 'Verpackung';
lang.MENU_ADMIN_ORIGINS = 'Ursprünge';
lang.MENU_ADMIN_EWCS = 'AVVen';
lang.MENU_SUPERADMIN_TENANTS = 'Mandanten';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'Laboranalyse - aktualisiert';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'Status Laboranalyse - aktualisiert';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = 'Lieferungen';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = 'Deklarierte Beschreibung';
lang.LABORATORY_ADVISEGOODS_TENANT = 'Tenant';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = 'Sortierung';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'Laborstatus';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'Laborliste';
lang.LABORATORY_ANALISYS_HEADER = 'Laboranalyse';
lang.LABORATORY_ANALISYS_MEASUREMENTS = 'Messungen';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'Status Laboranalyse - aktualisiert';
lang.LABORATORY_ANALISYS_WET_WEIGHT = 'Nassgewicht';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = 'Trockengewicht';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'Gewicht Umschmelzblock';
lang.LABORATORY_ANALISYS_YIELD = 'Yield';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = '.Spektralanalyse';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'Die Seite wurde an den Drucker gesendet';
lang.LABORATORY_PRINTER_WARN_MSG = 'Nicht unterstützter Drucker verwendet';
lang.LABORATORY_PRINTER_ERROR_MSG = 'Während des Druckens ist ein Fehler aufgetreten';
lang.LABORATORY_PRINTER_LABEL = 'Etikett';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'Keine Drucker';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = 'Die Verbindung zur Waage konnte nicht hergestellt werden';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = 'Waage - ein Fehler ist aufgetreten';
lang.LABORATORY_SCALE_FAKE_RESULT = 'Ergebnis';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'Filtern nach:';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = 'Neue Lieferung wurde empfangen';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `Lieferung ${name} wurde aktualisiert`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = 'Partie wurde beansprucht';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = 'Lieferung neu synchronisiert';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `Nach ${name} sortieren`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'Wareneingansnummern-Suche';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = 'Partie wurde beansprucht';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = 'Lieferung neu synchronisiert';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = 'Lieferung freigegeben';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = 'Lieferung abgeschlossen';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'Freigeben';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = 'Resynchronisiere Wb';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = 'Resynchronisiere Sr';
lang.DELIVERIES_DELIVERYLIST_RESP = 'Warenannehmer';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = 'Lieferung freigeben?';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `Möchten Sie <strong>${grn}</strong> freigeben?`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = 'Als abgeschlossen markieren?';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `Möchten Sie <strong>${grn}</strong> abschliessen?`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = 'Lieferung exportieren?';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `Möchten Sie <strong>${grn}</strong> exportieren?`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = '.Bestätigung der Gewichtsabweichung';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = '.Sortieren Das Gesamtgewicht stimmt nicht mit dem empfangenen Gewicht überein. Möchten Sie sich abmelden?';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'Es ist ein Fehler aufgetreten, zurück zur Lieferliste';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = 'Lieferung wurde erstellt';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = 'Hochladen fehlgeschlagen. Bitte laden Sie den Anhang erneut hoch';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = 'Vertragsnummer';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'Wareneingangsnr.';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = 'Neue Lieferung erstellen';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = 'Lieferscheinnr.';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = 'Kennzeichen';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'Containernr.';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = 'Spediteur';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = 'Keine Anhänge';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = 'Verantwortliche Personen';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = 'Erstellt von';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'Freigegeben von';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = 'Synchronisiert von';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = 'Abgeschlossen von';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = 'Konto';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = 'Dekl. Material';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = 'Dekl. Gewicht';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = '.Adv. Gewicht (brutto)';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = '.Adv. Gewicht (netto)';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'Lagerort';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = '.Bruttogewicht';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = '.Taragewicht';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'Wiegescheinnr.';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = 'Netto';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = 'Änderungen speichern?';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = 'Möchten Sie <strong>Änderungen speichern</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = 'Als abgeschlossen markieren?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = 'Möchten Sie die Lieferung als <strong>abgeschlossen </strong>markieren?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Ohne Anhänge speichern?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Möchten Sie die Lieferung <strong>ohne Anhänge speichern</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = 'Mit abgelaufenem Zertifikat speichern?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'Lieferantenzertifikat ist abgelaufen oder fehlt. Möchten Sie die Lieferung trotzdem sparen?';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = 'Ursprung';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = '.Mit abgelaufener Berechtigung speichern?';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = '.Die Autorisierung für RoadHaulier fehlt oder ist abgelaufen. Möchten Sie die Lieferung trotzdem sparen?';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = '.Art der Transaktion';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = '.Trade Payable';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = '.Transfer';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = '.Rückgabe von Ablehnungen';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = '.Leere';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'Empfohlene Warendetails';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = 'Dekl. Material';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = 'Dekl. Gewicht';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = '.Bestellnummer Seq.';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = '.Zeilennummer';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = 'Lieferung wurde aktualisiert';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = 'Anhänge erfolgreich aktualisiert!';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = 'Hochladen fehlgeschlagen. Bitte laden Sie den Anhang erneut hoch';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = 'Anlage entfernt';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = 'Anhangsbestätigung löschen';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = 'Möchten Sie diesen Anhang löschen?';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `Partie wurde aus Lieferung <strong>${deliveryGrn}<strong /> wurde angenommen`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `.Bestandscodes [${codes}] stimmen nicht mit den Bestandscodes von dOps überein. Bitte wenden Sie sich an Ihren Administrator`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = 'Lieferung löschen?';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = 'Möchten Sie die Lieferung löschen? Es wird nicht möglich sein, es in Zukunft wiederherzustellen.';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `Lieferung ${name} wurde gelöscht`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'AVV Nummer';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'Fahrer';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = 'Dekl. Partie aktualisiert';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `.Gewichte aktualisiert in: ${entity}`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = 'Dekl. Partie wurde GESPERRT';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = 'Dekl. Partie wurde ENTSPERRT';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = 'Dekl. Partie wurde abgeschlossen';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = 'Dekl. Partie wurde bestätigt';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'Die Laboranalyse wurde erfolgreich erstellt';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = 'Partie muss vor dem Erstellen des Transfers gespeichert werden';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = 'Partie muss vor dem Erstellen einer neuen Laboranalyse gespeichert werden';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = 'Partie muss vor dem Drucken gespeichert werden';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'Status zurücksetzen';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = 'Kennzeichen';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = 'Bruttogewicht';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = 'Taragewicht';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = 'Nettogewicht';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = '.Bruttogewicht erforderlich';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = 'Erm. Gewicht';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = '.Taragewicht erforderlich';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = 'Sortierung';
lang.ADVISEDGOOD_ROUTE_BALANCED = 'AutoSumme';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'zip-Datei heruntergeladen';
lang.ADVISEDGOOD_ROUTE_PICTURES = 'Bilder';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = 'Kein Bild';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = 'Bilder hochladen ...';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'Kommentar';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'Kein Kommentar';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `${hasFlag} Bestätigung`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Möchten Sie diese Partie <strong class=${className}>${hasFlag}</strong>?`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = 'Änderungen speichern?';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = 'Möchten Sie <strong>Änderungen speichern</strong>?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = 'Als abgeschlossen markieren?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = 'Möchten Sie diese dekl. Partie als <strong>Abgeschlossen</strong> kennzeichnen?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = 'Als bestätigt markieren';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = 'Möchten Sie diese dekl. Partie als <strong>Bestätigt</strong> markieren?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = '.Das Sortiergewicht ist größer als das Gewicht der LKW-Waage. Um fortzufahren, muss bestätigt werden, dass das größere Gewicht per Dezimalwaage bestimmt wurde.';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = 'Status auf angenommen zurücksetzen?';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = 'Möchten Sie den Status auf <strong>angenommen</strong> zurücksetzen?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = 'Rollback zum entladenen Status?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = 'Möchten Sie den Status auf <strong>entladen</strong> zurücksetzen?';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = 'Sortiersumme (brutto)';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = 'Sortiersumme (netto)';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = 'Bruttogewicht wurde aktualisiert';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = 'Das erhaltene Gewicht wurde aktualisiert';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'AVV nummer wurde aktualisiert';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = 'Lieferart';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = 'Bestätigen Sie den Entladeort';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = 'Wählen Sie den Entladeort aus';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = 'Entladeort';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = 'Dekl. Partie kann nicht überprüft werden, da die Sortierung insg. größer als das Erm. Gewicht ist';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = 'Annahme rückgängig machen';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = 'Möchten Sie Dekl. Partie herrlosen?';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = 'Annahme wurde rückgängig gemacht';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = '.Die empfangene Warenmenge darf nicht mehr als 10 betragen';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = '.Truck erhielt Gewicht';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = '.Truck Sortiersumme (brutto)';
lang.ADVISEDGOOD_ROUTE_PACKAGING = '.Verpackung';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = '.Keine Verpackung';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'AG abschreiben';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = 'AG abmelden?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = 'Möchten Sie AG abmelden?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG wurde erfolgreich unterzeichnet';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Ohne Anhänge speichern?';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Möchten Sie empfohlenes Gut <strong>ohne Anhänge</strong> speichern?';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = 'Möchten Sie die Artikelbeschreibung überschreiben?';
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = 'Partie - Detailansicht';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = 'Partie kann nicht übertragen werden, da das zu übertragende Gewicht größer ist als das aktuelle Gewicht';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = 'Partie wurde übertragen';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = 'Konto';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = 'Transfer';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'Laboranalyse';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'Keine Laboranalyse angefordert';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = 'Nitonanalyse';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI durchgeführt';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = 'Neue Laboranalyse erstellen';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = 'Möchten Sie eine neue Laboranalyse erstellen?';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'Laboranalyse löschen';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `Möchten Sie die Anforderung <strong>${labAnalysisName}</ strong> löschen?`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = 'Gerät';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'Zielcharge';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = 'Schüttdichte';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = 'Gefundene Qualität';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = 'Hauptform';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = 'Materialbeschreibung';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `Sie müssen vor dem speichern <strong>${emptyRequiredField}</ strong> ausfüllen`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = 'Die Partie muss vor dem Hinzufügen des Bildes gespeichert werden';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = 'Standortänderung';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = 'Zielort';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'Bemerkungen Labor';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = 'Bild löschen bestätigen';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = 'Möchten Sie dieses Bild löschen?';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = 'freies Wasser';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = 'freies Öl';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = 'freie Emulsion';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = 'freier Schnee/Eis';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = 'Kein Abzug';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = 'Abzug';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = 'Bestätigen Sie, dass die Partie entladen ist';
lang.ADVISEDGOOD_UNLOAD_TITLE = 'Entladen';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = 'Entladung abgeschlossen';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = '.Negative Gewichtsbestätigung';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = '.Das Gewicht des Materials ist negativ. Bitte bestätigen';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = 'Gut erhalten wurde FLAGGED';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = 'Gut erhalten wurde UN-FLAGGED';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Möchten Sie <strong class=${className}> ${hasFlag} </ strong> dies gut erhalten?`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = 'Kontamination';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'Andere';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = 'Keine Verunreinigung';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = 'Nichtmetall. Fremdanhaft.';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = 'Wasser (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = 'freies Öl/Emul. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = 'Schmutz (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = 'Holz (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'Kunststoff (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'Glaswolle (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = 'Brennschlacke (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'Bitumen (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = 'anhaft. Öl/Emul. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'Pappe (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = 'Metall. Fremdanhaft.';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = 'Eisen (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'Mn-Stahl (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = '10er Cr-Stahl (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = '13er Cr-Stahl (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_301 = '1.4310 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = '17er Cr-Stahl (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'Shredder (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = 'MV-Mat. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = 'Fremdmetalle';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'Al (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'Ms (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'Pb (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'Ti (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'VA/Cu-Kühler (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'SV-Mat. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = 'Späne in Abfällen';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'Cr-Stahl-Sp. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = 'nied. leg. Sp. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'VA-Späne (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = 'V2A-Späne (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = 'V4A-Späne (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = 'Unchargierfähig';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = 'Zur mobilen Schere (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = 'Zur stationären Schere (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'Zum Brennschneiden (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'Zur Presse (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = 'Kein Aggregat notw. (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = 'Enthält Hohlkörper (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = 'Andere Materialform';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = 'Pakete (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'Briketts (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'Blöcke (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = 'Feinmat./Butzen (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = 'Ausläufer (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = 'Tara (Eingang)';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = 'Gesamt (Stk.)';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'Fässer (Stk.)';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = 'Kisten (Stk.)';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'Gitterboxen (Stk.)';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = 'BigBags (Stk.)';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'Paletten (Stk.)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = 'Tara (Ausgang)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = 'Spanzusammensetzung';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = 'Magnet. Anteil';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = 'Metallspäne';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = 'Schleifspäne';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'Größen';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'Größe 0-15 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'Größe 16-35 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'Größe 36-60 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'Größe 61-80 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'Größe 81-120 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'Größe 121-150 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'Größe 150-999 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = 'Summe ist nicht 100%';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = 'Verpackung';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = 'Klassifizierung';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = 'sehr leicht';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = 'leicht';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = 'mittel';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = 'schwer';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = 'sehr schwer';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'Fe-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'gem. Cr-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'V13F-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = '1.4510-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = '1.4512-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = 'V17F-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = '1.4016-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = '1.4509-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = 'V2A-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'CrNi-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'CrNiMo-Abf.';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'Fe-Sp.';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'gem. Cr-/CrNi-Sp.';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'CrNi-Sp.';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'CrNiMo-Sp.';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'Sonstiges';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'Shredder';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = 'Abfälle';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = 'Späne';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = 'Pakete';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'Briketts';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'Blöcke';
lang.SORTINGREPORT_CLASSIFICATION_FINES = 'Feinmaterial';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = 'Müllverbrennung';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = 'Ausläufer';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = 'Abbruchschrott';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = 'Produktionschrott';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = 'Sammelschrott';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = 'Scherenschrott';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = 'Späne mit Festanteil';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = 'Bearbeitungszeit';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = 'Schneiden/Zerkleinern (h)';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'Bagger (h)';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'Cart (h)';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = 'Operator (h)';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = '.Protokoll';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = '.Tonnen';
lang.SORTINGREPORT_PROCESSING_TITLE = '.Wird bearbeitet';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = '.Operatorin';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = '.Gabelstapler';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = '.Kran';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = '.Brennerschneiden';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = '.Plasma';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = '.Kranschere';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = '.Guillotinenschere';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = '.Verdichter';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = '.Ballenpresse';
lang.SORTINGREPORT_MEASUREMENT_TITLE = 'Messung';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = 'Probe nass (g)';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = 'Probe trocken (g)';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = 'Feuchtigkeit (%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = 'Zum Schmelzen (g)';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'Barren (g)';
lang.SORTINGREPORT_MEASUREMENT_YIELD = 'Ausbeute (%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = 'Container';
lang.SORTINGREPORT_LASERNET_COPIES = 'Kopien';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} etiketten werden in die Warteschlange gestellt und 1 Minute lang gedruckt`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'Druckereinrichtungsfehler. Bitte wenden Sie sich an Ihren Administrator';
lang.STATUS_LABINPUT_PRE_SAMPLE = 'Vorbemusterung';
lang.STATUS_LABINPUT_SAMPLE = 'Probenahme';
lang.STATUS_LABINPUT_RE_SAMPLE = 'Zweitbemusterung';
lang.STATUS_LABINPUT_SORTATION = 'Sortierung';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'Nitonanalyse';
lang.STATUS_LABSTATUSES_IN_LAB = 'Im Labor';
lang.STATUS_LABSTATUSES_DRYING = 'Trocknen';
lang.STATUS_LABSTATUSES_MELTING = 'Schmelzen';
lang.STATUS_LABSTATUSES_MELTED = 'Geschmolzen';
lang.STATUS_LABSTATUSES_COMPLETE = 'Abgeschlossen';
lang.STATUS_LABSTATUSES_REQUESTED = 'Angefordert';
lang.STATUS_LABSTATUSES_SAMPLED = 'Bemustert';
lang.STATUS_LABSTATUSES_IN_PROGRESS = 'In Bearbeitung';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = 'In Bearbeitung';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = 'Bereit für Platzleitung';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'Bereit zur Freigabe';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = 'Teilweise unterschrieben';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'Freigegeben';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = 'Abgeschlossen';
lang.STATUS_ADVISEDGOOD_WAITING = 'Wartend';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'Sortierung';
lang.STATUS_ADVISEDGOOD_UNLOADED = 'Entladen';
lang.STATUS_ADVISEDGOOD_IN_LAB = 'Im Labor';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = 'Bereit für Platzleitung';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'Bereit zur Freigabe';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'Freigegeben';
lang.STATUS_ADVISEDGOOD_COMPLETE = 'Abgeschlossen';
lang.DASHBOARD_TITLES_DASHBOARD = 'Dashboard';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = 'Lieferungen in Bearbeitung';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'Lieferungen nach Status';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = 'Durchschnittliche Sortierzeit';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = 'Durchschnittliche Bearbeitungszeit';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = 'Sortierzeit für letzte Lieferungen';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'Gesperrte Lieferungen';
lang.DASHBOARD_TITLES_TREES_SAVED = 'Gerettete Bäume';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'Sortierzeit (Stunden)';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = 'Bearbeitungszeit (Stunden)';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'Sortierzeit';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `In Bearbeitung ${condition} Monate`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `In Bearbeitung ${condition} Monat`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `In Bearbeitung ${condition} Woche`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `Gekennzeichnete Artikel ${condition} Monate`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `Gekennzeichnete Artikel ${condition} Monat`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `Gekennzeichnete Artikel ${condition} Woche`;
lang.USA_CONFIGURATION_CONFIGURATION = 'Konfiguration';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = 'Vertragsnummer anzeigen';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = 'Detail der dekl. Ware anzeigen';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = 'Tara der dekl. Ware anzeigen';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = 'Lagerort der dekl. Ware anzeigen';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = 'Brutto/Tara/Netto der Lieferung anzeigen';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = 'Art der Lieferung anzeigen';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = 'Radioaktivitätsmessung hochladen';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = 'Zeige Wetterverhältnisse';
lang.USA_CONFIGURATION_STOCK_CODE = 'Format des Materialkontos';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = 'Toleranz der LKW-Waage';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = 'Der Upload der Radioaktivitätsmessung ist fehlgeschlagen. Bitte laden Sie diese erneut hoch.';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = 'Radioaktivitätsmessung ist erforderlich';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = 'Wetterverhältnisse';
lang.USA_CONFIGURATION_DELIVERY_TYPE = 'Lieferart';
lang.USA_CONFIGURATION_GROSS = 'Brutto';
lang.USA_CONFIGURATION_TARE = 'Tara';
lang.USA_CONFIGURATION_NET = 'Netto';
lang.USA_CONFIGURATION_RADIATION_FILE = 'Radioaktivitätsmessung';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = 'Keine Radioaktivitätsmessung';
lang.USA_CONFIGURATION_ADV_TARE = 'Dekl. Tara';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = '.Neue Lieferung erstellt';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = '.Lieferung zur Abmeldung bereit';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = '.Lieferung abgemeldet';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = '.Lieferung durch Händler abgeschlossen';

export default lang;
