import { action, autorun, computed, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { cloneObj, colorArray, getTranslation } from 'util/helpers';
import * as moment from 'moment';
import { I18N } from '../../../assets/i18n/i18n';
import RootService from 'services/RootService';
import { ChartLegendOptions, ChartOptions, ChartTooltipItem } from 'chart.js';
import UserModel from 'models/UserModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface ISortingTime {
  grn: string;
  sortingTime: number;
  user: UserModel<IUserModelConstructObj>;
}

interface IDeliveryByStatus {
  [key: string]: number;
}

interface IAverageSortingTime {
  month: number;
  avgSortingTime: number;
  year: number;
}

interface IAverageTurnAroundTime {
  month: number;
  avgTurnaroundTime: number;
  year: number;
}

export interface IDashboardResponse {
  averageSortingTimePerMonth: IAverageSortingTime[];
  averageTurnaroundTimePerMonth: IAverageTurnAroundTime[];
  deliveriesByStatus: IDeliveryByStatus;
  totalDeliveriesInProgress: number;
  totalFlaggedAdvisedGoods: number;
  deliveriesSortingTime: ISortingTime[];
  deliveriesInProgressMoreThan3Months: number;
  deliveriesInProgressMoreThan1Month: number;
  deliveriesInProgressLessThan1Month: number;
  deliveriesInProgressLessThan1Week: number;
  advisedGoodsFlaggedMoreThan3Months: number;
  advisedGoodsFlaggedMoreThan1Month: number;
  advisedGoodsFlaggedLessThan1Month: number;
  advisedGoodsFlaggedLessThan1Week: number;
}

export default class DashboardStore extends UpdateModel<DashboardStore | IDashboardResponse, undefined, RootService> {
  public constructor(private readonly rootService: RootService) {
    super();
    autorun(() => {
      moment.locale(this.rootService.translateService.language);
    });
  }

  @observable
  public totalDeliveriesInProgress: number = null;

  @observable
  public deliveriesInProgressMoreThan3Months: number = null;

  @observable
  public deliveriesInProgressMoreThan1Month: number = null;

  @observable
  public deliveriesInProgressLessThan1Month: number = null;

  @observable
  public deliveriesInProgressLessThan1Week: number = null;

  @observable
  public deliveriesByStatus: IDeliveryByStatus = null;

  @observable
  public averageSortingTimePerMonth: IAverageSortingTime[] = null;

  @observable
  public averageTurnaroundTimePerMonth: IAverageTurnAroundTime[] = null;

  @observable
  public deliveriesSortingTime: ISortingTime[] = null;

  @observable
  public totalFlaggedAdvisedGoods: number = null;

  @observable
  public advisedGoodsFlaggedMoreThan3Months: number = null;

  @observable
  public advisedGoodsFlaggedMoreThan1Month: number = null;

  @observable
  public advisedGoodsFlaggedLessThan1Month: number = null;

  @observable
  public advisedGoodsFlaggedLessThan1Week: number = null;

  @observable
  public isLoading: boolean = false;

  @computed
  public get isDataExisted(): boolean {
    return !!(
      this.deliveriesByStatus ||
      this.totalDeliveriesInProgress ||
      this.averageSortingTimePerMonth ||
      this.averageTurnaroundTimePerMonth ||
      this.deliveriesSortingTime ||
      this.totalFlaggedAdvisedGoods
    );
  }

  @computed
  public get formattedDeliveryByStatus(): Chart.ChartData {
    if (!this.deliveriesByStatus) {
      return null;
    }

    return Object.keys(this.deliveriesByStatus).reduce(
      (res: Chart.ChartData, status: string) => {
        res.labels.push(
          getTranslation(
            `STATUS_DELIVERYSTATUSES_${status}` as keyof I18N,
            this.rootService.translateService.t
          ) as string
        );
        res.datasets[0].data.push(this.deliveriesByStatus[status]);

        return res;
      },
      {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: colorArray,
            hoverBackgroundColor: colorArray,
          },
        ],
      }
    );
  }

  @computed
  public get formattedAverageSortingLeadTimePerMonth(): Chart.ChartData {
    if (!this.averageSortingTimePerMonth) {
      return null;
    }

    return this.averageSortingTimePerMonth.reduce((res: Chart.ChartData, avgTime: IAverageSortingTime) => {
      res.labels.push(`${moment(avgTime.month, 'M').format('MMM')} ${avgTime.year}`);

      res.datasets[0].data.push(this._convertTimeNumberToHours(avgTime.avgSortingTime));

      return res;
    }, this._createFormattedTimeData(this.rootService.translateService.t.DASHBOARD_TITLES_AVERAGE_SORTING_TIME));
  }

  @computed
  public get formattedAverageTurnAroundTimePerMonth(): Chart.ChartData {
    if (!this.averageTurnaroundTimePerMonth) {
      return null;
    }

    return this.averageTurnaroundTimePerMonth.reduce((res: Chart.ChartData, avgTime: IAverageTurnAroundTime) => {
      res.labels.push(`${moment(avgTime.month, 'M').format('MMM')} ${avgTime.year}`);

      res.datasets[0].data.push(this._convertTimeNumberToHours(avgTime.avgTurnaroundTime));

      return res;
    }, this._createFormattedTimeData(this.rootService.translateService.t.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME));
  }

  @computed
  public get formattedDeliveriesSortingTime(): Chart.ChartData {
    if (!this.deliveriesSortingTime) {
      return null;
    }

    return this.deliveriesSortingTime.reduce((res: Chart.ChartData, sorting: ISortingTime, index: number) => {
      res.labels.push((index + 1).toString());

      res.datasets[0].data.push(this._convertTimeNumberToHours(sorting.sortingTime));
      return res;
    }, this._createFormattedTimeData(this.rootService.translateService.t.DASHBOARD_TITLES_SORTING_TIME_TREND));
  }

  public get averageSortingTimePerMonthOptions(): ChartOptions {
    return {
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.rootService.translateService.t.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL,
            },
          },
        ],
      },
    };
  }

  public get averageSortingTurnaroundTimePerMonthOptions(): ChartOptions {
    return {
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.rootService.translateService.t.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL,
            },
          },
        ],
      },
    };
  }

  public get lastDeliveriesLeadTimeOptions(): ChartOptions {
    return {
      tooltips: {
        enabled: true,
        backgroundColor: 'black',
        mode: 'single',
        bodyFontColor: 'white',
        yPadding: 5,
        xPadding: 5,
        cornerRadius: 4,
        titleFontStyle: 'normal',
        callbacks: {
          title: (item: ChartTooltipItem[]) =>
            `${this.rootService.translateService.t.GLOBAL_LABEL_GRN}: ${this.deliveriesSortingTime[item[0].index].grn}`,
          afterTitle: (item: ChartTooltipItem[]) =>
            `${this.rootService.translateService.t.GLOBAL_LABEL_RESPONSIBLE}: ${
              this.deliveriesSortingTime[item[0].index].user.fullNameWithShortName
            }`,
          label: (item: ChartTooltipItem) =>
            `${
              this.rootService.translateService.t.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP
            }: ${this._convertTimeNumberToHoursAndMinutes(this.deliveriesSortingTime[item.index].sortingTime)}`,
        },
      },
      scales: this.averageSortingTimePerMonthOptions.scales,
    };
  }

  public get doughnutLegendOptions(): ChartLegendOptions {
    return {
      labels: {
        fontSize: 10,
        padding: 5,
      },
    };
  }

  @action
  public getDashboardData = (tenantId?: string) => {
    this._changeIsLoading(true);
    this._getDashboardDataRequest(tenantId).then((res: IDashboardResponse) => {
      this.update(res);
      this._changeIsLoading(false);
    });
  };

  @action
  public update = (obj: IDashboardResponse) => {
    const newDashboardStore = cloneObj(obj);

    if (newDashboardStore && newDashboardStore.deliveriesSortingTime) {
      newDashboardStore.deliveriesSortingTime = newDashboardStore.deliveriesSortingTime.map((sorting: ISortingTime) => {
        sorting.user = new UserModel<IUserModelConstructObj>().update(sorting.user);
        return sorting;
      });
    }

    this.updater.update(this, obj, DashboardStore, this.rootService);
    return this;
  };

  private _getDashboardDataRequest = (tenantId?: string): Promise<IDashboardResponse> => {
    return this.rootService.ajaxService.get(`dashboard${tenantId ? `/manager/${tenantId}` : ''}`);
  };

  @action
  private _changeIsLoading = (newValue: boolean) => (this.isLoading = newValue);

  private _createFormattedTimeData = (label: string): Chart.ChartData => ({
    labels: [],
    datasets: [
      {
        data: [],
        label,
        backgroundColor: 'rgba(0,128,0,0.2)',
        borderColor: 'rgba(0,128,0,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(0,128,0,0.4)',
        hoverBorderColor: 'rgba(0,128,0,1)',
      },
    ],
  });

  private _convertTimeNumberToHours = (time: number): number => {
    // we get time in milliseconds and convert it to hours with 2 decimals
    return parseFloat(moment.duration(time).asHours().toFixed(2));
  };

  private _convertTimeNumberToHoursAndMinutes = (time: number): string => {
    // we get time in milliseconds and convert it to hours with minutes
    const d = moment.duration(time);
    const hours = Math.floor(d.asHours());
    const minutes = Math.floor(d.asMinutes()) - hours * 60;
    return `${hours}h ${minutes}m`;
  };
}
