import * as React from 'react';
import * as classnames from 'classnames';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import ViewStore from 'stores/ViewStore';
import RoadHaulierModel from 'models/RoadHaulierModel';
import TranslateService from 'services/TranslateService';
import RoadHaulierDetailRoute from 'pod/admin/road-hauliers/RoadHaulierDetailRoute';
import NavlinkCustom from 'domain/NavlinkCustom';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import RoadHaulierDetailComponent from 'pod/admin/road-hauliers/RoadHaulierDetailComponent';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import DateFormattingService from 'services/DateFormattingService';

interface IProps {
  adminStore?: AdminStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  dateFormattingService?: DateFormattingService;
}

@inject('adminStore', 'viewStore', 'translateService', 'dateFormattingService')
@observer
export default class RoadHaulierListRoute extends React.Component<IProps> {
  @observable public content: RoadHaulierModel = new RoadHaulierModel();

  @action
  public clearModel = () => {
    this.content = new RoadHaulierModel();
  };

  public get roadHaulierList() {
    const {
      translateService: { t },
      viewStore: { isIT },
      adminStore: { roadHaulierListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.GLOBAL_LABEL_NAME}</th>
            {isIT && (
              <React.Fragment>
                <th data-test="authorization-number-label">{t.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER}</th>
                <th data-test="cert-exp-label">{t.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE}</th>
              </React.Fragment>
            )}
            <th className="pl-3">{t.GLOBAL_LABEL_CODE}</th>
          </tr>
        </thead>
        {roadHaulierListActiveFirst.map((roadHaulier) => this._renderItem(roadHaulier))}
      </table>
    );
  }

  public get roadHaulierForm() {
    return <RoadHaulierDetailComponent content={this.content} />;
  }

  public render() {
    const {
      adminStore: { roadHaulierApi },
      translateService: { t },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={roadHaulierApi}
        rootPath={AdminRootPath.ROAD_HAULIERS}
        itemsTable={this.roadHaulierList}
        itemCreateForm={this.roadHaulierForm}
        componentRoute={RoadHaulierDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_ROAD_HAULIER}
        exampleFileNames={ExampleFileNames.ROAD_HAULIERS}
        header={t.MENU_ADMIN_ROAD_HAULIERS}
      />
    );
  }

  private _renderItem = (roadHaulier: RoadHaulierModel) => {
    const {
      viewStore: { isIT },
      dateFormattingService: { formatDate },
    } = this.props;
    return (
      <tbody key={roadHaulier.id}>
        <NavlinkCustom
          to={`/admin/road-hauliers/${roadHaulier.id}`}
          tagName="tr"
          key={roadHaulier.id}
          className={classnames(['pointer', { 'bg-secondary': !roadHaulier.active }])}
        >
          <td className="pl-3" data-test="haulier-name">
            {roadHaulier.name}
          </td>
          {isIT && (
            <React.Fragment>
              <td data-test="authorization-number-data">{roadHaulier.authorizationNumber}</td>
              <td data-test="cert-exp-data">{formatDate(roadHaulier.formattedExpirationDate)}</td>
            </React.Fragment>
          )}
          <td className="pl-3" data-test="haulier-code">
            {roadHaulier.code}
          </td>
        </NavlinkCustom>
      </tbody>
    );
  };
}
