import * as React from 'react';
import { Button, Col, Form, FormGroup, Label } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';

import TranslateService from 'services/TranslateService';
import {
  hasCommonActive,
  hasCommonItemCode,
  hasCommonItemName,
  hasValidation,
  TAdminCommonItem,
  TAdminCommonItemWithActive,
} from 'pod/admin/AdminStore';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import { TStockItemModelValidatorKey } from 'models/StockItemModel';
import { instance as notification } from 'util/notification';
import { joinWithCommaSeparator } from 'util/helpers';
import { BORDER_DANGER } from 'util/constants';
import ViewStore from 'stores/ViewStore';
import { TLasernetPrinterConfigurationValidatorKey } from 'models/LasernetPrinterConfigurationModel';

interface ICommonAdminDetailComponentDefaultProps {
  limit?: number;
  isCodeRequired?: boolean;
}

interface ICommonAdminDetailComponentProps extends ICommonAdminDetailComponentDefaultProps {
  content: TAdminCommonItem;
  saveAction: (item: TAdminCommonItem) => void;
  children?: React.ReactNode;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  toggleHideConfirmationModal?: () => void;
  toggleUnhideConfirmationModal?: () => void;
}

@inject('viewStore', 'translateService')
@observer
export default class CommonAdminDetailComponent extends React.Component<ICommonAdminDetailComponentProps> {
  public static defaultProps: ICommonAdminDetailComponentDefaultProps = {
    limit: 50,
    isCodeRequired: true,
  };

  public render() {
    const {
      translateService: { t },
      content,
      children,
      limit,
      isCodeRequired,
    } = this.props;

    return content ? (
      <Form onSubmit={this._submitForm}>
        {hasCommonItemName(content) && (
          <FormGroup row>
            <Label for="name" sm={4}>
              {t.GLOBAL_LABEL_NAME}
            </Label>
            <Col sm={8}>
              <InputWithLimitOfCharacters
                value={content.name}
                name="name"
                id="name"
                maxLength={limit}
                required
                onChange={this._changeName}
                type="text"
                data-test="name-input"
              />
            </Col>
          </FormGroup>
        )}
        {hasCommonItemCode(content) && (
          <FormGroup row>
            <Label for="code" sm={4}>
              {t.GLOBAL_LABEL_CODE}
            </Label>
            <Col sm={8}>
              <InputWithLimitOfCharacters
                value={content.code}
                className={classnames({ [BORDER_DANGER]: this._isCodeInvalid })}
                name="code"
                id="code"
                maxLength={limit}
                required={isCodeRequired}
                onChange={this._changeCode}
                type="text"
                data-test="code"
              />
            </Col>
          </FormGroup>
        )}
        {children}
        <div className="clearfix">
          {this._renderHideUnhideButton}
          <Button type="submit" className="pull-right" color="success" data-test="save-btn">
            {t.GLOBAL_LABEL_SAVE}
          </Button>
        </div>
      </Form>
    ) : null;
  }

  private get _getValidationMessageMap(): Map<
    TStockItemModelValidatorKey | TLasernetPrinterConfigurationValidatorKey,
    string
  > {
    const {
      translateService: { t },
    } = this.props;
    return new Map<TStockItemModelValidatorKey | TLasernetPrinterConfigurationValidatorKey, string>([
      ['code', t.GLOBAL_LABEL_CODE],
      ['printerId', t.ADMIN_LASERNET_PRINTER_ID],
    ]);
  }

  private get _isCodeInvalid() {
    const { content } = this.props;
    return hasValidation(content) && content.needToCheck && !content.isValidByKey('code');
  }

  private _changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasCommonItemName(this.props.content)) {
      this.props.content.changeName(e.target.value);
    }
  };

  private _changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasCommonItemCode(this.props.content)) {
      this.props.content.changeCode(e.target.value);
    }
  };

  private _submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      content,
      translateService: { t },
      viewStore: { countryCode },
    } = this.props;

    if (hasValidation(content) && !content.isValid(countryCode)) {
      content.setNeedToCheck(true);
      const invalidKeyLabels: string[] = content.invalidValidatorKeys
        .get(countryCode)
        .map((key: TStockItemModelValidatorKey) => this._getValidationMessageMap.get(key));
      notification.error(t.GLOBAL_LABEL_INVALID_FIELDS(joinWithCommaSeparator(invalidKeyLabels)));
      return;
    }
    this.props.saveAction(this.props.content);
  };

  private get _renderHideUnhideButton() {
    if (hasCommonActive(this.props.content)) {
      return this._hideUnhideButtonsTemplate;
    }
    return null;
  }

  private get _hideUnhideButtonsTemplate() {
    const {
      toggleHideConfirmationModal,
      toggleUnhideConfirmationModal,
      translateService: { t },
    } = this.props;
    if (!toggleHideConfirmationModal || !toggleUnhideConfirmationModal) {
      return null;
    }
    return (this.props.content as TAdminCommonItemWithActive).active ? (
      <Button
        data-test="hide-button"
        type="button"
        color="danger"
        onClick={toggleHideConfirmationModal}
        className="pull-left"
      >
        {t.GLOBAL_LABEL_HIDE}
      </Button>
    ) : (
      <Button
        data-test="unhide-button"
        type="button"
        color="success"
        onClick={toggleUnhideConfirmationModal}
        className="pull-left"
      >
        {t.GLOBAL_LABEL_UNHIDE}
      </Button>
    );
  }
}
