import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import TranslateService from 'services/TranslateService';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import { AdminRootPath } from 'util/enums';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetPrinterModel from 'models/LasernetPrinterModel';
import LasernetPrinterDetailComponent from 'pod/admin/lasernetPrinters/printers/LasernerPrinterDetailComponent';
import LasernetPrinterDetailRoute from 'pod/admin/lasernetPrinters/printers/LasernetPrinterDetailRoute';

interface IProps {
  translateService?: TranslateService;
  lasernetPrinterStore?: LasernetPrinterStore;
}

@inject('lasernetPrinterStore', 'translateService')
@observer
export default class LasernetPrinterListRoute extends React.Component<IProps> {
  @observable public content: LasernetPrinterModel = new LasernetPrinterModel();

  @action
  public clearModel = () => {
    this.content = new LasernetPrinterModel();
  };

  public get printerList() {
    const {
      translateService: { t },
      lasernetPrinterStore: { printerListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="pl-3">{t.ADMIN_PRINTERLIST_NAME}</th>
            <th>{t.GLOBAL_LABEL_DESCRIPTION}</th>
            <th className="pr-3">{t.GLOBAL_LABEL_CONNECTION_ADDRESS}</th>
          </tr>
        </thead>
        {printerListActiveFirst.map((printer: LasernetPrinterModel) => this._renderItem(printer))}
      </table>
    );
  }

  public get printerForm() {
    return <LasernetPrinterDetailComponent content={this.content} />;
  }

  public render() {
    const {
      translateService: { t },
      lasernetPrinterStore: { printerApi },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={printerApi}
        rootPath={AdminRootPath.LASERNET_PRINTERS}
        itemsTable={this.printerList}
        itemCreateForm={this.printerForm}
        componentRoute={LasernetPrinterDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_PRINTER}
        header={t.MENU_ADMIN_PRINTERS}
        hasUploadBtn={false}
      />
    );
  }

  private _renderItem = (printer: LasernetPrinterModel) => (
    <tbody key={printer.id}>
      <NavlinkCustom
        to={`/admin/lasernet-printers/${printer.id}`}
        tagName="tr"
        key={printer.id}
        className={classnames(['pointer', { 'bg-secondary': !printer.active }])}
      >
        <td className="pl-3">{printer.name}</td>
        <td>{printer.description}</td>
        <td className="pr-3">{printer.address}</td>
      </NavlinkCustom>
    </tbody>
  );
}
