import { action, computed, observable } from 'mobx';
import AdvisedGoodsModel, { IAdvisedGoodsParams } from 'models/AdvisedGoodsModel';
import UpdateModel from 'models/UpdateModel';
import MaterialModel from 'models/MaterialModel';
import { cloneObj } from 'util/helpers';

interface IAllWeightsParams extends IAdvisedGoodsParams {}

export default class AllWeightsModel extends UpdateModel<AllWeightsModel, MaterialModel[], IAllWeightsParams> {
  public constructor(public readonly allWeightsParams: IAllWeightsParams) {
    super();
  }

  @observable public name?: string = null;
  @observable public id?: string = null;
  @observable public grossWeight?: number = null;
  @observable public tareWeight?: number = null;
  @observable public advisedGoods?: AdvisedGoodsModel[] = [];

  @computed
  public get truckSortingTotal(): number {
    return this.advisedGoods.length
      ? this.advisedGoods.reduce((res, ag) => res + ag.totalAdvisedGoodGrossWeight, 0)
      : null;
  }

  @computed
  public get truckReceivedWeight(): number {
    return this.grossWeight && this.tareWeight ? this.grossWeight - this.tareWeight : null;
  }

  @action
  public update = (obj: AllWeightsModel, defaultMaterials: MaterialModel[], nonDefaultMaterial: MaterialModel[]) => {
    const newAllWeightsModel = cloneObj(obj);

    if (newAllWeightsModel && newAllWeightsModel.advisedGoods && newAllWeightsModel.advisedGoods.length) {
      newAllWeightsModel.advisedGoods = newAllWeightsModel.advisedGoods.map((ag) =>
        new AdvisedGoodsModel({ ...this.allWeightsParams }).update(ag, defaultMaterials, nonDefaultMaterial)
      );
    }

    this.updater.update(this, newAllWeightsModel, AllWeightsModel, this.allWeightsParams);
    return this;
  };
}
